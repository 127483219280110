import "./add-survy.scss";
import {createContext, FunctionComponent, useEffect, useState} from "react";
import {Button, Form, Layout, message, Modal} from "antd";
import PageHeader from "../../../components/page-header/PageHeader";
import {PlayCircleOutlined, SaveOutlined, SettingOutlined,} from "@ant-design/icons";
import {useAddLesson} from "../../../stores/stores";
import {routesConfig} from "../../../config/routes";
import SettingLessonCourse
  from "../../../components/course-construction/content-course-construction/setting-lesson-course";
import {
  createMoocCourseBlockQuiz,
  createMoocCourseBlockQuizConnect,
  updateMoocCourseBlockQuiz,
  updateMoocCourseBlockQuizConnect,
} from "../../../service/course-plan-managment/mooc-course-block-quiz/mooc-course-block-quiz";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {HelpService} from "../../../service/helper.service";
import {mapAnsFEtoBE} from "../../../utils/arrays";
import {ModeQuestion, QuestionProgressModeClick, QuestionTypeEnum, TypeCreateQuestion,} from "../../../constants";
import {get, isEmpty} from "lodash";
import {useDirty} from "../../../contexts/DirtyProvider";
import SafeLink from "../../../components/link/SafeLink";
import {usePreventRouteChange} from "../../../hooks/usePreventRouteChange";
import {useWatch} from "antd/es/form/Form";
import {convertQuillToString} from "../../../utils/convertQuillToString";
import {getIdIndexDB, saveToIndexDB} from "../../page-builder/grapesjs-service";
import {
  getConfigBlockQuizAction,
  getConfigBlockQuizEvaluate,
  getConfigBlockQuizGeneral
} from "../../../service/course-construct/settingLessonCourse";
import AddSurvyCourse from "./add-survy-core/add-survy-core";

const {Content} = Layout;
const imgPath = "/images/";

type SurveyPlanPageProps = {
  isDisplaySetting?: boolean;
}

const ReachableContext = createContext<Partial<{ timeToPlay: string | null, completeTime: string | null }>>({
  timeToPlay: null,
  completeTime: null
});

const config = {
  title: 'Cảnh báo',
  okText: 'Tiếp tục chỉnh sửa',
  className: "modal-warning--submit_lesson",
  content: (
    <>
      <p>Thời gian thực hiện câu hỏi đang vượt quá thời gian thực hiện đánh giá.</p>
      <ReachableContext.Consumer>{(name: any) => {
        return (
          <ul>
            <li>Tổng thời gian thực hiện câu hỏi : {name.timeToPlay} phút</li>
            <li>Thời gian thực hiện đánh giá: {name.completeTime} phút</li>
          </ul>
        )
      }}</ReachableContext.Consumer>
    </>
  ),
};

const AddSurvyPage: FunctionComponent<SurveyPlanPageProps> = (props: any) => {
  const {isDisplaySetting} = props;
  const onRefreshQuizBlock = useAddLesson((state) => state.onRefreshQuizBlock);
  const modeQuestion = useAddLesson((state) => state.modeQuestion);
  const reviewList = useAddLesson((state) => state.reviewList);
  const settingQuestion = useAddLesson((state) => state.settingQuestion);
  const currentQuestionInfo = useAddLesson((state) => state.currentQuestionInfo);
  const question = useAddLesson((state) => state.question);
  const typeCreateQuestion = useAddLesson((state) => state.typeCreateQuestion);
  const setModeClick = useAddLesson((state) => state.setModeClick);
  const settingPopupQuestion = useAddLesson((state) => state.settingPopupQuestion);
  const setCurrentIdQuestionAdd = useAddLesson((state) => state.setCurrentIdQuestionAdd);
  const setDefaultTimeToPlay = useAddLesson((state) => state.setDefaultTimeToPlay);

  const [settingLessonModal, setSettingLessonModal] = useState<any>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [currentParentCourse, setCurrentParentCourse] = useState<string>();
  const [currentCourse, setCurrentCourse] = useState<any>();
  const [courseBlockId, setCourseBlockId] = useState<number>();
  const [generalSettingValue, setgeneralSettingValue] = useState<any>();
  const [actionSettingValue, setActionSettingValue] = useState<any>();
  const [evaluateSettingValue, setEvaluateSettingValue] = useState<any>();

  const helpService = new HelpService();
  const location = useLocation();
  const navigate = useNavigate();
  const {setDirty} = useDirty();
  const {confirmNavigation} = usePreventRouteChange();
  const [modal, contextHolder] = Modal.useModal();
  const params = useParams();
  const [form] = Form.useForm();
  const valueCompletionTime = useWatch('completionTime', form);
  const searchParams = new URLSearchParams(location.search);
  const unitId = searchParams.get('unitId');

  useEffect(() => {
    getIdIndexDB('current-course', (val: any) => {
      setCurrentParentCourse(val.parent);
      setCurrentCourse({id: val.id, name: val.name});
    });
  }, []);

  useEffect(() => {
    setCurrentCourse({id: reviewList.id, name: reviewList.name});
  }, [reviewList]);

  useEffect(() => {
    if (courseBlockId) {
      Promise.all([
        getConfigBlockQuizGeneral(courseBlockId),
        getConfigBlockQuizAction(courseBlockId),
        getConfigBlockQuizEvaluate(courseBlockId),
      ]).then((res) => {
        setgeneralSettingValue(res[0].data.data);
        setActionSettingValue(res[1].data.data);
        setEvaluateSettingValue(res[2].data.data);
      })
    }
  }, [courseBlockId]);

  useEffect(() => {
    if (actionSettingValue) {
      const time = actionSettingValue.timeForQuiz;
      setDefaultTimeToPlay(time)
    }
  }, [actionSettingValue]);

  useEffect(() => {
    getIdIndexDB("courseBlockId", (val: any) => {
      if (val) {
        setCourseBlockId(JSON.parse(val));
      }
    });
  }, []);

  const createQuiz = async (dataReq: any) => {
    try {
      setLoadingSubmit(true);
      let apiRespone: any;
      if (question.type === QuestionTypeEnum.CONNECT) {
        apiRespone = await createMoocCourseBlockQuizConnect(dataReq);
      } else {
        apiRespone = await createMoocCourseBlockQuiz(dataReq);
      }
      const dataRespone = apiRespone.data;
      setModeClick(QuestionProgressModeClick.END);
      setCurrentIdQuestionAdd(dataRespone.body.id);
      saveToIndexDB('current-course', {
        currentId: dataReq.id,
        id: dataReq.id,
        name: currentCourse.name,
        parent: currentParentCourse,
      });
      helpService.successMessage(dataRespone.body.message);
      onRefreshQuizBlock();
    } catch (err: any) {
      helpService.errorMessage(err?.message || "Tạo mới thất bại");
      console.error(err);
    } finally {
      setTimeout(() => setLoadingSubmit(false), 1000);
    }
  };

  const updateQuiz = async (dataReq: any) => {
    try {
      setLoadingSubmit(true);
      if (question.type === QuestionTypeEnum.CONNECT) {
        await updateMoocCourseBlockQuizConnect(dataReq);
      } else {
        await updateMoocCourseBlockQuiz(dataReq);
      }
      setModeClick(QuestionProgressModeClick.END);
      helpService.successMessage("Cập nhật câu hỏi thành công");
      saveToIndexDB('current-course', {
        currentId: dataReq.id,
        id: dataReq.id,
        name: currentCourse.name,
        parent: currentParentCourse,
      });
      onRefreshQuizBlock();
    } catch (err: any) {
      helpService.errorMessage(err?.message || "Cập nhật thất bại");
      console.error(err);
    } finally {
      setTimeout(() => setLoadingSubmit(false), 1000);
    }
  }

  // Hàm để loại bỏ các thẻ HTML
  const stripHtml = (html: string) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

  const handleSave = () => {
    let payload: any;
    if (typeCreateQuestion === TypeCreateQuestion.COURSEWARE) {
      payload = {
        id: question.id,
        orderNumber: 1,
        courseBlockGroupId: question.courseBlockGroupId,
        type: question.type,
        question: currentQuestionInfo.questionName,
        questionValue: convertQuillToString(currentQuestionInfo.questionName),
        answers: mapAnsFEtoBE(currentQuestionInfo, question.type as number),
        reminderText: currentQuestionInfo.reminderText,
        settingGeneral: {
          showForViewer: settingQuestion.isVisibleToViewers,
          requiredAnswer: settingQuestion.isRequiredAnswer,
          haveCorrectAnswer: settingQuestion.canEnterAnswer,
          isAutoMark: settingQuestion.canEnterAnswer,
          shuffleQuestion: settingQuestion.isRandomOrder,
          fileQuantity: settingQuestion.fileQuantity,
          fileCapacity: settingQuestion.fileCapacity,
          numberingType: settingQuestion.markNumber,
          timeToPlay: settingPopupQuestion.defaultQuestion ? settingQuestion.timeToPlay : 0,
          displayScoreCriteria: settingQuestion.displayScoreCriteria,
        },
        settingPoint: {
          isPerQuiz: settingQuestion.isSetScore4EachAns,
          correct: settingQuestion.scoreOfCorrect,
          incorrect: settingQuestion.scoreOfInCorrect,
        },
        settingHint: {
          isDisplayInstruction: settingQuestion.isShowGuide,
          content: settingQuestion.hintContent,
          uri: settingQuestion.uri,
          schema: settingQuestion.schema,
        },
        settingResponse: {
          isDisplayPerResponse: settingQuestion.isFeedback4EachAns,
          correct: settingQuestion.correctAns,
          incorrect: settingQuestion.incorrectAns,
          notYet: settingQuestion.notAnswer,
        },
      };
    }
    if (typeCreateQuestion === TypeCreateQuestion.SURVEY) {
      payload = {
        id: question.id,
        orderNumber: 1,
        courseBlockGroupId: question.courseBlockGroupId,
        type: question.type,
        question: currentQuestionInfo.questionName,
        answers: mapAnsFEtoBE(currentQuestionInfo, question.type as number),
        settingGeneral: {
          showForViewer: settingQuestion.isVisibleToViewers,
          requiredAnswer: settingQuestion.isRequiredAnswer,
          numberingType: settingQuestion.markNumber,
          fileQuantity: settingQuestion.fileQuantity,
          fileCapacity: settingQuestion.fileCapacity,
        },
      };
    }
    const strippedQuestionName = stripHtml(currentQuestionInfo.questionName).trim();
    const checkArr = payload.answers.some((i: any) => (isEmpty(i.filePath)));
    if ((QuestionTypeEnum.CHOOSE_VIDEO === payload.type ||
      QuestionTypeEnum.CHOOSE_MULTI_VIDEO === payload.type ||
      QuestionTypeEnum.CHOOSE_IMAGE === payload.type ||
      QuestionTypeEnum.CHOOSE_MULTI_IMAGE === payload.type) && checkArr && payload.answers.length !== 0) {
      message.error('Không được bỏ trống các giá trị file và nội dung');
    } else if (strippedQuestionName.length === 0) {
      message.error("Không được bỏ trống nội dung câu hỏi")
    } else {
      setDirty(false);
      if (modeQuestion === ModeQuestion.CREATE) {
        createQuiz(payload)
      }
      if (modeQuestion === ModeQuestion.UPDATE) {
        updateQuiz(payload)
      }
    }
  };

  const saveSettingLesson = () => {
    form.submit();
    setSettingLessonModal(false);
  };

  const listBreadcrumb = [
    {
      title: "Xây dựng khoá học",
    },
    {
      title: (
        <>
          {typeCreateQuestion === TypeCreateQuestion.COURSEWARE ? (
            <SafeLink to={`/${routesConfig.courseConstruction}/${routesConfig.contentConstruction}`}>
              <span>Xây dựng Nội dung</span>
            </SafeLink>
          ) : (<SafeLink to={`/${routesConfig.courseConstruction}/${routesConfig.surveyPlan}`}>
            <span>Kế hoạch khảo sát</span>
          </SafeLink>)}
        </>
      ),
    },
    {
      title:
        <a onClick={() => {
          confirmNavigation({link: ''}, false, () => {
            navigate(-1);
          });
        }}>
          {currentParentCourse ? currentParentCourse : 'Chưa có tên'}
        </a>
    },
    {
      title: get(currentCourse, 'name', 'Chưa có tên'),
    },
  ];

  const checkSubmit = () => {
    if (valueCompletionTime * 60 < get(settingQuestion, 'timeToPlay', 0)) {
      modal.warning(config)
    }
    if (valueCompletionTime * 60 > get(settingQuestion, 'timeToPlay', 0) || valueCompletionTime === undefined || get(settingQuestion, 'timeToPlay', 0) === 0) {
      handleSave()
    }
  }

  return (
    <>
      <ReachableContext.Provider value={{
        timeToPlay: (Math.floor(get(settingQuestion, 'timeToPlay', 0) / 60)).toString(),
        completeTime: valueCompletionTime ? valueCompletionTime.toString() : '',
      }}>
        <Content className='add-lesson'>
          <PageHeader
            title={get(currentCourse, 'name', 'Chưa có tên')}
            subTitle=''
            breadcrumb={true}
            listBreadcrumb={listBreadcrumb}
            arrowLeft={imgPath + 'arrowLeft.svg'}
            positionItem='column'
            headerGroupButton={true}
            childrenGroupButton={
              <div className='button-container'>
                <Button
                  icon={<PlayCircleOutlined/>}
                  loading={loadingSubmit}
                  onClick={() => {
                    confirmNavigation({link: ''}, false, () => {
                      saveToIndexDB('current-course', {
                        parent: currentParentCourse,
                        name: currentCourse.name,
                        currentId: question.id,
                        backUrl: `${location.pathname}${location.search}`,
                        backBreadCrumb: currentParentCourse,
                        selectedQuizz: question
                      });
                      window.open(`/${routesConfig.courseConstruction}/${routesConfig.previewPlan}?unitId=${unitId}`, '_blank');
                    });
                  }}
                >
                  Trình phát
                </Button>
                {isDisplaySetting ? <></> :
                  <Button
                    loading={loadingSubmit}
                    onClick={() => setSettingLessonModal(true)}
                  >
                    <SettingOutlined/> Cài đặt
                  </Button>
                }

                <Button
                  type='primary'
                  icon={<SaveOutlined/>}
                  onClick={checkSubmit}
                  loading={loadingSubmit}
                  disabled={modeQuestion === ''}
                >
                  Lưu
                </Button>
              </div>
            }
          />
          <AddSurvyCourse activeHandleSave={handleSave}/>
          <Modal
            open={settingLessonModal}
            title={'Cài đặt'}
            className='add-lesson-modal'
            closeIcon={true}
            onOk={saveSettingLesson}
            onCancel={() => setSettingLessonModal(false)}
            footer={null}
          >
            <SettingLessonCourse
              courseId={params.id}
              form={form}
              setSettingLessonModal={setSettingLessonModal}
              generalSettingValue={generalSettingValue}
              actionSettingValue={actionSettingValue}
              evaluateSettingValue={evaluateSettingValue}
            />
          </Modal>
          {contextHolder}
        </Content>
      </ReachableContext.Provider>
    </>
  );
};

export default AddSurvyPage;
