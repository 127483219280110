import {Button, Flex, Form, message, Typography} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import {
  template1,
  template2,
  template3
} from "../../setting-content-course/certificate-page-builder/template-sample/template1";
import {createCertification} from "../../../../../service/content-course/contenCourseApi";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import './index.scss'
import {get} from "lodash";
import FormItemDropdown from "../../../../form-dropdown/FormItemDropdown";

type TabCertificateProps = {
  courseId: number
  dataCertificateCourse?: any;
  isDirty: boolean,
  setDirty: (value: boolean) => void

}

const TabCertificate = ({courseId, dataCertificateCourse, isDirty, setDirty}: TabCertificateProps) => {
  const [form] = Form.useForm();
  const [htmlContent, setHtmlContent] = useState(template1);
  const contentRef = useRef<HTMLDivElement>(null);
  const originalSurname = "Name Surname";

  const [dataCertificateOption, setCertificateOption] = useState([])

  const certificatesOptions = [
    {
      code: template1,
      label: "Mẫu 1"
    },
    {
      code: template2,
      label: "Mẫu 2"
    },
    {
      code: template3,
      label: "Mẫu 3"
    }
  ]

  const createCertificateSample = async (dataReq: { courseId: number; template: any; }) => {
    try {
      const response = await createCertification(dataReq)
      const {status} = response
      if (status === 200) {
        message.success("Cài đặt thành công")
      }
    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {

  }, []);

  useEffect(() => {
    const handleInput = (e: any) => {
      const surnameDiv = contentRef.current?.querySelector('.surname');
      if (surnameDiv && surnameDiv.contains(e.target as Node)) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    const contentEditableDiv = contentRef.current;
    if (contentEditableDiv) {
      contentEditableDiv.addEventListener('input', handleInput);
    }

    return () => {
      if (contentEditableDiv) {
        contentEditableDiv.removeEventListener('input', handleInput);
      }
    };
  }, []);


  const onFinish = (values: any) => {
    setDirty(false)
    const dataReq = {
      courseId: courseId,
      template: htmlContent,

    }


    createCertificateSample(dataReq)
  };
  const handleDivChange = (e: any) => {
    const contentDiv = e.target;
    const surnameDiv = contentDiv.querySelector('.surname');

    if (surnameDiv) {
      surnameDiv.innerHTML = originalSurname;
    }

    setHtmlContent(contentDiv.innerHTML);
  };

  const handleSelect = (value: string) => {
    setHtmlContent(value)
  }

  const onReset = () => {
    form.resetFields();
    if (Object.keys(dataCertificateCourse).length > 0 && Object.values(dataCertificateCourse).some(value => value !== null)) {
      setHtmlContent(get(dataCertificateCourse, 'template', ''))
    } else {
      setHtmlContent(template1)
    }
  }

  useEffect(() => {
    if (Object.keys(dataCertificateCourse).length > 0 && Object.values(dataCertificateCourse).some(value => value !== null)) {
      setHtmlContent(get(dataCertificateCourse, 'template', ''))
    } else {
      setHtmlContent(template1)
    }
  }, [dataCertificateCourse]);

  console.log(certificatesOptions)
  return (
    <div>
      <Flex align="center" gap={"middle"}>
        <div>
          <span>Chọn chứng chỉ mẫu</span>
        </div>
        <div>
          <FormItemDropdown placeholder="Chọn mẫu" options={certificatesOptions} onChange={handleSelect}/>
        </div>
      </Flex>
      <Form
        form={form}
        name="html_css_form"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{htmlContent}}
      >

        <Form.Item
          name="htmlContent"
          hidden
        >
          <input type="hidden" value={htmlContent}/>
        </Form.Item>
        <div
          ref={contentRef}
          contentEditable={true}
          style={{marginTop: '20px', border: '1px solid #ccc', padding: '10px'}}
          dangerouslySetInnerHTML={{__html: htmlContent}}
          onBlur={handleDivChange}
        />
        <div className="save-certification--sample">
          <div className="group-button">
            <Button
              className="ant-btn-primary"
              htmlType="submit"
            >
              <SaveOutlined/>
              <Typography.Text>Lưu</Typography.Text>
            </Button>
            <Button className="cancel-btn" onClick={onReset}>
              <CloseOutlined/>
              <Typography.Text>Hủy</Typography.Text>
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default TabCertificate;
