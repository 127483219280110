// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.check-certificate {
  display: flex;
  flex-direction: column; }
  .check-certificate .ant-radio-wrapper {
    margin-bottom: 8px; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/content-course-construction/setting-content-course/certificate/certificate.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB,EAAA;EAFxB;IAKI,kBAAkB,EAAA","sourcesContent":[".check-certificate {\n  display: flex;\n  flex-direction: column;\n\n  .ant-radio-wrapper {\n    margin-bottom: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
