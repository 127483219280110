import React, { useState } from "react";
import CommonModal from "../../../../../components/modal/common";
import { InfoCircleOutlined } from "@ant-design/icons";
import FormInput from "../../../../../components/form-input-login/FormInput";
import { Typography } from "antd";
import { verifyPassword } from "../../../../../service/auth";
import { deleteReportAttendance } from "../../../../../service/setting-attendance";
import { deleteAutoScore } from "../../../../../service/auto-score";

interface IProps {
  IsOpenDelete: boolean;
  idScore: string;
  IsConfirmDelete: boolean;
  handleConfirmDelete: () => void;
  onCloseModal: () => void;
  handleNotice: (item: string) => void;
  title: string;
}
const DeleteScoreModal = ({
  IsOpenDelete,
  idScore,
  handleConfirmDelete,
  handleNotice,
  onCloseModal,
  title,
  IsConfirmDelete,
}: IProps) => {
  const [password, setPassWord] = useState("");
  const [checkPass, setCheckPassword] = useState<boolean>(true);

  const handleChange = (value: any) => {
    setPassWord(value);
  };

  const handleSubmitDelete = async () => {
    try {
      const response = await verifyPassword({ password });
      const { data } = response;
      if (data?.statusCode === 200) {
        await deleteAutoScore(idScore);
        handleNotice("Xoá bảng điểm thành công ");
        onCloseModal();
      } else {
        setCheckPassword(false);
      }
    } catch (error) {
      setCheckPassword(false);
    }
  };
  const cancelModal = () => {
    setPassWord("");
    onCloseModal();
    setCheckPassword(true);
  };
  return (
    <div>
      <CommonModal
        open={IsOpenDelete}
        title={`Xoá kết quả bảng điểm sinh viên`}
        desc={
          <div>
            Bảng điểm đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá
            bảng điểm này?
          </div>
        }
        closeIcon={true}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={handleConfirmDelete}
        closeModal={onCloseModal}
        className={""}
      />
      <CommonModal
        open={IsConfirmDelete}
        title={`Xoá kết quả bảng điểm sinh viên`}
        desc={
          <div>
            <FormInput
              type="password"
              value={password}
              title="Mã UserID"
              onChange={handleChange}
              isPassword
            />
          </div>
        }
        closeIcon={true}
        okText={"Xoá báo cáo"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={handleSubmitDelete}
        closeModal={cancelModal}
        className={"!w-[465px] report-delete-confirm"}
      />
    </div>
  );
};

export default DeleteScoreModal;
