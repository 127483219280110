import {
  CloseOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  SaveOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Typography,
  Upload,
  UploadFile,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { multimediaIconList } from "../../../constants/multimedia-constants/multimedia-constants";
import {
  createCourseByPDFReference,
  createCourseByScrom,
  createCourseByVideo,
  getListReferenceOption,
} from "../../../service/course-construct";
import { unitDetail } from "../../../types/course-review";
import { convertSecondsToMinutesAndSeconds } from "../../../utils/converDate";
import FormInput from "../../form-input/FormInput";
import "./addLearningMaterialsReferences.scss";
import ReactQuill from "react-quill";
import { isEmpty } from "lodash";
import CommonModal from "../../modal/common";
import FileUploader from "../../file-uploader/file-uploader.component";
import FormItemDropdown from "../../form-dropdown/FormItemDropdown";

type Props = {
  open?: boolean;
  isLesson?: boolean;
  title?: string;
  setIsOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  id?: number;
  sequenceId?: string;
  handleDetail?: any;
  itemDetail?: ItemDetail;
  isEdit?: boolean;
  multimediaType?: string;
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
};

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
  ],
};

const { Option } = Select;
const AddLearningMaterialsReferences = (props: Props) => {
  const {
    open,
    setIsOpenDrawer,
    id,
    multimediaType,
    itemDetail,
    isEdit,
    sequenceId,
    handleDetail,
    setIsEdit,
  } = props;
  const [form] = Form.useForm();
  const blockId = itemDetail?.block?.[0]?.id;
  const type = itemDetail?.block?.[0]?.module;

  const [step, setStep] = useState(1);
  const [chooseFile, setChooseFile] = useState(1);
  const [loading, setLoading] = useState(false);
  const [unitDetail, setUnitDetail] = useState<unitDetail>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [fileList, setFileList] = useState<any[]>([]);
  const [fileDelete, setFileDelete] = useState<any[]>();
  const [videoAttachment, setVideoAttachment] = useState<any>();
  const [fileUpload, setFileUpload] = useState<any>();
  const [openCorfirmModal, setOpenCorfirmModal] = useState<boolean>(false);
  const [fileListOption, setFileListOption] = useState<any>([]);
  const [fileListMain, setFileListMain] = useState<any>([]);
  const [fileOption, setFileOption] = useState<string>("");

  const handleChangeFileAttachment = (info: { file: any; fileList: any[] }) => {
    const deleteElm = unitDetail?.attachments.filter(
      (item1) => !info.fileList.some((item2) => item1.id === item2.id)
    );
    setFileList(info.fileList);
    setFileDelete(deleteElm);
  };

  const onCloseDraw = () => {
    setStep(1);
    form.resetFields();
    setIsOpenDrawer(false);
    if (isEdit && setIsEdit) {
      setIsEdit(false);
    }
  };
  const handleRadioChange = (e: RadioChangeEvent) => {
    setChooseFile(e.target.value);
  };
  const renderNameDraw = () => {
    return isEdit
      ? "Sửa tài liệu tham khảo " + unitDetail?.title
      : "Thêm " + renderTitle();
  };

  const handleChange = (value: string) => { };

  const returnAccept = () => {
    switch (multimediaType ? multimediaType?.toUpperCase() : type?.toUpperCase()) {
      case "REFERENCE":
        return ".mp4, .mp3, .pdf";
        break;
      default:
        break;
    }
  };

  const renderTitle = () => {
    switch (multimediaType ? multimediaType?.toUpperCase() : type?.toUpperCase()) {
      case "REFERENCE":
        return "Tài liệu tham khảo";
        break;
      default:
        break;
    }
  };

  const handlePreviewFile = async (file: any) => {
    return await URL.createObjectURL(file);
  };

  const savePDFReference = async (values: any) => {
    values.attachFile?.fileList?.map((item: any) => {
      return item.originFileObj;
    });
    const sumMinute = values.minutes ? values.minutes * 60 : 0;
    const sumSecond = values.seconds ? values.seconds : 0;

    let formData: any = {};
    formData["sequenceId"] = sequenceId ?? "";
    formData["title"] = values.title;
    if (!isEmpty(values.description)) {
      formData["description"] = !isEmpty(values.description) ? values.description : "";
    }
    formData["timeCompletedCondition"] = sumMinute + sumSecond;
    formData["iconType"] = values.icon ?? 1;
    formData["text"] = "";
    formData["referenceUrl"] = chooseFile === 3 ? values.referenceUrl : null;
    formData["expectedTime"] = values.expectedTime ?? 0;
    formData["expectedTimeType"] = values.expectedTimeType ?? 0;

    formData["fileUploadFrom"] = chooseFile === 1 ? 0 : 1;

    if (!isEmpty(fileOption) && chooseFile === 2) {
      formData["fileMain"] = fileOption ?? null;
    }

    if (!isEmpty(values.video?.fileList) && chooseFile === 1) {
      formData["fileMain"] = values.video?.file.response.fileKey || (values.video?.fileList[0].fileKey ?? null);
    }

    if (values.videoAttach?.file) {
      formData["videoAttachment"] = values.videoAttach?.file.response.fileKey || values.videoAttach?.file?.fileKey;
    }

    if (fileList && Array.isArray(fileList)) {
      formData["filesAttachment"] = fileList.map(i => i.response.fileKey || i.fileKey);
    }

    await createCourseByPDFReference(formData).then((res) => {
      if (res.status === 200) {
        message.success("Tạo tài liệu tham khảo thành công");
        setOpenCorfirmModal(false);
        setIsOpenDrawer(false);
        form.resetFields();
        handleDetail();
      }
      setLoading(false);
    });
  };

  const editUnit = async (values: any) => {
    const sumMinute = values.minutes ? values.minutes * 60 : 0;
    const sumSecond = values.seconds ? values.seconds : 0;
    const formData: any = {};

    formData["referenceUrl"] = !isEmpty(values.referenceUrl) && chooseFile === 3 ? values.referenceUrl : null;

    formData["blockId"] = String(unitDetail?.courseBlockId);
    formData["title"] = values.title;
    if (!isEmpty(values.description)) {
      formData["description"] = !isEmpty(values.description) ? values.description : "";
    }
    formData["timeCompletedCondition"] = sumMinute + sumSecond;
    if (!isEmpty(values.text)) {
      formData["text"] = values.text;
    }
    formData["unitId"] = String(unitDetail?.courseUnitId);
    formData["fileMain"] = values.video;
    formData["fileUploadFrom"] = chooseFile === 1 ? 0 : 1;

    if (!isEmpty(fileOption) && chooseFile === 2) {
      formData["fileMain"] = fileOption ?? null;
    }

    if (!isEmpty(values.video?.fileList) && chooseFile === 1) {
      formData["fileMain"] = values.video?.file.response.fileKey || (values.video?.fileList[0].fileKey ?? null);
    }

    if (chooseFile === 3) {
      formData["fileMain"] = null;
    }

    const elementsWithoutId = fileList.filter(
      (item: any) => item.id === undefined || item.id === null
    );
    if (elementsWithoutId && Array.isArray(elementsWithoutId)) {
      formData["filesAttachment"] = elementsWithoutId.map(i => i.fileKey || i.response.fileKey);
    }
    if (fileDelete) {
      formData["filesAttachmentDelete"] = String(fileDelete?.map((it: any) => it.id).join(",")) ?? '';
    }

    formData["iconType"] = values.icon ?? null;

    if (values.videoAttach?.file?.originFileObj) {
      formData["videoAttachment"] = values.videoAttach?.file?.originFileObj ?? null;
    }

    formData["expectedTime"] = values.expectedTime || null;
    formData["expectedTimeType"] = values.expectedTimeType || null;

    const apiUrl = multimediaIconList?.find((it) => it.type.toLocaleLowerCase() === type.toLocaleLowerCase())?.apiEdit;

    if (apiUrl) {
      await apiUrl(formData).then((res: any) => {
        if (res.status === 200) {
          message.success("Sửa học liệu thành công");
          setOpenCorfirmModal(false);
          setIsOpenDrawer(false);
          form.resetFields();
          handleDetail();
        }
        setLoading(false);
      });
    }
  };

  const getS3Prefix = () => {
    switch (multimediaType ? multimediaType?.toUpperCase() : type?.toUpperCase()) {
      case "SCROM":
      case "XAPI":
        return "scorm/" + sequenceId;
      default:
        return "sequences/" + sequenceId;
    }
  };

  const getTemplateOption = async () => {
    const data = {
      page: 1,
      size: 1000
    }

    const response = await getListReferenceOption(data)
    setFileListOption(response.data?.data?.data)
  }

  useEffect(() => {
    form.validateFields(["video"]);
  }, [form]);

  useEffect(() => {
    const getDetailCourseData = async () => {
      const apiUrl = multimediaIconList?.find(
        (it) => it.type === type
      )?.apiDetail;

      try {
        if (apiUrl) {
          const response = await apiUrl(blockId).then((res: any) => {
            const { data } = res.data;
            setUnitDetail(data);
            const fileList = data.attachments
              ?.filter((it: any) => it.attachmentType !== "video")
              .map((file: any) => ({
                id: file.id,
                name: file.fileName,
                status: "done",
                url: file.attachmentKey,
                attachmentType: file.attachmentType,
              }));
            setFileList(fileList);

            const objMainFileAttachment = [{
              uid: '-1',
              name: data.fileName,
              status: "done",
              url: data?.mainKeyUrl,
            }]
            setFileListMain(objMainFileAttachment)
            form.setFieldsValue({
              ...data,
              icon: data?.iconType || 1,
              minutes: convertSecondsToMinutesAndSeconds(
                data?.timeCompletedCondition
              )?.minutes,
              seconds: convertSecondsToMinutesAndSeconds(
                data?.timeCompletedCondition
              )?.seconds,
              videoAttach: fileList?.filter(
                (it: any) => it.attachmentType === "video"
              ),
              video: data.fileUploadFrom === 0 ? (data?.mainHtmlKey || data?.mainVideo || data?.mainKey) : "",
              selectVideo: data.fileUploadFrom === 1 ? (data?.mainHtmlKey || data?.mainVideo || data?.mainKey) : '',
              referenceUrl: data.referenceUrl ? data.referenceUrl : '',
            });
            setChooseFile(data.fileUploadFrom === 1 ? 2 : (data.fileUploadFrom === 0 ? 1 : (data.referenceUrl ? 3 : -1)))

          });
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    if (blockId && isEdit) {
      getDetailCourseData();
    }
  }, [blockId, isEdit]);

  useEffect(() => {
    if (open) {
      getTemplateOption()
    }
  }, [open])

  useEffect(() => {
    if (unitDetail) {
      if (!isEmpty(unitDetail?.referenceUrl)) {
        setChooseFile(3)
      }
      setFileUpload(unitDetail?.fileUploadFrom === 0 ? (unitDetail?.mainVideo || unitDetail?.mainHtmlKey || unitDetail?.mainKeyUrl) : '');
      setFileOption(unitDetail?.fileUploadFrom === 1 ? (unitDetail?.mainVideo || unitDetail?.mainHtmlKey || unitDetail?.mainKey) : '');
      setVideoAttachment(
        unitDetail?.attachments?.find((it) => it.attachmentType === "video")
          ?.attachmentKey
      );
    }
  }, [unitDetail]);

  const handleChangeVideoAttach = (info: { file: any; fileList: any[] }) => {
    if (info?.fileList.length > 0) {
      const videoObjectUrl = URL.createObjectURL(info.file.originFileObj);
      setVideoAttachment(info.file.originFileObj);
    } else {
      setVideoAttachment(null);
    }
  };

  const handleChangeFileUpload = (info: any) => {
    if (!isEmpty(info.fileList)) {
      if (multimediaType !== 'scrom' && multimediaType !== 'xAPI' && type !== 'scorm' && type !== 'xapi') {
        const videoObjectUrl = URL.createObjectURL(info.fileList[0]?.originFileObj);
        setFileUpload(videoObjectUrl);
        setFileListMain(info.fileList)
      } else {
        setFileUpload(info.fileList[0]?.originFileObj);
        setFileListMain(info.fileList)
      }
    } else {
      setFileUpload('');
    }
  };

  const beforeUpload = () => {
    return false
  }

  return (
    <Form.Provider
      onFormFinish={async (name, { values, forms }) => {
        setLoading(true);
        setOpenCorfirmModal(false)

        if (!isEdit) {
          savePDFReference(values)
        } else {
          editUnit(values);
        }
      }}
    >
      <Drawer
        title={renderNameDraw()}
        onClose={onCloseDraw}
        open={open}
        size={'large'}
        extra={
          <Space>
            <Button onClick={onCloseDraw} className="btn btn-outlined">
              <CloseOutlined />
              <Typography.Text>Hủy</Typography.Text>
            </Button>
            <Button
              className="btn btn-primary"
              // htmlType="submit"
              onClick={() => setOpenCorfirmModal(true)}
              loading={loading}
              disabled={chooseFile === 1 && isEmpty(fileUpload) || (chooseFile === 2 && isEmpty(fileOption))}
            >
              <SaveOutlined />
              <Typography.Text>Lưu</Typography.Text>
            </Button>
          </Space>
        }
      >
        <Form
          className="add-learning-materials"
          layout={"vertical"}
          form={form}
          name="add-chapter"
        >
          <>
            <Row>
              <Col span={6} className="setup-time-title">
                Thông tin chung
              </Col>
              <Col span={18} className="learning-materials-content">
                <Form.Item
                  label={"Tiêu đề"}
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Thông tin này bắt buộc",
                    },
                    {
                      max: 255,
                      message: "Tiêu đề không vượt quá 255 kí tự",
                    },
                  ]}
                >
                  <FormInput placeholder="Nhập tiêu đề" />
                </Form.Item>
                <Form.Item label={"Mô tả"} name="description">
                  <FormInput placeholder="Nhập mô tả" />
                </Form.Item>
                <Form.Item label={"Icon"} name="icon">
                  <Select defaultValue={1} style={{ width: 120 }}>
                    {multimediaIconList.map((it) => (
                      <Option key={it.value} value={it.value}>
                        <img
                          src={it.iconUrl}
                          alt={it.type}
                          style={{ width: "20px", height: "20px" }}
                        />
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={6} className="setup-time-title">
                Chọn file học liệu
              </Col>
              <Col span={18} className="learning-materials-content">
                <Radio.Group value={chooseFile}>
                  <div className="radio-item">
                    <Radio value={1} onChange={handleRadioChange}>
                      <Typography.Title className="add-chapter-title" level={5}>
                        {renderTitle()} từ thiết bị
                      </Typography.Title>
                    </Radio>
                    <div className="add-chapter-description">
                      <h2>
                        Chọn một {renderTitle()} từ thiết bị cá nhân của bạn
                      </h2>
                      <Form.Item
                        label={"Chọn từ thiết bị"}
                        rules={[
                          {
                            required: chooseFile === 1 ? true : false,
                            message: "Thông tin này bắt buộc",
                          },
                        ]}
                        name="video"
                        className="mb-0"
                      >
                        <FileUploader
                          beforeUpload={beforeUpload}
                          listType="picture"
                          accept={returnAccept()}
                          maxCount={1}
                          onChange={handleChangeFileUpload}
                          onRemove={() => {
                            setFileUpload(null)
                            setFileListMain([])
                          }}
                          fileList={fileListMain}
                          prefix={getS3Prefix()}
                        >
                          <Button icon={<UploadOutlined />}>
                            Tải lên từ thiết bị
                          </Button>
                        </FileUploader>
                      </Form.Item>
                      {multimediaType === 'xAPI' ? <p>Vui lòng tải tệp xAPI, Dung lượng tối đa 100MB</p> : null}
                      {multimediaType === 'PDF' ? <p>Vui lòng tải tệp PDF, Dung lượng tối đa 100MB</p> : null}

                      {!isEmpty(fileUpload) ? (
                        type === "video" ? (
                          <ReactPlayer
                            url={fileUpload}
                            controls
                            width="100%"
                            height="100%"
                            style={{ marginTop: "20px" }}
                          />
                        ) : (
                          <iframe
                            allow="fullscreen"
                            scrolling="yes"
                            width="100%"
                            height="100%"
                            src={fileUpload}
                            style={{ marginTop: "20px" }} />
                        )
                      ) : null}
                    </div>
                  </div>
                  {

                    <div className="radio-item">
                      <Radio value={2} onChange={handleRadioChange}>
                        <Typography.Title className="add-chapter-title" level={5}>
                          {renderTitle()} từ thư viện
                        </Typography.Title>
                      </Radio>

                      {chooseFile === 2 && (
                        <div className="add-chapter-description">
                          <Form.Item
                            label={""}
                            name="selectVideo"
                            rules={[
                              {
                                required: chooseFile === 2 ? true : false,
                                message: "Thông tin này bắt buộc",
                              },
                            ]}
                          >
                            <FormItemDropdown placeholder="Chọn file từ thư viện--" onChange={setFileOption}
                              options={fileListOption} />
                          </Form.Item>
                        </div>
                      )}

                      <div className="describe-text">
                        Chọn một {renderTitle()} từ thiết bị cá nhân của bạn
                      </div>
                    </div>
                  }

                  <div className="radio-item">
                    <Radio value={3} onChange={handleRadioChange}>
                      <Typography.Title className="add-chapter-title" level={5}>
                        Đường dẫn tài liệu tham khảo
                      </Typography.Title>
                    </Radio>

                    <div className="add-chapter-description">
                      <Form.Item
                        label={"Nhập một đường dẫn"}
                        name="referenceUrl"
                        rules={[
                          {
                            required: chooseFile === 3 ? true : false,
                            message: "Thông tin này bắt buộc",
                          },
                        ]}
                      >
                        <FormInput placeholder="Nhập một đường dẫn" />
                      </Form.Item>
                    </div>
                  </div>
                </Radio.Group>
              </Col>
            </Row>
          </>
          <CommonModal
            open={openCorfirmModal}
            title={"Thông báo xác nhận lưu tài liệu tham khảo"}
            desc={
              <><p>Xin vui lòng xác nhận việc lưu học liệu trong khoá học</p><div>
                Đảm bảo rằng tất cả các{" "}
                <span className="text-browse">
                  thông tin liên quan đã được kiểm tra và cập nhật đầy đủ{" "}
                </span>
                trước khi lưu khoá học
              </div></>
            }
            closeIcon={true}
            okText={"Xác nhận"}
            cancelText={"Huỷ"}
            icon={
              <InfoCircleOutlined
                style={{ color: "#FFC53D", marginRight: "10px" }}
              />
            }
            confirm={form.submit}
            closeModal={() => setOpenCorfirmModal(false)}
          />
        </Form>
      </Drawer>
    </Form.Provider>
  );
};

export default AddLearningMaterialsReferences;

interface ItemDetail {
  name: string;
  describe: string;
  id: number;
  video: any;
  block: any;
}
