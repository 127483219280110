import { Checkbox, Flex, Form } from "antd";
import {
  JSON_SUB_TYPE,
  QuestionBeProps,
  QuestionPropsV2,
} from "../../../../../types/course";
import { QuestionTypeEnum } from "../../../../../constants";
import { convertNumberingTypeToText } from "../../../../../utils/format";
import EmptyComponent from "../../../../../components/empty";

function ChooseMultiImage({ data, initialData, disabled }: QuestionPropsV2) {
  const placeholders = data
    ? 2 - (data.questions.length % 2 === 0 ? 2 : data.questions.length % 2)
    : null;
  return (
    <div>
      <div className="flex layout-two-col">
        {data?.questions && data?.questions?.length > 0 ? (
          data?.questions?.map((quiz: QuestionBeProps, index) => (
            <div className={`image-quiz-wrap`} key={quiz?.uuid + index}>
                <Checkbox id={`${index}`} disabled={disabled} checked={quiz?.isTrue===true}>
                  <Flex className="font-size-16 line-height-24 gap-1">
                    {data?.settingGeneral && convertNumberingTypeToText(
                      index + 1,
                      Number(JSON.parse(data?.settingGeneral).numberingType)
                    )}
                    .
                    <div
                      className="font-size-16 line-height-24"
                      dangerouslySetInnerHTML={{ __html: `${quiz?.content}` }}
                    ></div>
                  </Flex>
                </Checkbox>
           
              <label htmlFor={`${index}`}>
                <img
                  className="mt-1"
                  src={quiz?.filePath || ""}
                  alt="quiz-card"
                />
              </label>
            </div>
          ))
        ) : (
          <div className="center">
            <EmptyComponent description="Không có nội dung" />
          </div>
        )}
        {Array.from({ length: placeholders || 0 }).map((_, index) => (
          <div
            key={`placeholder-${index}`}
            className={`image-quiz-wrap choose-correct placeholder`}
          />
        ))}
      </div>
    </div>
  );
}

export default ChooseMultiImage;
