import React from "react";
import { Checkbox } from "antd";
import {
  QuizAnsProps,
  ResultDetail,
} from "../../../../../../types/scoring-course";
import { isArray } from "lodash";
import FormulaRenderer from "../../../../../../components/math-formula";

function ChooseMultiText({ data, showCorrect = true, result }: QuizAnsProps) {
  const currentAnsOfUser = result?.results[0];

  const renderTextQuiz = (isTrueOption: boolean, uuid: string) => {
    if (showCorrect) {
      // show result in single menu
      if (isTrueOption) return "text-result";
      return "";
    } else {
      // show answers of current user
      if (isArray(currentAnsOfUser)) {
        const currentAns = currentAnsOfUser?.find(
          (item: ResultDetail) => item.uuid === uuid
        );
        if (
          currentAns &&
          currentAns?.uuid === uuid &&
          currentAns?.isTrue === true
        )
          return "text-result";
        if (
          currentAns &&
          currentAns?.uuid === uuid &&
          currentAns?.isTrue === false
        )
          return "text-danger";
      }
      return "";
    }
  };

  const renderCheckAns = (isTrueOption: boolean, uuid: string) => {
    if (showCorrect) {
      if (isTrueOption) return true;
      return false;
    } else {
      // show answers of current user

      if (isArray(currentAnsOfUser)) {
        const currentAns = currentAnsOfUser?.find(
          (item: ResultDetail) => item.uuid === uuid
        );
        if (currentAns && currentAns?.uuid === uuid) return true;
        return false;
      }

      return false;
    }
  };

  const renderCheckStyle = (uuid: string) => {
    if (showCorrect) return "checkbox-correct";
    else {
      // show answers of current user
      if (isArray(currentAnsOfUser)) {
        const currentAns = currentAnsOfUser?.find(
          (item: ResultDetail) => item.uuid === uuid
        );
        if (
          currentAns &&
          currentAns?.uuid === uuid &&
          currentAns?.isTrue === false
        )
          return "checkbox-danger";
      }
      return "";
    }
  };

  return (
    <div className={"checkbox-correct"}>
      <div className="flex flex-column gap-12">
        {data?.questions &&
          data?.questions?.length > 0 &&
          data?.questions?.map((quiz: any, index: any) => (
            <div
              className={`${renderTextQuiz(quiz?.isTrue, quiz?.uuid)}`}
              key={quiz.uuid + index}
            >
              <Checkbox
                className={`${renderCheckStyle(quiz?.uuid)}`}
                disabled={true}
                checked={renderCheckAns(quiz?.isTrue, quiz?.uuid)}
              >
                <div className={`${renderTextQuiz(quiz?.isTrue, quiz?.uuid)}`}>
                  <FormulaRenderer
                    className="ml-1"
                    htmlString={quiz?.content || "N/A"}
                  />
                </div>
              </Checkbox>
            </div>
          ))}
      </div>
    </div>
  );
}

export default ChooseMultiText;
