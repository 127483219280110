// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bank-modal .ant-modal-content {
  padding: 0; }

.bank-modal .ant-modal-header {
  padding: 16px 24px;
  margin: 0; }

.form-update-bank {
  padding: 24px 24px 0 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.12); }
  .form-update-bank .ant-form-item {
    margin-bottom: 0; }
  .form-update-bank .text-gray {
    color: rgba(0, 0, 0, 0.45); }
  .form-update-bank .btn-group-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    padding-top: 10px;
    padding: 10px 16px 0 24px;
    margin: 24px -24px 0; }
  .form-update-bank .bank-qr-code {
    width: 100%;
    height: 100%;
    object-fit: contain; }
`, "",{"version":3,"sources":["webpack://./src/pages/enrollment-confirmation/components/style.scss"],"names":[],"mappings":"AAAA;EAEI,UAAU,EAAA;;AAFd;EAKI,kBAAkB;EAClB,SAAS,EAAA;;AAGb;EACE,yBAAyB;EACzB,yCAAyC,EAAA;EAF3C;IAII,gBAAgB,EAAA;EAJpB;IAOI,0BAA0B,EAAA;EAP9B;IAUI,yCAAyC;IACzC,iBAAiB;IACjB,yBAAyB;IACzB,oBAAoB,EAAA;EAbxB;IAgBI,WAAW;IACX,YAAY;IACZ,mBAAmB,EAAA","sourcesContent":[".bank-modal {\n  .ant-modal-content {\n    padding: 0;\n  }\n  .ant-modal-header{\n    padding: 16px 24px;\n    margin: 0;\n  }\n}\n.form-update-bank {\n  padding: 24px 24px 0 24px;\n  border-top: 1px solid rgba(0, 0, 0, 0.12);\n  .ant-form-item {\n    margin-bottom: 0;\n  }\n  .text-gray {\n    color: rgba(0, 0, 0, 0.45);\n  }\n  .btn-group-footer {\n    border-top: 1px solid rgba(0, 0, 0, 0.12);\n    padding-top: 10px;\n    padding: 10px 16px 0 24px;\n    margin: 24px -24px 0;\n  }\n  .bank-qr-code{\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
