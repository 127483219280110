export { default as DetailUser } from "./detail/Detail";
export { default as Login } from "./login/Login";
export { default as RoleManagement } from "./role-management/RoleManagement";
export { default as Profile } from "./user-info/profile/Profile";
export { default as ListTeacher } from "./list-teachers/ListTeachers";
export { default as ListUser } from "./list-user/ListUser";
export { default as Page500 } from "./500";
export { default as Page403 } from "./403";
export { default as Account } from "./user-info/account/Account";
export { default as DetailCourseConstruction } from "./course-construction/detail-course-construction";
export { default as AssignedDetailCourseConstruction } from "./course-construction/assigned-course-construction";
export { default as HistoryCourseConstruction } from "./course-construction/history-course-construction";
export { default as PageBuilderView } from "./page-builder/PageBuilderView";
export { default as HistoryBuildCourseConstruction } from "./course-construction/history-build-course-construction";
export { default as DetailContentConstruction } from "./course-construction/detail-content-construction";
