import {
  InboxOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Flex,
  Input,
  Layout,
  Modal,
  Spin,
  Switch,
  Typography,
  Upload,
  UploadProps,
  message,
} from "antd";
import { SearchProps } from "antd/es/input";
import { isEmpty } from "lodash";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommonModal from "../../../components/modal/common";
import PageHeaderCommon from "../../../components/page-header-common/PageHeaderCommon";
import { routesConfig } from "../../../config/routes";
import {
  createFileAutoScore,
  getFormExamAutoScore,
  getStudentAutoScore,
} from "../../../service/auto-score";
import {
  getSettingExamSupervisionById,
  updateSettingExamSupervision,
} from "../../../service/exam-supervision";
import { exportData } from "../../../utils/exportData";
import AutoMarkTable from "./AutoMarkTable";
import "./auto-mark.scss";
import { title } from "process";

const imgPath = "/images/";

const { Content } = Layout;
const { Title, Text } = Typography;
const { Search } = Input;
const { Dragger } = Upload;

interface SearchType {
  search?: string;
  course_block_id?: number | string;
  skip: number;
  take: number;
}

interface ItemProps {
  onSearch: any;
  active: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const ChildrenComponent = ({ onSearch, active, setIsOpen }: ItemProps) => {
  const [searchText, setSearchText] = useState<string>("");
  const handleChangeSearch = (e: any) => {
    setSearchText(e?.target?.value);
  };
  return (
    <Flex>
      <Search
        placeholder="Nhập tên sinh viên cần tìm                  "
        onSearch={onSearch}
        value={searchText}
        onChange={handleChangeSearch}
        disabled={!active}
      />
      <Button
        type="primary"
        className="ml-2"
        disabled={!active}
        onClick={() => setIsOpen(true)}
      >
        Nạp file kết quả chấm điểm tự động
      </Button>
    </Flex>
  );
};

const AutoMarkAction = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [searchValue, setSearchValue] = useState<string>("");
  const [fileList, setFileList] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenLoading, setIsOpenLoading] = useState(false);
  const [formExam, setFormExam] = useState<any>([]);

  const [listStudent, setListStudent] = useState<any[]>([]);
  const [paramFeature, setParamFeature] = useState<any>({
    id: "",
    is_auto_mark: false,
    title: ""
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);

  const fetchData = async (searchParam: SearchType) => {
    try {
      const response = await getStudentAutoScore(searchParam);
      const { data } = response.data;
      setListStudent(data.data);
      setTotalPage(data?.total?._count?.id);
      setLoading(false);
    } catch (error) { }
  };
  const fetchDataExamSupervision = async () => {
    try {
      const res = await getSettingExamSupervisionById(params?.id as string);
      const { data } = res.data;
      setParamFeature({
        ...paramFeature,
        id: data?.id,
        is_auto_mark: data.is_auto_mark,
        title: data?.mooc_course?.name
      });
    } catch (error) { }
  };

  const fetchFormExam = async () => {
    try {
      const response = await getFormExamAutoScore(params?.id || "");
      const { data } = response.data;
      setFormExam(data);
    } catch (error) { }
  };

  useEffect(() => {
    fetchDataExamSupervision();
    fetchFormExam();
    fetchData({
      search: searchValue,
      course_block_id: params?.id,
      take: pageSize,
      skip: (pageNumber - 1) * pageSize,
    });
  }, [pageSize, pageNumber, searchValue]);

  const onChangeActive = async (checked: boolean, name: string) => {
    if (!checked) {
      setIsConfirm(true);
    } else {
      try {
        await updateSettingExamSupervision(paramFeature?.id, {
          is_auto_mark: checked,
        });
        setParamFeature({
          ...paramFeature,
          is_auto_mark: checked,
        });
        messageApi.open({
          type: "success",
          content: "Thay đổi trạng thái thành công",
        });
      } catch (error: any) {
        messageApi.open({
          type: "error",
          content: error?.response?.data?.message,
        });
      }
    }
  };
  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    setSearchValue(value);
  };
  const listBreadcrumb = [
    {
      title: "Cài đặt nâng cao",
    },
    {
      title: "Quản lý chấm điểm tự động",
    },
    {
      title: paramFeature?.title || "",
    },
    {
      title: "Chấm điểm tự động",
    },
  ];

  const onCloseModal = () => {
    setIsOpen(false);
    setIsOpenLoading(false);
    setIsConfirm(false);
  };
  const handleConfirmFile = async () => {
    const paramFile = {
      course_block_id: Number(params?.id),
      data: fileList,
    };

    if (!isEmpty(fileList)) {
      setIsOpenLoading(true);
      try {
        await createFileAutoScore(paramFile);
        setIsOpen(false);
        navigate(`/${routesConfig.examStudentList}/${params?.id}`);
      } catch (error) { }
      setIsOpenLoading(false);
    } else {
      messageApi.open({
        type: "error",
        content: "Vui lòng chọn file chấm điểm tự động",
      });
    }
  };
  const handleConfirmNotice = async () => {
    try {
      await updateSettingExamSupervision(paramFeature?.id, {
        is_auto_mark: false,
      });
      setParamFeature({
        ...paramFeature,
        is_auto_mark: false,
      });
      messageApi.open({
        type: "success",
        content: "Thay đổi trạng thái thành công",
      });
      setIsConfirm(false);
    } catch (error) { }
  };
  const onDownload = () => {
    const convertForm = formExam?.map((data: any) => ({
      "ID câu hỏi (Không được sửa)": data?.id,
      "Câu hỏi (Không được sửa)": data?.question,
      "Điểm đáp án đúng": data?.setting_point?.correct || 0,
      "Điểm đáp án không đúng": data?.setting_point?.incorrect || 0,
    }));
    exportData(convertForm, "Mẫu điền kết quả chấm", ".csv");
  };

  const changeHandler = (event: any) => {

    if (isEmpty(event?.fileList)) {
      setFileList([]);
      return;
    }
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    if (event?.file?.type === "text/csv") {
      Papa.parse(event.file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const rowsArray = [];
          const valuesArray: any = [];

          // Iterating data to get column name and their values
          results.data.map((d) => {
            rowsArray.push(Object.keys(d as any));
            valuesArray.push(Object.values(d as any));
          });

          // Parsed Data Response in array format
          const outputArray = valuesArray.map((item: any) => ({
            course_block_quiz_id: Number(item[0]),
            correct: Number(item[2]),
            incorrect: Number(item[3]),
          }));
          setFileList(outputArray);
        },
      });
    }
  };
  const propsFile: UploadProps = {
    name: "file",
    // multiple: true,
    maxCount: 1,
    beforeUpload: (file) => {
      const isCSV = file.type === "text/csv";
      if (!isCSV) {
        message.error(`${file.name} is not a csv file`);
      }
      return false;
    },
    onChange: changeHandler as any,
  };

  return (
    <Content>
      {contextHolder}
      <PageHeaderCommon
        title="Chấm điểm tự động"
        listBreadcrumb={listBreadcrumb}
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        headerGroupButton
        childrenGroupButton={
          <ChildrenComponent
            onSearch={onSearch}
            active={paramFeature.is_auto_mark}
            setIsOpen={setIsOpen}
          />
        }
        handleBack={() =>
          navigate(-1)
        }
      />
      <div className="px-6">
        <Card className="w-full mt-6">
          <Flex justify="space-between" align="center">
            <div>
              <Flex align="center">
                <Title level={4} className="!m-0">
                  Thực hiện chấm điểm tự động
                </Title>
                <div className="px-4">
                  <Switch
                    defaultChecked
                    onChange={(checked) =>
                      onChangeActive(checked, "ip_attendance")
                    }
                    checked={paramFeature.is_auto_mark}
                  />
                  <Text className="px-4">
                    {paramFeature.is_auto_mark
                      ? "Đang hoạt động"
                      : "Không hoạt động"}
                  </Text>
                </div>
              </Flex>
              <Text className="text-[#00000073]">
                Vui lòng chọn bật để sử dụng tính năng <br /> Tính năng chỉ áp
                dụng cho dạng bài trắc nghiệm và tắt cài đặt hiển thị kết quả
                sau khi chấm
              </Text>
            </div>
          </Flex>
        </Card>
      </div>
      {paramFeature.is_auto_mark && (
        <div className="px-6 auto-mark mt-6">
          <Card>
            <Flex justify="space-between" className="p-6">
              <div>
                <Title level={4} className="!leading-3">
                  Danh sách sinh viên
                </Title>
                <Text className="text-[#00000073]">
                  Chọn sinh viên cần chấm điểm tự đồng và bấm nút Nạp file kết
                  quả chấm điểm
                </Text>
              </div>
            </Flex>
            <AutoMarkTable
              listStudent={listStudent}
              setPageNumber={setPageNumber}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              pageSize={pageSize}
              loading={loading}
              totalPage={totalPage}
            />
          </Card>
        </div>
      )}
      <CommonModal
        open={isConfirm}
        title={"Xác nhận tắt cài đặt thông báo?"}
        desc={
          "Hệ thống sẽ gỡ toàn bộ các cài đặt. Bạn có chắc chắn muốn tắt cài đặt giám sát thi cử?"
        }
        closeIcon={true}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={handleConfirmNotice}
        closeModal={onCloseModal}
      />
      <CommonModal
        open={isOpen}
        title={"Nạp file kết quả chấm điểm tự động"}
        desc={
          <div className="border-b pb-3">
            <Dragger {...propsFile}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Nhấn hoặc thả tệp lên từ máy tính của bạn{" "}
                <span className="text-[#1677FF]">vào đây</span>
              </p>
              <p className="ant-upload-hint">
                Định dạng tệp khả dụng: CSV (Tối đa 01 tệp - 10 MB)
              </p>
            </Dragger>
            <div className="pt-3">
              <span className="font-bold">
                Hướng dẫn các bước nạp tệp kết quả và xác nhận bắt đầu tiến
                trình:
              </span>
              <ol className="list-decimal px-4">
                <li className="list-decimal">
                  Tải mẫu điền kết quả chấm{" "}
                  <span
                    className="text-[#1677FF] cursor-pointer"
                    onClick={() => setIsOpenForm(true)}
                  >
                    vào đây
                  </span>
                </li>
                <li className="list-decimal">
                  Điền kết quả chấm điểm. Kết quả chấm cần khớp với đề thi bạn
                  đã chọn và lưu file theo định dạng .CSV - Quy định trường hợp
                  điểm lẻ (có phần thập phân) sửa dụng dấu “.”
                </li>
                <li className="list-decimal">
                  Nhấn hoặc thả tệp cần tải lên vào khu vực tải tệp
                </li>
                <li className="list-decimal">
                  Kiểm tra tệp trước khi chọn “Xác nhận"
                </li>
              </ol>
            </div>
          </div>
        }
        closeIcon={true}
        okText={"Bắt đầu"}
        cancelText={"Huỷ"}
        icon=""
        confirm={handleConfirmFile}
        closeModal={onCloseModal}
        className={"!w-full md:!w-[520px]"}
      />
      <Modal
        open={isOpenForm}
        title={"Mẫu điền kết quả chấm"}
        closeIcon={true}
        onCancel={() => setIsOpenForm(false)}
        className={"!w-full md:!w-[700px]"}
        centered
        footer=""
      >
        <div className="border-t pt-4">
          <div className="bg-[#FAFAFA] p-3 rounded-lg border">
            <Flex justify="space-between" align="center">
              <Flex align="center">
                <img
                  className="pr-2"
                  loading="eager"
                  alt=""
                  src={"/images/csv-icon.svg"}
                />
                <Text>Mẫu điền kết quả chấm.csv</Text>
              </Flex>
              <Text
                className="text-[#1677FF] cursor-pointer"
                onClick={onDownload}
              >
                Tải xuống
              </Text>
            </Flex>
          </div>
          <div className="!mt-3">
            <Text>Mẫu điền kết quả chấm xem trước:</Text>
          </div>
          <div className="border-b p-4 bg-[#0000000f] flex mt-4">
            <Text className="w-1/4 border-r text-center">
              <span>ID câu hỏi</span> <br />
              (Không được sửa)
            </Text>
            <Text className="w-1/4 border-r text-center">
              <span>Câu hỏi</span> <br />
              (Không được sửa)
            </Text>
            <Text className="w-1/4 border-r text-center">Điểm đáp án đúng</Text>
            <Text className="w-1/4 text-center">Điểm đáp án không đúng</Text>
          </div>
          <div className="overflow-auto max-h-[300px]">
            {formExam.map((exam: any, index: number) => (
              <Flex className="border-b py-4" key={index}>
                <Text className="w-1/4 px-4">{exam?.id}</Text>
                <Text className="w-1/4 px-4">{exam?.question}</Text>
                <Text className="w-1/4 px-4">
                  {exam?.setting_point?.correct || 0}
                </Text>
                <Text className="w-1/4 px-4">
                  {exam?.setting_point?.incorrect || 0}
                </Text>
              </Flex>
            ))}
          </div>
        </div>
      </Modal>
      <Modal
        title="Đang xử lý . . ."
        open={isOpenLoading}
        footer=""
        centered
        onCancel={onCloseModal}
        className="processing-checkin-modal"
      >
        <div className="text-center">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
          <p className="pt-4">
            {" "}
            Hệ thống đang tiến hành điểm danh. Vui lòng chờ
          </p>
        </div>
      </Modal>
    </Content>
  );
};

export default AutoMarkAction;
