import React, { useEffect } from "react";
import AreaStackedChart from "../../../../../../components/types-of-charts/AreaStackedChart";
import { Col, Empty, Row, Select, Spin } from "antd";
import BarStackedChart from "../../../../../../components/types-of-charts/BarStackedChart";
import ComboDualAxesChart from "../../../../../../components/types-of-charts/ComboDualAxesChart";
import ColumnStackedPercentChart from "../../../../../../components/types-of-charts/ColumnStackedPercentChart";
import StatisticalReportCard from "../../StatisticalReportCard";
import useChartExport from "../../../../../../components/static-report/useChartExport";
import { staticReportStore } from "../../../../../../stores/stores";
import {
  BarStackedChartProps,
  DualChart,
} from "../../../../../../types/static-report";

type Props = {
  loading: Record<string, boolean>;
  unitDiscussUniversityList: BarStackedChartProps[];
  courseDiscussActionUniversity: BarStackedChartProps[];
  unitReviewAccessUniversity: DualChart[];
  rateUnitByModuleUniversity: BarStackedChartProps[];
  rateCourseByModuleUniversity: BarStackedChartProps[];
  rateSequenceByModuleUniversity: BarStackedChartProps[];
  setModuleGroup: (data: number) => void;
};

const FeedbackAndEvaluationChartTab = (props: Props) => {
  const {
    loading,
    unitDiscussUniversityList,
    courseDiscussActionUniversity,
    unitReviewAccessUniversity,
    rateUnitByModuleUniversity,
    rateCourseByModuleUniversity,
    rateSequenceByModuleUniversity,
    setModuleGroup,
  } = props;

  // Hook for managing chart exports
  const { createChartRef, handleExportImage, handleExportAllImagesAsZip } =
    useChartExport();

  const { tab, setHandleExportAllImagesAsZip, exportType } =
    staticReportStore();

  useEffect(() => {
    setHandleExportAllImagesAsZip(() => handleExportAllImagesAsZip(tab));
  }, [tab, exportType]);

  return (
    <div className="feedbackAndEvaluationChartTab">
      <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
        <Col span={12} className="chart-card">
          <StatisticalReportCard
            chartName="Số lượng phản hồi theo thời gian"
            content={
              <Spin spinning={loading.unitDiscussUniversityList}>
                {unitDiscussUniversityList.length > 0 ? (
                  <AreaStackedChart
                    data={unitDiscussUniversityList}
                    colors={["#69C0FF", "#5CDBD3", "#FFC069", "#FF7875"]}
                    title=""
                    yAxisTitle="Số lượng phản hồi"
                    hasTotal={true}
                    ref={createChartRef(
                      tab,
                      "Số lượng phản hồi theo thời gian"
                    )}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(
                    tab,
                    "Số lượng phản hồi theo thời gian",
                    "image/png"
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "Số lượng phản hồi theo thời gian",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col span={12} className="chart-card">
          <StatisticalReportCard
            chartName="Số lượng phản hồi theo bài giảng"
            content={
              <Spin spinning={loading.courseDiscussActionUniversity}>
                {courseDiscussActionUniversity.length > 0 ? (
                  <BarStackedChart
                    data={courseDiscussActionUniversity}
                    title=""
                    showDataLabels={false}
                    colors={["#69C0FF", "#FFC069", "#5CDBD3", "#FF7875"]}
                    ref={createChartRef(
                      tab,
                      "Số lượng phản hồi theo bài giảng"
                    )}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(
                    tab,
                    "Số lượng phản hồi theo bài giảng",
                    "image/png"
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "Số lượng phản hồi theo bài giảng",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col span={12} className="chart-card">
          <StatisticalReportCard
            chartName="Số lượt đánh giá vs Số lượt hoàn thành học liệu"
            content={
              <Spin spinning={loading.unitReviewAccessUniversity}>
                {unitReviewAccessUniversity.length > 0 ? (
                  <ComboDualAxesChart
                    data={unitReviewAccessUniversity}
                    columnName="Lượt làm đánh giá"
                    columnColor="#69c0ff"
                    lineName="Lượt hoàn thành học liệu"
                    lineColor="#f87572"
                    hasTotal={false}
                    ref={createChartRef(
                      tab,
                      "Số lượt đánh giá vs Số lượt hoàn thành học liệu"
                    )}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(
                    tab,
                    "Số lượt đánh giá vs Số lượt hoàn thành học liệu",
                    "image/png"
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "Số lượt đánh giá vs Số lượt hoàn thành học liệu",
                    "application/pdf"
                  ),
              },
            ]}
            moreOption={
              <Select
                className="mr-2"
                defaultValue={3}
                style={{ minWidth: 160 }}
                onSelect={(value) => {
                  setModuleGroup(value);
                }}
                options={[
                  { value: 3, label: "Bài kiểm tra" },
                  { value: 2, label: "Tài liệu tham khảo" },
                  { value: 1, label: "Đa phương tiện" },
                  { value: 4, label: "SCORM & xAPI" },
                ]}
              />
            }
          />
        </Col>
        <Col span={12} className="chart-card">
          <StatisticalReportCard
            chartName="Tỷ lệ đánh giá khóa học"
            content={
              <Spin spinning={loading.rateCourseByModuleUniversity}>
                {rateCourseByModuleUniversity.length > 0 ? (
                  <ColumnStackedPercentChart
                    data={rateCourseByModuleUniversity}
                    title=""
                    showDataLabels={false}
                    colors={[
                      "#ff7875",
                      "#ffc069",
                      "#5cdbd3",
                      "#69c0ff",
                      "#b37feb",
                    ]}
                    ref={createChartRef(tab, "Tỷ lệ đánh giá khóa học")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(
                    tab,
                    "Tỷ lệ đánh giá khóa học",
                    "image/png"
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "Tỷ lệ đánh giá khóa học",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col span={12} className="chart-card">
          <StatisticalReportCard
            chartName="Tỷ lệ đánh giá học liệu theo phân loại"
            content={
              <Spin spinning={loading.rateUnitByModuleUniversity}>
                {rateUnitByModuleUniversity.length > 0 ? (
                  <ColumnStackedPercentChart
                    data={rateUnitByModuleUniversity}
                    title=""
                    showDataLabels={false}
                    colors={[
                      "#ff7875",
                      "#ffc069",
                      "#5cdbd3",
                      "#69c0ff",
                      "#b37feb",
                    ]}
                    ref={createChartRef(
                      tab,
                      "Tỷ lệ đánh giá học liệu theo phân loại"
                    )}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(
                    tab,
                    "Tỷ lệ đánh giá học liệu theo phân loại",
                    "image/png"
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "Tỷ lệ đánh giá học liệu theo phân loại",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col span={12} className="chart-card">
          <StatisticalReportCard
            chartName="Tỷ lệ đánh giá bài giảng"
            content={
              <Spin spinning={loading.rateSequenceByModuleUniversity}>
                {rateSequenceByModuleUniversity.length > 0 ? (
                  <ColumnStackedPercentChart
                    data={rateSequenceByModuleUniversity}
                    title=""
                    showDataLabels={false}
                    colors={[
                      "#ff7875",
                      "#ffc069",
                      "#5cdbd3",
                      "#69c0ff",
                      "#b37feb",
                    ]}
                    ref={createChartRef(tab, "Tỷ lệ đánh giá bài giảng")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(
                    tab,
                    "Tỷ lệ đánh giá bài giảng",
                    "image/png"
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "Tỷ lệ đánh giá bài giảng",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
};

export default FeedbackAndEvaluationChartTab;
