import React, { useEffect, useState } from "react";
import ChooseText from "./choose-text/ChooseText";
import { Button, Form, Popover, Spin } from "antd";
import ChooseImage from "./choose-image";
import ChooseVideo from "./choose-video";
import TrueOrFalse from "./true-or-false";
import DropDown from "./drop-down";
import ChooseMultiText from "./choose-multi-text";
import ChooseMultiImage from "./choose-multi-image";
import ChooseMultiVideo from "./choose-multi-video";
import Connect from "./connect";
import ShortText from "./short-text";
import FileUpload from "./file-upload";
import Video from "./video";
import Mp3 from "./mp3";
import LongText from "./long-text";
import FillText from "./fill-text/FillText";
import {
  HoursGlassIcon,
  NextIcon,
  PreviousIcon,
  QuestionMarkIcon,
} from "../../../../components/icons/svg";
import { toNumber } from "lodash";
import Countdown from "../components/Countdown";
import { QuestionTypeEnum } from "../../../../constants";
import {
  QuestionBeProps,
  QuizProps,
  QuizRequest,
} from "../../../../types/course";
import { addOrUpdateQuizRequest } from "../../../../utils/arrays";
import EmptyComponent from "../../../../components/empty";

interface QuizLayoutProps {
  quiz?: any;
  loading?: boolean;
  isPausing?: boolean;
  disableNextBtn?: boolean;
  disablePrevBtn?: boolean;
  quizzes: QuizProps[];
  quizzesCompleted: QuizRequest[];
  isTrainingQuizHasTime?: boolean;
  timeOfCurrentQuiz?: number;
  quizzesHasTimeCompleted?: number[];
  onPause?: () => void;
  onFinish?: () => void;
  onNext?: () => void;
  onPrevious?: () => void;
  onChange?: (data: QuizRequest[]) => void;
  onFinishTrainingQuizHasTime?: () => void;
}

function QuizLayout({
  quiz,
  loading = false,
  quizzes = [],
  disableNextBtn = false,
  disablePrevBtn = false,
  quizzesCompleted,
  isTrainingQuizHasTime = false,
  isPausing = false,
  timeOfCurrentQuiz = 0,
  quizzesHasTimeCompleted = [],
  onPause = () => {},
  onFinish = () => {},
  onNext = () => {},
  onPrevious = () => {},
  onChange = () => {},
  onFinishTrainingQuizHasTime = () => {},
}: QuizLayoutProps) {
  const [form] = Form.useForm();
  const data = {
    ...quiz,
    questions: quiz?.answers !== undefined ? JSON.parse(quiz?.answers) : "",
  };
  const type = data?.type;
  const [fieldValues, setFieldValues] = useState<QuizRequest[]>([]);
  const disabledQuiz =
    quizzesHasTimeCompleted?.includes(data?.id) && isTrainingQuizHasTime;

  useEffect(() => {
    setFieldValues(quizzesCompleted);
  }, []);

  const initialData = () => {
    return quizzesCompleted?.find(
      (quiz: QuizRequest) => quiz.quizId === data?.id
    );
  };

  const renderQuestionByType = () => {
    switch (type) {
      case QuestionTypeEnum.CHOOSE_TEXT:
        return (
          <ChooseText
            disabled={disabledQuiz}
            data={data}
            initialData={initialData()}
          />
        ); //done push value

      case QuestionTypeEnum.CHOOSE_IMAGE:
        return (
          <ChooseImage
            disabled={disabledQuiz}
            data={data}
            initialData={initialData()}
          />
        ); //done push value

      case QuestionTypeEnum.CHOOSE_VIDEO:
        return (
          <ChooseVideo
            disabled={disabledQuiz}
            data={data}
            initialData={initialData()}
          />
        ); //done push value

      case QuestionTypeEnum.TRUE_OR_FALSE:
        return (
          <TrueOrFalse
            disabled={disabledQuiz}
            data={data}
            initialData={initialData()}
          />
        ); //done push value

      case QuestionTypeEnum.DROP_DOWN:
        return (
          <DropDown
            disabled={disabledQuiz}
            data={data}
            initialData={initialData()}
          />
        ); //done push value

      case QuestionTypeEnum.CHOOSE_MULTI_TEXT:
        return (
          <ChooseMultiText
            disabled={disabledQuiz}
            data={data}
            initialData={initialData()}
          />
        ); //done push value

      case QuestionTypeEnum.CHOOSE_MULTI_IMAGE:
        return (
          <ChooseMultiImage
            disabled={disabledQuiz}
            data={data}
            initialData={initialData()}
          />
        ); //done push value

      case QuestionTypeEnum.CHOOSE_MULTI_VIDEO:
        return (
          <ChooseMultiVideo
            disabled={disabledQuiz}
            data={data}
            initialData={initialData()}
          />
        ); //done push value

      case QuestionTypeEnum.CONNECT: //done push value
        return (
          <Connect
            disabled={disabledQuiz}
            data={data}
            onChange={handleChangeValues}
            initialData={initialData()}
          />
        );

      case QuestionTypeEnum.SHORT_TEXT:
        return (
          <ShortText
            disabled={disabledQuiz}
            data={data}
            initialData={initialData()}
          />
        ); //done push value

      case QuestionTypeEnum.LONG_TEXT:
        return (
          <LongText
            disabled={disabledQuiz}
            data={data}
            initialData={initialData()}
          />
        ); //done push value

      case QuestionTypeEnum.FILE_UPLOAD: //done push value
        return (
          <FileUpload
            disabled={disabledQuiz}
            form={form}
            data={data}
            onChange={handleChangeValues}
            initialData={initialData()}
          />
        );

      case QuestionTypeEnum.VIDEO: //done push value
        return (
          <Video
            disabled={disabledQuiz}
            data={data}
            onChange={handleChangeValues}
            initialData={initialData()}
          />
        );

      case QuestionTypeEnum.MP3: //done push value
        return (
          <Mp3
            disabled={disabledQuiz}
            data={data}
            onChange={handleChangeValues}
            initialData={initialData()}
          />
        );

      case QuestionTypeEnum.FillText: //done push value
        return (
          <FillText
            disabled={disabledQuiz}
            data={data}
            initialData={initialData()}
          />
        );

      default:
        <p>Xin vui lòng chọn câu hỏi</p>
        break;
    }
  };

  useEffect(() => {
    onChange(fieldValues);
  }, [fieldValues]);

  const handleChangeValues = (fields: any) => {
    const fieldKey = Object.keys(fields)[0];
    const fieldValue = Object.values(fields);
    if (fieldKey) {
      const fieldInfo = fieldKey?.split("-");
      // default quiz payload
      let quizReq: QuizRequest = {
        type: toNumber(fieldInfo[2]),
        quizId: toNumber(fieldInfo[1]),
        questionType: fieldInfo[3],
        answer: fieldValue,
      };
      const type = toNumber(fieldInfo[2]);
      const quizId = toNumber(fieldInfo[1]);
      if (
        type === QuestionTypeEnum.CHOOSE_MULTI_TEXT ||
        type === QuestionTypeEnum.CHOOSE_MULTI_IMAGE ||
        type === QuestionTypeEnum.CHOOSE_MULTI_VIDEO
      ) {
        const current = quizzes.find((quiz: QuizProps) => quiz.id === quizId);
        console.log(current);

        if (current) {
          quizReq = {
            type: type,
            quizId: quizId,
            questionType: fieldInfo[3],
            answer: JSON.parse(current?.answers as string)
              .map((quest: QuestionBeProps) => quest.uuid)
              .filter(
                (_: QuestionBeProps, index: number) => !!fieldValue[index]
              ),
          };
        }
      }
      if (type === QuestionTypeEnum.CONNECT) {
        quizReq = {
          type: toNumber(fieldInfo[2]),
          quizId: toNumber(fieldInfo[1]),
          questionType: fieldInfo[3],
          answer: fieldValue[0],
        };
      }
      setFieldValues((prevState: QuizRequest[]) =>
        addOrUpdateQuizRequest(prevState, quizReq)
      );
    }
  };

  return quizzes?.length > 0 ? (
    <div className="quiz-layout flex flex-column">
      <div className="question-content flex-1">
        <Spin spinning={loading}>
          <div className="question-header">
            {data?.timeToCompleted ? (
              <div className="question-time flex align-center gap-8">
                <HoursGlassIcon />
                <div className="text-primary font-size-14 line-height-20 font-weight-6 flex align-center gap-8">
                  Trả lời câu hỏi trong:
                  <Countdown
                    pause={isPausing}
                    initialTime={isTrainingQuizHasTime ? timeOfCurrentQuiz : 0}
                    onFinish={onFinishTrainingQuizHasTime}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="question-title font-size-16 line-height-24 font-weight-6 flex gap-8 mt-1">
              <div
                dangerouslySetInnerHTML={{ __html: `${data?.question ? data?.question : 'Xin vui lòng chọn câu hỏi' }` }}
              ></div>
              {data?.settingHint?.isDisplayInstruction ? (
                <Popover
                  content={
                    <div style={{ padding: "5px 16px" }}>
                      {data?.settingHint?.content || "Không có gợi ý"}
                    </div>
                  }
                >
                  <span className="quest-icon text-secondary">
                    <QuestionMarkIcon />
                  </span>
                </Popover>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="question-display-content pt-2 flex-1">
            <Form
              form={form}
              onValuesChange={(v, value) => handleChangeValues(value)}
            >
              {renderQuestionByType()}{" "}
            </Form>
          </div>
        </Spin>
      </div>

      <div className="quiz-layout-footer">
        <div className="btn-group-left flex gap-16">
          <Button className="h-36 btn-quiz-footer" onClick={onPause}>
            <span className="font-size-14 font-weight-6">Tạm dừng</span>
          </Button>
          <Button
            className="h-36 btn-quiz-footer btn-outline-primary"
            onClick={onFinish}
          >
            <span className="font-size-14 font-weight-6 text-primary">
              Nộp bài
            </span>
          </Button>
        </div>
        <div className="btn-group-right flex gap-12">
          <Button
            className="btn-paginate center h-36"
            onClick={onPrevious}
            disabled={disablePrevBtn}
          >
            <PreviousIcon />
          </Button>
          <Button
            className="btn-paginate center h-36"
            onClick={onNext}
            disabled={disableNextBtn}
          >
            <NextIcon />
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <div className="center">
      <EmptyComponent description="Không có nội dung" />
    </div>
  );
}

export default QuizLayout;
