// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trans-locations-page .total-display-wrap {
  border-radius: 100px;
  padding: 0 8px;
  font-size: 12px;
  width: max-content;
  color: white; }

.trans-locations-page .confirmed-total {
  background: #1677ff; }

.trans-locations-page .not-confirmed-total {
  background: #ff4d4f; }
`, "",{"version":3,"sources":["webpack://./src/pages/enrollment-confirmation/locations/style.scss"],"names":[],"mappings":"AAAA;EAEI,oBAAoB;EACpB,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,YAAY,EAAA;;AANhB;EASI,mBAAmB,EAAA;;AATvB;EAYI,mBAAmB,EAAA","sourcesContent":[".trans-locations-page {\n  .total-display-wrap {\n    border-radius: 100px;\n    padding: 0 8px;\n    font-size: 12px;\n    width: max-content;\n    color: white;\n  }\n  .confirmed-total {\n    background: #1677ff;\n  }\n  .not-confirmed-total {\n    background: #ff4d4f;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
