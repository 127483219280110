import React, { useEffect, useRef, useState } from "react";
import { Flex, FormInstance, Spin, Tooltip } from "antd";
import {
  QuestionBeProps,
  QuestionProps,
  QuizProps,
} from "../../../../../../../types/course";
import EmptyComponent from "../../../../../../../components/empty";
import { convertNumberingTypeToText } from "../../../../../../../utils/format";
import {
  JSON_SUB_TYPE,
  QuestionTypeEnum,
} from "../../../../../../../constants/course";
import { isEmpty, toNumber } from "lodash";
import {
  HoursGlassIcon,
  QuestionMarkIcon,
} from "../../../../../../../components/icons";
import Countdown from "../../../../../../../components/countdown/CountDown";
import useKeyword from "../../../../../../../hooks/useKeyword";
import { startSingleQuiz } from "../../../../../../../service/learning-course";
import { Link } from "react-router-dom";
import { SortableList } from "../../../../../../SortableList";

interface ConnectProps extends QuestionProps {
  data?: QuizProps;
  form?: FormInstance<any>;
  onChange?: (value: any) => void;
}
function Connect({
  data,
  pause,
  configs,
  disabled,
  initialData,
  onFinish = () => {},
  onChange = () => {},
}: ConnectProps) {
  const initialTime =
    data?.timeToCompleted || configs?.actionConfig?.timeForQuiz || 0;
  const [answers, setAnswers] = useState<any>([]);

  const connectRef = useRef<HTMLDivElement | null>(null);
  const [itemHeight, setItemHeight] = useState<number | undefined>(0);
  const [itemSwapped, setItemSwapped] = useState<number[]>([]);

  const [constAnswer, setConstAnswer] = useState<any[]>([]);
  const [dirtyAns, setDirtyAns] = useState<string[]>([]);
  const blockId = toNumber(useKeyword("blockId"));
  const currentScreen = useKeyword("screen");
  const isSequentiallyQuiz = configs?.generalConfig?.sequentiallyQuiz;
  const [loading, setLoading] = useState<boolean>(false);
  const [canStart, setCanStart] = useState<boolean>(false);

  useEffect(() => {
    // Xử lý câu hỏi có đếm thời gian riêng
    if (currentScreen === "train") {
      if (!isEmpty(data) && isSequentiallyQuiz && initialTime) {
        const startSingle = async () => {
          try {
            setLoading(true);
            await startSingleQuiz({
              blockId: blockId,
              quizId: data?.id,
            });
          } catch (err: any) {
          } finally {
            setCanStart(true);
            setLoading(false);
          }
        };
        startSingle();
      }
    }
  }, [isSequentiallyQuiz]);

  useEffect(() => {
    setItemHeight(connectRef.current?.clientHeight);
  }, [connectRef.current, window.innerWidth]);

  useEffect(() => {
    if (data?.questions && data?.questions?.length > 0) {
      setAnswers(
        data?.questions?.map((ans: QuestionBeProps) => ({
          id: ans?.content?.right.key,
          value: ans?.content?.right.content,
        }))
      );
      setConstAnswer(
        data?.questions?.map((ans: QuestionBeProps) => ({
          id: ans?.content?.right.key,
          value: ans?.content?.right.content,
        }))
      );
    }
  }, [data?.id]);

  useEffect(() => {
    if (answers?.length) {
      const dirtyArr: string[] = [];
      for (let i = 0; i < answers.length; i++) {
        if (JSON.stringify(constAnswer[i]) !== JSON.stringify(answers[i])) {
          dirtyArr.push(constAnswer[i]?.id, answers[i]?.id);
        }
      }
      setDirtyAns((prevState: string[]) => [...prevState, ...dirtyArr]);
    }
  }, [answers]);

  useEffect(() => {
    if (!isEmpty(initialData)) {
      const initData = initialData?.answer?.map((item: any) => ({
        id: item?.right?.key,
        value: item?.right?.content,
      }));
      setAnswers(initData);

      // các item đã swap
      if (initialData?.answer[0]?.swapped)
        setItemSwapped((prevState) => [
          ...prevState,
          ...initialData?.answer[0]?.swapped,
        ]);
    }
  }, [data?.id, initialData]);

  const handleDragChange = (fieldValues: any) => {
    setAnswers(fieldValues);

    // các item đã swap
    let newItemsSwapped: any = [];
    for (let i = 0; i < fieldValues?.length; i++) {
      if (answers[i]?.id !== fieldValues[i].id) {
        newItemsSwapped.push(fieldValues[i].id);
      }
    }
    setItemSwapped((prevState) => [...prevState, ...newItemsSwapped]);
    // item changed
    if (data) {
      const answer = data?.questions?.map(
        (item: QuestionBeProps, index: number) => ({
          left: { ...item.content?.left },
          right: {
            key: fieldValues[index]?.id,
            content: fieldValues[index]?.value,
          },
          swapped: [...itemSwapped, ...newItemsSwapped],
        })
      );
      onChange({
        [`connect-${data?.id}-${QuestionTypeEnum.CONNECT}-${JSON_SUB_TYPE.ConnectingRequest}`]:
          answer,
      });
    }
  };

  return (
    <div>
      <Spin spinning={loading}>
        <div className="question-header mb-3">
          {configs?.actionConfig?.timeForQuiz && configs?.generalConfig?.sequentiallyQuiz ? (
            <div className="question-time flex align-center gap-8">
              <HoursGlassIcon />
              <div className="text-primary font-size-14 line-height-20 font-weight-6 flex align-center gap-8">
                Trả lời câu hỏi trong:
                <Countdown
                  pause={pause}
                  initialTime={canStart ? toNumber(initialTime) : 0}
                  onFinish={onFinish}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="question-title">
            {/* <Flex
              justify="space-between"
              className="font-size-16 line-height-24 font-weight-6"
            >
              Câu hỏi {toNumber(data?.index) + 1}:{" "}
              {configs?.generalConfig?.showQuizScore && (
                <span className="total-point font-size-14">
                  {`(${data?.totalPoints || 0} điểm)`}
                </span>
              )}
            </Flex> */}
            <Flex align="flex-start" gap={8}>
              <div
                className="font-size-16 line-height-24 font-weight-6"
                dangerouslySetInnerHTML={{ __html: `${data?.question}` }}
              ></div>
              {data?.settingHint?.isDisplayInstruction && data?.isShowHint ? (
                <Tooltip
                  placement="bottom"
                  title={
                    <div>
                      {data?.settingHint?.content ? (
                        <p className="text-14 font-weight-5">
                          Hướng dẫn: {data?.settingHint?.content}
                        </p>
                      ) : (
                        "Không có hướng dẫn"
                      )}

                      {data?.settingHint?.schema ? (
                        <p className="font-size-14 mt-2">
                          Đường dẫn tham khảo:{" "}
                          <Link
                            target="_blank"
                            to={`${data?.settingHint?.uri}${data?.settingHint?.schema}`}
                          >
                           {`${data?.settingHint?.uri}${data?.settingHint?.schema}`}
                          </Link>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  }
                >
                  <span className="quest-icon text-secondary w-max-content">
                    <QuestionMarkIcon />
                  </span>
                </Tooltip>
              ) : (
                ""
              )}
            </Flex>
          </div>
        </div>
        <div className="flex">
          <div className="connect-quest justify-space-between w-50 mr-3">
            {data?.questions && data?.questions?.length > 0 ? (
              data?.questions?.map((quiz: QuestionBeProps, index) => (
                <div
                  className={`connect-quest-item ${dirtyAns?.includes(quiz?.content?.right?.key) && "connect-quest-item-connecting"}`}
                  key={`${quiz.uuid}-${index}-left`}
                  ref={connectRef}
                >
                  <Flex className="font-size-16 line-height-24 gap-1">
                    {convertNumberingTypeToText(
                      index + 1,
                      Number(data?.settingGeneral.numberingType)
                    )}
                    .
                    <div
                      className="font-size-16 line-height-24"
                      dangerouslySetInnerHTML={{ __html: `${quiz?.content.left?.content}` }}
                    ></div>
                  </Flex>
                </div>
              ))
            ) : (
              <div className="center">
                <EmptyComponent description="Không có nội dung" />
              </div>
            )}
          </div>
          <div className="w-50 ml-3">
            <SortableList
              items={answers}
              className="gap-16 justify-space-between"
              disabled={disabled}
              onChange={handleDragChange}
              renderItem={(record: any) => (
                <SortableList.Item
                  className={`connect-item ${itemSwapped?.includes(record?.id) && `connect-item-active`}`}
                  id={record.id}
                  styles={{ height: toNumber(itemHeight) + 2 }}
                >
                  <SortableList.DragHandle />
                  <div className="font-size-14">{record.value}</div>
                </SortableList.Item>
              )}
            />
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default Connect;
