// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.exam-container .exam-layout {
  height: 68vh; }
`, "",{"version":3,"sources":["webpack://./src/components/details-exam/styles.scss"],"names":[],"mappings":"AAEA;EAEQ,YAAY,EAAA","sourcesContent":["@import \"../../styles/variables\";\n\n.exam-container{\n    .exam-layout{\n        height: 68vh;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
