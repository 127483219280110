import {
  Button,
  Flex,
  Form,
  Layout,
  PaginationProps,
  Table,
  TableColumnsType,
  TableProps,
} from "antd";
import React, { KeyboardEvent, useEffect, useState } from "react";

import {
  DownloadOutlined,
  LeftOutlined,
  RightOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { DEFAULT_PAGE_NUM, DEFAULT_PAGE_SIZE } from "../../../constants";
import { getTransactionUniversities } from "../../../service/transaction";
import { routesConfig } from "../../../config/routes";
import FormItemInput from "../../../components/form-input/FormInput";
import "./style.scss";

function LocationList() {
  const [searchValue, setSearchValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [pageNum, setPageNum] = useState<number>(DEFAULT_PAGE_NUM);
  const [universities, setUniversities] = useState([]);
  const [sort, setSort] = useState<string[]>();

  const [hoverSortMessage, setHoverSortMessage] = useState<{
    triggerDesc: string;
    triggerAsc: string;
  }>({
    triggerDesc: "Sắp xếp theo thứ tự Z-A",
    triggerAsc: "Sắp xếp thứ tự A-Z",
  });

  useEffect(() => {
    getUniversities();
  }, [pageNum, pageSize, sort]);

  const getUniversities = async () => {
    try {
      setLoading(true);
      const res = await getTransactionUniversities({
        page: pageNum,
        size: pageSize,
        keyword: searchValue ? searchValue : undefined,
        sort: sort,
      });
      setTotal(res.data?.data?.totalPages);
      setUniversities(res.data.data?.content);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <LeftOutlined />;
    }
    if (type === "next") {
      return <RightOutlined />;
    }
    return originalElement;
  };

  const handleChangePaginate = (page: number, size: number) => {
    setPageNum(page);
    setPageSize(size);
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      handleSubmitSearch();
    }
  };

  const handleSubmitSearch = () => {
    getUniversities();
  };

  const handleChangeInput = (e: any) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      align: "center",
      width: "5%",
      render: (_: any, record, index: number) => {
        return <div>{record.numericalOrder}</div>;
      },
    },
    {
      title: "Tên trường",
      dataIndex: "universityName",
      key: "universityName",
      width: "30%",
      render: (value: any, record, index: number) => {
        return (
          <Link
            className="text-primary text-link"
            to={`${routesConfig.enrollmentConfirmation}?university=${record?.id}`}
          >
            {value}
          </Link>
        );
      },
    },
    {
      title: "Chờ xác nhận",
      dataIndex: "notConfirmedCount",
      key: "notConfirmedCount",
      width: 200,
      sorter: true,
      onHeaderCell: (column) => {
        return {
          onMouseEnter: () => {
            setHoverSortMessage({
              triggerDesc: "Sắp xếp theo thứ từ lớn đến nhỏ",
              triggerAsc: "Sắp xếp theo thứ tự từ nhỏ đến lớn",
            });
          },
        };
      },
      render: (value: string) => (
        <div className="total-display-wrap not-confirmed-total">
          {value || "0"}
        </div>
      ),
    },
    {
      title: "Đã xác nhận",
      dataIndex: "confirmedCount",
      key: "confirmedCount",
      sorter: true,
      onHeaderCell: (column) => {
        return {
          onMouseEnter: () => {
            setHoverSortMessage({
              triggerDesc: "Sắp xếp theo thứ từ lớn đến nhỏ",
              triggerAsc: "Sắp xếp theo thứ tự từ nhỏ đến lớn",
            });
          },
        };
      },
      render: (value: string) => (
        <div className="total-display-wrap confirmed-total">{value || "0"}</div>
      ),
    },
  ];

  const locale = {
    emptyText: (
      <span>
        <p>
          <img
            className="image-empty-data"
            src="/images/empty-img-gray.png"
            alt="empty-img"
          ></img>
        </p>
        <p className="nodata-text">Không tìm thấy</p>
      </span>
    ),
    triggerDesc: hoverSortMessage.triggerDesc,
    triggerAsc: hoverSortMessage.triggerAsc,
    cancelSort: "Huỷ sắp xếp",
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const sortField =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";

    setSort(sortField ? [`${sorter?.field},${sortField}`] : undefined);
  };

  return (
    <Layout className="page-header-group trans-locations-page">
      <Form.Provider>
        <div className="enrollment-page w-full">
          <div className="enrollment-page-header">
            <div className="flex justify-between align-center gap-8">
              <h3 className="page-title flex-1">Danh sách cơ sở đào tạo</h3>

              <div className="search-input">
                <FormItemInput
                  style={{ width: 280 }}
                  placeholder="Nhập tên trường"
                  value={searchValue}
                  onChange={handleChangeInput}
                  onKeyPress={handleSearch}
                  afterPrefixIcon={
                    <SearchOutlined onClick={handleSubmitSearch} />
                  }
                />
              </div>
            </div>
          </div>
          <div className="enrollment-content">
            <div className="session-content">
              <div className="table-content">
                <Table
                  loading={loading}
                  dataSource={universities?.map((item: any, index: number) => ({
                    ...item,
                    key: index,
                    numericalOrder: index + 1,
                  }))}
                  locale={locale}
                  columns={dataColumns}
                  className="pagination-table"
                  onChange={handleTableChange}
                  pagination={{
                    total: total,
                    pageSize: pageSize,
                    current: pageNum,
                    itemRender: itemRender,
                    showSizeChanger: true,
                    onChange: handleChangePaginate,
                    locale: {
                      items_per_page: "/ trang",
                      jump_to: "Đi đến trang",
                      page: "",
                    },
                    showQuickJumper: true,
                    showTotal: (total: number) => `Tổng số ${total} bản ghi`,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Form.Provider>
    </Layout>
  );
}

export default LocationList;
