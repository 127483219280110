import { Col, Form, MenuProps, Row, Spin, Switch, Typography } from "antd";
import { FormInstance } from "antd/lib";
import { FunctionComponent, useEffect, useState } from "react";
import FormInput from "../../../../../components/form-input/FormInput";
import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";
import { get, isEmpty } from "lodash";
import { useForm, useWatch } from "antd/es/form/Form";
import { getIndustryStudent } from "../../../../../service/category-management/student-management";
import { regexEmailV2, phoneNumberRegex, regexEmail } from "../../../../../utils/regex";

const validations = {
	name: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (value && value.length > 512) {
				return Promise.reject(new Error("Tên sinh viên không vượt quá 512 ký tự"))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập tên sinh viên"))
			}
			return Promise.resolve()
		}
	},
	code: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (value && value.length > 512) {
				return Promise.reject(new Error("Mã sinh viên không vượt quá 512 ký tự"))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập mã sinh viên"))
			}
			return Promise.resolve()
		}
	},
	university: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (!value) {
				return Promise.reject(new Error("Vui lòng chọn cơ sở đào tạo"))
			}
			return Promise.resolve()
		}
	},
	department: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (!value || value.length < 1) {
				return Promise.reject(new Error("Vui lòng chọn khoa"))
			}
			return Promise.resolve()
		}
	},
	specialized: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (!value || value.length < 1) {
				return Promise.reject(new Error("Vui lòng chọn chuyên ngành"))
			}
			return Promise.resolve()
		}
	},
	gender: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (!value) {
				return Promise.reject(new Error("Vui lòng chọn giới tính"))
			}
			return Promise.resolve()
		}
	},
	domicile: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (!value) {
				return Promise.reject(new Error("Vui lòng chọn nguyên quán"))
			}
			return Promise.resolve()
		}
	},
	phone: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (!isEmpty(value) && !value.match(new RegExp(phoneNumberRegex))) {
				return Promise.reject(new Error("Sai định dạng, vui lòng nhập lại"))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập số điện thoại"))
			}
			return Promise.resolve()
		}
	},
	email: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (!isEmpty(value) && !value.match(new RegExp(regexEmail))) {
				return Promise.reject(new Error("Sai định dạng, vui lòng nhập lại"))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập địa chỉ email"))
			}
			return Promise.resolve()
		}
	},
}

type FormCreateUniversityProps = {
	form: FormInstance,
	onFinish?: any,
	dataOptions?: any,
	loadingForm: boolean,
}

const gendersOptions = [
    {
      id: "Nam",
      name: "Nam",
    },
    {
      id: "Nữ",
      name: "Nữ",
    },
	{
	  id: "Khác",
	  name: "Khác",
	},
];

const FormCreateUniversity: FunctionComponent<FormCreateUniversityProps> = ({ form, onFinish, dataOptions, loadingForm }) => {
	const departmentId = useWatch("department", form);
	const [loadingDataSpecialized, setLoadingDataSpecialized] = useState<boolean>(false);
	const [dataSpecialized, setDataSpecialized] = useState([]);

	const getDataSpecialized = async (departmentId: string) => {
		const getCodeDepartmentById = get(dataOptions, 'dataDepartment', '').find((item: any) => item.id === departmentId)?.code;
		setLoadingDataSpecialized(true);
		try {
			await getIndustryStudent(getCodeDepartmentById).then((res) => {
				if(res.status === 200) {
					setDataSpecialized(res.data.data);
					
					setTimeout(() => {
						setLoadingDataSpecialized(false);
					}, 333);
				}
			})
		} catch (err) {
			setLoadingDataSpecialized(false);
			console.error(err);
		}
	}

	const handleChangeDepartment = () => {
		setDataSpecialized([]);
		form.setFieldsValue({
			specialized: [],
		});
	}

	useEffect(() => {
		if(departmentId) {
			getDataSpecialized(departmentId);
		} else {
			setDataSpecialized([]);
		}
	}, [departmentId])

	return (
        <>
            <Form layout={"vertical"} form={form} onFinish={onFinish} name="createStudent" initialValues={{ status : true}}>
				{loadingForm ? 
					<div className="statistic-course-content statistic-course-loading">
						<Spin size="large" />
					</div> 
					: 
					<Row gutter={[16,16]}>
						<Col span={24}>
							<Form.Item name="name" rules={[validations.name]} label="Tên sinh viên">
								<FormInput placeholder="Nhập tên sinh viên" />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item name="university" rules={[validations.university]} label="Cơ sở đào tạo">
								<FormItemDropdown options={get(dataOptions, 'dataUniversity', '')} allowClear={true} placeholder="Chọn cơ sở đào tạo" />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item name="code" rules={[validations.code]} label="Mã sinh viên">
								<FormInput placeholder="Nhập mã sinh viên" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="department" rules={[validations.department]} label="Khoa">
								<FormItemDropdown onChange={handleChangeDepartment} options={get(dataOptions, 'dataDepartment', '')} allowClear={true} placeholder="Chọn khoa" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="specialized" rules={[validations.specialized]} label="Chuyên ngành">
								<FormItemDropdown modeDropdown="multiple" options={dataSpecialized} allowClear={true} placeholder="Chọn chuyên ngành" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="gender" rules={[validations.gender]} label="Giới tính">
								<FormItemDropdown options={gendersOptions} placeholder="Chọn giới tính" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="domicile" rules={[validations.domicile]} label="Nguyên quán">
								<FormItemDropdown options={get(dataOptions, 'dataProvince', '')} allowClear={true} placeholder="Chọn nguyên quán" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="phone" rules={[validations.phone]} label="Điện thoại liên hệ">
								<FormInput placeholder="Nhập số điện thoại" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="email" rules={[validations.email]} label="Địa chỉ Email">
								<FormInput placeholder="Nhập địa chỉ email" />
							</Form.Item>
						</Col>
						<Col span={24} style={{display:"flex", justifyContent:"space-between"}}>
							<span>Trạng thái</span>
							<Form.Item name="status">
								<Switch defaultChecked />
							</Form.Item>
						</Col>
					</Row>
				}
			</Form>
        </>
    )
}

export default FormCreateUniversity;