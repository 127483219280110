import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Row,
  Select,
  TimePicker,
} from "antd";
import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { KeyValue } from "../../../constants";
import { viLocale } from "../../../utils/pickerType";
import { getCourseFilterUniversity } from "../../../service/statistical-report";
import { BankProps } from "../../../types/transaction";

const { RangePicker } = DatePicker;

export interface FilterSearchValues {
  status?: number;
  createdPerson?: string[];
  date?: any;
}

interface FilterNotifications {
  onChange?: (value: FilterSearchValues) => void;
  onSearch?: (value: FilterSearchValues) => void;
  onClear?: (value: null) => void;
}

function FilterSearch({
  onSearch = () => {},
  onClear = () => {},
}: FilterNotifications) {
  const [form] = Form.useForm();
  const [courseOptions, setCourseOptions] = useState<KeyValue[]>([]);

  const handleFinish = (values: FilterSearchValues) => {
    onSearch(values);
  };

  useEffect(() => {
    const getCourses = async () => {
      const res = await getCourseFilterUniversity();
      setCourseOptions(
        res.data.data.map((item: BankProps) => ({
          label: item.name,
          value: item.id,
        }))
      );
    };
    getCourses();
  }, []);

  const handleClear = () => {
    form.resetFields();
    onClear(null);
  };

  return (
    <div className="mt-2">
      <Form form={form} onFinish={handleFinish}>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Form.Item name="courseId">
              <Select
                className="w-full"
                placeholder="Tên khoá học"
                options={courseOptions}
                showSearch
                filterOption={(input: any, option: any) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="date">
              <RangePicker
                className="w-full"
                placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
                format="YYYY-MM-DD"
                locale={viLocale}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="time">
              <TimePicker.RangePicker
                placeholder={["Thời gian bắt đầu", "Thời gian kết thúc"]}
                locale={viLocale}
                className="w-full"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Flex gap={8}>
              <Button
                htmlType="submit"
                icon={<FilterOutlined />}
                className="btn-primary"
              >
                Lọc
              </Button>
              <Button
                onClick={handleClear}
                icon={<DeleteOutlined />}
                className="btn-outline"
              >
                Xoá tất cả
              </Button>
            </Flex>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default FilterSearch;
