import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Row, Typography } from "antd";
import { FormInstance } from "antd/lib";
import { FunctionComponent } from "react";
import FormItemDropdown from "../../../form-dropdown/FormItemDropdown";
import "./filter-group.scss";

type FormFilterProps = {
  handleCloseFilter: () => void;
  showFilter?: boolean;
  form: FormInstance;
  clearFilter: () => void;
  onFinish?: () => void;
  setFilterData: (dateString: string[], planCreationDate: string) => void;
  setPageNumber: (page: number) => void;
  dataOptions?: any;
  filterData: any;
};

const FormFilter: FunctionComponent<FormFilterProps> = ({
  onFinish,
  form,
  showFilter,
  clearFilter,
  filterData,
  setFilterData,
  setPageNumber,
}) => {
  const { RangePicker } = DatePicker;

  const listStatus = [
    {
      id: 13,
      name: 'Đã xuất bản'
    },
    {
      id: 12,
      name: 'Chờ xuất bản'
    },
  ]

  const courseType = [
    { id: 1, name: "Tự triển khai" },
    { id: 2, name: "Hợp tác xây dựng" },
    { id: 3, name: "Dùng chung" },
  ];

  const courseFormat = [
    { id: 1, name: "Thẻ ghi danh" },
    { id: 2, name: "Riêng tư" },
    { id: 3, name: "Tự do" },
  ]

  const searchFnc =() => {
    setPageNumber(1)
    form.submit()
  }


  return (
    <>
      <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
        <Form form={form} name="filterForm" onFinish={onFinish}>
          <Row gutter={[{ xs: 8, xl: 24, md: 16 }, 12]}>
            <Col
              xl={{ span: 5 }}
              md={{ span: 5 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Typography.Title level={5}>
                Trạng thái
              </Typography.Title>
              <Form.Item name="status">
                <FormItemDropdown placeholder="Trạng thái" onChange={(option) => setFilterData(option, 'status')}
                  options={listStatus} />
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 4 }}
              md={{ span: 5 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Typography.Title level={5}>
                Loại khoá học
              </Typography.Title>
              <Form.Item name="courseType">
                <FormItemDropdown placeholder="Loại khoá học" onChange={(option) => setFilterData(option, 'courseType')}
                  modeDropdown="multiple"
                  options={courseType} />
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 4 }}
              md={{ span: 5 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Typography.Title level={5}>
                Hình thức khoá học
              </Typography.Title>
              <Form.Item name="courseFormat">
                <FormItemDropdown placeholder="Hình thức khoá học"
                  onChange={(option) => setFilterData(option, 'courseFormats')}
                  modeDropdown="multiple"
                  options={courseFormat}
                />
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 4 }}
              md={{ span: 5 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Typography.Title level={5}>Ngày xuất bản</Typography.Title>
              <Form.Item name="approvedDate">
                <RangePicker
                  placeholder={["Từ ngày", "Đến ngày"]}
                  format="DD-MM-YYYY"
                  value={filterData.approvedDate}
                  onChange={(_value, dateString: any) =>
                    setFilterData(dateString, "approvedDate")
                  }
                />
              </Form.Item>
            </Col>

            <Col
              xl={{ span: 3 }}
              md={{ span: 9 }}
              sm={{ span: 9 }}
              xs={{ span: 24 }}
              className="flex align-end"
            >
              <div className="group-btn--filter">
                <Button
                  className="btn btn-primary filter-btn"
                  htmlType="submit"
                  onClick={searchFnc}
                >
                  <FilterOutlined />
                  <Typography.Text>Lọc</Typography.Text>
                </Button>
                <Button
                  className="btn btn-outlined clear-filter-btn"
                  onClick={clearFilter}
                >
                  <DeleteOutlined />
                  <Typography.Text>Xóa bộ lọc</Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default FormFilter;
