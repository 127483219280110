import './route.scss';
import { Key, useEffect, useState, useTransition } from "react";
import { RegistrationClassTabProps } from "../registration-class";
import { useLocation } from "react-router-dom";
import { getCourseRoadmap, updateCourseRoadmap } from "../../../../service/course-construct";
import { Button, DatePicker, message, notification, Space, TimePicker, Tree, TreeDataNode } from "antd";
import { FilePdfOutlined, FundProjectionScreenOutlined } from "@ant-design/icons";
import { extractKeys } from "../../../../utils/arrays";
import CustomCard from '../../../../components/custom-card/CustomCard';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useDirty } from '../../../../contexts/DirtyProvider';
import { usePreventRouteChange } from '../../../../hooks/usePreventRouteChange';
import moment from 'moment';
import _, { isEmpty } from 'lodash';
import vi from "antd/es/date-picker/locale/vi_VN";
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(customParseFormat);
dayjs.extend(timezone);

const locale: typeof vi = {
  ...vi,
  lang: {
    ...vi.lang,
    dateSelect: "Chọn ngày",
    placeholder: "Chọn ngày",
    timeSelect: "Chọn giờ"
  },
  timePickerLocale: {
    placeholder: "Chọn giờ"
  },
}

function RouteTab(props: RegistrationClassTabProps) {
  const dateFormatAPI = 'YYYY-MM-DD';
  const dateFormatHTML = 'DD/MM/YYYY';
  const timeFormat = 'HH:mm'
  const location = useLocation();
  const [isPending, startTransition] = useTransition();
  const { setDirty } = useDirty();
  const { confirmNavigation } = usePreventRouteChange();
  const [loadingContent, setLoadingContent] = useState<boolean>(true);
  const [openAlertSaveChangeRoute, setOpenAlertSaveChangeRoute] = useState<boolean>(false);
  const [alertSaveChangeRoute, contextSaveChangeRoute] = notification.useNotification();
  const [dataRoute, setDataRoute] = useState<any[]>([]);
  const [dataChangeRoute, setDataChangeRoute] = useState<any[]>([]);

  useEffect(() => {
    setLoadingContent(true);
    setDataRoute([]);
    alertSaveChangeRoute.destroy();
    if (props.currentTab === '2') {
      setOpenAlertSaveChangeRoute(false);
      getRegisterClassRoute();
    }
  }, [props.currentTab]);

  const getRegisterClassRoute = async () => {
    startTransition(() => {
      getCourseRoadmap({ enrollClassId: location.state.id, courseId: location.state.courseId }).then((res) => {
        if (res.status === 200) {
          let courseRoadmapAPI = res.data.data;
          let courseRoadmapRender: TreeDataNode[] = [];
          courseRoadmapAPI.forEach((section: any, i: number) => {
            let itemSectionRender = {
              sectionId: section.sectionId,
              title: section.name,
              key: JSON.stringify(section.sectionId),
              children: section.sequences.map((sequence: any) => {
                let defaultSequenceValue: any = [];
                let defaultSequenceTimeValue: any = [];
                if (sequence.roadmapStartedDate !== null && sequence.roadmapEndedDate !== null) {
                  defaultSequenceValue = [dayjs(sequence.roadmapStartedDate, dateFormatAPI), dayjs(sequence.roadmapEndedDate, dateFormatAPI)];
                  defaultSequenceTimeValue = [dayjs(sequence.roadmapStartedDate).tz('Asia/Bangkok'), dayjs(sequence.roadmapEndedDate).tz('Asia/Bangkok')];
                }
                return {
                  sequenceId: sequence.sequenceId,
                  title: sequence.name,
                  key: JSON.stringify(sequence.sequenceId),
                  roadmapEndedDate: sequence.roadmapEndedDate,
                  roadmapStartedDate: sequence.roadmapStartedDate,
                  enrollClassRoadmapid: sequence.enrollClassRoadmapid,
                  enrollClassId: location.state.id,
                  minDate: dayjs(location.state.closedDate, dateFormatAPI),
                  maxDate: dayjs(location.state.closingDate, dateFormatAPI),
                  defaultValue: defaultSequenceValue,
                  defaultTimeValue: defaultSequenceTimeValue,

                  children: sequence.units.map((unit: any) => {
                    let defaultUnitValue: any = [];
                    let defaultUnitTimeValue: any = [];
                    let defaultUnitMin: any = null;
                    let defaultUnitMax: any = null;

                    if (unit.roadmapStartedDate !== null && unit.roadmapEndedDate !== null) {
                      defaultUnitValue = [dayjs(unit.roadmapStartedDate, dateFormatAPI), dayjs(unit.roadmapEndedDate, dateFormatAPI)];
                      defaultUnitTimeValue = [dayjs(unit.roadmapStartedDate).tz('Asia/Bangkok'), dayjs(unit.roadmapEndedDate).tz('Asia/Bangkok')];
                    }

                    if (sequence.roadmapStartedDate !== null && sequence.roadmapEndedDate !== null) {
                      defaultUnitMin = dayjs(sequence.roadmapStartedDate, dateFormatAPI);
                      defaultUnitMax = dayjs(sequence.roadmapEndedDate, dateFormatAPI);
                    } else {
                      defaultUnitMin = dayjs(location.state.closedDate, dateFormatAPI);
                      defaultUnitMax = dayjs(location.state.closingDate, dateFormatAPI);
                    }

                    return {
                      unitId: unit.unitId,
                      title: unit.name,
                      key: JSON.stringify(unit.unitId),
                      roadmapEndedDate: unit.roadmapEndedDate,
                      roadmapStartedDate: unit.roadmapStartedDate,
                      enrollClassRoadmapid: unit.enrollClassRoadmapid,
                      enrollClassId: location.state.id,
                      minDate: defaultUnitMin,
                      maxDate: defaultUnitMax,
                      defaultValue: defaultUnitValue,
                      defaultTimeValue: defaultUnitTimeValue,
                    }
                  }),
                }
              }),
            }
            courseRoadmapRender.push(itemSectionRender);
            if (i === (courseRoadmapAPI.length - 1)) {
              setDataRoute(courseRoadmapRender);
            }
          });
        }
      }).catch((error) => { });
    });
  };

  const formatChangeDate = (id: number, dates: any, dateStrings: [string, string], isUpdateSequence?: boolean) => {
    if (dateStrings[1] !== '') {
      setOpenAlertSaveChangeRoute(true);
      if (!openAlertSaveChangeRoute) {
        openSaveAlert();
        setDirty(true);
      }
      let changeDataRoute = dataRoute;
      let newDateTimeRange = {
        defaultValue: dates,
        roadmapStartedDate: moment(dateStrings[0], dateFormatHTML).format(dateFormatAPI),
        roadmapEndedDate: moment(dateStrings[1], dateFormatHTML).format(dateFormatAPI),
      }
      if (isUpdateSequence) {
        changeDataRoute.forEach((section: any) => {
          const sequence = section.children.find((sequence: any) => { return sequence.sequenceId === id });
          if (sequence) {
            const sequenceChild = sequence.children;
            let newDateLimitRange = {
              minDate: dayjs(sequence.roadmapStartedDate, dateFormatAPI),
              maxDate: dayjs(sequence.roadmapEndedDate, dateFormatAPI),
            }
            sequenceChild.forEach((unit: any) => {
              updateDataRoute(changeDataRoute, unit.unitId, newDateLimitRange);
            });
          }
        });
      }
      updateDataRoute(changeDataRoute, id, newDateTimeRange);
      const itemChange = findDataRoute(changeDataRoute, id);
      setDataRoute(changeDataRoute);
      formatChangeRouteToApi(itemChange);
    }
  }

  const formatChangeTime = (id: number, times: any, timeStrings: [string, string]) => {
    if (timeStrings[1] !== '') {
      setOpenAlertSaveChangeRoute(true);
      if (!openAlertSaveChangeRoute) {
        openSaveAlert();
        setDirty(true);
      }
      let changeDataRoute = dataRoute;
      const itemChange = findDataRoute(changeDataRoute, id);
      let newDateTimeRange = {
        defaultTimeValue: times,
        roadmapStartedDate: combineDateTime(itemChange.roadmapStartedDate, timeStrings[0]),
        roadmapEndedDate: combineDateTime(itemChange.roadmapEndedDate, timeStrings[1]),
        timeStartInDay: timeStrings[0],
        timeEndInDay: timeStrings[1],
      }
      updateDataRoute(changeDataRoute, id, newDateTimeRange);
      itemChange.roadmapStartedDate = newDateTimeRange.roadmapStartedDate;
      itemChange.roadmapEndedDate = newDateTimeRange.roadmapEndedDate;
      itemChange.timeStartInDay = newDateTimeRange.timeStartInDay;
      itemChange.timeEndInDay = newDateTimeRange.timeEndInDay;
      setDataRoute(changeDataRoute);
      formatChangeRouteToApi(itemChange);
    }
  };

  const updateDataRoute = (changeDataRoute: any, id: number, newValue: any): boolean => {
    for (let i = 0; i < changeDataRoute.length; i++) {
      if (changeDataRoute[i].key === JSON.stringify(id)) {
        changeDataRoute[i] = { ...changeDataRoute[i], ...newValue };
        return changeDataRoute;
      }
      if (changeDataRoute[i].children) {
        const found = updateDataRoute(changeDataRoute[i].children, id, newValue);
        if (found) return true;
      }
    }
    return false;
  };

  const findDataRoute = (changeDataRoute: any, id: number): any => {
    for (const item of changeDataRoute) {
      if (item.key === JSON.stringify(id)) {
        return item;
      }
      if (item.children) {
        const result = findDataRoute(item.children, id);
        if (result) {
          return result;
        }
      }
    }
    return undefined;
  };

  const formatChangeRouteToApi = (itemChangeRoute: any) => {
    const getDataChangeRoute = dataChangeRoute;
    let checkItemChangeRouteIndex: any;
    let pushItemChangeRoute: any;
    let idItemChangeRoute: any;
    pushItemChangeRoute = {
      enrollClassRoadmapid: itemChangeRoute.enrollClassRoadmapid,
      enrollClassId: itemChangeRoute.enrollClassId,
      roadmapStartedDate: new Date(itemChangeRoute.roadmapStartedDate).toISOString(),
      roadmapEndedDate: new Date(itemChangeRoute.roadmapEndedDate).toISOString(),
      unitId: 0,
      sequenceId: 0,
      key: '',
      timeStartInDay: itemChangeRoute.timeStartInDay,
      timeEndInDay: itemChangeRoute.timeEndInDay,
    }
    if (_.has(itemChangeRoute, 'sequenceId')) {
      delete pushItemChangeRoute.unitId;
      pushItemChangeRoute.sequenceId = itemChangeRoute.sequenceId;
      pushItemChangeRoute.key = JSON.stringify(itemChangeRoute.sequenceId);
      idItemChangeRoute = itemChangeRoute.sequenceId;
      checkItemChangeRouteIndex = getDataChangeRoute.findIndex((item: any) => {
        return item.sequenceId === itemChangeRoute.sequenceId
      });
    }

    if (_.has(itemChangeRoute, 'unitId')) {
      delete pushItemChangeRoute.sequenceId;
      pushItemChangeRoute.unitId = itemChangeRoute.unitId;
      pushItemChangeRoute.key = JSON.stringify(itemChangeRoute.unitId);
      idItemChangeRoute = itemChangeRoute.unitId;
      checkItemChangeRouteIndex = getDataChangeRoute.findIndex((item: any) => {
        return item.unitId === itemChangeRoute.unitId
      });
    }

    if (checkItemChangeRouteIndex > -1) {
      updateDataRoute(getDataChangeRoute, idItemChangeRoute, pushItemChangeRoute);
    } else {
      getDataChangeRoute.push(pushItemChangeRoute);
    }
    setDataChangeRoute(getDataChangeRoute);
  }

  const openSaveAlert = () => {
    const btn = (
      <Space>
        <Button type="primary" onClick={() => {
          saveChangeRoute()
        }}>
          Lưu
        </Button>
        <Button type="link" onClick={() => {
          alertSaveChangeRoute.destroy()
        }}>
          Hủy
        </Button>
      </Space>
    );
    alertSaveChangeRoute.open({
      message: 'Thông báo lưu',
      description: 'Bạn vừa thay đổi thời gian khóa học',
      btn,
      placement: 'bottomRight',
      duration: 0,
      closeIcon: false,
    });
  };

  const saveChangeRoute = () => {
    alertSaveChangeRoute.destroy();
    setDirty(false);
    setOpenAlertSaveChangeRoute(false);
    const callApiUpdateCourseRoadmap = updateCourseRoadmap(dataChangeRoute);
    callApiUpdateCourseRoadmap.then((res: any) => {
      const dataRes = res.data;
      message.success(dataRes.message);
    }).catch((error: any) => { });
  }

  const combineDateTime = (dateStr: string, timeStr: string) => {
    const date = new Date(dateStr);
    const [hours, minutes] = timeStr.split(':').map(Number);
    date.setUTCHours(hours - 7, minutes);
    return date.toISOString();
  }

  return (
    <>
      {contextSaveChangeRoute}
      <section className="wrapper-settings">
        <div className="registration-class-route">
          <CustomCard className="wrapper-settings--card">
            {dataRoute.length > 0 ? dataRoute.map((section: any) => {
              return (
                <div key={section.sectionId} className='registration-class-route__item'>
                  <div className='registration-class-route__item__section'>
                    <div className='title'>{section.title}</div>
                    {section.children.map((sequence: any) => {
                      return (
                        <div key={sequence.sequenceId} className='registration-class-route__item__sequence'>
                          <div className='top'>
                            <div className='title'>
                              <span><FundProjectionScreenOutlined /></span>
                              <span>{sequence.title}</span>
                            </div>
                            <div className='date-time'>
                              <DatePicker.RangePicker
                                className='mr-2'
                                allowClear={false}
                                defaultValue={sequence.defaultValue}
                                format={dateFormatHTML}
                                placeholder={["Bắt đầu", "Kết thúc"]}
                                minDate={sequence.minDate}
                                maxDate={sequence.maxDate}
                                onChange={(dates, dateStrings,) => {
                                  formatChangeDate(sequence.sequenceId, dates, dateStrings, true);
                                }}
                              />
                              <TimePicker.RangePicker
                                allowClear={false}
                                defaultValue={sequence.defaultTimeValue}
                                disabled={isEmpty(sequence.defaultValue)}
                                placeholder={["Bắt đầu", "Kết thúc"]}
                                format={timeFormat}
                                onChange={(times, timeStrings) => {
                                  formatChangeTime(sequence.sequenceId, times, timeStrings);
                                }}
                              />
                            </div>
                          </div>
                          <div className='bottom'>
                            {sequence.children.map((unit: any) => {
                              return (
                                <div key={unit.unitId} className='registration-class-route__item__unit'>
                                  <div className='title'>
                                    <span><FilePdfOutlined /></span>
                                    <span>{unit.title}</span>
                                  </div>
                                  <div className='date-time'>
                                    <DatePicker.RangePicker
                                      className='mr-2'
                                      allowClear={false}
                                      defaultValue={unit.defaultValue}
                                      format={dateFormatHTML}
                                      placeholder={["Bắt đầu", "Kết thúc"]}
                                      minDate={unit.minDate}
                                      maxDate={unit.maxDate}
                                      onChange={(dates, dateStrings) => {
                                        formatChangeDate(unit.unitId, dates, dateStrings, false);
                                      }}
                                    />
                                    <TimePicker.RangePicker
                                      allowClear={false}
                                      defaultValue={unit.defaultTimeValue}
                                      disabled={isEmpty(unit.defaultValue)}
                                      placeholder={["Bắt đầu", "Kết thúc"]}
                                      format={timeFormat}
                                      onChange={(times, timeStrings) => {
                                        formatChangeTime(unit.unitId, times, timeStrings);
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            }) : (
              <div>
                Không có dữ liệu
              </div>
            )}
          </CustomCard>
        </div>
      </section>
    </>
  );
}

export default RouteTab;
