import {
  Col,
  Layout,
  Row,
  TableColumnsType,
  TableProps,
  Typography,
} from "antd";
import moment from "moment";
import { ChangeEvent, FunctionComponent, useEffect, useMemo, useState, KeyboardEvent } from "react";
import { useLocation, useParams } from "react-router-dom";
import PageHeader from "../../../../components/page-header/PageHeader";
import TableData from "../../../../components/table-data/TableData";
import { routesConfig } from "../../../../config/routes";
// import {
//   getAppriseResultInformationByCourse,
//   getListAppriseResultInformationByCourse,
//   getProfessionalApproveResultByCourse,
// } from "../../../../../service/course-browse/course-browse";
// import { HistoryData } from "../../../../../types/assign-course-construction";
import "./index.scss";
import FilterDataCourseBrowse from "./filter-table/FilterTable";
import FormFilter from "./filter-group/FormFilter";
import { getCourseHistory } from "../../../../service/content-course/contenCourseApi";
import { isEmpty } from "lodash";
// import { objectToQueryString } from "../../../../../utils/getPageFromLink";

const { Content } = Layout;
const imgPath = "/images/";

const ActionCourseHistory: FunctionComponent = () => {
  const location = useLocation();
  const { isCourseEndorse } = location.state;
  const [searchValue, setSearchValue] = useState<string>("");

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [history, setHistory] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [commonInfoCourse, setCommonInfoCourse] = useState<any>({});
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const params = useParams();

  const getHistoryCourseData = async () => {
    setLoading(true);
    const data = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue?.trim()
    };

    await getCourseHistory(params?.id, data).then((res) => {
      console.log(res)
      if (res.status === 200) {
        setTotalRecords(res.data.data.total)
        setHistory(res.data.data.data);
        setLoading(false)
      }
    })
  };

  useEffect(() => {
    setLoading(false);
    getHistoryCourseData();
  }, [pageNumber, pageSize]);

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 10,
      shouldCellUpdate(record, prevRecord) {
        return true;
      },
      render: (_: any, record, index) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 40,
      render: (_: any, record, index) => {
        return <div>{record?.id}</div>;
      },
    },
    {
      title: "Thời gian",
      dataIndex: "time",
      key: "time",
      width: 30,
      render: (_: any, record, index) => {
        return (
          <div className="flex gap-1">
            <p>{!isEmpty(record?.time) ? moment(record?.time).format("DD/MM/yyyy") : null}</p>
            <p>{!isEmpty(record?.time) ? moment(record?.time).format("HH:mm:ss") : null}</p>
          </div>
        );
      },
    },
    {
      title: "Tài khoản",
      dataIndex: "username",
      key: "username",
      width: 40,
    },
    {
      title: "Sự kiện",
      dataIndex: "courseName",
      key: "courseName",
      width: 40,
    },
    {
      title: "Thao tác",
      dataIndex: "operation",
      key: "operation",
      width: 40,
    },
  ];

  const defaultCheckedList = dataColumns.map((item) => item.key as string);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const newColumnsAsigned = useMemo(() => {
    return dataColumns.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
  }, [checkedList]);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {

  };

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleSubmitSearch = () => {
    getHistoryCourseData();
  };

  const handleSearch = (e: any) => {
    if (e.key === "Enter") {
      getHistoryCourseData();
    }
  };

  const handleChangeInput = (e: any) => {
    const { value, name } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };

  return (
    <>
      <Content className="action-course-history">
        <div className="content-action-course">
          <div
            className={`page-header-group--layout ${showFilter ? "open-filter" : ""
              }`}
          >
            <div className="table-header--wrapper pl-6 pr-6 pt-6">
              <div className="table-header--left">
                <h1 className="fs-22">Lịch sử hoạt động</h1>
              </div>
              <FilterDataCourseBrowse
                searchValue={searchValue}
                dataColumns={dataColumns}
                handleOpenFilter={handleOpenFilter}
                handleSubmitSearch={handleSubmitSearch}
                handleSearch={handleSearch}
                checkedList={checkedList}
                setCheckedList={setCheckedList}
                handleChangeInput={handleChangeInput}
              />
            </div>
          </div>

          <TableData
            dataTable={history}
            dataColumns={newColumnsAsigned}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
            hideSelection={false}
            pagination={true}
          />
        </div>


      </Content>
    </>
  );
};

export default ActionCourseHistory;
