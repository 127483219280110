import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../config/api/configApiv2";
import {
  ApproveTransactionPayload,
  BankInfoPayload,
  ExportTransactionFilePayload,
  FilterSearchTransactionValues,
} from "../../types/transaction";

export const searchTransactions: (
  payload: FilterSearchTransactionValues
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/mooc-course-payment-transaction/get-all-transactions`,
    payload
  );
};

export const getTransactionDetail: (
  id: number
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(
    `/mooc-course-payment-transaction/get-transaction/${id}`
  );
};

export const approveTransaction: (
  payload: ApproveTransactionPayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/mooc-course-payment-approval/approve-transaction`,
    payload
  );
};

export const getApprovalHistories: (
  id: number
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(
    `/mooc-course-payment-approval/get-approval-histories/${id}`
  );
};

export const getTransactionUniversities: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/mooc-course-payment-transaction/get-university-list`,
    payload
  );
};

export const getBankList: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/university-bank-account/get-all-bank`);
};

export const createBankInfo: (
  payload: BankInfoPayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/university-bank-account/create-bank-account`,
    payload
  );
};

export const getBankInfo: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/university-bank-account/get-bank-info`);
};

export const exportPaymentTransactionFile: (
  payload: ExportTransactionFilePayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/mooc-course-payment-transaction/export-file`,
    payload,
    { responseType: "arraybuffer" }
  );
};
