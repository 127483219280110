import React from "react";
import { Col, Empty, Row, Spin } from "antd";
import "./index.scss";
import StatisticalReportCard from "../../StatisticalReportCard";
import TableWithTotals from "../../../../../../components/table-with-totals/TableWithTotals";
import { handleExportReport } from "../../../../../../utils/staticReport";
import {
  exportTableStudentIndustry,
  exportTableTeacherIndustry,
  exportTableUnitResourcesUniversity,
  exportTableUniversityCourseBySponsor,
  exportTableVisitorUniversity,
  exportTableVisitsUniversity,
} from "../../../../../../service/statistical-report";
import dayjs from "dayjs";
import { staticReportStore } from "../../../../../../stores/stores";
import { createTableData } from "../../../../../../components/static-report/table/tableConvertData";
import { BarStackedChartProps } from "../../../../../../types/static-report";
import { formatDateV2 } from "../../../../../../utils/converDate";
import { isNull } from "lodash";

type OverviewTableProps = {
  synthesizeUniversityList: Array<any>;
  universityVisits: Array<any>;
  universityVisitors: Array<any>;
  teacherByUniversity: Array<BarStackedChartProps>;
  studentByUniversity: Array<BarStackedChartProps>;
  courseByUniversitySponsor: Array<BarStackedChartProps>;
  unitResources: Array<any>;
  loading: boolean;
};

const OverviewTable = ({
  synthesizeUniversityList,
  universityVisits,
  universityVisitors,
  teacherByUniversity,
  studentByUniversity,
  courseByUniversitySponsor,
  unitResources,
  loading,
}: OverviewTableProps) => {
  const { staticReportType, filterValues, tab } = staticReportStore();

  const exportPayload = {
    from: dayjs(filterValues.dateFrom).toISOString(),
    to: dayjs(filterValues.dateTo).toISOString(),
    courseLevelIds: filterValues?.courseLevelIds,
    industryGroupIds: filterValues?.industryGroupIds,
    courseIds: filterValues?.courseIds,
    timeUnit: filterValues.timeUnit,
    teacherIds: filterValues?.lecturerIds,
    universityId: filterValues?.universityIds,
  };

  return (
    <>
      <section className="section-table--chart">
        <div className="table-chart--label">
          <p className="text">Thống kê lượt truy cập</p>
        </div>
        <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượt truy cập"
              content={
                <Spin spinning={loading}>
                  {universityVisits.length > 0 ? (
                    <TableWithTotals
                      totalLabel={"Tổng"}
                      data={createTableData(universityVisits).dataSource}
                      columns={[
                        {
                          title: "Ngày",
                          dataIndex: "name",
                          key: "name",
                          render: (text: any) => (
                            <div className="font-weight-5">
                              {formatDateV2(text)}
                            </div>
                          ),
                        },
                        ...createTableData(universityVisits).columns,
                        {
                          title: "Tổng",
                          dataIndex: "Tổng",
                          key: "Tổng",
                          align: "right",
                        },
                      ]}
                      keyColumn="criteria"
                      // height={275}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "Excel",
                  action: () =>
                    handleExportReport(
                      exportTableVisitsUniversity,
                      "Số lượt truy cập",
                      "excel",
                      exportPayload
                    ),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportReport(
                      exportTableVisitsUniversity,
                      "Số lượt truy cập",
                      "pdf",
                      exportPayload
                    ),
                },
              ]}
            />
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số người truy cập"
              content={
                <Spin spinning={loading}>
                  {!isNull(universityVisitors) &&
                  universityVisitors.length > 0 ? (
                    <TableWithTotals
                      totalLabel={"Tổng"}
                      data={createTableData(universityVisitors).dataSource}
                      columns={[
                        {
                          title: "Ngày",
                          dataIndex: "name",
                          key: "name",
                          render: (text: any) => (
                            <div className="font-weight-5">
                              {formatDateV2(text)}
                            </div>
                          ),
                        },
                        ...createTableData(universityVisitors, "right").columns,
                        {
                          title: "Tổng",
                          dataIndex: "Tổng",
                          key: "Tổng",
                          align: "right",
                        },
                      ]}
                      keyColumn="criteria"
                      height={275}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "Excel",
                  action: () =>
                    handleExportReport(
                      exportTableVisitorUniversity,
                      "Số người truy cập",
                      "excel",
                      exportPayload
                    ),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportReport(
                      exportTableVisitorUniversity,
                      "Số người truy cập",
                      "pdf",
                      exportPayload
                    ),
                },
              ]}
            />
          </Col>
        </Row>
      </section>
      <section className="section-table--chart">
        <div className="table-chart--label">
          <p className="text">Thống kê giảng viên - học viên - khóa học</p>
        </div>
        <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
          <Col sm={{ span: 24 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượng giảng viên"
              content={
                <Spin spinning={loading}>
                  {teacherByUniversity.length > 0 ? (
                    <TableWithTotals
                      totalLabel={"Tổng"}
                      data={createTableData(teacherByUniversity).dataSource}
                      columns={[
                        {
                          title: "Khoa",
                          dataIndex: "name",
                          key: "name",
                          render: (text: any) => (
                            <div className="font-weight-5">{text}</div>
                          ),
                        },
                        ...createTableData(teacherByUniversity, "right")
                          .columns,
                        {
                          title: "Tổng",
                          dataIndex: "Tổng",
                          key: "Tổng",
                          align: "right",
                        },
                      ]}
                      keyColumn="criteria"
                      height={275}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "Excel",
                  action: () =>
                    handleExportReport(
                      exportTableTeacherIndustry,
                      "Số lượng giảng viên",
                      "excel",
                      exportPayload
                    ),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportReport(
                      exportTableTeacherIndustry,
                      "Số lượng giảng viên",
                      "pdf",
                      exportPayload
                    ),
                },
              ]}
            />
          </Col>
        </Row>
      </section>
      <section className="section-table--chart">
        <div className="table-chart--label">
          <p className="text">Thống kê số lượng học liệu</p>
        </div>
        <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượng học viên"
              content={
                <Spin spinning={loading}>
                  {studentByUniversity.length > 0 ? (
                    <TableWithTotals
                      totalLabel={"Tổng"}
                      data={createTableData(studentByUniversity).dataSource}
                      columns={[
                        {
                          title: "Khoa",
                          dataIndex: "name",
                          key: "name",
                          render: (text: any) => (
                            <div className="font-weight-5">{text}</div>
                          ),
                        },
                        ...createTableData(studentByUniversity, "right")
                          .columns,
                        {
                          title: "Tổng",
                          dataIndex: "Tổng",
                          key: "Tổng",
                          align: "right",
                        },
                      ]}
                      keyColumn="criteria"
                      height={275}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "Excel",
                  action: () =>
                    handleExportReport(
                      exportTableStudentIndustry,
                      "Số lượng học viên",
                      "excel",
                      exportPayload
                    ),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportReport(
                      exportTableStudentIndustry,
                      "Số lượng học viên",
                      "pdf",
                      exportPayload
                    ),
                },
              ]}
            />
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượng khoá học theo phân công"
              content={
                <Spin spinning={loading}>
                  {courseByUniversitySponsor.length > 0 ? (
                    <TableWithTotals
                      totalLabel={"Tổng"}
                      data={
                        createTableData(courseByUniversitySponsor).dataSource
                      }
                      columns={[
                        {
                          title: "Khoa",
                          dataIndex: "name",
                          key: "name",
                          render: (text: any) => (
                            <div className="font-weight-5">{text}</div>
                          ),
                        },
                        ...createTableData(courseByUniversitySponsor, "right")
                          .columns,
                        {
                          title: "Tổng",
                          dataIndex: "Tổng",
                          key: "Tổng",
                          align: "right",
                        },
                      ]}
                      keyColumn="criteria"
                      height={275}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "Excel",
                  action: () =>
                    handleExportReport(
                      exportTableUniversityCourseBySponsor,
                      "Số lượng khoá học theo phân công",
                      "excel",
                      exportPayload
                    ),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportReport(
                      exportTableUniversityCourseBySponsor,
                      "Số lượng khoá học theo phân công",
                      "pdf",
                      exportPayload
                    ),
                },
              ]}
            />
          </Col>
        </Row>
      </section>
      <section className="section-table--chart">
        <div className="table-chart--label">
          <p className="text">Thống kê số lượng học liệu</p>
        </div>
        <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
          <Col sm={{ span: 24 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượng tài nguyên theo khoa"
              content={
                <Spin spinning={loading}>
                  {unitResources.length > 0 ? (
                    <TableWithTotals
                      totalLabel={"Tổng"}
                      data={createTableData(unitResources).dataSource}
                      columns={[
                        {
                          title: "Khoa",
                          dataIndex: "name",
                          key: "name",
                          render: (text: any) => (
                            <div className="font-weight-5">{text}</div>
                          ),
                        },
                        ...createTableData(unitResources, "right").columns,
                        {
                          title: "Tổng",
                          dataIndex: "Tổng",
                          key: "Tổng",
                          align: "right",
                        },
                      ]}
                      keyColumn="criteria"
                      height={275}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "Excel",
                  action: () =>
                    handleExportReport(
                      exportTableUniversityCourseBySponsor,
                      "Số lượng tài nguyên theo khoa",
                      "excel",
                      exportPayload
                    ),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportReport(
                      exportTableUniversityCourseBySponsor,
                      "Số lượng tài nguyên theo khoa",
                      "pdf",
                      exportPayload
                    ),
                },
              ]}
            />
          </Col>
        </Row>
      </section>
    </>
  );
};

export default OverviewTable;
