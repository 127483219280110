import { Button, Flex, PaginationProps, Table, TableColumnsType } from "antd";
import React, { useEffect, useState } from "react";
import { DEFAULT_PAGE_NUM, DEFAULT_PAGE_SIZE } from "../../constants";
import {
  DownloadOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import { searchTransactions } from "../../service/transaction";
import {
  FilterSearchTransactionValues,
  TransactionProps,
} from "../../types/transaction";
import moment from "moment";
import { formatCurrency } from "../../utils/format";
import { isNumber, toNumber } from "lodash";
import BankInfoModal from "./components/BankInfoModal";
import useKeyword from "../../hooks/useKeyword";
import ExportFile from "./components/ExportFile";

interface WaitingForConfirmationProps {
  filter?: FilterSearchTransactionValues;
  isSubmitSearch?: boolean;
  searchValue?: string;
}

function Confirmed({
  filter,
  isSubmitSearch,
  searchValue,
}: WaitingForConfirmationProps) {
  const university = useKeyword("university");
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [pageNum, setPageNum] = useState<number>(DEFAULT_PAGE_NUM);
  const [transactions, setTransactions] = useState<TransactionProps[]>([]);

  useEffect(() => {
    const getTransactions = async () => {
      try {
        setLoading(true);
        const res = await searchTransactions({
          page: pageNum,
          size: pageSize,
          isConfirmed: true,
          universityId: toNumber(university) || undefined,
          startDate: filter?.startDate,
          endDate: filter?.endDate,
          startTime: filter?.startTime,
          endTime: filter?.endTime,
          courseIds: filter?.courseIds,
          keyword: searchValue || undefined,
        });
        setTotal(res.data?.data?.totalPages);
        setTransactions(res.data.data?.content);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getTransactions();
  }, [pageNum, pageSize, isSubmitSearch]);

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <LeftOutlined />;
    }
    if (type === "next") {
      return <RightOutlined />;
    }
    return originalElement;
  };

  const handleChangePaginate = (page: number, size: number) => {
    setPageNum(page);
    setPageSize(size);
  };

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      align: "center",
      width: "5%",
      render: (_: any, record, index: number) => {
        return <div>{record.numericalOrder}</div>;
      },
    },
    {
      title: "Mã giao dịch",
      dataIndex: "id",
      key: "id",
      width: 150,
    },
    {
      title: "Email",
      dataIndex: "studentEmail",
      key: "studentEmail",
      width: 200,
    },
    {
      title: "Số điện thoại",
      dataIndex: "studentPhoneNumber",
      key: "studentPhoneNumber",
      render: (value: string) => value || "_",
    },
    {
      title: "Thời gian",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 200,
      render: (value: string) =>
        value ? moment(value).format("HH:mm:ss - DD/MM/YYYY") : "_",
    },
    {
      title: "Tên khoá học",
      dataIndex: "courseName",
      key: "courseName",
    },
    {
      title: "Giá khoá học (1)",
      dataIndex: "coursePrice",
      key: "coursePrice",
      render: (value: number) => formatCurrency(value) || "_",
    },
    {
      title: "Giá tín chỉ (2)",
      dataIndex: "certificatePrice",
      key: "certificatePrice",
      render: (value: number) => formatCurrency(value) || "_",
    },
    {
      title: "Tổng (1+2)",
      dataIndex: "total",
      key: "total",
      render: (_: any, record, index: number) => {
        const total = record?.coursePrice + record?.certificatePrice;
        return <div>{isNumber(total) ? formatCurrency(total) : "_"}</div>;
      },
    },
    {
      dataIndex: "action",
      key: "action",
      render: (_: any, record, index: number) => {
        return (
          <Link
            className="text-primary"
            to={`${routesConfig.enrollmentConfirmation}/${record?.id}`}
          >
            Phê duyệt
          </Link>
        );
      },
    },
  ];

  return (
    <div className="session-content">
      <div className="table-content">
        <Flex className="table-header" justify="space-between" align="center">
          <span className="text-20 font-weight-5 line-height-28">
            Danh sách ghi danh
          </span>
          <Flex gap={8}>
            <BankInfoModal>
              <Button className="btn-primary">
                Thông tin tài khoản ngân hàng
              </Button>
            </BankInfoModal>
            <ExportFile isConfirmed={true} />
          </Flex>
        </Flex>
        <Table
          loading={loading}
          dataSource={transactions?.map(
            (item: TransactionProps, index: number) => ({
              ...item,
              key: index,
              numericalOrder: index + 1,
            })
          )}
          columns={dataColumns}
          className="pagination-table"
          scroll={{ x: 1400, y: 600 }}
          pagination={{
            total: total,
            pageSize: pageSize,
            current: pageNum,
            itemRender: itemRender,
            showSizeChanger: true,
            onChange: handleChangePaginate,
            locale: {
              items_per_page: "/ trang",
              jump_to: "Đi đến trang",
              page: "",
            },
            showQuickJumper: true,
            showTotal: (total: number) => `Tổng số ${total} bản ghi`,
          }}
        />
      </div>
    </div>
  );
}

export default Confirmed;
