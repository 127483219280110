import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../config/api/configApiv2";

export const getAccountConfiguration: () => Promise<AxiosResponse<any>> = (
  
) => {
  return axiosConfigV2.get(`/mooc-global-config/account`);
};

export const saveAccountConfiguration: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/mooc-global-config`, data);
};