import React, { useEffect, useRef } from "react";
import katex from "katex";

interface FormulaRendererProps {
  htmlString: string;
  className?: string;
}

const FormulaRenderer: React.FC<FormulaRendererProps> = ({
  htmlString,
  className,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      // Render HTML ban đầu
      containerRef.current.innerHTML = htmlString;

      // Tìm tất cả các công thức trong thẻ <span class="ql-formula">
      const formulaElements =
        containerRef.current.querySelectorAll(".ql-formula");

      formulaElements.forEach((element) => {
        const formula = element.getAttribute("data-value");
        if (formula) {
          try {
            // Sử dụng KaTeX để render công thức vào nội dung
            katex.render(formula, element as HTMLElement, {
              throwOnError: false,
            });
          } catch (error) {
            console.error("Lỗi khi render công thức:", error);
          }
        }
      });
    }
  }, [htmlString]);

  return <div className={className} ref={containerRef} />;
};

export default FormulaRenderer;
