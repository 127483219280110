import {
  ApartmentOutlined,
  AuditOutlined,
  BarChartOutlined,
  BellOutlined,
  BookOutlined,
  BulbOutlined,
  FolderOpenOutlined,
  FormOutlined,
  FundOutlined,
  GlobalOutlined,
  ReadOutlined,
  ReconciliationOutlined,
  SettingOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Key, ReactNode } from "react";
import SafeLink from "../../components/link/SafeLink";
import { routesConfig } from "../../config/routes";
import { MenuItem } from "../../types/sidebar";
import { Permission } from "./permission.enum";

function getItem(
  label: ReactNode,
  key: Key,
  icon?: ReactNode,
  children?: MenuItem[],
  type?: string,
  permission?: string[]
): MenuItem {
  return {
    key,
    label,
    icon,
    children,
    type,
    permission,
  } as MenuItem;
}

export const menuItems: MenuItem[] = [
  getItem(
    "Quản lý tài khoản",
    routesConfig.accountSystem,
    <TeamOutlined />,
    [
      getItem(
        <SafeLink
          to={`/${routesConfig.accountSystem}/${routesConfig.schoolManagementParticipates}`}
        >
          Trường tham gia nền tảng
        </SafeLink>,
        `/${routesConfig.accountSystem}/${routesConfig.schoolManagementParticipates}`,
        undefined,
        undefined,
        undefined
        // [Permission.ProcessRequestUpdate]
      ),
      getItem(
        <SafeLink
          to={`/${routesConfig.accountSystem}/${routesConfig.listUsers}`}
        >
          Tài khoản quản trị cơ sở
        </SafeLink>,
        `/${routesConfig.accountSystem}/${routesConfig.listUsers}`,
        undefined,
        undefined,
        undefined
        // [
        //   Permission.LockUnlockFAAccountUpdate,
        //   Permission.ChangeFAAccountUpdate,
        //   Permission.DeleteFAAccountUpdate,
        //   Permission.CreateFAAccountUpdate,
        // ]
      ),
      getItem(
        <SafeLink
          to={`/${routesConfig.accountSystem}/${routesConfig.listTeachers}`}
        >
          Tài khoản giảng viên
        </SafeLink>,
        `/${routesConfig.accountSystem}/${routesConfig.listTeachers}`,
        undefined,
        undefined,
        undefined
        // [
        //   Permission.LockUnlockLecturerAccountUpdate,
        //   Permission.ChangeLecturerAccountUpdate,
        //   Permission.DeleteLecturerAccountUpdate,
        //   Permission.CreateLecturerAccountUpdate,
        // ]
      ),
      getItem(
        <SafeLink
          to={`/${routesConfig.accountSystem}/${routesConfig.listStudents}`}
        >
          Tài khoản sinh viên
        </SafeLink>,
        `/${routesConfig.accountSystem}/${routesConfig.listStudents}`,
        undefined,
        undefined,
        undefined
      ),
      getItem(
        <SafeLink
          to={`/${routesConfig.accountSystem}/${routesConfig.accountRegistrationReport}`}
        >
          Báo cáo đăng ký tài khoản
        </SafeLink>,
        `/${routesConfig.accountSystem}/${routesConfig.accountRegistrationReport}`,
        undefined,
        undefined,
        undefined
      ),
      getItem(
        <SafeLink
          to={`/${routesConfig.accountSystem}/${routesConfig.accountConfiguration}`}
        >
          Cấu hình tài khoản
        </SafeLink>,
        `/${routesConfig.accountSystem}/${routesConfig.accountConfiguration}`,
        undefined,
        undefined,
        undefined
        // [Permission.AccountSettingsUpdate]
      ),
    ],
    undefined
  ),

  getItem(
    <SafeLink to={routesConfig.roleManagement}>Quản lý vai trò</SafeLink>,
    routesConfig.roleManagement,
    <ApartmentOutlined />,
    undefined,
    undefined
  ),

  getItem(
    "Xây dựng khoá học",
    "course-construction",
    <BookOutlined />,
    [
      getItem(
        <SafeLink
          to={`/course-construction/${routesConfig.contentConstruction}`}
        >
          Xây dựng nội dung
        </SafeLink>,
        `/course-construction/${routesConfig.contentConstruction}`,
        undefined,
        undefined,
        undefined
        // [Permission.ManageCourseStructureUpdate]
      ),
      getItem(
        "Kiểm định nội dung",
        "course-browse",
        null,
        [
          getItem(
            <SafeLink
              to={`/course-construction/course-browse/${routesConfig.contentBrowse}`}
            >
              QTKH duyệt
            </SafeLink>,
            `/course-construction/course-browse/${routesConfig.contentBrowse}`,
            undefined,
            undefined,
            undefined
            // [Permission.ReviewCoursePresentationPageUpdate]
          ),
          getItem(
            <SafeLink
              to={`/course-construction/course-browse/${routesConfig.courseEvaluation}`}
            >
              Hội đồng thẩm định
            </SafeLink>,
            `/course-construction/course-browse/${routesConfig.courseEvaluation}`,
            undefined,
            undefined,
            undefined
            // [Permission.AssessCourseContentUpdate]
          ),
          getItem(
            <SafeLink
              to={`/course-construction/course-browse/${routesConfig.courseApproval}`}
            >
              Tổ chuyên môn duyệt
            </SafeLink>,
            `/course-construction/${routesConfig.courseApproval}`,
            undefined,
            undefined,
            undefined
            // [Permission.ApproveCourseContentUpdate]
          ),
          getItem(
            <SafeLink
              to={`/course-construction/course-browse/${routesConfig.publishCourse}`}
            >
              Xuất bản khoá học
            </SafeLink>,
            `/course-construction/${routesConfig.publishCourse}`,
            undefined,
            undefined,
            undefined
            // [Permission.PublishCourseUpdate]
          ),
        ],
        undefined,
        undefined
      ),

      getItem(
        <SafeLink to={`/course-construction/${routesConfig.surveyPlan}`}>
          Kế hoạch khảo sát
        </SafeLink>,
        `/course-construction/${routesConfig.surveyPlan}`,
        undefined,
        undefined,
        undefined,
        undefined
      ),
    ],
    undefined
  ),

  getItem(
    <SafeLink to={`/${routesConfig.portalAdministration}`}>
      Quản trị cổng thông tin
    </SafeLink>,
    routesConfig.portalAdministration,
    <GlobalOutlined />,
    undefined,
    undefined,
    undefined
  ),

  getItem(
    "Quản lý danh mục",
    "category-management",
    <FolderOpenOutlined />,
    [
      getItem(
        <SafeLink
          to={`/category-management/${routesConfig.lecturerManagement}`}
        >
          Quản lý giảng viên
        </SafeLink>,
        `/category-management/${routesConfig.lecturerManagement}`,
        undefined,
        undefined,
        undefined
      ),
      getItem(
        <SafeLink to={`/category-management/${routesConfig.studentManagement}`}>
          Quản lý sinh viên
        </SafeLink>,
        `/category-management/${routesConfig.studentManagement}`,
        undefined,
        undefined,
        undefined
      ),
    ],
    undefined
  ),

  // getItem(
  //   <SafeLink to={`/${routesConfig.adminInterface}`}>
  //     Thiết lập giao diện quản trị
  //   </SafeLink>,
  //   `/${routesConfig.adminInterface}`,
  //   <LayoutOutlined />,
  //   undefined,
  //   undefined,
  //   [Permission.InterfaceSetupAdminUpdate]
  // ),

  getItem(
    "Hệ thống gợi ý khoá học",
    "system-suggest-course",
    <BulbOutlined />,
    [
      getItem(
        <SafeLink
          to={`/system-suggest-course/${routesConfig.learningDocumentationSystem}`}
        >
          Hệ thống học liệu
        </SafeLink>,
        `/system-suggest-course/${routesConfig.learningDocumentationSystem}`
      ),
    ]
  ),

  getItem(
    "Mô hình dự đoán xu hướng",
    "prediction-model",
    <FundOutlined />,
    [
      getItem(
        <SafeLink
          to={`/prediction-model/${routesConfig.managementGroupContent}`}
        >
          Quản lý nhóm nội dung
        </SafeLink>,
        `/prediction-model/${routesConfig.managementGroupContent}`,
        undefined,
        undefined,
        undefined
      ),
      getItem(
        <SafeLink to={`/prediction-model/${routesConfig.settingPrediction}`}>
          Cài đặt phân tích
        </SafeLink>,
        `/prediction-model/${routesConfig.settingPrediction}`,
        undefined,
        undefined,
        undefined
      ),
    ],
    undefined
  ),
  getItem(
    <SafeLink to={`${routesConfig.scoringListCourse}`}>
      Quản lý chấm điểm
    </SafeLink>,
    routesConfig.scoringListCourse,
    <AuditOutlined />,
    undefined,
    undefined
  ),
  getItem(
    <SafeLink to={`${routesConfig.notifications}`}>Quản lý thông báo</SafeLink>,
    routesConfig.notifications,
    <BellOutlined />,
    undefined,
    undefined
  ),
  getItem(
    "Cài đặt nâng cao",
    "setting-high-level",
    <SettingOutlined />,
    [
      getItem(
        <SafeLink to={`/setting-high-level/${routesConfig.settingIdentify}`}>
          Cài đặt định danh
        </SafeLink>,
        `/setting-high-level/${routesConfig.settingIdentify}`,
        undefined,
        undefined,
        undefined
      ),
    ],
    undefined
  ),
  getItem(
    "Quản lý khoá học",
    `${routesConfig.courseManagement}`,
    <ReadOutlined />,
    [
      getItem(
        <SafeLink to={`${routesConfig.courseManagement}/course-list`}>
          Danh sách khoá học
        </SafeLink>,
        `${routesConfig.courseManagement}/course-list`,
        undefined,
        undefined,
        undefined
      ),
    ],
    undefined
  ),
  getItem(
    "Báo cáo thống kê",
    `${routesConfig.statisticalReport}`,
    <BarChartOutlined />,
    [
      getItem(
        <SafeLink to={`${routesConfig.statisticalReportForUniversity}`}>
          BCTK dành cho Trường
        </SafeLink>,
        `${routesConfig.statisticalReportForUniversity}`,
        undefined,
        undefined,
        undefined
      ),
    ],
    undefined
  ),
  getItem(
    <SafeLink to={`${routesConfig.enrollmentConfirmation}`}>
      Xác nhận ghi danh
    </SafeLink>,
    routesConfig.enrollmentConfirmation || routesConfig.trainingLocations,
    <ReconciliationOutlined />,
    undefined,
    undefined
  ),
];
