import {Form, Radio} from 'antd';
import React from 'react';
import CustomCard from "../../../../custom-card/CustomCard";
import './certificate.scss'

type CertificateProps = {
  setIsCertificate?: (value: boolean) => void
}

const Certificate = ({setIsCertificate}: CertificateProps) => {


  const handleChange = () => {
    if (setIsCertificate) {
      setIsCertificate(true);
    }
  }

  return (
    <>
      <CustomCard title="Bằng cấp/Chứng chỉ/Chứng nhận" className="wrapper-settings--card">
        <Form.Item name="isHasCertification" className="form-item-setting">
          <Radio.Group>
            <div className="wrapper-radio check-certificate">
              <Radio value={false}>
                Không
              </Radio>
              <Radio value={true}>
                <span className="message">
                  Có
              </span>
              </Radio>
            </div>
          </Radio.Group>
        </Form.Item>
      </CustomCard>
    </>
  );
};

export default Certificate;
