import {
  LogoutOutlined,
  MenuOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  TableOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Dropdown,
  Layout,
  MenuProps,
  Modal,
  Spin,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MyNotificationDrop from "../../components/my-notifycation-drop";
import { routesConfig } from "../../config/routes";
import { globalStore, useAuthStore } from "../../stores/stores";
import "./header.scss";
import { postLogout } from "./services";
import SafeLink from "../../components/link/SafeLink";
import { getPermission } from "../../service/common";
import { getDetailAdminInterface } from "../../service/admin-interface";
import { useViewport } from "../../hooks/useViewport";
const imgPath = "/images/";

export type HeaderLayoutProps = {
  activeMenu: () => void;
};

const HeaderLayout = (props: HeaderLayoutProps) => {
  const logout = useAuthStore((state) => state.logout);
  const accessToken = useAuthStore((state) => state.accessToken);

  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState<any>({});
  const [logoPersonal, setLogoPersonal] = useState<any>("");
  const [backgroundColor, setBackgroundColor] = useState<any>({});
  const [logo, setLogo] = useState<any>("");
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const navigate = useNavigate();
  const { reloadAdminInterface } = globalStore();
  const location = useLocation();
  const pathname = location.pathname;
  const { width } = useViewport();

  useEffect(() => {
    if (width <= 1200) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  useEffect(() => {
    getColor();
  }, [navigate, reloadAdminInterface]);

  useEffect(() => {
    getColorDetail();
  }, [navigate, reloadAdminInterface]);

  const logoutFNC = async () => {
    await postLogout().then((res) => {
      if (res.status === 200) {
        logout();
        setTimeout(() => {
          navigate(routesConfig.login);
          setLoading(false);
        }, 1000);
      }
    });
  };

  const getColor = async () => {
    await getPermission().then((res) => {
      if (res.status === 200) {
        setColor(res.data?.data?.color);
        setLogoPersonal(res.data?.data?.logo);
      }
    });
  };

  const getColorDetail = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id: any = urlParams.get("interfaceId");
    if (id) {
      await getDetailAdminInterface(id).then((res) => {
        if (res.status === 200) {
          setBackgroundColor(res.data?.data?.color?.backgroundColor);
          setLogo(res.data?.data?.logo);
        }
      });
    }
  };

  const { getUserInfo } = useAuthStore((state) => {
    return {
      getUserInfo: state.user,
    };
  });

  const handleLogout = async () => {
    setLoading(true);
    if (accessToken) {
      try {
        logoutFNC();
      } catch (error) {
        logout();
        setLoading(false);
      }
    }
  };

  const { confirm } = Modal;

  const showConfirm = () => {
    confirm({
      title: (
        <div className="title-container">
          <div style={{ color: "#1677FF", fontSize: "20px" }}>
            <LogoutOutlined />
          </div>
          <span>Xác nhận đăng xuất?</span>
        </div>
      ),
      //   centered: true,
      icon: null,
      okText: "Đăng xuất",
      cancelText: "Hủy",
      onOk() {
        handleLogout();
      },
      onCancel() {},
    });
  };

  const items: MenuProps["items"] = [
    {
      key: "0",
      label: (
        <div className="account account-drop">
          <div className="account__avatar">
            <div className="account__avatar__wrapper">
              <a onClick={(e) => e.preventDefault()}>
                <Avatar src={getUserInfo?.anhDaiDien} icon={<UserOutlined />} />{" "}
              </a>
            </div>
          </div>
          <div className="account__name">
            <a
              className="account__name__wrapper"
              onClick={(e) => e.preventDefault()}
            >
              <span>{getUserInfo?.hoVaTen}</span>
              <span className="email">{getUserInfo?.email}</span>
            </a>
          </div>
        </div>
      ),
    },
    {
      key: "1",
      label: (
        <SafeLink
          className="account__menu__item"
          rel="account"
          to={routesConfig.userInfo}
        >
          <UserOutlined />
          <span>Thông tin cá nhân</span>
        </SafeLink>
      ),
    },
    {
      key: "3",
      label: (
        <a className="account__menu__item" rel="settings">
          <SettingOutlined />
          <span>Cài đặt</span>
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <a className="account__menu__item border-bottom" rel="helper">
          <QuestionCircleOutlined />
          <span>Trợ giúp</span>
        </a>
      ),
    },
    {
      key: "5",
      label: (
        <a className="account__menu__item" rel="log-out" onClick={showConfirm}>
          <LogoutOutlined />
          <span>Đăng xuất</span>
        </a>
      ),
    },
  ];

  return (
    <>
      <div className="global-header">
        <div
          className="global-header1"
          style={{
            background: `${
              pathname.includes(routesConfig.detailAdminInterface)
                ? backgroundColor
                : color
                ? color?.backgroundColor
                : "#FFFFFF"
            }`,
          }}
        >
          {isMobile ? (
            <Button className="btn-mobile" onClick={props.activeMenu}>
              <MenuOutlined />
            </Button>
          ) : null}
          <div
            className="logo-mooc2 cursor-pointer"
            onClick={() => navigate("/")}
          >
            {logo || logoPersonal ? (
              <img
                src={
                  pathname.includes(routesConfig.detailAdminInterface)
                    ? logo
                    : logoPersonal
                }
                className="w-8 h-8 rounded-lg object-cover"
                alt="logo-mooc"
              />
            ) : (
              <div className="shape2" />
            )}
            <h2 className="mooc2">Mooc</h2>
          </div>
          <div className="menulegacy">
            <ul>
              <li>
                <Link to={"#"} className="active-link--menu">
                  <TableOutlined />
                  <Typography.Text>Quản lý</Typography.Text>
                </Link>
              </li>
            </ul>
          </div>
          <div className="toolbar">
            <div className="icon-wrapper">
              <a href="#">
                <img
                  className="search-icon"
                  loading="eager"
                  alt=""
                  src={imgPath + "search.svg"}
                />
              </a>
            </div>
            <div className="icon-wrapper">
              <a href="#">
                <img
                  className="questioncircle-icon"
                  loading="eager"
                  alt=""
                  src={imgPath + "questioncircle.svg"}
                />
              </a>
            </div>
            <MyNotificationDrop />
            <Dropdown
              overlayClassName="header-account"
              trigger={["click"]}
              menu={{ items }}
            >
              <div className="account">
                <div className="account__avatar">
                  <div className="account__avatar__wrapper">
                    <a onClick={(e) => e.preventDefault()}>
                      <Avatar
                        src={getUserInfo?.anhDaiDien}
                        icon={<UserOutlined />}
                      />
                    </a>
                  </div>
                </div>
                <div className="account__name">
                  <a
                    className="account__name__wrapper"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span>{getUserInfo?.hoVaTen}</span>
                  </a>
                </div>
              </div>
            </Dropdown>
          </div>
        </div>
        <Spin spinning={loading} fullscreen size="large" />
      </div>
    </>
  );
};

export default HeaderLayout;
