import {
  Breadcrumb,
  Flex,
  Form,
  Layout,
  TableColumnsType,
  TableProps,
} from "antd";
import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useNavigate, useParams } from "react-router-dom";

import { ArrowLeftOutlined } from "@ant-design/icons";

import { FormInstance } from "antd/lib";
import { isEmpty } from "lodash";
import PageHeader from "../../../../../components/page-header/PageHeader";
import TableData from "../../../../../components/table-data/TableData";
import {
  getAllStudentInfo,
  getLearningTrendAllBod,
  getLearningTrendAllFavorite,
  getLearningTrendIdAllLevel,
  getLearningTrendReportAllAddress,
  getLearningTrendReportAllUniversity,
} from "../../../../../service/prediction-model";
import FormFilter from "./components/filter-group/FormFilter";
import FilterTable from "./components/filter-table/FilterTable";

type InfoProfileStudentProps = {
  title?: string;
};

const InfoProfileStudent = ({ title }: InfoProfileStudentProps) => {
  const navigate = useNavigate();
  const params = useParams();

  const [documentSystem, setDocumentSystem] = useState<any[]>([]);
  const [dataFilter, setDataFilter] = useState({
    university: [],
    address: [],
    year: [],
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [optionSelectSchool, setOptionSelectSchool] = useState([]);
  const [optionSelectAddress, setOptionSelectAddress] = useState([]);
  const [optionSelectYear, setOptionSelectYear] = useState([]);
  const [optionSelectFavorite, setOptionSelectFavorite] = useState([]);
  const [optionSelectLevel, setOptionSelectLevel] = useState([]);
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("asc");

  const [checkedList, setCheckedList] = useState<any>([]);
  const [listVisible, setListVisible] = useState<any>([]);

  const dataColumnsInfoProfile: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 5,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    // {
    //   title: "Mã người dùng",
    //   dataIndex: "code",
    //   key: "code",
    //   width: 15,
    //   render: (_: any, record) => {
    //     return (
    //       <Flex className="table-order-course-name">
    //         <div className="pl-2">
    //           <span className="course-title">{record?.code}</span>
    //         </div>
    //       </Flex>
    //     );
    //   },
    // },
    {
      title: "Tên học viên",
      dataIndex: "name",
      key: "name",
      width: 25,
      render: (_: any, record) => {
        return (
          <Flex className="table-order-course-name">
            <div className="pl-2">
              <span className="course-title">{record?.name}</span>
            </div>
          </Flex>
        );
      },
      sorter: true,
    },
    {
      title: "Năm sinh",
      dataIndex: "year_of_birth",
      key: "year_of_birth",
      render: (_: any, record: any) => {
        return <div>{record?.year_of_birth}</div>;
      },
      width: 10,
    },
    {
      title: "Trường",
      dataIndex: "university",
      key: "university",
      render: (_: any, record: any) => {
        return <div>{record?.university?.join("")}</div>;
      },
      width: 10,
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
      render: (_: any, record: any) => {
        return <div>{record.address}</div>;
      },
      width: 10,
    },
    {
      title: "Trình độ",
      dataIndex: "academic_level",
      key: "academic_level",
      render: (_: any, record: any) => {
        return <div>{record.academic_level}</div>;
      },
      width: 10,
    },
    {
      title: "Sở thích",
      dataIndex: "hobby",
      key: "hobby",
      render: (_: any, record: any) => {
        return <div>{record?.hobby?.join(", ")}</div>;
      },
      width: 10,
    },
  ];
  const keysCheck = dataColumnsInfoProfile.map((item) => item.key);

  const fetchData = async (searchParam: any) => {
    try {
      setLoading(true);
      const response = await getAllStudentInfo(searchParam);
      const { data } = response.data;
      setDocumentSystem(data?.data);

      const defaultCheckedList = keysCheck.filter((key) =>
        data?.data?.some((obj: any) => Object.keys(obj).includes(key as any))
      );
      if (isEmpty(checkedList) || isEmpty(listVisible)) {
        setCheckedList([...defaultCheckedList, "numericalOrder"]);
        setListVisible([...defaultCheckedList, "numericalOrder"]);
      }
      setTotalPage(data?.total?._count?.id);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchListTagUniversity = async () => {
    try {
      const responseUniversity = await getLearningTrendReportAllUniversity(
        params?.id || ""
      );
      const { data: dataUniversity } = responseUniversity.data;

      setOptionSelectSchool(
        dataUniversity?.map((tag: any) => ({
          label: tag?.name,
          value: tag?.id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchListTagYear = async () => {
    try {
      const response = await getLearningTrendAllBod(params?.id || "");
      const { data } = response.data;

      setOptionSelectYear(
        data?.map((tag: any) => ({
          label: tag,
          value: tag,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchListTagFavorite = async () => {
    try {
      const response = await getLearningTrendAllFavorite(params?.id || "");
      const { data } = response.data;

      setOptionSelectFavorite(
        data?.map((tag: any) => ({
          label: tag?.name,
          value: tag?.id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchListTagLevel = async () => {
    try {
      const response = await getLearningTrendIdAllLevel();
      const { data } = response.data;

      setOptionSelectLevel(
        data?.map((tag: any) => ({
          label: tag?.level_name,
          value: tag?.id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchListTagAddress = async () => {
    try {
      const responseAddress = await getLearningTrendReportAllAddress(
        params?.id || ""
      );
      const { data: dataAddress } = responseAddress.data;

      setOptionSelectAddress(
        dataAddress?.map((tag: any) => ({
          label: tag?.full_name,
          value: tag?.code,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData({
      ...dataFilter,
      name: searchValue,
      take: pageSize,
      skip: (pageNumber - 1) * pageSize,
      sort: fieldSortOrder,
    });
    fetchListTagAddress();
    fetchListTagUniversity();
    fetchListTagYear();
    fetchListTagFavorite();
    fetchListTagLevel();
  }, [pageSize, pageNumber, fieldSortOrder]);

  const columnChecked = useMemo(() => {
    return dataColumnsInfoProfile.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
  }, [checkedList, fieldSortOrder]);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };

  const handleSearch = async (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      const searchParam = {
        ...dataFilter,
        take: 10,
        skip: 0,
        name: searchValue,
      };
      setPageNumber(1);
      fetchData(searchParam);
    }
  };

  const handleSubmitSearch = () => {

    const searchParam = {
      ...dataFilter,
      take: 10,
      skip: 0,
      name: searchValue,
    };
    setPageNumber(1);
    fetchData(searchParam);
  };

  const clearFilter = async (formInstance: FormInstance) => {
    setLoading(true);
    setDataFilter({
      university: [],
      address: [],
      year: [],
    });
    const searchParam = {
      take: 10,
      skip: 0,
      name: searchValue,
    };
    setPageNumber(1);
    fetchData(searchParam);
    formInstance.resetFields();
  };

  const handleFinish = (values: any) => {
    setLoading(true);

    const searchParam = {
      ...values,
      take: 10,
      skip: 0,
      name: searchValue,
    };
    setPageNumber(1);
    fetchData(searchParam);
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter?.order === "ascend"
        ? "asc"
        : sorter?.order === "descend"
        ? "desc"
        : "";

    setFieldSortOrder(data);
  };

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <div
          className={`page-header-group--layout ${
            showFilter ? "open-filter" : ""
          }`}
        >
          <Breadcrumb
            className="w-full"
            items={[
              {
                title: "Hệ thống gợi ý khoá học",
              },
              {
                title: "Cài đặt Dự đoán, phân tích xu hướng",
              },
              {
                title: "Phân tích nội dung đầu vào sinh viên",
              },
              {
                title: "Thông tin hồ sơ sinh viên",
              },
            ]}
          />
          <div className="table-header--wrapper justify-between">
            <div className="table-header--left">
              <ArrowLeftOutlined
                className=" mr-2"
                onClick={() => navigate(-1)}
              />
              <PageHeader title={"Thông tin hồ sơ sinh viên"} />
            </div>
            <FilterTable
              searchValue={searchValue}
              dataColumns={dataColumnsInfoProfile}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              listVisible={listVisible}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
            />
          </div>
          <FormFilter
            clearFilter={clearFilter}
            checkedList={checkedList}
            onFinish={handleFinish}
            showFilter={showFilter}
            optionSelectSchool={optionSelectSchool}
            optionSelectAddress={optionSelectAddress}
            optionSelectYear={optionSelectYear}
            optionSelectFavorite={optionSelectFavorite}
            optionSelectLevel={optionSelectLevel}
            dataFilter={dataFilter}
            setDataFilter={setDataFilter}
          />
        </div>
        <TableData
          dataTable={documentSystem}
          dataColumns={columnChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1300, y: 600 }}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
    </Layout>
  );
};

export default InfoProfileStudent;
