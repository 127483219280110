import { Form, Input } from "antd";
import { QuestionTypeEnum } from "../../../../../constants";
import { JSON_SUB_TYPE, QuestionProps } from "../../../../../types/course";

function LongText({ data, disabled, initialData }: QuestionProps) {
  return (
    <Form.Item
      initialValue={initialData}
      name={`longText-${data?.id}-${QuestionTypeEnum.LONG_TEXT}-${JSON_SUB_TYPE.ContentRequest}`}
    >
      <Input.TextArea
        disabled={disabled}
        rows={3}
        placeholder="Nhập câu trả lời của bạn"
      />
    </Form.Item>
  );
}

export default LongText;
