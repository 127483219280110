import { DeleteOutlined, InfoCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Layout,
  Space,
  TableColumnsType,
  TableProps,
  Tooltip
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import CommonModal from "../../../../components/modal/common";
import TableData from "../../../../components/table-data/TableData";
import { routesConfig } from "../../../../config/routes";
import {
  deleteLearningTrend,
  getLearningTrends,
} from "../../../../service/prediction-model";

interface SearchProps {
  take: number;
  group_id?: number;
  skip: number;
  name?: string;
}
interface IProps {
  searchValue: string;
  setSelectedRowKeysAction?: any;
  statusTag: any;
  isRefresh: boolean;
  setInfoDesc: any;
  handleNotice: any;
  handleRefresh: any;
}

const InfoAction = ({
  searchValue,
  setSelectedRowKeysAction,
  statusTag,
  isRefresh,
  setInfoDesc,
  handleNotice,
  handleRefresh,
}: IProps) => {
  const [infoActionData, setInfoActionData] = useState<any[]>([]);
  const [infoActionItem, setInfoActionItem] = useState<any>({
    id: [],
    isOpen: false,
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);

  const fetchData = async (searchParam: SearchProps) => {
    try {
      const response = await getLearningTrends(searchParam);
      const { data } = response.data;

      setInfoActionData(data?.data);
      setTotalPage(data?.total?._count?.id);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData({
      take: pageSize,
      group_id: 2,
      skip: (pageNumber - 1) * pageSize,
      name: searchValue,
    });
  }, [pageSize, pageNumber, searchValue, isRefresh, statusTag?.key]);

  const handleChangeDesc = (e: any, id: number) => {
    setInfoDesc((prevArray: any) => {
      const index = prevArray.findIndex(
        (item: { id: number; description: string }) => item.id === id
      );

      if (index !== -1) {
        return prevArray.map((item: { id: number; description: string }) =>
          item.id === id ? { ...item, description: e.target.value } : item
        );
      } else {
        return [...prevArray, { id: id, description: e.target.value }];
      }
    });
  };

  const dataColumnsInfoAction: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 5,
      render: (_: any, record) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: (
        <div>
          Nhóm nội dung{" "}
          <Tooltip
            placement="bottom"
            title={
              <div>
                Kiểm tra dữ liệu đầu ra tại{" "}
                <Link
                  to={`/prediction-model/${routesConfig.settingPrediction}`}
                  className="text-[#40A9FF]"
                >
                  Cài đặt phân tích
                </Link>
              </div>
            }
          >
            <QuestionCircleOutlined
              style={{ color: "#00000073", paddingLeft: "5px" }}
            />
          </Tooltip>
        </div>
      ),
      dataIndex: "name",
      key: "name",
      width: 15,
      render: (_: any, record) => {
        return (
          <div className="pl-2">
            <span className="course-title">
              {record?.mooc_learning_trends_fields?.name}
            </span>
          </div>
        );
      },
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
      key: "note",
      width: 30,
      render: (_, data: any) => {
        return (
          <div>
            {statusTag?.isEditAction ? (
              <Input
                onChange={(e) => handleChangeDesc(e, data?.id)}
                defaultValue={data?.description}
              />
            ) : (
              data?.description
            )}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const handleDelete = () => {
          setSelectedRowKeysAction([]);
          setInfoActionItem({
            id: [...infoActionItem?.id, record.id],
            isOpen: true,
          });
        };
        return (
          <Space>
            <span
              className="action-table cursor-pointer"
              onClick={handleDelete}
            >
              <DeleteOutlined />
            </span>
          </Space>
        );
      },
      fixed: "right",
      width: 8,
    },
  ];

  const defaultCheckedList = dataColumnsInfoAction.map(
    (item) => item.key as string
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const columnChecked = useMemo(() => {
    return dataColumnsInfoAction.map((item) => ({
      ...item,
    }));
  }, [checkedList, statusTag?.isEditAction]);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {};

  const handleConfirmDelete = async () => {
    try {
      await deleteLearningTrend({ field_ids: infoActionItem?.id });
      handleRefresh();
      handleNotice("success" ,"Xoá thông tin thành công");
    } catch (error) {
      console.log(error);
    }
    setInfoActionItem({
      id: [],
      isOpen: false,
    });
  };
  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <TableData
          dataTable={infoActionData}
          dataColumns={columnChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          hideSelection={true}
          setSelectedRowKeysProps={setSelectedRowKeysAction}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1200, y: 600 }}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
      <CommonModal
        open={infoActionItem?.isOpen}
        title={"Xoá nhóm thông tin hành vi của sinh viên"}
        desc={
          <div>
            Nhóm nội dung thông tin đã xoá sẽ không thể khôi phục. Bạn có chắc
            chắn muốn xoá nội dung này?
          </div>
        }
        closeIcon={false}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={handleConfirmDelete}
        closeModal={() => setInfoActionItem({ id: null, isOpen: false })}
      />
    </Layout>
  );
};

export default InfoAction;
