import React, {useEffect, useMemo, useState, useTransition} from "react";
import {debounce} from "lodash";
import ResourceChartTab from "../chart/resource-tab/ResourceChartTab";
import {BarStackedChartProps, PieChart} from "../../../../../types/static-report";
import {staticReportStore} from "../../../../../stores/stores";
import dayjs from "dayjs";
import {
  getListCourseByFormatUniversity,
  getListCourseByIndustryGroup,
  getListCourseByTeacherUniversity,
  getListSynthesizeCourseUniversity,
  getListUnitByIndustryGroup,
  getListUniversityMultiMediaUnit,
  getListUniversityReferenceSourceByType,
  getListUniversityScormXapiUnit,
  getListUniversityTestByType
} from "../../../../../service/statistical-report";
import ResourceTable from "../table/resource-table";
import {renderColBarStackedChart, renderColDonutChart} from "../../../../../utils/staticReport";

type Props = {
  dataType: string;
  universityId?: number;
};

const ResourceTab = (props: Props) => {
  const {dataType, universityId} = props;
  const {staticReportType, filterValues, tab} = staticReportStore();
  const [isPending, startTransition] = useTransition();

  const [chartData, setChartData] = useState({
    dataListSynthesizeCourseUniversity: [] as Array<any>,
    dataListSynthesizeCourseUniversityTotal: 0,
    dataListCourseByFormatUniversity: [] as PieChart[],
    dataListCourseByFormatUniversityTotal: 0,
    dataListCourseByIndustryGroup: [] as BarStackedChartProps[],
    dataListCourseByIndustryGroupTotal: 0,
    dataListCourseByTeacherUniversity: [] as BarStackedChartProps[],
    dataListCourseByTeacherUniversityTotal: 0,
    dataListUnitByIndustryGroup: [] as PieChart[],
    dataListUnitByIndustryGroupTotal: 0,
    dataListUniversityTestByType: [] as PieChart[],
    dataListUniversityTestByTypeTotal: 0,
    dataListUniversityReferenceSourceByType: [] as PieChart[],
    dataListUniversityReferenceSourceByTypeTotal: 0,
    dataListUniversityScormXapiUnit: [] as PieChart[],
    dataListUniversityScormXapiUnitTotal: 0,
    dataListUniversityMultiMediaUnit: [] as PieChart[],
    dataListUniversityMultiMediaUnitTotal: 0,
  });

  const [loading, setLoading] = useState({
    dataListCourseByFormatUniversity: false,
    dataListCourseByIndustryGroup: false,
    dataListCourseByTeacherUniversity: false,
    dataListUnitByIndustryGroup: false,
    dataListUniversityTestByType: false,
    dataListUniversityReferenceSourceByType: false,
    dataListUniversityScormXapiUnit: false,
    dataListUniversityMultiMediaUnit: false,
  });

  const fetchData = async () => {
    const payload = {
      timeUnit: filterValues.timeUnit,
      from: dayjs(filterValues.dateFrom).toISOString(),
      to: dayjs(filterValues.dateTo).toISOString(),
      courseLevelIds: filterValues?.courseLevelIds,
      industryGroupIds: filterValues?.industryGroupIds,
      courseIds: filterValues?.courseIds,
      universityIds: filterValues?.universityIds,
      teacherIds: filterValues?.lecturerIds
    };
    try {
      const apiCallList = [
        {
          key: "dataListSynthesizeCourseUniversity",
          total: "dataListSynthesizeCourseUniversityTotal",
          api: getListSynthesizeCourseUniversity,
          params: payload,
        },
        {
          key: "dataListCourseByFormatUniversity",
          total: "dataListCourseByFormatUniversityTotal",
          api: getListCourseByFormatUniversity,
          params: payload,
        },
        {
          key: "dataListCourseByTeacherUniversity",
          total: "dataListCourseByTeacherUniversityTotal",
          api: getListCourseByTeacherUniversity,
          params: payload,
        },
        {
          key: "dataListCourseByIndustryGroup",
          total: "dataListCourseByIndustryGroupTotal",
          api: getListCourseByIndustryGroup,
          params: payload,
        },
        {
          key: "dataListUnitByIndustryGroup",
          total: "dataListUnitByIndustryGroupTotal",
          api: getListUnitByIndustryGroup,
          params: payload,
        },
        {
          key: "dataListUniversityTestByType",
          total: "dataListUniversityTestByTypeTotal",
          api: getListUniversityTestByType,
          params: payload,
        }, {
          key: "dataListUniversityReferenceSourceByType",
          total: "dataListUniversityReferenceSourceByTypeTotal",
          api: getListUniversityReferenceSourceByType,
          params: payload,
        }, {
          key: "dataListUniversityScormXapiUnit",
          total: "dataListUniversityScormXapiUnitTotal",
          api: getListUniversityScormXapiUnit,
          params: payload,
        }, {
          key: "dataListUniversityMultiMediaUnit",
          total: "dataListUniversityMultiMediaUnitTotal",
          api: getListUniversityMultiMediaUnit,
          params: payload,
        },
      ]
      await Promise.allSettled(
        apiCallList.map(async ({key, api, total, params}) => {
          setLoading((prev) => ({...prev, [key]: true, [total]: true}));
          try {
            const response = await api(params);
            startTransition(() => {
              setChartData((prevState) => ({
                ...prevState,
                [key]: response?.data?.data?.statistic || response?.data?.data,
                [total]: response?.data?.data?.total || response?.data?.total,
              }));
            })
          } catch (error) {
            console.error(`Error fetching data for ${key}:`, error);
            startTransition(() => {
              setChartData(prevState => ({
                ...prevState,
                [key]: [], // Provide fallback empty data
              }));
            });
          } finally {
            setLoading((prev) => ({...prev, [key]: false}));
          }
        })
      );
    } catch (err) {
      console.error(err)
    }
  }

  const debouncedFetchData = useMemo(
    () => debounce(fetchData, 300),
    [filterValues]
  );

  useEffect(() => {

    if (tab === "2" && universityId && universityId !== 0) {
      debouncedFetchData();
    }
    return () => {
      debouncedFetchData.cancel();
    };
  }, [filterValues, tab]);

  const memoizedChartData = useMemo(() => {
    const series = ["Thẻ ghi danh", "Tự do", "Riêng tư"];
    renderColBarStackedChart(series, chartData.dataListCourseByIndustryGroup);
    renderColDonutChart(series, chartData.dataListCourseByFormatUniversity);

    return {
      dataListSynthesizeCourseUniversity: chartData.dataListSynthesizeCourseUniversity,
      dataListSynthesizeCourseUniversityTotal: chartData.dataListSynthesizeCourseUniversityTotal,
      dataListCourseByFormatUniversity: chartData.dataListCourseByFormatUniversity,
      dataListCourseByFormatUniversityTotal: chartData.dataListCourseByFormatUniversityTotal,
      dataListCourseByTeacherUniversity: chartData.dataListCourseByTeacherUniversity ? chartData.dataListCourseByTeacherUniversity : [],
      dataListCourseByTeacherUniversityTotal: chartData.dataListCourseByTeacherUniversityTotal,
      dataListCourseByIndustryGroup: chartData.dataListCourseByIndustryGroup,
      dataListCourseByIndustryGroupTotal: chartData.dataListCourseByIndustryGroupTotal,
      dataListUnitByIndustryGroup: chartData.dataListUnitByIndustryGroup,
      dataListUnitByIndustryGroupTotal: chartData.dataListUnitByIndustryGroupTotal,
      dataListUniversityTestByType: chartData.dataListUniversityTestByType,
      dataListUniversityTestByTypeTotal: chartData.dataListUniversityTestByTypeTotal,
      dataListUniversityReferenceSourceByType: chartData.dataListUniversityReferenceSourceByType,
      dataListUniversityReferenceSourceByTypeTotal: chartData.dataListUniversityReferenceSourceByTypeTotal,
      dataListUniversityScormXapiUnit: chartData.dataListUniversityScormXapiUnit,
      dataListUniversityScormXapiUnitTotal: chartData.dataListUniversityScormXapiUnitTotal,
      dataListUniversityMultiMediaUnit: chartData.dataListUniversityMultiMediaUnit,
      dataListUniversityMultiMediaUnitTotal: chartData.dataListUniversityMultiMediaUnitTotal,
    };
  }, [chartData]);

  return (
    (universityId && universityId !== 0)
      ?
      (dataType === "1") ?
        <ResourceChartTab {...memoizedChartData} loading={loading}/> :
        <ResourceTable {...memoizedChartData} loading={isPending}/>
      :
      <p style={{fontSize: 18, color: "#5f5c5c", textAlign: "center"}}>Không có dữ liệu</p>
  )
};

export default ResourceTab;
