import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../config/api/configApiv2";

// ====== University ======
// Lấy trường hiện tại
export const getCurrentUniversity: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/chart-university/get-current-university`);
};

// Lấy danh sách giảng viên
export const getTeacherFilterUniversity: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfigV2.get(`/chart-university/get-teacher-filter`);
};

// Lấy danh sách khối
export const getIndustryGroupFilterUniversity: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfigV2.get(`/chart-university/get-industry-group-filter`);
};

// Lấy danh sách khóa học
export const getCourseFilterUniversity: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfigV2.get(`/chart-university/get-course-filter`);
};

// === Tab Overview ===
// Lấy Tổng hợp số liệu
export const getListSynthesizeDataUniversityOverview: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university/get-synthesize-data-university`,
    data
  );
};

export const getUniversityVisits: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/chart-university/get-table-visits`, data);
};

export const getUniversityVisitors: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/get-table-visitors`, data);
};

// lấy số lượt truy cập và số người truy cập
export const getVisitsAndVisitorsUniversity: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-university/get-visits-and-visitors`, data);
};

// Lấy đối tượng truy cập
export const getVisitsAndVisitorsByRegisterUniversity: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `chart-university/get-visits-and-visitors-by-register`,
    data
  );
};

// Lấy danh sách số lượng giảng viên
export const getListTeacherByIndustryGroupOverview: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university/get-teacher-by-industry-group`,
    data
  );
};

// Lấy danh sách số lượng học viên
export const getListStudentByIndustryGroup: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university/get-student-by-industry-group`,
    data
  );
};

// Lấy danh sách khóa học theo khoa
export const getListCourseByUniversitySponsor: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university/get-course-by-university-sponsor`,
    data
  );
};

// Lấy số lượng tài nguyên
export const getListUnitResources: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/get-unit-resources`, data);
};

// === Tab Resource ===
// Lấy Tổng hợp số liệu
export const getListSynthesizeCourseUniversity: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university/get-synthesize-course-university`,
    data
  );
};

// Lấy khóa học theo hình thức xuất bản
export const getListCourseByFormatUniversity: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university/get-course-by-format-university`,
    data
  );
};

export const getListCourseByTeacherUniversity: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `chart-university/get-teacher-assign-by-instruct`,
    data
  );
};

// Lấy số lượng khóa học theo khoa
export const getListCourseByIndustryGroup: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university/get-course-by-industry-group`,
    data
  );
};

// Lấy số lượng bài giảng theo khoa
export const getListUnitByIndustryGroup: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university/get-unit-by-industry-group`,
    data
  );
};

// Lấy số lượng bài kiểm tra theo loại
export const getListUniversityTestByType: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university/get-university-test-by-type`,
    data
  );
};

// Lấy số lượng tài liệu tham khảo theo loại
export const getListUniversityReferenceSourceByType: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university/get-university-reference-source-by-type`,
    data
  );
};

// Lấy số lượng Scorm & xAPI
export const getListUniversityScormXapiUnit: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university/get-university-scorm-xapi-unit`,
    data
  );
};

// Lấy số lượng đa phương tiện theo loại
export const getListUniversityMultiMediaUnit: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university/get-university-multi-media-unit`,
    data
  );
};

// === Tab Feedback and evaluation ===
// Lấy số lượt đánh giá vs số lượng hoàn thành học liệu
export const getUnitDiscussUniversity: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-university/by-time/get-unit-discuss`, data);
};

export const getCourseDiscussActionUniversity: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-university/get-discuss-by-sequence`, data);
};

export const getListUnitReviewAndAccess: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university/by-time/get-unit-review-and-access`,
    data
  );
};

// Lấy tỷ lệ đánh giá học liệu theo phân loại
export const getListRateUnitByModule: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/get-rate-unit-by-module`, data);
};

// Lấy tỷ lệ đánh giá khóa học
export const getListRateUnitByCourse: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/get-rate-unit-by-course`, data);
};

// Lấy tỷ lệ đánh giá bài giảng
export const getListRateUnitBySequence: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university/get-rate-unit-by-sequence`,
    data
  );
};

// === Tab User behavior ===
// Lấy số lượt tải về/xem/chia sẻ tài nguyên
export const getListUnitAction: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/chart-university/by-time/get-unit-action`, data);
};

export const getSynthesizeUniversity: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `chart-university/get-synthesize-data-university`,
    data
  );
};

export const getTeacherByIndustryUniversity: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `chart-university/get-teacher-by-industry-group`,
    data
  );
};

export const getSearchHistoryUniversity: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `chart-university/by-time/get-search-history`,
    data
  );
};
export const getSearchKeywordUniversity: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-university/get-search-keyword-count`, data);
};
export const getUnitActionUniversity: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-university/by-time/get-unit-action`, data);
};

export const exportTableVisitsUniversity: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-university-export/export-visits`, data, {
    responseType: "arraybuffer",
  });
};

export const exportTableVisitorUniversity: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university-export/export-visitors`, data, {
    responseType: "arraybuffer",
  });
};

export const exportTableCourseFormatUniversityAction: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university-export/get-course-by-format-university`,
    data,
    { responseType: "arraybuffer" }
  );
};
export const exportTableTeacherAssignByInstruct: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university-export/get-teacher-assign-by-instruct`,
    data,
    { responseType: "arraybuffer" }
  );
};

export const exportTableUniversityCourseBySponsor: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university-export/get-course-by-university-sponsor`,
    data,
    { responseType: "arraybuffer" }
  );
};

export const exportTableTeacherIndustry: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university-export/get-teacher-by-university`,
    data,
    { responseType: "arraybuffer" }
  );
};
export const exportTableStudentIndustry: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university-export/get-student-by-university`,
    data,
    { responseType: "arraybuffer" }
  );
};

export const exportTableUnitResourcesUniversity: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university-export/get-unit-resources-table`,
    data,
    { responseType: "arraybuffer" }
  );
};

export const exportTableUnitByIndustryGroup: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university-export/get-unit-by-industry-group`,
    data,
    { responseType: "arraybuffer" }
  );
};

export const exportTableTestByIndustryGroup: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university-export/get-university-test-by-type`,
    data,
    { responseType: "arraybuffer" }
  );
};

export const exportTableUniversityReferenceSourceByType: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university-export/get-university-reference-source-by-type`,
    data,
    { responseType: "arraybuffer" }
  );
};

export const exportTableUniversityScormUnit: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university-export/get-university-scorm-xapi-unit`,
    data,
    { responseType: "arraybuffer" }
  );
};

export const exportTableMultiMediaUnit: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university-export/get-university-multi-media-unit`,
    data,
    { responseType: "arraybuffer" }
  );
};

export const exportTableUniversityUnitDiscussByTime: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university-export/by-time/get-unit-discuss`,
    data,
    { responseType: "arraybuffer" }
  );
};

export const exportTableUniversityUnitDiscussCourseAction: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university-export/get-discuss-by-sequence`,
    data,
    { responseType: "arraybuffer" }
  );
};

export const exportTableUniversityUnitReviewAccess: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university-export/by-time/get-unit-review-and-access`,
    data,
    { responseType: "arraybuffer" }
  );
};

export const exportTableUniversityRateUnitByModule: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university-export/get-rate-unit-by-module`,
    data,
    { responseType: "arraybuffer" }
  );
};

export const exportTableUniversityRateUnitByCourse: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university-export/get-rate-unit-by-course`,
    data,
    { responseType: "arraybuffer" }
  );
};

export const exportTableUniversityRateUnitBySequence: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university-export/get-rate-unit-by-sequence`,
    data,
    { responseType: "arraybuffer" }
  );
};

export const exportTableUniversitySearchKeywordCount: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university-export/get-search-keyword-count`,
    data,
    { responseType: "arraybuffer" }
  );
};

export const exportTableUniversitySearchHistory: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university-export/by-time/get-search-history`,
    data,
    { responseType: "arraybuffer" }
  );
};

export const exportTableUniversityDownloadResources: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university-export/by-time/get-unit-action`,
    data,
    { responseType: "arraybuffer" }
  );
};

// export all
//tab tài nguyên
export const exportAllResource: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/export-all-tab-resource`,
    data,
    { responseType: "arraybuffer" }
  );
};

//tab phản hồi và đánh giá
export const exportAllFeedbackAndEvaluation: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/export-all-tab-feedback`,
    data,
    { responseType: "arraybuffer" }
  );
};

//tab hành vi người dùng
export const exportAllUserBehavior: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/export-all-tab-behavior`,
    data,
    { responseType: "arraybuffer" }
  );
};

export const getCourseLevel: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/mooc-courses/get-course-level`);
};
