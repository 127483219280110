import { DatePicker, Form, Modal, Radio, RadioChangeEvent, TimePicker, Typography, message } from "antd";
import { useEffect, useState } from "react";
import "./index.scss";
import { useForm } from "antd/es/form/Form";
import { viLocale } from "../../../utils/pickerType";
import dayjs, { Dayjs } from "dayjs";
import { createDisabledTime, formatDateTime } from "../../../utils/converDate";
import { getDetailPublicListProgram, publishListProgram } from "../../../service/portal-administration";

type Props = {
  open: boolean
  publicId: string
  handleClose: () => void
};

export default function PublishCourseProgramModal(props: Props) {
  const { open, handleClose, publicId } = props
  const [form] = useForm()
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setSelectEndDate] = useState<Dayjs | null>(null);
  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<Dayjs | null>(null);
  const [value, setValue] = useState(1)

  const handleChangeStartDate = (date: any) => {
    setStartDate(date);
    setStartTime(date);
  };
  const handleChangeEndDate = (date: any) => {
    setSelectEndDate(date);
    setEndTime(date);
  };
  const handleChangeStartTime = (time: any | null) => {
    setStartTime(time);
  };

  const handleChangeEndTime = (time: any | null) => {
    setEndTime(time);
  };

  const radioItemList = [
    {
      value: 1,
      title: "Bản nháp",
      describe: "Chương trình học sẽ không hiển thị với Người học",
    },
    {
      value: 2,
      title: "Công khai",
      describe:
        "Người học của tất cả các trường đại học trên hệ thống có thể tìm kiếm chương trình học",
    },
    {
      value: 3,
      title: "Riêng tư",
      describe: "Chỉ Người học của Trường đại học mới có thể tìm kiếm chương trình học",
    },
  ];

  const handleChange = (e: RadioChangeEvent) => {
    setValue(e.target.value)
  }

  const disabledTime: (
    date: Dayjs,
    range?: "start" | "end" | undefined
  ) => any = (date) => createDisabledTime(startTime || null);

  const resetData = () => {
    setValue(1)
    setStartDate(null)
    setSelectEndDate(null)
    setStartTime(null)
    setEndTime(null)
  }

  const getDetail = async () => {
    await getDetailPublicListProgram(publicId).then((res) => {
      if(res.status === 200) {
        const {data} = res.data
        setValue(Number(data?.publishedStatus))
        form.setFieldValue("status", Number(data?.publishedStatus))
        setStartDate(
          data?.publishedStartDate
            ? dayjs.utc(data?.publishedStartDate)
            : null
        );
        setSelectEndDate(
          data?.publishedEndDate
            ? dayjs.utc(data?.publishedEndDate)
            : null
        );
        setStartTime(
          data?.publishedStartDate
            ? dayjs.utc(data?.publishedStartDate)
            : null
        );
        setEndTime(
          data?.publishedEndDate
            ? dayjs.utc(data?.publishedEndDate)
            : null
        );
      }
    })
  }

  useEffect(() => {
    if(open) {
      getDetail()
    }
  }, [open])

  return (
    <Form.Provider
      onFormFinish={async (name, { values, forms }) => {
        if (name === 'form-publish-course-program') {
          const data = {
            id: publicId,
            status: values.status,
            publishedStartDate: formatDateTime(startDate, startTime),
            publishedEndDate: formatDateTime(endDate, endTime)
          }
          await publishListProgram(data).then((res) => {
            if (res.status === 200) {
              message.success("Xuất bản chương trình học thành công!")
              resetData()
              handleClose()
              form.resetFields();
            }
          })
        }
      }}
    >
      <Modal
        title="Xuất bản chương trình học"
        okText="Lưu"
        open={open}
        width={700}
        onCancel={handleClose}
        onOk={() => form.submit()}
        className="publish-course-program-modal"
        cancelText="Hủy"
      >
        <Form layout={"horizontal"} form={form} className="mb-4" name="form-publish-course-program" labelCol={{ span: 5 }} initialValues={{ status: 1 }}
          wrapperCol={{ span: 16 }}>
          <Form.Item label="Trạng thái xuất bản" name="status" >
            <Radio.Group value={value} defaultValue={value} onChange={handleChange}>
              {radioItemList.map((item, index) => (
                <div key={index} className={`radio-item ${index === 0 ? 'mt-1' : 'mt-2'}`}>
                  <div className="wrapper-radio">
                    <Radio value={item.value}>
                      <Typography.Title
                        className="setting-chapter-title"
                        level={5}
                      >
                        {item.title}
                      </Typography.Title>
                      <p className='text-gray-500'>{item.describe}</p>
                    </Radio>
                  </div>
                </div>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Ngày xuất bản" name="status" className="mt-3">
            <div className="flex flex-col">
              <div className="time-picker flex gap-3">
                <div className="time-picker-content basis-1/2">
                  <DatePicker
                    onChange={handleChangeStartDate}
                    locale={viLocale}
                    format={"DD/MM/YYYY"}
                    value={startDate}
                    placeholder="Chọn ngày bắt đầu"
                    className="w-full"
                  />
                </div>
                <div className="time-picker-content basis-1/2">
                  <TimePicker
                    locale={viLocale}
                    value={startTime}
                    onChange={handleChangeStartTime}
                    format={"HH:mm"}
                    placeholder="Chọn giờ bắt đầu"
                    className="w-full"
                  />
                </div>
              </div>
              <div className="time-picker flex mt-3 gap-3">
                <div className="time-picker-content basis-1/2">
                  <DatePicker
                    onChange={handleChangeEndDate}
                    locale={viLocale}
                    format={"DD/MM/YYYY"}
                    value={endDate}
                    minDate={startDate ? startDate : undefined}
                    placeholder="Chọn ngày kết thúc"
                    className="w-full"
                  />
                </div>
                <div className="time-picker-content basis-1/2">
                  <TimePicker
                    locale={viLocale}
                    value={endTime}
                    onChange={handleChangeEndTime}
                    format={"HH:mm"}
                    disabledTime={disabledTime}
                    placeholder="Chọn giờ kết thúc"
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </Form.Provider>
  );
}
