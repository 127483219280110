import React, { forwardRef, useState } from "react";
import classNames from "classnames";

import { Handle } from "../Item";

import styles from "./Container.module.scss";
import { detachedTitle } from "../../../../utils/convertQuillToString";
import { ArrowDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Edit } from "../Item/components/Edit/Edit";
import { Delete } from "../Item/components/Delete/Delete";

export interface Props {
  collapsible?: boolean;
  children: React.ReactNode;
  columns?: number;
  label?: string;
  style?: React.CSSProperties;
  horizontal?: boolean;
  hover?: boolean;
  handleProps?: React.HTMLAttributes<any>;
  scrollable?: boolean;
  shadow?: boolean;
  placeholder?: boolean;
  unstyled?: boolean;
  onClick?(): void;
  onRemove?(): void;
  idGroup?: any;
  onEditNameGroup?: (id: any) => void;
  onDeleteGroup?: (id: any) => void;
}

export const Container = forwardRef<HTMLDivElement, Props>(
  (
    { collapsible,
      children,
      columns = 1,
      handleProps,
      horizontal,
      hover,
      onClick,
      onRemove,
      label,
      placeholder,
      style,
      scrollable,
      shadow,
      unstyled,
      idGroup,
      onEditNameGroup,
      onDeleteGroup,
      ...props
    }: Props,
    ref
  ) => {
    const Component = onClick ? "button" : "div";
    const [isCollapse, setIsisCollapse] = useState<boolean>(false);
    return (
      <Component
        {...props}
        // @ts-ignore
        ref={ref}
        style={
          {
            ...style,
            "--columns": columns
          } as React.CSSProperties
        }
        className={classNames(
          styles.Container,
          unstyled && styles.unstyled,
          horizontal && styles.horizontal,
          hover && styles.hover,
          placeholder && styles.placeholder,
          scrollable && styles.scrollable,
          shadow && styles.shadow
        )}
        onClick={onClick}
        tabIndex={onClick ? 0 : undefined}
      >
        {label ? (
          <div className={styles.Header} style={{ paddingLeft: collapsible ? '30px' : '10px' }}>

            {collapsible ? <CaretRightOutlined className={styles.Collapse} style={{ transform: isCollapse ? 'rotate(0deg)' : 'rotate(90deg)' }}
              onClick={() => {
                setIsisCollapse(!isCollapse);
              }} /> : ''}

            {label}
            <div className={styles.ActionsWrapper}>
              <div className={styles.Actions}>
                <Edit onClick={() => {
                  if (onEditNameGroup) {
                    onEditNameGroup(idGroup)
                  }
                }} />
              </div>
              <div className={styles.Actions}>
                <Delete onClick={() => {
                  if (onDeleteGroup) {
                    onDeleteGroup(idGroup)
                  }
                }} />
              </div>
              <div className={styles.Actions}>
                <Handle {...handleProps} />
              </div>
            </div>
          </div>
        ) : null}
        {placeholder ? children : <ul className={isCollapse ? styles.ulClose : ''}>{children}</ul>}
      </Component>
    );
  }
);
