import {CaretRightOutlined, DoubleRightOutlined, InfoCircleOutlined,} from "@ant-design/icons";
import type {RadioChangeEvent} from "antd";
import {Button, Collapse, Form, message, Radio, Select, Typography} from "antd";
import moment from "moment";
import {useEffect, useState} from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {useLocation, useNavigate} from "react-router-dom";
import {getAppriseGroup, getApproveInformationDetail,} from "../../service/course-construct";
import {approvedInformationTabStore} from "../../stores/approved-information-tab-store/slice";
import {objectToQueryString} from "../../utils/getPageFromLink";
import CommonModal from "../modal/common";
import AppraisalBoardMemberListModal from "./appraisal-board-member-list-modal/AppraisalBoardMemberListModal";
import "./approved-information-tab.scss";
import {appriseContentCourse, putPublishCourse} from "../../service/content-course/contenCourseApi";
import {isEmpty} from "lodash";
import {FORMAT_REACT_QUILL} from "../../constants";


type Props = {
  setTab?: (value: string) => void;
  tab?: string;
  screenText?: string;
  informationDetail?: any;
  detailData?: any;
  getDetailDataProps?: any;
};

const {Title, Text} = Typography;
const {Panel} = Collapse;
const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [
      {list: "ordered"},
      {list: "bullet"},
      {indent: "-1"},
      {indent: "+1"},
    ],
    ['formula']
  ],
};

const ApprovedInformationTabSlideCourse = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {flag, toggleFlag} = approvedInformationTabStore();

  const {setTab, tab, screenText, detailData, getDetailDataProps} = props;
  const [transferAppraisal, setTransferAppraisal] = useState(1);
  const [approvedResult, setApprovedResult] = useState(1);
  const [evaluationResults, setEvaluationResults] = useState(1);
  const [value, setValue] = useState("");
  const [appraisalBoardList, setAppraisalBoardList] = useState([]);
  const [appraisalBoard, setAppraisalBoard] = useState("");
  const [appriseGroupId, setAppriseGroupId] = useState("");
  const [lecturers, setLecturers] = useState("");
  const [isOpenMemberList, setIsOpenMemberList] = useState(false);
  const {id, appriseCourseId, resultAppriseId, appriseFormId} =
    location.state;
  const [approveInformationDetail, setApproveInformationDetail] =
    useState<any>();
  const [isConfirm, setIsConfirm] = useState(false);
  const [statusCourse, setStatusCourse] = useState(false);
  const [form] = Form.useForm()

  const getStatusResult = (status: number): StatusResult => {
    let result: StatusResult = {};
    switch (status) {
      case 3:
        result = {isCourseApprove: true};
        break;
      case 4:
        result = {isReturn: true};
        break;
      case 5:
        result = {isCourseEvaluationTotal: true};
        break;
      case 6:
        result = {isCourseEvaluationReturn: true};
        break;
      case 7:
        result = {isCourseEvaluationTotal: true};
        break;
      case 8:
        result = {isCourseApprove: true};
        break;
      case 9:
        result = {isCourseApproveTotal: true};
        break;

      case 10:
        result = {isCourseApproveTotal: true};
        break;
      case 11:
        result = {isPendingPublication: true};
        break;
      case 12:
        result = {waitpublish: true};
        break;
      case 13:
        result = {published: true};
        break;
      default:
        result = {published: false};
        break;
    }
    return result;
  };
  const {
    // isCourseApprove, // duyệt
    isCourseEvaluation, // thẩm định
    isCourseEndorse, //phê duyệt
    // isCourseEvaluationTotal, //tổng hợp thẩm định
    // isCourseApproveTotal, // tổng hợp phê duyệt
    status,
    slideStatus,
    contentStatus,
  } = location.state;

  const {
    isCourseApprove,
    isCourseEvaluationTotal, //tổng hợp thẩm định
    isCourseApproveTotal, // tổng hợp phê duyệt
    isCourseEvaluationReturn,
    isReturn,
    published,
    waitpublish,
  } = getStatusResult(status);

  const getDetailData = async () => {
    const params = {
      courseId: id,
      type: isCourseEvaluationTotal ? 0 : isCourseEndorse ? 0 : 1,
    };
    const paramsGet = objectToQueryString(params);
    const urlLink = getApproveInformationDetail(id)

    await urlLink.then((res: any) => {
      const {data} = res;
      setApproveInformationDetail(data);
    });
  };

  const getAppriseBoardListData = async () => {
    const id = isCourseApprove ? 0 : 1;
    await getAppriseGroup("" + id).then((res: any) => {
      const {data} = res;
      setAppraisalBoardList(
        data.map((it: any) => {
          return {
            ...it,
            label: it.name,
            value: it.id,
          };
        })
      );
    });
  };

  useEffect(() => {
    getDetailData();

    if (isCourseApprove || isCourseEvaluationTotal || isCourseEvaluation) {
      getAppriseBoardListData();
    }
  }, []);

  const onChangeApprovedResult = (event: RadioChangeEvent) => {
    setApprovedResult(event.target.value);
  };
  const onChangeEvaluationResults = (event: RadioChangeEvent) => {
    setEvaluationResults(event.target.value);
  };

  const onChangeSummarizeResults = (event: RadioChangeEvent) => {
    setTransferAppraisal(event.target.value);
  };

  // const submitForm = () => {
  //   if (tab === "1") {
  //     message.success(
  //       `${screenText ? screenText : ""} trang giới thiệu thành công`
  //     );
  //     if (setTab) {
  //       setTab("2");
  //     }
  //   } else if (tab === "2") {
  //     message.success(
  //       `${screenText ? screenText : ""} nội dung khoá học thành công`
  //     );
  //     if (setTab) {
  //       setTab("3");
  //     }
  //   } else {
  //     message.success(`${screenText ? screenText : ""} khoá học thành công`);
  //     navigate(
  //       `/course-construction/${routesConfig.courseBrowse}/${routesConfig.contentBrowse}`
  //     );
  //   }
  // };

  const handleChangeCouncil = (value: string) => {
    setAppraisalBoard(value);
  };

  const handleChangeAppriseGroupId = (value: string) => {
    setAppriseGroupId(value);
  };

  const handleSendApprove = async () => {
    const data = {
      courseIds: [id],
      type: 1,
      status: approvedResult,
      description: value,
      groupAppriseId: appriseGroupId ?? null
    };
    await putPublishCourse(data).then((result) => {
      if(approvedResult === 1) {
        message.success(`Duyệt nội dung khoá học thành công`);
      } else {
        message.error(`Duyệt nội dung khoá học thất bại`);
      }
      setIsConfirm(false);
      setApprovedResult(1)
      getDetailDataProps && getDetailDataProps()
    });
  };


  const handleApprove = async () => {
    const data = {
      courseIds: [id],
      type: 0,
      status: evaluationResults,
      groupAppriseId: appriseGroupId,
      description: value
    }
    await appriseContentCourse(data).then((result) => {
      if(evaluationResults === 1) {
        message.success(`Thẩm định nội dung khoá học thành công`);
      } else {
        message.error(`Thẩm định nội dung khoá học thất bại`);
      }
      setIsConfirm(false);
      setApprovedResult(1)
      getDetailDataProps && getDetailDataProps()
    });
  };

  const handleSaveAndPublish = async () => {
    const data = {
      courseIds: [id],
      type: 1,
      status: approvedResult,
      description: value
    }
    await appriseContentCourse(data).then((result) => {
      if(approvedResult === 1) {
        message.success(`Phê duyệt khoá học thành công`);
      } else {
        message.error(`Phê duyệt khoá học thất bại`);
      }
      setIsConfirm(false);
      getDetailDataProps && getDetailDataProps()
      setApprovedResult(1)
    });
  };

  const arrSection = [
    {
      title: "Thông tin gửi duyệt",
      content: (
        <div>
          <div className="list-item">
            <Title level={5} className="list-item--title">
              Trường chủ trì:
            </Title>
            <div className="list-item--description">
              <Text className="content">
                {approveInformationDetail?.enterpriseName ||
                  approveInformationDetail?.enterpriseNameSponsor}
              </Text>
            </div>
          </div>

          <div className="list-item">
            <Title level={5} className="list-item--title">
              Người gửi:
            </Title>
            <div className="list-item--description">
              <Text className="content">
                {approveInformationDetail?.userNameSendApprove ||
                  approveInformationDetail?.fromUserName}
              </Text>
            </div>
          </div>
          <div className="list-item">
            <Title level={5} className="list-item--title">
              Ngày gửi:
            </Title>
            <div className="list-item--description">
              <Text className="content">
                {moment(
                  approveInformationDetail?.approveSendDate ||
                  approveInformationDetail?.createdDate
                ).format("DD/MM/YYYY")}
              </Text>
            </div>
          </div>
        </div>
      ),
    },

    ...(detailData?.approveContentStatus === 1
      ? [
        {
          title: "Thông tin duyệt",
          content: (
            <>
              <div style={{paddingTop: "8px"}}>
                <div className="list-item">
                  <Title level={5} className="list-item--title">
                    Duyệt trang trình bày
                  </Title>
                  <div className="list-item--description">
                    <Text className="content">
                      {
                        <div contentEditable='true'
                             dangerouslySetInnerHTML={{__html: Number(detailData?.approveSLideStatus) === 1 ? `Duyệt - <${detailData?.slideDescription}>` : `Không duyệt - <${detailData?.slideDescription}>`}}></div>
                      }
                    </Text>
                  </div>
                </div>

                <div className="list-item">
                  <Title level={5} className="list-item--title">
                    Duyệt nội dung
                  </Title>
                  <div className="list-item--description">
                    <Text className="content">
                      {
                        <div contentEditable='true'
                             dangerouslySetInnerHTML={{__html: Number(detailData?.approveContentStatus) === 1 ? `Duyệt - <${detailData?.contentDescription}>` : `Không duyệt - <${detailData?.contentDescription}>`}}></div>
                      }
                    </Text>
                  </div>
                </div>

                <div className="list-item">
                  <Title level={5} className="list-item--title">
                    Người gửi
                  </Title>
                  <div className="list-item--description">
                    <Text className="content">
                      {detailData?.approvedContentUsername}
                    </Text>
                  </div>
                </div>
                <div className="list-item">
                  <Title level={5} className="list-item--title">
                    Ngày gửi
                  </Title>
                  <div className="list-item--description">

                    <Text className="content">
                      {!isEmpty(detailData?.approvedTime) ? moment(detailData?.approvedTime).format("DD/MM/yyyy") : null}
                    </Text>
                  </div>
                </div>
              </div>
            </>
          ),
        },
      ]
      : []),

    ...(approveInformationDetail?.status === 3
      ? [
        {
          title: "Duyệt nội dung",
          content: (
            <>
              {contentStatus === 3 ? (
                <Radio.Group
                  onChange={onChangeApprovedResult}
                  value={approvedResult}
                  style={{display: "flex"}}
                >
                  <div className="radio-group-list">
                    <div className="wrapper-radio">
                      <Radio value={1}>Đồng ý</Radio>
                      {isCourseApprove || isReturn ? (
                        <Radio value={2}>Không đồng ý</Radio>
                      ) : null}
                    </div>
                  </div>
                </Radio.Group>
              ) : null}

              {approveInformationDetail?.status === 3 ? (
                <>
                  <Title
                    level={5}
                    className="list-item--title"
                    style={{padding: "10px 0px"}}
                  >
                    Mô tả duyệt:
                  </Title>
                  <ReactQuill
                    modules={modules}
                    formats={FORMAT_REACT_QUILL}
                    className='approve-react-quill'
                    theme="snow"
                    onChange={setValue}
                    placeholder="Nhập text"
                  />
                </>
              ) : null}

              {contentStatus === 5 ? (
                <div>
                  <Title level={5} className="approved-infor--title">
                    {approveInformationDetail?.approveUser}
                  </Title>
                  <div className="approved-infor--item">
                    <span className="content">
                      {moment(approveInformationDetail?.updatedDate).format(
                        "DD/MM/yyyy"
                      )}
                    </span>
                    <span className="dot-green"></span>
                    <span className="content">Đồng ý</span>
                  </div>
                  <div className="approved-description">
                    <Title level={5} className="approved-infor--title">
                      Mô tả:
                    </Title>
                    <span className="content">
                      {approveInformationDetail?.description === "" ? (
                        "Không có nhận xét"
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: approveInformationDetail?.description,
                          }}
                        />
                      )}
                    </span>
                  </div>
                </div>
              ) : null}
            </>
          ),
        },
      ]
      : []),

    ...(isCourseEvaluation
      ? [
        {
          title: "Thông tin thẩm đinh",
          content: (
            <>
              <div className="radio-group-list">
                <Radio.Group
                  onChange={onChangeEvaluationResults}
                  value={evaluationResults}
                >
                  <div className="wrapper-radio">
                    <Radio value={1}>Đồng ý</Radio>
                    <Radio value={2}>Không đồng ý</Radio>
                  </div>
                </Radio.Group>
              </div>
              <Title
                level={5}
                className="list-item--title"
                style={{padding: "10px 0px"}}
              >
                Mô tả thẩm định:
              </Title>
              <ReactQuill
                modules={modules}
                formats={FORMAT_REACT_QUILL}
                className="approve-react-quill"
                theme="snow"
                onChange={setValue}
                placeholder="Nhập text"
              />
            </>
          ),
        },
      ]
      : []),

    ...(isCourseEndorse
      ? [
        {
          title: "Thông tin thẩm định",
          content: (
            <>
              {/* {approveInformationDetail?.appraiseResults?.map(
                (it: any) => ( */}
              <div style={{paddingTop: "8px"}}>
                <div className="list-item">
                  <Title level={5} className="list-item--title">
                    Hội đồng thẩm định
                  </Title>
                  <div className="list-item--description">
                    <Text className="content">
                      {detailData?.councilName}
                    </Text>
                  </div>
                </div>
                <div className="list-item">
                  <Title level={5} className="list-item--title">
                    Duyệt nội dung
                  </Title>
                  <div className="list-item--description">
                    <Text className="content">
                      {
                        <div contentEditable='true'
                             dangerouslySetInnerHTML={{__html: Number(detailData?.councilApprisedStatus) === 1 ? `Duyệt - <${detailData?.councilDescription}>` : `Không duyệt - <${detailData?.councilDescription}>`}}></div>
                      }
                    </Text>
                  </div>
                </div>
                <div className="list-item">
                  <Title level={5} className="list-item--title">
                    Người gửi
                  </Title>
                  <div className="list-item--description">
                    <Text className="content">
                      {detailData?.senderCouncilUsername}
                    </Text>
                  </div>
                </div>
                <div className="list-item">
                  <Title level={5} className="list-item--title">
                    Ngày gửi
                  </Title>
                  <div className="list-item--description">
                    <Text className="content">
                      {!isEmpty(detailData?.councilApprisedTime) ? moment(detailData?.councilApprisedTime).format("DD/MM/yyyy") : null}
                    </Text>
                  </div>
                </div>
              </div>
              {/* } */}
            </>
          ),
        },
      ]
      : []),


    ...(approveInformationDetail?.status === 3
      ? [
        {
          title: "Chuyển thẩm định",
          content: (
            <>
              <div className="">
                <Select
                  style={{width: "100%", height: "38px"}}
                  placeholder="Chọn hội đồng thẩm định"
                  options={appraisalBoardList}
                  disabled={approvedResult === 2}
                  onChange={handleChangeAppriseGroupId}
                />
              </div>
            </>
          ),
        },
      ]
      : []),

    ...(isCourseEndorse
      ? [
        {
          title: "Chuyển tổ chuyên môn",
          content: (
            <>
              <div className="">
                <Radio.Group
                  onChange={onChangeApprovedResult}
                  value={approvedResult}
                  style={{display: "flex"}}
                >
                  <div className="radio-group-list">
                    <div className="wrapper-radio">
                      <Radio value={1}>Đồng ý</Radio>
                      <Radio value={2}>Không đồng ý</Radio>
                    </div>
                  </div>
                </Radio.Group>

                <>
                  <Title
                    level={5}
                    className="list-item--title"
                    style={{padding: "10px 0px"}}
                  >
                    Mô tả duyệt:
                  </Title>
                  <ReactQuill
                    className="approve-react-quill"
                    formats={FORMAT_REACT_QUILL}
                    modules={modules}
                    theme="snow"
                    onChange={setValue}
                    placeholder="Nhập text"
                  />
                </>
              </div>
            </>
          ),
        },
      ]
      : []),

    ...(isCourseEvaluation
      ? [
        {
          title: "Chuyển tổ chuyên môn",
          content: (
            <>
              <div className="">
                <Select
                  style={{width: "100%", height: "38px"}}
                  placeholder="Chọn tổ chuyên môn"
                  options={appraisalBoardList}
                  disabled={approvedResult === 2 || evaluationResults === 2}
                  onChange={handleChangeAppriseGroupId}
                />
              </div>
            </>
          ),
        },
      ]
      : []),
  ];

  return (
    <>
      <div className={`approved-information-tab ${flag ? "" : "w-50px"}`}>
        <DoubleRightOutlined
          className="approved-information-move-btn"
          rotate={flag ? 0 : 180}
          onClick={toggleFlag}
        />
        {flag ? (
          <>
            <div className="flex">
              <div className="approved-information-title">Thông tin duyệt</div>
            </div>
            <Collapse
              defaultActiveKey={["sub-2", "sub-3", "sub-4", "sub-5"]}
              expandIcon={({isActive}) => (
                <CaretRightOutlined
                  rotate={isActive ? 90 : 0}
                  style={{fontSize: "20px"}}
                />
              )}
            >
              {arrSection.map((el, index) => {
                return (
                  <Panel key={`sub-${index + 1}`} header={el?.title}>
                    <div className="sub-content">{el?.content}</div>
                  </Panel>
                );
              })}
            </Collapse>
            <div className="approved-information-btn">
              <Button
                className="btn btn-outlined h-32"
                onClick={() => navigate(-1)}
              >
                <Text>Hủy</Text>
              </Button>

              <Button
                className="btn btn-primary h-32"
                htmlType="submit"
                onClick={
                  // confirmBrowse
                  () => {
                    setStatusCourse(false);
                    if ((detailData?.approveContentStatus === 0 || detailData?.approveContentStatus === null) && !appriseGroupId && approvedResult !== 2) {
                      message.error("Chưa chọn hội đồng thẩm định")
                      return
                    } else if (!detailData.councilApprisedStatus && !appriseGroupId && approvedResult !== 2 && evaluationResults !== 2) {
                      message.error("Chưa chọn tổ chuyên môn")
                      return
                    } else {
                      setIsConfirm(true);
                    }
                  }
                }
              >
                <Text>Lưu</Text>
              </Button>
            </div>
            <AppraisalBoardMemberListModal
              isOpen={
                (isOpenMemberList && appraisalBoard !== "") ||
                (isOpenMemberList && appriseGroupId !== "")
              }
              onCloseModal={() => setIsOpenMemberList(false)}
              appraisalBoardId={+appraisalBoard || +appriseGroupId}
            />
            <CommonModal
              open={isConfirm}
              title={"Thông báo xác nhận lưu"}
              desc={
                <div>
                  Đảm bảo rằng tất cả các{" "}
                  <span className="text-browse">
                    thông tin liên quan đã được kiểm tra và cập nhật đầy đủ{" "}
                  </span>
                  trước khi Xác nhận kết quả duyệt trang trình bày khoá học.
                </div>
              }
              closeIcon={true}
              okText={"Xác nhận"}
              cancelText={"Huỷ"}
              icon={
                <InfoCircleOutlined
                  style={{color: "#FFC53D", marginRight: "10px"}}
                />
              }
              confirm={() =>
                isCourseApprove
                  ? handleSendApprove()
                  : isCourseEndorse
                    ? handleSaveAndPublish()
                    : handleApprove()
              }
              closeModal={() => setIsConfirm(false)}
              className={""}
            />
          </>
        ) : null}
      </div>
    </>
  );
};

export default ApprovedInformationTabSlideCourse;

interface Detail {
  enterpriseName: string;
  userNameSendApprove: string;
  approveSendDate: any;
  approveDate: any;
  enterpriseNameSponsor: string;
  fromUserName: string;
  createdDate: any;
  approveUser: any;
  updatedDate: any;
  description: any;
  appraiseResults: any;
  appraiseUser: string;
  approveResults: any;
}

interface StatusResult {
  [key: string]: boolean;
}
