import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, Radio, RadioChangeEvent, Row, Space, TableColumnsType, TableProps, TimePicker, Typography, Upload, message } from 'antd'
import React, { ChangeEvent, useEffect, useState } from 'react'
import FormInput from '../../form-input/FormInput'
import { CloseOutlined, DownloadOutlined, SaveOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons'
import TableData from '../../table-data/TableData'
import { getListSequenceById, getSyncTemplate, syncSequenceCourse } from '../../../service/content-list-course/contentListCourseApi'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import FormItemDropdown from '../../form-dropdown/FormItemDropdown'
import { appriseContentCourse, putPublishCourse } from '../../../service/content-course/contenCourseApi'
import { getAppriseGroup } from '../../../service/course-construct'

type Props = {
  open: boolean
  handleClose: () => void
  getListDataProps: () => void
  setSelectedRowKeys: (value: any) => void
  setClearRowkey?: (value: boolean) => void
  quickReview: boolean
  selectedRowKeys: any
  title: string
  type: string
  quickReviewId: string
}

export default function ReviewMultipleContent(props: Props) {
  const { open, handleClose, quickReview, selectedRowKeys, getListDataProps, setSelectedRowKeys, title, type, quickReviewId, setClearRowkey } = props
  const [value, setValue] = useState(1);
  const [appraisalBoardList, setAppraisalBoardList] = useState([]);


  const onChange = (e: RadioChangeEvent) => {
    form.resetFields()
    setValue(e.target.value);
  };
  const [form] = Form.useForm()

  const getAppriseBoardListData = async () => {
    const id = type === 'duyet' ? 0 : 1;
    await getAppriseGroup("" + id).then((res: any) => {
      const { data } = res;
      setAppraisalBoardList(
        data.map((it: any) => {
          return {
            ...it,
            label: it.name,
            value: it.id,
          };
        })
      );
    });
  };

  const reviewContentCourse = async (values: any) => {
    const ids = selectedRowKeys.map((item: any) => item.id);
    const data = {
      courseIds: isEmpty(ids) ? [quickReviewId] : ids,
      type: 1,
      status: value,
      groupAppriseId: values.groupAppriseId ?? null
    };
    await putPublishCourse(data).then((result) => {
      if(value=== 1) {
        message.success(`Duyệt nội dung khoá học thành công`);
      } else {
        message.error(`Duyệt nội dung khoá học thất bại`);
      }
      getListDataProps();
      handleClose()
      if (setClearRowkey) {
        setClearRowkey(true)
      }
      form.resetFields()
      setValue(1)
    });
  }

  const approvalContentCourse = async (values: any) => {
    const ids = selectedRowKeys.map((item: any) => item.id);
    const data = {
      courseIds: isEmpty(ids) ? [quickReviewId] : ids,
      type: 0,
      status: value,
      groupAppriseId: values.groupAppriseId ?? null
    };
    await appriseContentCourse(data).then((result) => {
      if(value=== 1) {
        message.success(`Thẩm định nội dung khoá học thành công`);
      } else {
        message.error(`Thẩm định nội dung khoá học thất bại`);
      }
      getListDataProps();
      handleClose()
      if (setClearRowkey) {
        setClearRowkey(true)
      }
      setValue(1)
      form.resetFields()
    });
  }

  useEffect(() => {
    if (open) {
      getAppriseBoardListData()
    }
  }, [open])

  return (
    <Form.Provider
      onFormFinish={async (name, { values, forms }) => {
        if (name === 'review-multiple-content-modal') {
          if (type === 'duyet') {
            reviewContentCourse(values)
          }
          if (type === 'thamdinh') {
            approvalContentCourse(values)
          }
        }
      }}
    >
      <Modal
        className="review-multiple-content-modal"
        title={title}
        closeIcon={true}
        centered
        open={open}
        onCancel={handleClose}
        footer={null}
        width={"600px"}
      >
        <Form form={form} name="review-multiple-content-modal">
          <Row gutter={40} className='p-6'>
            <Col md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xs={{ span: 24 }}>
              <Typography.Title level={5} className='text-center'>Nhập nội dung {type === 'duyet' ? 'duyệt' : (type === 'thamdinh' ? 'thẩm định' : 'phê duyệt')} khoá học</Typography.Title>
            </Col>
            <Col md={{ span: 9 }} lg={{ span: 9 }} xl={{ span: 9 }} xs={{ span: 9 }} className='mt-1'>
              <Radio.Group onChange={onChange} value={value}>
                <Space direction="vertical">
                  <div className="wrapper-radio">
                    <Radio value={1}>Đồng ý</Radio>
                    <Radio value={2}>Không đồng ý</Radio>
                  </div>
                </Space>
              </Radio.Group>
            </Col>
            <Col md={{ span: 15 }} lg={{ span: 15 }} xl={{ span: 15 }} xs={{ span: 15 }} className='mt-1'>
              {
                type === 'duyet' ?
                  <Form.Item name="groupAppriseId" rules={[
                    {
                      required: type === 'duyet' && value !== 2 ? true : false,
                      message: "Thông tin này bắt buộc",
                    },
                  ]}>
                    <FormItemDropdown
                      disabled={value === 2}
                      placeholder="Chọn hội đồng thẩm định"
                      options={appraisalBoardList}
                    />
                  </Form.Item> :
                  <Form.Item name="groupAppriseId" rules={[
                    {
                      required: type !== 'duyet' && value !== 2 ? true : false,
                      message: "Thông tin này bắt buộc",
                    },
                  ]}>
                    <FormItemDropdown
                      disabled={value === 2}
                      placeholder="Chọn tổ chuyên môn"
                      options={appraisalBoardList}
                    />
                  </Form.Item>
              }
            </Col>
            <Col md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xs={{ span: 24 }}>

              <div className='mt-9'>
                <Space className='float-right'>
                  <Button onClick={handleClose} className="btn btn-outlined">
                    <Typography.Text>
                      Hủy bỏ
                    </Typography.Text>
                  </Button>
                  <Button className="btn btn-primary" onClick={() => form.submit()}
                  >
                    <Typography.Text>
                      Xác nhận
                    </Typography.Text>
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Form.Provider>
  )
}
