import {
  CheckCircleOutlined,
  EyeOutlined,
  FileDoneOutlined,
  InfoCircleOutlined,
  MoreOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Form,
  Layout,
  Modal,
  Space,
  TableColumnsType,
  TableProps,
  message,
} from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import {
  ChangeEvent,
  FunctionComponent,
  KeyboardEvent,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import FormFilter from "../../../../components/course-browse/course-browse/filter-group/FormFilter";
import FilterDataCourseBrowse from "../../../../components/course-browse/course-browse/filter-table/FilterTable";
import PageHeader from "../../../../components/page-header/PageHeader";
import TableData from "../../../../components/table-data/TableData";
import { routesConfig } from "../../../../config/routes";
import { getContentBrowseList } from "../../../../service/course-construct";
import { IBodyRequestDataPost } from "../../../../types/roleManagement";
import { getCurrentPageFromLink } from "../../../../utils/getPageFromLink";
import "./index.scss";
import { renderStatusCourse } from "../../../../utils/functionUltils";
import { putPublishCourse, unPublishslidesCourse } from "../../../../service/content-course/contenCourseApi";
import ReviewMultipleContent from "../../../../components/modal/review-multiple-content";
import ReviewMultipleSlideContent from "../../../../components/modal/review-multiple-slide";
import { formatOriginTime, formatOriginTimev3 } from "../../../../utils/converDate";


type RoleMangementProps = {
  title?: string;
};

const CourseBrowseIndex: FunctionComponent<RoleMangementProps> = ({
  title,
}) => {
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [openReviewMultiSlidesModal, setOpenReviewMultiSlidesModal] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [listCourseBrowse, setListCourseBrowse] = useState([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [quickReviewId, setQuickReviewId] = useState<string>("");
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");


  const [dataOptions, setDataOptions] = useState({
    dataUsers: [],
    dataPermissions: [],
  });
  const navigate = useNavigate();
  const [quickReview, setQuickReview] = useState(false)
  const [loading, setLoading] = useState<boolean>(true);
  const [clearRowkey, setClearRowkey] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [filterData, setFilterData] = useState<any>({
    approveSlideStatus: null,
    approveContentStatus: null,
    approvedDate: null,
    courseType: null,
  });
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [openReviewContentModal, setOpenReviewContentModal] = useState<boolean>(false);

  const handleReturnCourseType = (courseType: number) => {
    switch (courseType) {
      case 1:
        return "Tự triển khai";
      case 2:
        return "Hợp tác xây dựng";
      case 3:
        return "Dùng chung";
      default:
        return "";
    }
  };

  const optionsMenu = (slideStatus: number, contentStatus: number) => {
    return [
      ...(slideStatus !== 13 && slideStatus !== 4
        ? [
          {
            key: "1",
            label: "Duyệt trang trình bày khoá học",
            icon: <FileDoneOutlined />,
          },
          {
            key: "2",
            label: "Duyệt nhanh trang trình bày khoá học",
            icon: <ThunderboltOutlined />,
          },
        ]
        : []),

      ...(slideStatus === 13
        ? [
          {
            key: "20",
            label: "Thu hồi duyệt trang trình bày",
            icon: <FileDoneOutlined />,
          },
        ]
        : []),

      ...(slideStatus === 5 || slideStatus === 7
        ? [
          {
            key: "3",
            label: "Kết quả thẩm định",
            icon: <InfoCircleOutlined />,
          },
        ]
        : []),
      ...(slideStatus === 9 || slideStatus === 10
        ? [
          {
            key: "4",
            label: "Kết quả phê duyệt",
            icon: <InfoCircleOutlined />,
          },
        ]
        : []),

      ...(contentStatus === 3
        ? [
          {
            key: "5",
            label: "Duyệt nội dung khoá học",
            icon: <FileDoneOutlined />,
          },
          {
            key: "6",
            label: "Duyệt nhanh nội dung khoá học",
            icon: <ThunderboltOutlined />,
          },
        ]
        : []),

      ...[
        {
          key: "7",
          label: "Kết quả duyệt",
          icon: <FileDoneOutlined />,
        },
      ]
    ];
  };

  const handleCheckCourse = (id: string) => {
    submitData([id]);
  };

  const handleBulkCheckCourse = () => {
    const ids = selectedRowKeys.map((item: any) => item.id);
    submitData(ids);
  };

  const submitData = async (ids: string[]) => {
    const data = {
      courseIds: ids,
      type: 2,
      status: 1
    };
    await putPublishCourse(data).then((result) => {
      message.success(`Duyệt trang trình bày thành công`);
      getListData();
      setPageNumber(1);
      setSelectedRowKeys([])
    });
  };

  const renderStatus = (value: any) => {
    switch (value) {
      case isEmpty(value) || value === 0:
        <div>Chưa duyệt</div>
        break;
      case value === 1:
        <div>Đã duyệt</div>
        break;
      case value === 2:
        <div>Từ chối duyệt</div>
        break;
      default:
        break;
    }
  }

  const unPublishSlideCourse = async (id: number) => {
    const data = {
      courseId: id
    }
    await unPublishslidesCourse(data).then((res) => {
      if (res.status === 200) {
        message.success("Thu hồi duyệt trang trình bày thành công")
        getListData()
      }
    })
  }

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Tên khoá học",
      dataIndex: "name",
      sorter: true,
      key: "name",
      width: 40,
      render: (_: any, record) => {
        return (
          <div className="table-order-course-name">
            <img
              className="item-carousel__image"
              loading="eager"
              alt=""
              src={record?.image || "/images/ic_school_placeholder.svg"}
            />
            <div>
              <span className="course-title">{record?.name}</span>
              <span>{record?.enterpriseName}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Trạng thái duyệt trang trình bày",
      dataIndex: "slideStatus",
      key: "slideStatus",
      render: (_: any, record) => {
        return (
          <>
            <span className="status-content">
              {renderStatusCourse(record?.slideStatus)}
            </span>
          </>
        );
      },
      width: 30,
    },
    {
      title: "Trạng thái duyệt nội dung",
      dataIndex: "contentStatus",
      key: "contentStatus ",
      render: (_: any, record) => {
        return (
          <>
            <span className="status-content">
              {renderStatusCourse(record?.contentStatus)}
            </span>
          </>
        );
      },
      width: 30,
    },
    {
      title: "Loại khoá học",
      dataIndex: "name",
      key: "name",
      width: 20,
      render: (_: any, record) => {
        return (
          <div className="table-course-type">
            {record.courseType === 2 ? (
              <div className="table-course-type-2">
                {handleReturnCourseType(record.courseType)}
              </div>
            ) : record.courseType === 1 ? (
              <div className="table-course-type-1">
                {handleReturnCourseType(record.courseType)}
              </div>
            ) : <div className="table-course-type-3">
              {handleReturnCourseType(record.courseType)}
            </div>}
          </div>
        );
      },
    },
    {
      title: "Ngày duyệt",
      dataIndex: "contentApprovedDate",
      key: "contentApprovedDate",
      render: (_: any, record) => {
        return (
          <>
            <span>{!isEmpty(record?.contentApprovedDate) ? formatOriginTimev3(record?.contentApprovedDate) : null}</span>
          </>
        );
      },
      width: 20,
    },
    {
      title: "Ngày gửi duyệt",
      dataIndex: "approveSendDate",
      key: "approveSendDate",
      render: (_: any, record) => {
        return (
          <>
            <span>{!isEmpty(record?.createdDate) ? formatOriginTimev3(record?.createdDate) : null}</span>
          </>
        );
      },
      width: 20,
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      fixed: 'right',
      render: (_, record) => {
        return (
          <Space className="dropdown-course-browse">
            <a
              className="action-table"
              onClick={() =>
                navigate(`${routesConfig.detailCourseBrowse}/${record.id}`, {
                  state: {
                    ...record,
                    slideStatus: record?.slideStatus,
                    contentStatus: record?.contentStatus,
                    mode: 'view'
                  },
                })
              }
            >
              <EyeOutlined />
            </a>
            <Dropdown
              className="dropdown-course-browse"
              menu={{
                items: optionsMenu(record?.slideStatus, record?.contentStatus),
                onClick: ({ key }) => {
                  if (key === "1") {
                    navigate(
                      `${routesConfig.detailCourseBrowse}/${record.id}`,
                      {
                        state: {
                          ...record,
                          isCourseApprove: true,
                          isSlide: true,
                          slideStatus: record?.slideStatus,
                          contentStatus: record?.contentStatus,
                          mode: 'edit'
                        },
                      }
                    );
                  }
                  if (key === "2") {
                    Modal.confirm({
                      title: `Xác nhận duyệt khóa học.`,
                      content: `Xin vui lòng xác nhận việc duyệt thông tin khóa học.`,
                      onOk: () => handleCheckCourse(record.id),
                      okText: "Xác nhận",
                      cancelText: "Đóng",
                      centered: true,
                      icon: (
                        <CheckCircleOutlined style={{ color: "#1677FF" }} />
                      ),
                      footer: (_, { OkBtn, CancelBtn }) => (
                        <>
                          <CancelBtn />
                          <OkBtn />
                        </>
                      ),
                    });
                  }
                  if (key === "3") {
                    navigate(
                      `${routesConfig.detailCourseBrowse}/${record.id}`,
                      {
                        state: {
                          ...record,
                          isCourseEvaluationTotal: true,
                        },
                      }
                    );
                  }
                  if (key === "4") {
                    navigate(
                      `${routesConfig.detailCourseBrowse}/${record.id}`,
                      {
                        state: {
                          ...record,
                          isCourseApproveTotal: true,
                        },
                      }
                    );
                  }
                  if (key === "5") {
                    navigate(
                      `${routesConfig.detailCourseBrowse}/${record.id}/?tab=Review course content`,
                      {
                        state: {
                          ...record,
                          isCourseApprove: true,
                        },
                      }
                    );
                  }
                  if (key === '6') {
                    setOpenReviewContentModal(true)
                    setQuickReview(true)
                    setQuickReviewId(record?.id)
                  }
                  if (key === '7') {
                    navigate(`${routesConfig.detailCourseBrowse}/${record.id}`, {
                      state: {
                        ...record,
                        slideStatus: record?.slideStatus,
                        contentStatus: record?.contentStatus,
                        tab: 3
                      },
                    })
                  }

                  if (key === "20") {
                    Modal.warning({
                      title: 'Bạn có muốn thu hồi duyệt trang trình bày của khoá học?',
                      okText: 'Xác nhận',
                      cancelText: 'Huỷ',
                      className: 'modal-warning-certificate',
                      onCancel() {
                      },
                      footer: <>
                        <div className="group-button--certificate">
                          <Button onClick={() => Modal.destroyAll()}>Huỷ</Button>
                          <Button className="btn btn-primary" onClick={() => {
                            Modal.destroyAll()
                            unPublishSlideCourse(record?.id)
                          }}>Xác nhận</Button>
                        </div>
                      </>,
                      content: 'Mọi thay đổi sẽ không được lưu nếu quay lại',
                    })
                  }
                }
              }}
              placement="bottomRight"
            >
              <Button>
                <MoreOutlined />
              </Button>
            </Dropdown>
          </Space>
        );
      },
      width: 20,
    },
  ];

  const defaultCheckedList = dataColumns.map((item) => item.key as string);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const fetchOptionsFilter = async (
    dataPermission?: IBodyRequestDataPost,
    dataUser?: IBodyRequestDataPost
  ) => {
    try {
    } catch (error) {
      console.error(error);
    }
  };

  const updateUser = (id: string, data: any) => { };

  useEffect(() => {
    setLoading(false);
    const pageLink = getCurrentPageFromLink(location.search);
    if (!isEmpty(pageLink)) {
      setPageNumber(Number(pageLink));
    } else {
      fetchOptionsFilter();
    }
  }, []);


  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (value.length === 0) {
      setSearchValue("");
    } else {
      setSearchValue(value);
    }
  };

  const handleOpenCreate = () => {
    setOpenReviewMultiSlidesModal(true)
  };

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      if (pageNumber === 1) {
        getListData();
      } else {
        setPageNumber(1);
      }
    }
  };

  const handleSubmitSearch = () => {
    if (pageNumber === 1) {
      getListData();
    } else {
      setPageNumber(1);
    }
  };

  const listBreadcrumb = [
    {
      title: "Quản trị vai trò",
    },
  ];

  const clearFilter = () => {
    form.resetFields();
    setFilterData({
      approveSlideStatus: null,
      approveContentStatus: null,
      approvedDate: null,
      courseType: null,
    });
  };
  const handleCloseFilter = () => {
    // setShowFilter(false);
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder("name");
  };

  const formatDateWithTime = (dateString: string | null): string | null => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split('-');
    const formattedDate = `${year}-${month}-${day}`;
    return `${formattedDate}T00:00:00.000Z`;
  }
  const getListData = () => {
    setLoading(true);
    const approvedDate = filterData.approvedDate?.filter((item: any) => !!item);
    const data = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue.trim() || undefined,
      status: filterData?.approveContentStatus ?
        filterData?.approveContentStatus
        : [],
      slideStatus:
        filterData?.approveSlideStatus ? filterData?.approveSlideStatus : [],
      courseType: filterData.courseType ?
        filterData.courseType
        : [],
      approvedFrom: !isEmpty(filterData?.approvedDate) ? formatDateWithTime(approvedDate[0]) : null,
      approvedTo: !isEmpty(filterData?.approvedDate) ? formatDateWithTime(approvedDate[1]) : null,
      sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder) ? [] : [`${fieldSortOrder},${statusOrder}`]
    };

    getContentBrowseList(data)
      .then((res) => {
        const { data } = res;
        setListCourseBrowse(data?.content);
        setTotalRecords(data.totalElements);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getListData();
  }, [pageNumber, pageSize, fieldSortOrder, statusOrder]);

  return (
    <Layout className="page-header-group course-browse-index">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "formRole") {
          }
        }}
      >
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
            }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterDataCourseBrowse
              reviewSlides={handleOpenCreate}
              reviewContent={() => {
                if (isEmpty(selectedRowKeys)) {
                  message.error("Bạn chưa chọn khoá học cần duyệt nội dung")
                } else {
                  setOpenReviewContentModal(true)
                }
              }}
              searchValue={searchValue}
              dataColumns={dataColumns}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
              selectedRowKeys={selectedRowKeys}
            />
          </div>
          <FormFilter
            onFinish={handleSubmitSearch}
            handleCloseFilter={handleCloseFilter}
            dataOptions={dataOptions}
            clearFilter={clearFilter}
            form={form}
            showFilter={showFilter}
            filterData={filterData}
            setFilterData={handleChangeFilter}
          />
        </div>
        <TableData
          dataColumns={dataColumns}
          loadingTable={loading}
          dataTable={listCourseBrowse}
          pageSize={pageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          totalRecords={totalRecords}
          scroll={{ x: 1500, y: 800 }}
          pagination={true}
          hideSelection={true}
          handleTableChangeProps={handleTableChange}
          setSelectedRowKeysProps={setSelectedRowKeys}
          clearRowkey={clearRowkey}
        />

      </Form.Provider>
      <ReviewMultipleContent
        title={`${quickReview ? 'Duyệt nhanh' : 'Duyệt nhiều'} `}
        type="duyet"
        open={openReviewContentModal}
        handleClose={() => setOpenReviewContentModal(false)}
        quickReview={quickReview}
        selectedRowKeys={selectedRowKeys}
        getListDataProps={getListData}
        setSelectedRowKeys={() => setSelectedRowKeys([])}
        quickReviewId={quickReviewId}
        setClearRowkey={setClearRowkey}
      />
      <ReviewMultipleSlideContent
        open={openReviewMultiSlidesModal}
        handleClose={() => setOpenReviewMultiSlidesModal(false)}
        getListDataProps={getListData}
      />
    </Layout>
  );
};

export default CourseBrowseIndex;
