import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { FormInstance } from "antd/lib";
import { FunctionComponent } from "react";
import SelectUiCard from "../../../../../../../components/select-ui";
import "./filter-group.scss";

type FormFilterProps = {
  showFilter?: boolean;
  clearFilter: (form: FormInstance) => void;
  onFinish?: any;
  optionSelectIndustry: any;
  optionSelectSchool: any;
  setDataFilter: any;
  dataFilter: any;
  checkedList: any;
};

const FormFilter: FunctionComponent<FormFilterProps> = ({
  showFilter,
  clearFilter,
  onFinish,
  optionSelectIndustry,
  optionSelectSchool,
  setDataFilter,
  dataFilter,
  checkedList,
}) => {
  const [form] = useForm();
  const onChangeFilter = (val: string, name: string) => {
    setDataFilter({
      ...dataFilter,
      [name]: val
    });
  };

  return (
    <>
      <div className={`wrapper-filter ${showFilter ? "open" : ""} !pl-0`}>
        <Form
          name="formFilterMinistries"
          onFinish={onFinish}
          form={form}
          className="flex-wrap flex"
        >
          <Flex justify="end" className="w-full lg:w-4/5 pr-3">
            {checkedList.includes("university") && (
              <SelectUiCard
                className="font-medium w-full lg:w-1/3 pr-2"
                name="university"
                label=""
                filterOption={(input: any, option: any) =>
                  option?.label.toLowerCase().includes(input.toLowerCase())
                }
                options={optionSelectSchool}
                mode="multiple"
                placeholder="Trường chủ trì"
                allowClear
                onChange={(val) => onChangeFilter(val, "university")}
              />
            )}
            {checkedList.includes("is_free") && (
              <SelectUiCard
                className="font-medium w-full lg:w-1/3 pr-2"
                name="is_free"
                label=""
                filterOption={(input: any, option: any) =>
                  option?.label.toLowerCase().includes(input.toLowerCase())
                }
                options={[
                  { label: "Miễn phí", value: 1 },
                  { label: "Trả phí", value: 0 },
                  { label: "Tất cả", value: -1 },
                ]}
                placeholder="Chi phí"
                allowClear
                onChange={(val) => onChangeFilter(val, "is_free")}
              />
            )}
            {checkedList.includes("industry") && (
              <SelectUiCard
                className="font-medium w-full lg:w-1/3"
                name="industry"
                label=""
                filterOption={(input: any, option: any) =>
                  option?.label.toLowerCase().includes(input.toLowerCase())
                }
                options={optionSelectIndustry}
                mode="multiple"
                placeholder="Khoa môn học"
                allowClear
                onChange={(val) => onChangeFilter(val, "industry")}
              />
            )}
          </Flex>
          <Flex className="w-full lg:w-1/5">
            <Button
              className="btn btn-primary filter-btn mr-2 lg:mr-0"
              htmlType="submit"
            >
              <FilterOutlined />
              <Typography.Text>Lọc</Typography.Text>
            </Button>
            <Button
              className="btn btn-outlined clear-filter-btn"
              onClick={() => clearFilter(form)}
            >
              <DeleteOutlined />
              <Typography.Text>Xóa bộ lọc</Typography.Text>
            </Button>
          </Flex>
        </Form>
      </div>
    </>
  );
};

export default FormFilter;
