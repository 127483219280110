interface Option {
  value: string;
  label: string;
}

type ExportType = "excel" | "pdf";

export const chartContentTypes: Option[] = [
  {value: "1", label: "Đa phương tiện"},
  {value: "2", label: "Tài liệu tham khảo"},
  {value: "3", label: "Bài kiểm tra đánh giá"},
  {value: "4", label: "SCORM & xAPI"},
  {value: "5", label: "Bài giảng"},
  {value: "6", label: "Khoá học"},
];


export const handleExportReport = async (
  apiEndpoint: (data: any) => Promise<any>,
  fileName: string,
  exportType: ExportType,
  payload?: any
) => {
  const mimeType = exportType === "excel"
    ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    : "application/pdf";

  const fileExtension = exportType === "excel" ? "xlsx" : "pdf";

  try {
    const res = await apiEndpoint({...payload, exportType});
    const fileData = new Blob([res.data], {type: mimeType});

    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(fileData);
    downloadLink.download = `${fileName}.${fileExtension}`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  } catch (error) {
    console.error(`Error downloading ${exportType.toUpperCase()} file:`, error);
  }
};

export const renderCourseStructureType = (type: string) => {
  switch (type) {
    case "5":
      return "sequence";
    case "6":
      return "course";
    case "1":
    case "2":
    case "3":
    case "4":
      return "unit";
    default:
      break;
  }
}

export const renderColBarStackedChart = (series: String[], listData: any) => {
  if (listData?.length > 0) {
    listData?.map((i: any) => {
      series.forEach(criteria => {
        // check criteria exist
        const exists = i.values?.some((item: any) => item.criteria === criteria);

        if (!exists) {
          i.values?.push({
            count: 0,
            percentage: null,
            criteria: criteria
          });
        }


      });
      // sort by series
      i.values?.sort((a: any, b: any) => {
        return series.indexOf(a.criteria) - series.indexOf(b.criteria);
      });
    })
  }
}

export const renderColDonutChart = (series: String[], listData: any) => {
  if (listData?.length > 0) {
    series.forEach(criteria => {
      // check criteria exist
      const exists = listData.some((item: any) => item.criteria === criteria);

      if (!exists) {
        listData.push({
          count: 0,
          percentage: null,
          criteria: criteria
        });
      }


    });
    // sort by series
    listData.sort((a: any, b: any) => {
      return series.indexOf(a.criteria) - series.indexOf(b.criteria);
    });
  }
}

export const renderColDualChart = (series: String[], listData: any) => {
  if (listData?.length > 0) {
    listData.forEach((data: any) => {
      series.forEach(criteria => {
        // check criteria exist
        const exists = data?.values?.some((item: any) => item.criteria === criteria);

        if (!exists) {
          data?.values?.push({
            count: 0,
            percentage: null,
            criteria: criteria
          });
        }

        // sort by series
        data?.values?.sort((a: any, b: any) => {
          return series.indexOf(a.criteria) - series.indexOf(b.criteria);
        });
      });
    })
  }
}

export const renderColBarStackChart = (series: String[], listData: any) => {
  if (listData?.length > 0) {
    listData.forEach((data: any) => {
      series.forEach(criteria => {
        // check criteria exist
        const exists = data?.values?.some((item: any) => item.criteria === criteria);

        if (!exists) {
          data?.values?.push({
            count: 0,
            percentage: null,
            criteria: criteria
          });
        }

        // sort by series
        data?.values?.sort((a: any, b: any) => {
          return series.indexOf(a.criteria) - series.indexOf(b.criteria);
        });
      });
    })
  }
}