import { SendOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Input, Radio, Space } from "antd";
import React from "react";

const { TextArea } = Input;

interface ApproveFormProps {
  onSubmit?: (value: any) => void;
}

function ApproveForm({ onSubmit = () => {} }: ApproveFormProps) {
  const [form] = Form.useForm();
  const statusWatch = Form.useWatch("status", form);

  return (
    <Form form={form} onFinish={onSubmit}>
      <Form.Item
        name={"status"}
        rules={[
          {
            required: true,
            message: "Vui lòng chọn trạng thái giao dịch",
          },
        ]}
      >
        <Radio.Group>
          <Space direction="vertical">
            <Radio value={true}> Giao dịch thành công </Radio>
            <Radio value={false}>Giao dịch không thành công </Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      {statusWatch === false && (
        <Form.Item
          className="mt-1 mb-3"
          name={"reason"}
          rules={[
            {
              required: true,
              message: "Vui lòng nhập lý do",
            },
          ]}
        >
          <TextArea
            placeholder="Nhập lý do"
            maxLength={1000}
            showCount
            rows={3}
          />
        </Form.Item>
      )}

      <Flex justify="flex-end" style={{ marginTop: 30 }}>
        <Button
          icon={<SendOutlined />}
          htmlType="submit"
          className="btn-primary"
        >
          Gửi
        </Button>
      </Flex>
    </Form>
  );
}

export default ApproveForm;
