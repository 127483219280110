import { FC } from "react";
import CustomCard from "../../../../custom-card/CustomCard";
import { Col, Form, Row, Tag } from "antd";
import "../student-detail/studentDetail.scss";
interface StudentDetailProps {
  studentRecord: any;
  specialized: string[];
}

const StudentDetail: FC<StudentDetailProps> = ({
  studentRecord,
  specialized,
}) => {
  return (
    <section className="wrapper-settings category-management-detail">
      <div className="setting-student wrapper-inside">
        <CustomCard className="header-card" title="Thông tin danh mục dùng chung">
          <Form.Item className="setting-item" name="name" label="Tên sinh viên">
            <p className="student-content">{studentRecord?.name}</p>
          </Form.Item>
          <Form.Item className="setting-item" name="code" label="Mã sinh viên">
            <p className="student-content">{studentRecord?.code}</p>
          </Form.Item>
          <Form.Item className="setting-item" name="gender" label="Giới tính">
            <p className="student-content">{studentRecord?.gender}</p>
          </Form.Item>
          <Form.Item className="setting-item" name="department" label="Khoa">
            <p className="student-content">{studentRecord?.industryGroupInfo?.name}</p>
          </Form.Item>
          <Form.Item className="setting-item" name="domicile" label="Nguyên quán">
            <p className="student-content">{studentRecord?.provinceDto.name}</p>
          </Form.Item>
          <Form.Item className="setting-item" name="universityName" label="Tên trường">
            <p className="student-content">{studentRecord?.universityName}</p>
          </Form.Item>
          <Form.Item className="setting-item" name="specialized" label="Chuyên ngành">
            <div className="student-content">
              {specialized?.map((item: any) => (
                <Tag key={item}>
                  {item}
                </Tag>
              ))}
            </div>
          </Form.Item>
          <Form.Item className="setting-item" name="email" label="Email">
            <p className="student-content">{studentRecord?.email}</p>
          </Form.Item>
          <Form.Item className="setting-item" name="phone" label="Điện thoại liên hệ">
            <p className="student-content">{studentRecord?.phoneNumber}</p>
          </Form.Item>
        </CustomCard>
      </div>
    </section>
  )
}
export default StudentDetail;