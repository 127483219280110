import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, Radio, RadioChangeEvent, Row, Space, TableColumnsType, TableProps, TimePicker, Typography, Upload, message } from 'antd'
import React, { ChangeEvent, useEffect, useState } from 'react'
import FormInput from '../../form-input/FormInput'
import { CloseOutlined, DownloadOutlined, SaveOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons'
import TableData from '../../table-data/TableData'
import { getListSequenceById, getSyncTemplate, syncSequenceCourse } from '../../../service/content-list-course/contentListCourseApi'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import FormItemDropdown from '../../form-dropdown/FormItemDropdown'
import { appriseContentCourse, putPublishCourse } from '../../../service/content-course/contenCourseApi'
import { getAppriseGroup } from '../../../service/course-construct'

type Props = {
  open: boolean
  handleClose: () => void
  getListDataProps: () => void
  setSelectedRowKeys: (value: any) => void
  setClearRowkey?: (value: boolean) => void
  quickReview: boolean
  selectedRowKeys: any
  title: string
  quickReviewId: string
}

export default function ApprovalMultipleContent(props: Props) {
  const { open, handleClose, quickReview, selectedRowKeys, getListDataProps, setSelectedRowKeys, title, quickReviewId, setClearRowkey } = props
  const [value, setValue] = useState(1);
  const [appraisalBoardList, setAppraisalBoardList] = useState([]);


  const onChange = (e: RadioChangeEvent) => {
    form.resetFields()
    setValue(e.target.value);
  };
  const [form] = Form.useForm()

  const reviewContentCourse = async (values: any) => {
    const ids = selectedRowKeys.map((item: any) => item.id);
    const data = {
      courseIds: isEmpty(ids) ? [quickReviewId] : ids,
      type: 1,
      status: value,
      groupAppriseId: values.groupAppriseId ?? null
    };
    await putPublishCourse(data).then((result) => {
      if(value === 1) {
        message.success(`Duyệt nội dung khoá học thành công`);
      } else {
        message.error(`Duyệt nội dung khoá học thất bại`);
      }
      getListDataProps();
      handleClose()
      setSelectedRowKeys([])
      setValue(1)
    });
  }

  const approvalContentCourse = async (values: any) => {
    const ids = selectedRowKeys.map((item: any) => item.id);
    const data = {
      courseIds: isEmpty(ids) ? [quickReviewId] : ids,
      type: 1,
      status: value,
    };
    await appriseContentCourse(data).then((result) => {
      if(value === 1) {
        message.success(`Phê duyệt nội dung khoá học thành công`);
      } else {
        message.error(`Phê duyệt nội dung khoá học thất bại`);
      }
      getListDataProps();
      handleClose()
      if (setClearRowkey) {
        setClearRowkey(true)
      }
      form.resetFields()
      setValue(1)
    });
  }


  return (
    <Form.Provider
      onFormFinish={async (name, { values, forms }) => {
        if (name === 'approval-multiple-content-modal') {
          approvalContentCourse(values)
        }
      }}
    >
      <Modal
        className="review-multiple-content-modal"
        title={title}
        closeIcon={true}
        centered
        open={open}
        onCancel={handleClose}
        footer={null}
        width={"600px"}
      >
        <Form form={form} name="approval-multiple-content-modal">
          <Row gutter={40} className='p-6'>
            <Col md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xs={{ span: 24 }}>
              <Typography.Title level={5} className='text-center'>Nhập nội dung phê duyệt khoá học</Typography.Title>
            </Col>
            <Col md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xs={{ span: 24 }} className='mt-1 flex justify-center'>
              <Radio.Group onChange={onChange} value={value}>
                <div className="radio-group-list">
                  <div className="wrapper-radio">
                    <Space direction="horizontal">
                      <Radio value={1}>Đồng ý</Radio>
                      <Radio value={2}>Không đồng ý</Radio>
                    </Space>
                  </div>
                </div>
              </Radio.Group>
            </Col>

            <Col md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xs={{ span: 24 }}>

              <div className='mt-9'>
                <Space className='float-right'>
                  <Button onClick={handleClose} className="btn btn-outlined">
                    <Typography.Text>
                      Hủy bỏ
                    </Typography.Text>
                  </Button>
                  <Button className="btn btn-primary" onClick={() => form.submit()}
                  >
                    <Typography.Text>
                      Xác nhận
                    </Typography.Text>
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Form.Provider>
  )
}
