import {
  Button,
  Form,
  Layout,
  message,
  Modal,
  Popconfirm,
  Space,
  Switch,
  TableColumnsType,
  Typography,
} from "antd";
import TableData from "../../components/table-data/TableData";
import PageHeader from "../../components/page-header/PageHeader";
import {
  ChangeEvent,
  FunctionComponent,
  KeyboardEvent,
  useEffect,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SaveOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import FilterData from "./components/filter-data/FilterData";
import DrawerCustom from "../../components/drawer/Drawer";
import FormFilter from "./components/filter-group/FormFilter";
import CreateRole from "./components/create-role/CreateRole";
import { routesConfig } from "../../config/routes";
import {
  IBodyRequestDataPost,
  IDataListRoles,
} from "../../types/roleManagement";
import dayjs from "dayjs";
import { get, isEmpty } from "lodash";
import {
  createRole,
  deleteRole,
  editRole,
  getDataRoles,
  getOptionsUser,
  getPermissions,
  viewInfoRole,
} from "../../service/role-management/roleManagementApi";
import { validateFieldCreate } from "./constants/data";
import { FormInstance } from "antd/lib";
import { getCurrentPageFromLink } from "../../utils/getPageFromLink";
import Page403 from "../403";

type RoleMangementProps = {
  title?: string;
};

const RoleManagement: FunctionComponent<RoleMangementProps> = ({ title }) => {
  const [params, setParams] = useSearchParams();

  const navigation = useNavigate();

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [dataListRoles, setDataListRoles] = useState<IDataListRoles[]>([]);
  const [dataOptions, setDataOptions] = useState({
    dataUsers: [],
    dataPermissions: [],
    trangThai: [],
    createdDateFrom: null,
    createdDateTo: null,
  });
  const [dataFilter, setDataFilter] = useState({
    quyens: [],
    nguoiDungs: [],
    trangThai: [],
    createdDateFrom: null,
    createdDateTo: null,
  });
  const [resetPermissions, setResetPermissions] = useState({});
  const [resetUsers, setResetUsers] = useState({});

  const [dataDetail, setDataDetail] = useState();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [isAcceptEdit, setIsAcceptEdit] = useState<boolean>(false);
  const [isTitleEdit, setIsTitleEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState(false);
  const [detail, setDetail] = useState<IDataListRoles | null>(null);

  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [dataUpdateUser, setDataUpdateUser] = useState({});
  const [activeKey, setActiveKey] = useState("1"); // Initial tab is 1

  const [checkedListCreate, setCheckedListCreate] = useState<string>(""); // pass props later

  // Function to change to Tab 2
  const changeToTab2 = () => {
    setActiveKey("2");
  };

  const [form] = Form.useForm();

  const checkDefault = (defaultQt: boolean, defaultSv: boolean, defaultGv: boolean) => {
    let defaultType = 0
    if(defaultQt) defaultType = 1
    else if(defaultSv) defaultType = 2
    else if(defaultGv) defaultType = 3
    switch (defaultType) {
      case 1:
        return "Quản trị"
      case 2:
        return "Sinh viên"
      case 3:
        return "Giảng viên"
      default:
        return ""; //default can be change later
    }
  }

  const dataColumns: TableColumnsType<IDataListRoles> = [
     {
      title: "STT",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        const orderNumber = (pageNumber - 1) * pageSize + index + 1;
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Tên vai trò",
      dataIndex: "ten",
      key: "ten",
      sorter: {
        compare: (a, b) => a.ten.localeCompare(b.ten),
      },
      width: 60,
    },
    {
      title: "Mô tả",
      dataIndex: "moTa",
      key: "moTa",
      width: 50,
    },
    {
      title: "Mặc định",
      dataIndex: "macDinh",
      key: "macDinh",
      render: (_: any, record: any) => {
        return (
          <>
            <span>
              {checkDefault(record.defaultQtcs, record.defaultSv, record.defaultGv)}
            </span>
          </>
        );
      },
      width: 60,
    },
    {
      title: "Ngày tạo",
      dataIndex: "ngayTao",
      key: "ngayTao",
      render: (_: any, record: { ngayTao: string }) => {
        return (
          <>
            <span>
              {dayjs(record?.ngayTao).format("DD/MM/YYYY").toString()}
            </span>
          </>
        );
      },
      width: 60,
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      render: (
        _: any,
        record: { id: number; trangThai: boolean; ten: string }
      ) => {
        const handleChange = (checked: boolean) => {
          record.trangThai = checked;
        };

        const confirm = (id: number) => {
          editRole(id.toString(), { trangThai: record.trangThai }).then(
            (res) => {
              const { statusCode } = res.data;

              if (statusCode === 200) {
                message.success("Thay đổi trạng thái thành công");
                fetchData();
              }
            }
          );
        };
        const cancel = () => {};
        return (
          <Popconfirm
            description={`Xác nhận đổi trạng thái thành ${
              record.trangThai ? "“Không hoạt động”?" : "“Hoạt động“?"
            }`}
            onConfirm={() => confirm(get(record, "id"))}
            onCancel={cancel}
            okText="Xác nhận"
            cancelText="Huỷ"
            title=""
          >
            <Switch
              defaultChecked={record.trangThai}
              checked={record.trangThai}
              onChange={handleChange}
            />
          </Popconfirm>
        );
      },
      width: 40,
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const handleShowEdit = () => {
          setIsEdit(true);
          setIsTitleEdit(true);
          setIsAcceptEdit(true);
          setIsOpened(true);
          setDetail(record);
          setParams({ id: record.id.toString() });
          viewInfoRole(record.id.toString()).then((res) => {
            const { data } = res.data;
            setDataDetail(data);
          });
        };
        return (
          <Space>
            <Button className="action-table" onClick={handleShowEdit}>
              <EyeOutlined />
            </Button>
          </Space>
        );
      },
      fixed: "right",
      width: 10,
    },
  ];

  const defaultCheckedList = dataColumns.map((item) => item.key as string);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const fetchData = () =>
    getDataRoles({
      trangThai: dataFilter.trangThai,
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize,
      createdDateFrom: dataFilter.createdDateFrom || "",
      createdDateTo: dataFilter.createdDateTo || "",
    })
      .then((res) => {
        const { data, totalRecords } = res.data.data;
        setDataListRoles(data);
        setTotalRecords(totalRecords);
        setLoading(false);
        setError(false);
      })
      .catch((err) => {
        console.error(err);
        setError(true);
      });

  const handleChangeFilter = (value: any, name: string) => {
    setDataOptions({ ...dataOptions, [name]: value });
  };

  const handleChangeDataFilter = (value: any, name: string) => {
    setDataFilter({ ...dataFilter, [name]: value });
  };

  const fetchOptionsFilter = async (
    dataPermission?: IBodyRequestDataPost,
    dataUser?: IBodyRequestDataPost
  ) => {
    try {
      const response = await Promise.all([
        getPermissions(dataPermission),
        getOptionsUser(dataUser),
      ]);
      const dataPermissions = response[0].data.data;
      const dataUsers = response[1].data.data;
      setDataOptions({
        ...dataOptions,
        dataPermissions: dataPermissions,
        dataUsers: dataUsers,
      });
    } catch (error) {
      console.error(error);
    }
  };

  // const updateUser = (id: string, data: any) => {
  //   try {
  //     updatePermissionsUsers(id, data).then(res => {
  //       const {data} = res.data
  //       message.success(get(data, 'message', 'Chỉnh sửa quyền thành công'))
  //     })
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  useEffect(() => {
    const pageLink = getCurrentPageFromLink(location.search);
    if (!isEmpty(pageLink)) {
      setPageNumber(Number(pageLink));
    } else {
      fetchOptionsFilter();
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [pageNumber, pageSize, dataFilter]);

  useEffect(() => {
    if (detail && dataDetail) {
      const defaults = [];
      if (detail.defaultQtcs) defaults.push("defaultQtcs");
      if (detail.defaultGv) defaults.push("defaultGv");
      if (detail.defaultSv) defaults.push("defaultSv");
      form.setFieldsValue({
        ten: get(dataDetail, "ten", ""),
        moTa: get(dataDetail, "moTa", ""),
        trangThai: get(dataDetail, "trangThai", ""),
        quyens: get(dataDetail, "quyens", []),
        macDinh: defaults,
      });
    }
  }, [detail, dataDetail]);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setParams({ [name]: value });
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
      setParams();
    }
  };

  const handleOpenCreate = () => {
    setIsOpened(true);
    setIsTitleEdit(false);
    setIsEdit(false);
    setIsCreate(true);
  };

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
    setIsAcceptEdit(false);
  };

  const handleClose = () => {
    setIsCreate(false);
    setIsAcceptEdit(false);
    if (params?.size > 0) {
      setParams({});
    }
    setActiveKey("1");
    setDataDetail(undefined);
    setDetail(null);
    form.resetFields();
    setCheckedListCreate("");
    setIsOpened(false);
    setResetPermissions({
      pageSize: 100,
      isExist: false,
    });
  };

  const handleEdit = () => {
    setIsEdit((prevState) => !prevState);
  };

  const handleSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getDataRoles({
        keyword: searchValue,
      })
        .then((res) => {
          const { data, totalRecords } = res.data.data;
          setDataListRoles(data);
          setTotalRecords(totalRecords);
        })
        .catch((err) => console.error(err));
    }
  };
  const handleSubmitSearch = () => {
    getDataRoles({
      keyword: searchValue,
    })
      .then((res) => {
        const { data, totalRecords } = res.data.data;
        setDataListRoles(data);
        setTotalRecords(totalRecords);
      })
      .catch((err) => console.error(err));
    if (searchValue === "") {
      setPageNumber(1);
    }
  };

  const clearFilter = (formInstance: FormInstance) => {
    setLoading(true);
    getDataRoles({})
      .then((res) => {
        const { data, totalRecords } = res.data.data;
        setDataListRoles(data);
        setLoading(false);
        setTotalRecords(totalRecords);
        setDataOptions({
          ...dataOptions,
          trangThai: [],
          createdDateFrom: null,
          createdDateTo: null,
        });
        formInstance.resetFields();
      })
      .catch((err) => console.error(err));
  };
  const onFinishCreate = (values: any, mode: string) => {
    setLoading(true);
    const paramData = {
      ten: values.ten,
      moTa: values.moTa,
      quyens: values.quyens,
      trangThai: values.trangThai,
      nguoiDungs: values.nguoiDungs,
      defaultSv: values.macDinh === "SV",
      defaultQtcs: values.macDinh === "QT",
      defaultGv: values.macDinh === "GV",
    };
    if (detail) {
      editRole(detail.id.toString(), paramData)
        .then((res) => {
          const { statusCode, data } = res.data;
          if (statusCode === 200) {
            message.success(
              get(data, "message", "Chỉnh sửa vai trò thành công")
            );
            setLoading(false);
            fetchData();
            if (mode === "save") {
              form.resetFields();
              handleClose();
            } else {
              changeToTab2();
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      createRole(paramData)
        .then((res) => {
          const { statusCode, data } = res.data;
          if (statusCode === 200) {
            message.success(get(data, "message", "Tạo vai trò thành công"));
            setLoading(false);
            fetchData();
            setIsCreate(false);
            if (mode === "save") {
              handleClose();
              form.resetFields();
            } else {
              setParams({ id: data.id.toString() });
              setIsEdit(false);
              viewInfoRole(data.id.toString()).then((res) => {
                const { data } = res.data;
                setDataDetail(data);
                setDetail(data);
              });
              changeToTab2();
            }
            setResetPermissions({
              pageSize: 10,
              isExist: false,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  };

  const handleDeleteRole = () => {
    Modal.confirm({
      title: "Xoá vai trò",
      icon: <WarningOutlined />,
      content: (
        <p>
          Hiện tại có <span className="span-red">{count}</span> tài khoản được
          cài đặt vai trò này. Sau khi thực hiện xoá vai trò, tất cả tài khoản
          trên sẽ được cài đặt vai trò <b>"Mặc định"</b>
        </p>
      ),
      className: "modal-custom danger",
      okButtonProps: {
        className: "btn btn-filled--danger",
      },
      cancelButtonProps: {
        className: "btn btn-outlined",
      },
      okText: "Xác nhận",
      cancelText: "Huỷ",
      onOk: () => {
        navigation(routesConfig.roleManagement);
        if (dataDetail) {
          deleteRole(get(dataDetail, "id", "").toString())
            .then((res) => {
              const { data, statusCode } = res.data;
              if (statusCode === 200) {
                message.success(get(data, "message", "Xóa vai trò thành công"));
                handleClose();
                form.resetFields();
                fetchData();
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      },
      onCancel: () => {},
    });
  };

  const handleFinish = (values: any) => {
    setDataFilter({
      ...dataFilter,
      trangThai: dataOptions.trangThai,
      createdDateFrom: values?.rangeDate
        ? (values?.rangeDate[0]).add(1, "day").toISOString()
        : "",
      createdDateTo: values?.rangeDate
        ? (values?.rangeDate[1]).add(1, "day").toISOString()
        : "",
    });
  };

  const listBreadcrumb = [
    {
      title: "Quản trị vai trò",
    },
  ];

  return (
    <Layout className="page-header-group">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "formRole") {
            const { formRole } = forms;
            const data = formRole.getFieldsValue(validateFieldCreate);
            onFinishCreate(data, "save");
          }
        }}
      >
        <div
          className={`page-header-group--layout ${
            showFilter ? "open-filter" : ""
          }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterData
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              dataColumns={dataColumns}
              handleOpenFilter={handleOpenFilter}
              handleSearch={handleSearch}
              handleSubmitSearch={handleSubmitSearch}
              placeholder={"Nhập vai trò, mô tả để tìm kiếm"}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
            />
          </div>
          <FormFilter
            clearFilter={clearFilter}
            onFinish={handleFinish}
            dataOptions={dataOptions}
            showFilter={showFilter}
            setDataFilter={handleChangeFilter}
          />
        </div>
        {!error ? (
          <TableData
            dataColumns={dataColumns}
            loadingTable={loading}
            dataTable={dataListRoles}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            scroll={{ x: 1500, y: 800 }}
            pagination={true}
            hideSelection={true}
            handleTableChangeProps={() => {}}
          />
        ) : (
          <Page403 />
        )}
        <DrawerCustom
          title={isTitleEdit ? "Chi tiết vai trò" : "Tạo vai trò"}
          open={isOpened}
          onClose={handleClose}
          extraNode={
            <Space>
              {isTitleEdit && (
                <Button
                  className="btn btn-outlined btn-remove"
                  onClick={handleDeleteRole}
                >
                  <DeleteOutlined />
                  <Typography.Text>Xóa vai trò</Typography.Text>
                </Button>
              )}
              {isEdit ? (
                <>
                  <Button className="btn btn-outlined" onClick={handleEdit}>
                    <EditOutlined />
                    <Typography.Text>Chỉnh sửa vai trò</Typography.Text>
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                    className="btn btn-outlined"
                  >
                    <CloseOutlined />
                    <Typography.Text>Hủy</Typography.Text>
                  </Button>
                  <Button
                    className="btn btn-primary"
                    htmlType="submit"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    <SaveOutlined />
                    <Typography.Text>Lưu</Typography.Text>
                  </Button>
                  <Button
                    className="btn btn-primary"
                    htmlType="submit"
                    onClick={() => {
                      if (
                        form.getFieldValue("ten") &&
                        form.getFieldValue("moTa")
                      ) {
                        console.log(
                          'form.getFieldValue("ten")',
                          form.getFieldValue("ten")
                        );
                        onFinishCreate(form.getFieldsValue(), "change");
                      } else {
                        form.submit();
                      }
                    }}
                  >
                    <SaveOutlined />
                    <Typography.Text>Lưu và gán quyền</Typography.Text>
                  </Button>
                </>
              )}
            </Space>
          }
          widthDrawer={730}
        >
          <CreateRole
            checkedList={checkedListCreate}
            setCheckedList={setCheckedListCreate}
            setCount={setCount}
            count={count}
            form={form}
            resetPermissions={resetPermissions}
            checkEdit={isEdit}
            checkIsFormEdit={isAcceptEdit}
            isOpened={isOpened}
            dataEdit={dataDetail}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            isCreate={isCreate}
          />
        </DrawerCustom>
      </Form.Provider>
    </Layout>
  );
};

export default RoleManagement;
