import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import Quill from "quill";
import "react-quill/dist/quill.snow.css";
import "./style.scss";
import { useAuthStore } from "../../stores/stores";
import axios from "axios";
import { BASE_URL_V2 } from "../../config/api/configApiv2";
import {Modal} from "antd";
import {now} from "moment";

interface TextEditorProps {
  value?: any;
  onChange?: (value: string) => void;
}

interface MathOutput {
  exportAs?: (type: string) => string;
}

function TextEditor({ value = "", onChange = () => { } }: TextEditorProps) {
  const [currentValue, setCurrentValue] = useState("");
  const accessToken = useAuthStore((state) => state.accessToken);
  const reactQuillRef = useRef<ReactQuill>(null);
  const instanceId = now();
  const textEditorRef = useRef<HTMLDivElement>(null);

  class MathFormula {
    public quill: Quill;
    public mathOutput: MathOutput | undefined;
    public instanceId = now();
    constructor(quill: Quill, options: any) {
      const This = this;
      this.quill = quill;
      let toolbar = quill.getModule('toolbar');
      let button = document.createElement('button');
      button.innerHTML = '<svg viewBox="0 0 18 18"> <path class="ql-fill" d="M11.759,2.482a2.561,2.561,0,0,0-3.53.607A7.656,7.656,0,0,0,6.8,6.2C6.109,9.188,5.275,14.677,4.15,14.927a1.545,1.545,0,0,0-1.3-.933A0.922,0.922,0,0,0,2,15.036S1.954,16,4.119,16s3.091-2.691,3.7-5.553c0.177-.826.36-1.726,0.554-2.6L8.775,6.2c0.381-1.421.807-2.521,1.306-2.676a1.014,1.014,0,0,0,1.02.56A0.966,0.966,0,0,0,11.759,2.482Z"></path> <rect class="ql-fill" height="1.6" rx="0.8" ry="0.8" width="5" x="5.15" y="6.2"></rect> <path class="ql-fill" d="M13.663,12.027a1.662,1.662,0,0,1,.266-0.276q0.193,0.069.456,0.138a2.1,2.1,0,0,0,.535.069,1.075,1.075,0,0,0,.767-0.3,1.044,1.044,0,0,0,.314-0.8,0.84,0.84,0,0,0-.238-0.619,0.8,0.8,0,0,0-.594-0.239,1.154,1.154,0,0,0-.781.3,4.607,4.607,0,0,0-.781,1q-0.091.15-.218,0.346l-0.246.38c-0.068-.288-0.137-0.582-0.212-0.885-0.459-1.847-2.494-.984-2.941-0.8-0.482.2-.353,0.647-0.094,0.529a0.869,0.869,0,0,1,1.281.585c0.217,0.751.377,1.436,0.527,2.038a5.688,5.688,0,0,1-.362.467,2.69,2.69,0,0,1-.264.271q-0.221-.08-0.471-0.147a2.029,2.029,0,0,0-.522-0.066,1.079,1.079,0,0,0-.768.3A1.058,1.058,0,0,0,9,15.131a0.82,0.82,0,0,0,.832.852,1.134,1.134,0,0,0,.787-0.3,5.11,5.11,0,0,0,.776-0.993q0.141-.219.215-0.34c0.046-.076.122-0.194,0.223-0.346a2.786,2.786,0,0,0,.918,1.726,2.582,2.582,0,0,0,2.376-.185c0.317-.181.212-0.565,0-0.494A0.807,0.807,0,0,1,14.176,15a5.159,5.159,0,0,1-.913-2.446l0,0Q13.487,12.24,13.663,12.027Z"></path> </svg>';
      button.onclick = (e) =>  {
        This.showMathInputModal(This);
      };
      toolbar.container.appendChild(button);

      function clearSelectedImages() {
        const images = document.querySelectorAll('.ql-container img');
        images.forEach((img) => img.classList.remove('selected'));
      }

      quill.root.addEventListener('click', function (event) {
        // @ts-ignore
        if (event.target && event.target.tagName === 'IMG') {
          clearSelectedImages();
          const img = event.target as HTMLImageElement;
          img.classList.add('selected');
        } else {
          // If the user clicks outside the image, clear selection
          clearSelectedImages();
        }
      });

      quill.root.addEventListener('dblclick', function (event: MouseEvent) {
        // @ts-ignore
        if (event.target && event.target.tagName === 'IMG') {
          // @ts-ignore
          const sName = event.target.src.match(/(gif|svg)\.image\?(.*)/);
          This.showMathInputModal(This, sName[2]);
        }
      });
      const host = 'latex.codecogs.com';
      const http = ('https:' == window.location.protocol ? 'https://' : 'http://');
      this.loadExtScript(http+host+'/js/eqneditor.api.min.js').then(() => {
      });
    }

    showMathInputModal = (This: any, inp="") => {
      let instanceId = This.instanceId;
      Modal.confirm({
        title: 'Công thức toán học',
        className: "equation-editor",
        content: (
            <div>
              <div id={"equation-editor-" + instanceId}>
                <div id={"history-" + instanceId}></div>
                <div id={"toolbar-" + instanceId}></div>
                <div id={"latexInput-" + instanceId} autoCorrect="off"></div>
                <div id={"equation-output-" + instanceId} className={"equation-output"}>
                  <img id={"output-" + instanceId}/>
                </div>
              </div>
            </div>
        ),
        onOk() {
          // @ts-ignore
          let image = This.mathOutput.exportAs('html');
          insertContent(This, image);
        },
        width: '750px',
        icon: null,
        okText: 'Đồng ý',
        cancelText: 'Hủy'
      });
      //setShowMathForm(true);
      setTimeout(function () {
        let toolbarElement = document.getElementById('toolbar-' + instanceId);
        if (toolbarElement) {
          toolbarElement.innerHTML = '';
        }
        let historyElement = document.getElementById('history-' + instanceId);
        if (historyElement) {
          historyElement.innerHTML = '';
        }
        // @ts-ignore
        let output = EqEditor.Output.link("output-" + instanceId);
        This.mathOutput = output;
        // @ts-ignore
        let textarea = EqEditor.TextArea.link('latexInput-' + instanceId)
            .addOutput(output)
            // @ts-ignore
            .addHistoryMenu(new EqEditor.History("history-" + instanceId));
        // @ts-ignore
        EqEditor.Toolbar.link("toolbar-" + instanceId).addTextArea(textarea);

        // @ts-ignore
        const nodes = document.getElementById('history-' + instanceId).childNodes;
        // @ts-ignore
        for(let i=0; i<nodes.length; i++) {
          // @ts-ignore
          nodes[i].style.padding = 'revert';
        }

        textarea.clear();
        // @ts-ignore
        textarea.insert(inp.replace(/&space;/g, ' '), inp.length);
      }, 50);


    }
    loadExtScript(url: string): Promise<void> {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = url;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script ${url}`));
        document.head.appendChild(script);
      });
    }
  }

  ReactQuill.Quill.register('modules/mathFormula', MathFormula);

  useEffect(() => {
    if (value) setCurrentValue(value);
  }, [value]);

  const uploadToCloudinary = async (
    file: File,
    type: string
  ): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(
      BASE_URL_V2 + "/mooc-course-block-quiz/upload-file",
      formData,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const url = response.data.filePath;
    return url;
  };

  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      if (input !== null && input.files !== null) {
        const file = input.files[0];
        const url = await uploadToCloudinary(file, "image");
        const quill = reactQuillRef.current;
        if (quill) {
          const range = quill.getEditorSelection();
          range && quill.getEditor().insertEmbed(range.index, "image", url);
          const img = quill.getEditor().root.querySelector(`img[src="${url}"]`);
          if (img) {
            img.setAttribute(
              "style",
              `width: 150px; height: 150px; margin-bottom: 10px`
            );
            img.classList.add("custom-img-class");
          }
        }
      }
    };
  }, []);

  const videoHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "video/*");
    input.click();
    input.onchange = async () => {
      if (input !== null && input.files !== null) {
        const file = input.files[0];
        const url = await uploadToCloudinary(file, "video");
        const quill = reactQuillRef.current;
        if (quill) {
          const range = quill.getEditorSelection();
          range && quill.getEditor().insertEmbed(range.index, "video", url);
        }
      }
    };
  }, []);

  const handleChange = (value: string) => {
    onChange(value);
    setCurrentValue(value);
  };
  const FORMAT_TEXT_EDITOR = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code",
    "list",
    "align",
    "link",
    "image",
    "video",
    "formula"
  ];
  const MODULES_TEXT_EDITOR = {
    mathFormula: {},
    toolbar: {
      container: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        [{ header: 1 }, { header: 2 }],
        ["blockquote", "code"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: "" }, { align: "center" }, { align: "right" }],
        ["link", "image", "video"],
      ],
      handlers: {
        image: imageHandler,
        video: videoHandler,
      },
    },
  };

  function findImageRangeIndex(quill: any): number | null {
    const selectedImage = document.querySelector('.text-editor img.selected');

    if (selectedImage) {
      const blot = Quill.find(selectedImage);
      if (blot) {
        return quill.getIndex(blot);
      }
    }

    return null; // Return null if no selected image is found
  }

  function insertContent(This: MathFormula, content: string) {
    let quillEditor = This.quill;
    let rangeIndex = findImageRangeIndex(quillEditor);
    if (rangeIndex != null) {
      quillEditor?.deleteText(rangeIndex, 1);
      quillEditor?.clipboard.dangerouslyPasteHTML(rangeIndex, content);
    } else {
      quillEditor?.focus();
      const range = quillEditor?.getSelection(); // Lấy vị trí hiện tại của con trỏ
      if (range) {
        quillEditor?.clipboard.dangerouslyPasteHTML(range.index, content);
      } else {
        quillEditor?.clipboard.dangerouslyPasteHTML(quillEditor.getLength(), content);
      }
    }
  }
  return (
      <div className="text-editor" ref={textEditorRef} data-id={instanceId}>
        <ReactQuill
            ref={reactQuillRef}
            theme="snow"
            modules={MODULES_TEXT_EDITOR}
            formats={FORMAT_TEXT_EDITOR}
            value={currentValue}
            onChange={handleChange}
            style={{minHeight: 65}}
        />
      </div>
  );
}

export default TextEditor;
