import React, { useState } from "react";
import SingleMenuCustom from "../../../components/menu/SingleMenuCustom";
import { Col, Flex, Row } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { TransactionHistoryProps } from "../../../types/transaction";
import moment from "moment";
import { formatCurrency } from "../../../utils/format";
import { toNumber } from "lodash";

interface TransactionItemProps {
  type?: "success" | "warning" | "failed";
  reason?: string;
  className?: string;
  data?: TransactionHistoryProps;
}

function TransactionItem({ type, className = "", data }: TransactionItemProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const renderDot = () => {
    switch (type) {
      case "success":
        return <div className="trans-dot trans-dot-success"></div>;
      case "warning":
        return <div className="trans-dot trans-dot-warning"></div>;
      default:
        break;
    }
  };
  return (
    <div className={className}>
      <SingleMenuCustom
        alignItem="flex-start"
        className="transaction-item"
        iconClassName="icon-menu-trans-item"
        titleClassName={`trans-item-header ${isOpen ? "" : "trans-border"}`}
        onOpen={setIsOpen}
        title={
          <div>
            <Flex className="text-14 font-weight-5" align="center" gap={8}>
              {renderDot()} <span>Giao dịch không thành công</span>{" "}
              <span className="text-info ml-5">
                {moment(data?.createdDate).format("HH:mm:ss - DD/MM/YYYY")}
              </span>
            </Flex>
            <div className="text-info text-14 mt-1 ml-3">
              Người xử lý: <span className="ml-5">{data?.approverEmail}</span>
            </div>
          </div>
        }
      >
        <div className="trans-item-content">
          {type === "warning" && (
            <div>
              <Row>
                <Col md={6}>
                  <p className="text-14 font-weight-4">Lý do:</p>
                </Col>
                <Col md={18}>
                  <p className="text-14 font-weight-4">{data?.reason}</p>
                </Col>
              </Row>
              <div className="break-line mt-4 mb-3"></div>
            </div>
          )}

          <Row>
            <Col md={6}>
              <p className="text-14 font-weight-6">Số tiền đã nhận:</p>
            </Col>
            <Col md={18}>
              <p className="text-14 font-weight-4">
                {formatCurrency(data?.receivedAmount)} {`(VND)`}
              </p>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={6}>
              <p className="text-14 font-weight-6">Số tiền bổ sung:</p>
            </Col>
            <Col md={18}>
              <p className="text-14 font-weight-4">
                {formatCurrency(data?.additionalAmount)} {`(VND)`}
              </p>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={6}>
              <p className="text-14 font-weight-6">Số tiền hoàn trả:</p>
            </Col>
            <Col md={18}>
              <p className="text-14 font-weight-4">
                {formatCurrency(data?.refundAmount)}
                {`(VND)`}
              </p>
            </Col>
          </Row>
          <Row className="mt-2 total-price">
            <Col md={6}>
              <p className="text-14 font-weight-6"> Tổng đã nhận:</p>
            </Col>
            <Col md={18}>
              <p className="text-14 font-weight-4">
                {formatCurrency(
                  toNumber(data?.receivedAmount) +
                    toNumber(data?.additionalAmount) -
                    toNumber(data?.refundAmount)
                )}{" "}
                {`(VND)`}
              </p>
            </Col>
          </Row>

          <Flex align="center" gap={8} className="mt-1">
            <LinkOutlined className="text-primary" />
            <a href={data?.billImage} target="_blank" className="text-primary">
              Sao kê giao dịch
            </a>
          </Flex>
        </div>
      </SingleMenuCustom>
    </div>
  );
}

export default TransactionItem;
