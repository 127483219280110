import {Button, Form, message, Modal, Typography} from "antd";
import CustomCard from "../../../custom-card/CustomCard";

import {formatDate} from "../../../../utils/converDate";
import "./course-construct.scss";
import {ConstructCourseDetailType, Section,} from "../../../../types/course-construction";

import {TreeCourseConstruct} from "../tree-course-construct";
import {useState} from "react";
import SyncCourseModal from "../../../modal/sync-course";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {syncSource} from "../../../../service/course-construct";

type Prop = {
  sections: Section[];
  detailData: ConstructCourseDetailType;
  handleGetDetail: any;
  paramId: number | string | undefined;
  setSections: any;
};

const CourseConstruct = (props: Prop) => {
  const {sections, detailData, handleGetDetail, paramId, setSections} = props;

  const infoData: ConstructCourseDetailType = {
    createDate: detailData.createDate ?? "Chưa có dữ liệu",
    progress: detailData.progress ?? "Chưa có dữ liệu",
    constructVariable: detailData.constructVariable ?? "Chưa có dữ liệu",
  };

  const [isOpenSyncCourseModal, setIsOpenSyncCourseModal] = useState<boolean>(false);
  const [syncCourseCheckbox, setSyncCourseCheckbox] = useState<boolean>(false);
  const [clearForm, setClearForm] = useState<boolean>(false);
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const convertObjectData = Object.entries(infoData);

  const renderSwitch = (value: string) => {
    switch (value) {
      case "createDate":
        return <div>Ngày bắt đầu</div>;
      case "progress":
        return <div>Tiến độ học</div>;
      case "constructVariable":
        return <div>Tham số cấu hình</div>;
      default:
        return "";
    }
  };

  const listInfo = convertObjectData.map((item, index) => (
    <div className="list-item" key={index}>
      <Typography.Title level={5} className="list-item--title">
        {renderSwitch(item[0])}
      </Typography.Title>
      <div className="list-item--description">
        {item[0] === "createDate" ? (
          <Typography.Text className="content">
            {formatDate(item[1])}
          </Typography.Text>
        ) : item[0] === "progress" ? (
          <Typography.Text className="content-progress">
            {item[1]}
          </Typography.Text>
        ) : (
          <Typography.Text className="content">{item[1]}</Typography.Text>
        )}
      </div>
    </div>
  ));

  const confirmModal = () => {
    handleGetDetail()
  };

  const saveSyncCourse = async (coursePlanId: number, isSync: boolean) => {
    const data = {
      courseId: detailData?.id ?? "",
      coursePlanId: coursePlanId,
      isSync: isSync,
    };
    await syncSource(data).then((res) => {
      if (res.status === 200) {
        message.success("Khoá học đã được đồng bộ thành công");
        handleGetDetail()
        setIsOpenSyncCourseModal(false);
        setClearForm(true);
      }
    });
  };


  return (
    <>
      <Form.Provider onFormFinish={(name, {values, forms}) => {
        if (name === "create-sync-course-modal") {
          Modal.confirm({
            title: `Xác nhận đồng bộ`,
            content: `Bạn có chắc chắn muốn đồng bộ khoá học này?`,
            onOk: () => {
              saveSyncCourse(values.lecturerName, syncCourseCheckbox);
            },
            okText: "Xác nhận",
            cancelText: "Huỷ",
            centered: true,
            icon: <ExclamationCircleOutlined style={{color: "#1677FF"}}/>,
            footer: (_, {OkBtn, CancelBtn}) => (
              <>
                <CancelBtn/>
                <OkBtn/>
              </>
            ),
          });
        }
      }}>
        <CustomCard
          title={
            <div className="card-heading--info">
              <div>
                <Typography.Title level={5}>
                  Cấu trúc của khóa học
                </Typography.Title>
                <Typography.Text className="sub-title">
                  Chi tiết cấu trúc được duyệt của kế hoạch
                </Typography.Text>
              </div>
              <Button
                onClick={() => {
                  setIsOpenSyncCourseModal(true);
                }}
              >
                Đồng bộ cấu trúc
              </Button>
            </div>
          }
          className="card-info--wrapper"
        >
          <div className="list-wrapper-course-construct">{listInfo}</div>
        </CustomCard>
        <TreeCourseConstruct
          data={sections}
          name={detailData.name}
          id={detailData.id}
          handleGetDetail={handleGetDetail}
          setSections={setSections}
          paramId={paramId}
        />
        <SyncCourseModal
          clearForm={clearForm}
          setSyncCourseCheckbox={setSyncCourseCheckbox}
          title={`Đồng bộ khoá học ${detailData?.name}`}
          open={isOpenSyncCourseModal}
          handleOk={confirmModal}
          handleCancel={() => {
            setIsOpenSyncCourseModal(!isOpenSyncCourseModal);
          }}
        />
      </Form.Provider>
    </>
  );
};

export default CourseConstruct;
