import { Button, Layout, message } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TableDetailScore from "./TableDetailScore";
import PageHeaderCommon from "../../../../components/page-header-common/PageHeaderCommon";
import { routesConfig } from "../../../../config/routes";
import CommonModal from "../../../../components/modal/common";
import { InfoCircleOutlined } from "@ant-design/icons";
import { deleteAutoScore } from "../../../../service/auto-score";
import { DEFAULT_PERMISSION_AUTO_SCORE } from "../../../../constants/automatic-mark";
import { getPermissionsAuthority } from "../../../../service/setting-authority";

const { Content } = Layout;
const imgPath = "/images/";
const { GET } = DEFAULT_PERMISSION_AUTO_SCORE;

const DetailScore = () => {
  const navigate = useNavigate();
  const [score, setScore] = useState({
    courseName: "",
    examName: "",
    title: "",
  });
  const [isRemote, setIsRemote] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [permissionScore, setPermissionScore] = useState<string[]>([]);
  const [idCourse, setIdCourse] = useState("");

  const [role, setRole] = useState<any>({
    is_edit: false,
    is_remove: false,
    is_view: false,
    is_assign: false,
  });
  const params = useParams();

  const listBreadcrumb = [
    {
      title: "Danh sách khoá học",
    },
    {
      title: score.courseName,
    },
    {
      title: score.examName,
    },
    {
      title: "Quản lý chấm bài thi tự động",
    },
    {
      title: "Xem bảng điểm",
    },
  ];

  const fetchPermissionData = async () => {
    try {
      const res = await getPermissionsAuthority(`${idCourse}`);
      const { data } = res.data;
      setPermissionScore(data?.scorePermission);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (idCourse) {
      fetchPermissionData();
    }
  }, [idCourse]);
  const handleNotice = (type: any, message: string) => {
    messageApi.open({
      type: type,
      content: message,
    });
  };
  const handleRemote = async () => {
    try {
      await deleteAutoScore(`${params?.id}`);
      handleNotice("success", `Xóa bảng điểm ${score?.title} thành công`);
      setIsRemote(false);
      navigate(-1);
    } catch (error) {
      handleNotice("error", `Xóa bảng điểm ${score?.title} thất bại`);
    }
  };
  return (
    <Content>
      {contextHolder}
      <PageHeaderCommon
        listBreadcrumb={listBreadcrumb}
        title={"Xem bảng điểm"}
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        headerGroupButton={true}
        childrenGroupButton={
          role.is_remove && (
            <Button danger onClick={() => setIsRemote(true)}>
              Xóa bảng điểm
            </Button>
          )
        }
        breadcrumb={true}
        handleBack={() => navigate(-1)}
      />
      <TableDetailScore
        setScore={setScore}
        setRole={setRole}
        role={role}
        handleNotice={handleNotice}
        permissionScore={permissionScore}
        setIdCourse={setIdCourse}
      />
      <CommonModal
        open={isRemote}
        title={score?.title}
        desc={
          <div>
            Bảng điểm đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá
            báo cáo này?
          </div>
        }
        closeIcon={true}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={handleRemote}
        closeModal={() => setIsRemote(false)}
      />
    </Content>
  );
};

export default DetailScore;
