import {
  CheckOutlined,
  CloseOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Collapse,
  DatePicker,
  Form,
  FormInstance,
  message,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
  Typography,
} from "antd";
import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getDetailQuestionBank,
  getDetailQuestionBankMinistry,
  putApproveQuestionBank,
} from "../../../../service/question-bank";
import { dataInfo } from "../../../../pages/detail/constants/dataInfo";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import { LocationState } from "../..";

const { Panel } = Collapse;

export type UpdateSettingsProps = {
  handelChangeForm?: (val: any, name: string, cb?: any) => void;
  handelUpdateSettings?: (val: any, name: string, cb?: any) => void;
  form?: FormInstance;
  mode?: string;
};

type QuestionBankDetail = {
  id: number;
  unitId: number;
  code: string;
  title: string;
  topics: string;
  numberQuestion: number;
  description: string | null;
  tags: string;
  numberOfUse: number;
  authors: string | string[];
  access: string;
  createdBy: string | null;
  publishingUnit: string | null;
  hierarchy: string | null;
  moocQuestionBankId: number;
  moocCourseUnitId: number;
  typeProduction: number;
  productionUnitId: number | null;
  productionUnitName: string | null;
  typeApproved: string;
  approvedSendDate: string;
  approvedDate: string;
  status: boolean;
  approvedBy: number;
  approveByName: string;
  author: string | string[] | any[]; // Array, you may specify the exact type if needed
  topic: any[]; // Array, you may specify the exact type if needed
  approvedDescription: string;
  publishStatus: number;
  publicDate: string;
  scheduleType: number;
};

const SettingExam = (props: UpdateSettingsProps) => {
  const [dataDetail, setDataDetail] = useState<QuestionBankDetail>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const unitId = searchParams.get("id");

  const NO_PUBLISH = 1;
  const PUBLISH_NOW = 2;
  const PUBLISH_SCHEDULE = 3;

  const getPublishStatus = (scheduleType: number): string => {
    switch (scheduleType) {
      case NO_PUBLISH:
        return "Chưa xuất bản";
      case PUBLISH_NOW:
        return "Xuất bản ngay";
      case PUBLISH_SCHEDULE:
        return "Đã lên lịch";
      default:
        return "";
    }
  };

  const [approveRadio, setApproveRadio] = useState(1);
  const [comment, setComment] = useState("");
  const [publishMode, setPublishMode] = useState(1);
  const [publishDate, setPublishDate] = useState("");
  const [isVisibleConfirm, setIsVisibleConfirm] = useState(false);
  const { state } = location as { state: LocationState };

  const mode = state.mode
  const [showAllAuthors, setShowAllAuthors] = useState(false);

  const onChangeRadio = (e: RadioChangeEvent) => {
    setApproveRadio(e.target.value);
  };
  const navigate = useNavigate();
  const navigateBack = () => {
    navigate(state.backUrl)
  };

  const handleShowMore = () => {
    setShowAllAuthors(!showAllAuthors);
  };

  const renderAuthors = () => {
    if (typeof dataDetail?.authors === "string") {
      // If only one author (a single string)
      return <span>{dataDetail.authors}</span>;
    } else if (Array.isArray(dataDetail?.authors)) {
      // If multiple authors (array of strings)
      if (dataDetail && dataDetail?.authors.length <= 2) {
        return <span>{dataDetail?.authors[0]}</span>;
      } else {
        return (
          <>
            {/* Display the first author and a "More" button if there are multiple authors */}
            <span>{dataDetail?.authors[0]}</span>
            <span>{dataDetail?.authors[1]}</span>
            {!showAllAuthors &&
              dataDetail &&
              dataDetail?.authors.length > 2 && (
                <button
                  style={{
                    color: "blue",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    marginLeft: 8,
                  }}
                  onClick={handleShowMore}
                >
                  More
                </button>
              )}
            {showAllAuthors && (
              <span>
                {dataDetail?.authors.slice(1).map((author, index) => (
                  <span key={index}> , {author}</span>
                ))}
              </span>
            )}
          </>
        );
      }
    }
  };

  const handleGetDetail = async () => {
    try {
      await getDetailQuestionBank(Number(unitId)).then((result) => {
        if (result.data) {
          setDataDetail(result.data.data);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetDetailMinistryContent = async () => {
    try {
      await getDetailQuestionBankMinistry(Number(unitId)).then((result) => {
        if (result.data) {
          setDataDetail(result.data.data);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetDetailUniversityContent = async () => {
    try {
      await getDetailQuestionBankMinistry(Number(unitId)).then((result) => {
        if (result.data) {
          setDataDetail(result.data.data);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (mode === "0") handleGetDetail();
    else if (mode === "1") handleGetDetailMinistryContent();
    else if (mode === "2") handleGetDetailUniversityContent();
  }, []);

  const [flag, setFlag] = useState<boolean>(true);
  const toggleFlag = () => {
    setFlag((prev) => !prev);
  };
  // const [mode, setMode] = useState("1");

  const handlePopupConfirm = () => {
    setIsVisibleConfirm(true);
  };

  const handleConfirm = async () => {
    const params = [{
      id: unitId,
      status: approveRadio,
      description: comment,
      publishDate: publishDate.toString(),
      scheduleType: publishMode,
    }];
    try {
      await putApproveQuestionBank(params).then((result) => {
        if (result.data) {
          message.success(`Lưu nội dung kiểm duyệt thành công`);
          setIsVisibleConfirm(false);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    // if (publishMode === 2) set
  }, [publishMode])
  

  const detailExam = () => {
    return (
      <div className={`${mode !== "1" ? "info" : ""}`}>
        <div className="info-item">
          <div className="info-title">Mã đề:</div>
          {dataDetail?.code ? (
            <div>{dataDetail?.code || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Tiêu đề:</div>
          {dataDetail?.title ? (
            <div>{dataDetail?.title || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Chủ đề:</div>
          {dataDetail?.topics ? (
            <div>{dataDetail?.topics || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Số câu hỏi:</div>
          {dataDetail?.numberQuestion ? (
            <div>{dataDetail?.numberQuestion || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Mô tả:</div>
          {dataDetail?.description ? (
            <div>{dataDetail?.description || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Nhãn:</div>
          {dataDetail?.tags ? (
            <div>{dataDetail?.tags || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Lượt dùng:</div>
          {dataDetail?.numberOfUse ? (
            <div>{dataDetail?.numberOfUse || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Phân cấp:</div>
          {dataDetail?.hierarchy ? (
            <div>{dataDetail?.hierarchy || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Tác giả:</div>
          <div>{renderAuthors()}</div>
        </div>
        <div className="info-item">
          <div className="info-title">Đơn vị xuất bản:</div>
          {dataDetail?.publishingUnit ? (
            <div>{dataDetail?.publishingUnit || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Quyền truy cập:</div>
          {dataDetail?.access ? (
            <div>{dataDetail?.access || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
      </div>
    );
  };

  const checkInfo = () => {
    return (
      <div className={`${mode !== "1" ? "info" : ""}`}>
        <div className="info-item">
          <div className="info-title">Nội dung gửi duyệt:</div>
          {dataDetail?.typeApproved ? (
            <div>{dataDetail?.typeApproved || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Ngày gửi duyệt:</div>
          {dataDetail?.approvedSendDate ? (
            <div>
              {moment(dataDetail?.approvedSendDate).format(
                "DD/MM/YYYY HH:mm:ss"
              )}
            </div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Nội dung duyệt:</div>
          {dataDetail?.approvedDescription ? (
            <div>{dataDetail?.approvedDescription || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Người duyệt:</div>
          {dataDetail?.approveByName ? (
            <div>{dataDetail?.approveByName || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Ngày duyệt:</div>
          {dataDetail?.approvedDate ? (
            <div>
              {moment(dataDetail?.approvedDate).format("DD/MM/YYYY HH:mm:ss")}
            </div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
      </div>
    );
  };

  const publishInfo = () => {
    return (
      <div className={`${mode !== "1" ? "info" : ""}`}>
        <div className="info-item">
          <div className="info-title">Ngày xuất bản:</div>
          {dataDetail?.publicDate ? (
            <div>
              {moment(dataDetail?.publicDate).format("DD/MM/YYYY HH:mm:ss")}
            </div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Trạng thái:</div>
          {dataDetail?.scheduleType ? (
            <div>{getPublishStatus(dataDetail?.scheduleType || 0)}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
      </div>
    );
  };

  const infoApprove = () => {
    return (
      <div className={`${mode !== "1" ? "info" : ""}`}>
        <div className="info-item">
          <div className="info-title">Ngày gửi:</div>
          {dataDetail?.publicDate ? (
            <div>
              {moment(dataDetail?.publicDate).format("DD/MM/YYYY HH:mm:ss")}
            </div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Nội dung gửi duyệt:</div>
          {dataDetail?.approvedDescription ? (
            <div>{dataDetail?.approvedDescription || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="">
          <div className="info-title">Nội dung duyệt:</div>
          <Radio.Group
            className="radio-approve"
            onChange={onChangeRadio}
            value={approveRadio}
          >
            <Radio value={1}>Duyệt</Radio>
            <Radio value={0}>Không duyệt</Radio>
          </Radio.Group>
          <TextArea
            placeholder="Nhập ý kiến"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            rows={4}
          />
        </div>
      </div>
    );
  };

  const handleChangePublishDate = (time: any) => {
    const formatTime = time.toISOString();
    setPublishDate(formatTime);
  };

  const publishSetting = () => {
    return (
      <div>
        <Select
          disabled={approveRadio === 0}
          className="w-full mb-2"
          showSearch
          value={publishMode}
          placeholder="Select a person"
          optionFilterProp="label"
          onChange={(value) => setPublishMode(Number(value))}
          // onSearch={onSearch}
        >
          <Select.Option value={1}>Không xuất bản</Select.Option>
          <Select.Option value={2}>Xuất bản ngay</Select.Option>
          <Select.Option value={3}>Hẹn lịch xuất bản</Select.Option>
        </Select>

        <DatePicker
          disabled={publishMode !== 3}
          format={"DD/MM/YYYY HH:mm:ss"}
          className="w-full"
          showTime
          onChange={handleChangePublishDate}
        />
      </div>
    );
  };

  const detailTab = [
    {
      title: "Chi tiết đề",
      content: detailExam(),
    },
    {
      title: "Thông tin duyệt",
      content: checkInfo(),
    },
    {
      title: "Ngày xuất bản",
      content: publishInfo(),
    },
  ];

  const approveTab = [
    {
      title: "Thông tin đề",
      content: detailExam(),
    },
    {
      title: "Thông tin duyệt",
      content: infoApprove(),
    },
    {
      title: "Lịch xuất bản",
      content: publishSetting(),
    },
  ];

  return (
    <div className={`setting-exam ${flag ? "" : "tab-closed w-[50px]"}`}>
      <div className="setting-exam__header flex">
        <MenuUnfoldOutlined
          className="setting-exam__header__first"
          rotate={flag ? 0 : 180}
          onClick={toggleFlag}
        />
        <SettingOutlined className="setting-exam__header__second" />
        <div className="setting-exam__header__title">{"Thông tin đề"}</div>
      </div>
      <div
        className="setting-exam__content"
        style={{ visibility: flag ? "visible" : "hidden" }}
      >
        <div className={`${flag ? "info-container" : "info-container-v2"}`}>
          {mode === "0" ? (
            detailExam()
          ) : (
            <Collapse defaultActiveKey={[0]}>
              {/* change tab later */}
              {mode !== "2"
                ? detailTab.map((el, index) => {
                    return (
                      <Panel key={index} header={el?.title}>
                        <div className="sub-content">{el?.content}</div>
                      </Panel>
                    );
                  })
                : approveTab.map((el, index) => {
                    return (
                      <Panel key={index} header={el?.title}>
                        <div className="sub-content">{el?.content}</div>
                      </Panel>
                    );
                  })}
            </Collapse>
          )}
        </div>
        {mode === "2" ? (
          <div className="button-container">
            <Button
              onClick={() => {
                handlePopupConfirm();
              }}
              className="btn btn-primary width-button-100 ml-2"
            >
              <CheckOutlined />
              <Typography.Text>Lưu lại</Typography.Text>
            </Button>
            <Button onClick={navigateBack} className="btn width-button-100">
              <CloseOutlined />
              <Typography.Text>Hủy bỏ</Typography.Text>
            </Button>
          </div>
        ) : (
          <></>
        )}

        <Modal
          title="Xác nhận"
          open={isVisibleConfirm}
          onOk={handleConfirm}
          onCancel={() => {
            setIsVisibleConfirm(false);
          }}
          className="h-56"
          centered
          footer={[
            <div className="flex flex-row items-center w-full justify-evenly">
              <Button
                key="cancel"
                icon={<CloseOutlined />}
                onClick={() => {
                  setIsVisibleConfirm(false);
                }}
              >
                Hủy bỏ
              </Button>
              <Button
                key="ok"
                icon={<CheckOutlined />}
                type="primary"
                onClick={handleConfirm}
              >
                Xác nhận
              </Button>
            </div>,
          ]}
        >
          <div className="text-center h-20 flex flex-col justify-evenly">
            <p>Bạn chắc chắn muốn lưu lại nội dung kiểm duyệt?</p>
            {state.titleBreadCrumb !== 'Kiểm duyệt nội dung cấp Bộ' && (
              <p style={{ color: "red", fontStyle: "italic" }}>
                Nội dung sẽ được gửi lên cấp Bộ sau khi duyệt.
              </p>
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default SettingExam;
