import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AuthGuard } from "../../components/authentication/auth-guard";
import { Button, Flex, Form, Layout, Modal, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import LoadingFullScreen from "../../components/loading-full-screen/LoadingFullScreen";
import { MenuItem } from "../../types/sidebar";
import { getPermissions } from "../../utils/checkRole";
import HeaderLayout from "../header/Header";
import SiderLayout from "../sidebar/Sidebar";
import { routesConfig } from "../../config/routes";
import { InfoCircleOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import _ from "lodash";
import { menuItems } from "../sidebar/MenuItem";
import { useAuthStore } from "../../stores/stores";
import ChangePasswordModal from "../../pages/detail/components/change-password/ChangePassword";
const { Header, Sider, Content } = Layout;

export default function DashboardRootPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const pathName = location.pathname;

  const [loading, setLoading] = useState(false);
  const [listPermission, setListPermission] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState<MenuItem[]>([]);
  const [collapsedContent, setCollapsedContent] = useState(false);
  const [pageAddQuestion, setPageAddQuestion] = useState<boolean>(false);
  const [view, setView] = useState<number>(1);
  const { config } = useAuthStore()
  const [isOpenedModal, setIsOpenedModal] = useState(false);
  const [form] = Form.useForm();

  const [userPassword, setUserPassword] = useState({
    oldPassword: "",
    newPassword: "",
    reWriteNewPassword: "",
  });

  let layoutStyle = {
    width: '100%',
    height: '100vh',
    paddingTop: pathName.includes(routesConfig.detailAdminInterface) ? 'unset' : '48px',
    backgroundColor: '#f5f5f5',
    transition: 'all 333ms ease-in-out',
  };

  let headerStyle: React.CSSProperties = {
    width: '100%',
    height: 48,
    backgroundColor: '#fff',
    position: pathName.includes(routesConfig.detailAdminInterface) ? "unset" : 'fixed',
    top: '0px',
    left: '0px',
    padding: 0,
    transition: 'all 333ms ease-in-out',
  };

  let siderStyle: React.CSSProperties = {
    width: 260,
    height: 'calc(100% - 48px)',
    backgroundColor: '#fff',
    position: 'fixed',
    top: pathName.includes(routesConfig.detailAdminInterface) ? 70 : 48,
    left: pathName.includes(routesConfig.detailAdminInterface) ? "unset" : 0,
    transition: 'all 333ms ease-in-out',
    zIndex: 100,
  };

  let contentStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    overflowY: 'auto',
    overflowX: 'hidden',
    transition: 'all 333ms ease-in-out',
  };

  let btnCollapsedMenuStyle: React.CSSProperties = {
    width: !collapsedContent ? 260 : 80,
    transform: pageAddQuestion ? 'translateX(-100%)' : 'translateX(0%)',
    left: pathName.includes(routesConfig.detailAdminInterface) ? 'unset' : 0,
    zIndex: 100,
  }

  const hasChildren = (
    item: MenuItem
  ): item is MenuItem & { children: MenuItem[] } => {
    return item != null && "children" in item;
  };

  const hasPermission = (
    item: MenuItem
  ): item is MenuItem & { permission: string[] } => {
    return item != null && "permission" in item;
  };

  const filterMenuItems = (
    items: MenuItem[],
    permissions: string[]
  ): MenuItem[] => {
    return items
      .filter((item) => {
        if (!item) return false;
        // If item doesn't have permissions, include it by default
        if (!item.permission) return true;
        // If item has permissions, check if user has any of those permissions
        if (
          item.permission.some((permission) => permissions.includes(permission))
        ) {
          if (hasChildren(item) && item.children) {
            item.children = filterMenuItems(item.children, permissions);
          }
          return true;
        }

        return false;
      })
      .map((item) => ({ ...item }));
  };

  const getRoles = async () => {
    const params = await getPermissions();
    const listPermissionConvert = JSON.parse(JSON.stringify(params))
    setListPermission(listPermissionConvert)
  }

  useEffect(() => {
    setLoading(true);
    getRoles();
    setLoading(false);
  }, [navigate]);

  useEffect(() => {
    const menuFilter = _.cloneDeep(menuItems)
    const filteredMenu = filterMenuItems(
      menuFilter as any,
      listPermission
    );
    setSelectedMenu(filteredMenu);
    setLoading(false);
  }, [listPermission]);

  useEffect(() => {
    if (
      pathName.includes(routesConfig.addLesson) ||
      pathName.includes(routesConfig.addSurvey) ||
      pathName.includes(routesConfig.createQuestionRepository)
    ) {
      setPageAddQuestion(true);
    } else {
      setPageAddQuestion(false);
    }
  }, [navigate, pathName]);

  const closeModal = () => {
    setIsOpenedModal(false);
  };

  return (
    <AuthGuard>
      <div className="flex-fol">
        {
          pathName.includes(routesConfig.detailAdminInterface) ?
            <div className="flex justify-center gap-3 bg-white">
              <Tooltip title="PC">
                <svg
                  style={{ display: "block", maxWidth: "22px", cursor: "pointer" }}
                  viewBox="0 0 24 24"
                  onClick={() => setView(1)}
                >
                  <path
                    fill="#8b8b8b"
                    d="M21,16H3V4H21M21,2H3C1.89,2 1,2.89 1,4V16A2,2 0 0,0 3,18H10V20H8V22H16V20H14V18H21A2,2 0 0,0 23,16V4C23,2.89 22.1,2 21,2Z"
                  ></path>
                </svg>
              </Tooltip>
              <Tooltip title="Tablet">
                <svg
                  style={{ display: "block", maxWidth: "22px", cursor: "pointer" }}
                  viewBox="0 0 24 24"
                  onClick={() => setView(2)}
                >
                  <path
                    fill="#8b8b8b"
                    d="M19,18H5V6H19M21,4H3C1.89,4 1,4.89 1,6V18A2,2 0 0,0 3,20H21A2,2 0 0,0 23,18V6C23,4.89 22.1,4 21,4Z"
                  ></path>
                </svg>
              </Tooltip>
              <Tooltip title="Mobile">
                <svg
                  style={{ display: "block", maxWidth: "22px", cursor: "pointer" }}
                  viewBox="0 0 24 24"
                  onClick={() => setView(3)}
                >
                  <path
                    fill="#8b8b8b"
                    d="M17,19H7V5H17M17,1H7C5.89,1 5,1.89 5,3V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V3C19,1.89 18.1,1 17,1Z"
                  ></path>
                </svg>
              </Tooltip>
            </div>
            : null
        }
        <div
          className={`margin-0 ${view === 1 ? "w-100" : view === 2 ? "w-70" : "w-40"
            }`}
        >
          <Flex gap="middle">
            <Layout style={layoutStyle}>
              <Header style={headerStyle}>
                <HeaderLayout activeMenu={() => {
                  setCollapsedContent(!collapsedContent)
                }} />
              </Header>
              <Sider style={{ ...siderStyle, transform: pageAddQuestion ? 'translateX(-100%)' : 'translateX(0%)' }} className="main-sider" width={collapsedContent ? 80 : 260}>
                <SiderLayout
                  selectedMenu={selectedMenu}
                  collapsedContent={collapsedContent}
                  isHideSider={pageAddQuestion}
                />
              </Sider>
              <Content style={{ ...contentStyle, paddingLeft: pageAddQuestion ? 0 : collapsedContent ? 80 : 260 }} className={collapsedContent ? 'content-stretch main-content' : 'main-content'}>
                {loading && <LoadingFullScreen spinning={loading} />}
                <Outlet />
              </Content>
              <Button
                className="btn-layout-sider-trigger"
                style={btnCollapsedMenuStyle}
                onClick={() => {
                  setCollapsedContent(!collapsedContent);
                }}

              >
                {!collapsedContent ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
              </Button>
            </Layout>
            <ChangePasswordModal
              open={isOpenedModal}
              title="Đổi mật khẩu"
              hideModal={closeModal}
              initalValue={userPassword}
              config={config}
              footer={
                <>
                  <div className="btn-modal--footer">
                    <Button className="btn btn-outlined" onClick={closeModal}>
                      <Typography.Text>Hủy</Typography.Text>
                    </Button>
                    <Button
                      className="btn btn-primary"
                      htmlType="submit"
                      onClick={() => form.submit()}
                    >
                      <Typography.Text>Lưu</Typography.Text>
                    </Button>
                  </div>
                </>
              }
            />
          </Flex>
        </div>
      </div>
    </AuthGuard >
  );
}
