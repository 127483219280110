// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-lecturer-import {
  width: 100% !important; }
  .list-lecturer-import .page-header-group--layout .modal-import-header {
    padding: 0 24px; }
  .list-lecturer-import .tabs-heading .modal-import-search {
    padding: 0 24px; }
`, "",{"version":3,"sources":["webpack://./src/pages/category-management/lecturer-management/components/import-industry-db-modal/import-industry-db-modal.scss"],"names":[],"mappings":"AAAA;EACI,sBAAsB,EAAA;EAD1B;IAKY,eAAe,EAAA;EAL3B;IAWY,eAAe,EAAA","sourcesContent":[".list-lecturer-import {\n    width: 100% !important;\n\n    .page-header-group--layout {\n        .modal-import-header {\n            padding: 0 24px;\n        }\n    }\n\n    .tabs-heading {\n        .modal-import-search {\n            padding: 0 24px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
