import React, { useEffect, useRef, useState } from "react";
import { Button, Flex, Spin, Tooltip } from "antd";
import {
  DeleteIcon,
  ExportIcon,
  HoursGlassIcon,
  PauseIcon,
  QuestionMarkIcon,
  RecordIcon,
  RefreshIcon,
} from "../../../../../../../components/icons";
import EmptyComponent from "../../../../../../../components/empty";
import { QuestionProps, QuizProps } from "../../../../../../../types/course";
import { FormInstance } from "antd/lib";
import {
  JSON_SUB_TYPE,
  MAX_QUIZ_FILE_UPLOAD_SIZE,
  QuestionTypeEnum,
} from "../../../../../../../constants/course";
import { isEmpty, toNumber } from "lodash";
import Countdown from "../../../../../../../components/countdown/CountDown";
import useKeyword from "../../../../../../../hooks/useKeyword";
import { startSingleQuiz } from "../../../../../../../service/learning-course";
import { Link } from "react-router-dom";
import { uploadFileV2 } from "../../../../../../../service/uploadFile/infoDetailApi";

interface VideoRecorderProps extends QuestionProps {
  data?: QuizProps;
  form?: FormInstance<any>;
  onChange?: (value: any) => void;
}

const VideoRecorder = ({
  data,
  pause,
  configs,
  disabled,
  initialData,
  onFinish = () => {},
  onChange = () => {},
}: VideoRecorderProps) => {
  const maxSize = data?.settingGeneral
    ? data?.settingGeneral.fileCapacity * 1024 * 1024
    : MAX_QUIZ_FILE_UPLOAD_SIZE;

  const initialTime =
    data?.timeToCompleted || configs?.actionConfig?.timeForQuiz || 0;
  const [videoURL, setVideoURL] = useState<string>("");
  const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);

  // control
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const [mode, setMode] = useState<"default" | "recording" | "view" | "error">(
    "default"
  );
  const [isPaused, setIsPaused] = useState(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);

  const blockId = toNumber(useKeyword("blockId"));
  const currentScreen = useKeyword("screen");
  const isSequentiallyQuiz = configs?.generalConfig?.sequentiallyQuiz;
  const [loading, setLoading] = useState<boolean>(false);
  const [canStart, setCanStart] = useState<boolean>(false);

  useEffect(() => {
    // Xử lý câu hỏi có đếm thời gian riêng
    if (currentScreen === "train") {
      if (!isEmpty(data) && isSequentiallyQuiz && initialTime) {
        const startSingle = async () => {
          try {
            setLoading(true);
            await startSingleQuiz({
              blockId: blockId,
              quizId: data?.id,
            });
          } catch (err: any) {
          } finally {
            setCanStart(true);
            setLoading(false);
          }
        };
        startSingle();
      }
    }
  }, [isSequentiallyQuiz]);

  const startCamera = async () => {
    setMode("recording");
    setIsRecording(true);
    setIsPaused(false);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }

      const recorder = new MediaRecorder(stream);
      let chunks: BlobPart[] = [];

      recorder.ondataavailable = (e: BlobEvent) => {
        chunks.push(e.data);
        // Kiểm tra kích thước dữ liệu hiện tại
        const currentSize = chunks.reduce((total, chunk) => {
          if (chunk instanceof Blob) {
            return total + chunk.size;
          }
          return total;
        }, 0);
        if (currentSize >= maxSize) {
          stopRecording();
          uploadVideo(videoBlob);
        }
      };

      recorder.onstop = () => {
        const completeBlob = new Blob(chunks, { type: "video/webm" });
        uploadVideo(completeBlob);
        setVideoBlob(completeBlob);
        setVideoURL(URL.createObjectURL(completeBlob));
      };

      setMediaRecorder(recorder);
      recorder.start();
    } catch (error) {
      console.error("Error accessing the camera", error);
      setMode("error");
    }
  };

  const stopRecording = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      (videoRef.current.srcObject as MediaStream)
        .getTracks()
        .forEach((track) => track.stop());

      videoRef.current.srcObject = null;

      if (mediaRecorder) {
        mediaRecorder.stop();
      }
    }

    setMode("view");
  };

  const pauseRecording = () => {
    if (mediaRecorder?.state === "recording") {
      mediaRecorder.pause();
      setIsPaused(true);
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder?.state === "paused") {
      mediaRecorder.resume();
    }
    if (videoRef.current) {
      videoRef.current.play();
    }
    setIsPaused(false);
    setMode("recording");
  };

  const handleDeleteRecord = () => {
    setVideoURL("");
    setMode("default");
    onChange({
      [`recorderVideo-${data?.id}-${QuestionTypeEnum.VIDEO}-${JSON_SUB_TYPE.ContentRequest}`]:
        null,
    });
  };

  const uploadVideo = async (videoBlob: Blob | null) => {
    if (!videoBlob) return;

    const formData = new FormData();
    formData.append("file", videoBlob);

    try {
      setUploading(true);
      const response = await uploadFileV2(formData);
      onChange({
        [`recorderVideo-${data?.id}-${QuestionTypeEnum.VIDEO}-${JSON_SUB_TYPE.ContentRequest}`]:
          response?.data?.filePath,
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(initialData?.answer[0])) {
      const getFile = async () => {
        setVideoURL(initialData?.answer[0]);
        setMode("view");
      };
      getFile();
    }
  }, []);

  useEffect(() => {
    return () => stopRecording();
  }, []);

  const renderVideoScreen = () => {
    switch (mode) {
      case "default":
        return (
          <div className="start-screen">
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/card.png`}
              alt="Snapshot"
              className="screen-video-snapshot"
            />

            <Button
              className="btn-start h-40 center"
              icon={<RecordIcon />}
              onClick={startCamera}
              disabled={disabled}
            >
              Bắt đầu quay
            </Button>
          </div>
        );
      case "view":
        return (
          <div className="view-screen flex gap-16">
            <video src={videoURL} className="screen-video-snapshot" controls />
            <div className="flex flex-column gap-16 btn-action-view-group">
              <Button
                className="btn-action"
                onClick={startCamera}
                disabled={disabled}
              >
                <RefreshIcon />
              </Button>
              <Button
                className="btn-action btn-delete"
                onClick={handleDeleteRecord}
                disabled={disabled}
              >
                <DeleteIcon />
              </Button>
            </div>
          </div>
        );

      case "recording":
        return (
          <div className="recording-screen">
            <video ref={videoRef} className="screen-video-snapshot" />
            <div className="pause-btn-group">
              {isRecording && !isPaused && (
                <Button className="mb-1 btn-pause" onClick={pauseRecording}>
                  Pause
                </Button>
              )}
              {isPaused && (
                <div className="pause-btn-group flex gap-8">
                  <Button
                    className="btn-action center"
                    onClick={resumeRecording}
                  >
                    <PauseIcon />
                  </Button>
                  <Button
                    className="btn-primary btn-action center"
                    onClick={stopRecording}
                  >
                    <ExportIcon />
                  </Button>
                </div>
              )}
            </div>
          </div>
        );
      case "error":
        return (
          <div className="w-full center">
            <EmptyComponent description="Không tìm thấy thiết bị được yêu cầu" />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Spin spinning={uploading || loading}>
        <div className="question-header mb-3">
          {configs?.actionConfig?.timeForQuiz &&
          configs?.generalConfig?.sequentiallyQuiz ? (
            <div className="question-time flex align-center gap-8">
              <HoursGlassIcon />
              <div className="text-primary font-size-14 line-height-20 font-weight-6 flex align-center gap-8">
                Trả lời câu hỏi trong:
                <Countdown
                  pause={pause}
                  initialTime={canStart ? toNumber(initialTime) : 0}
                  onFinish={() => {
                    if (!uploading) {
                      stopRecording();
                      uploadVideo(videoBlob);
                      onFinish();
                    }
                  }}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="question-title">
            {/* <Flex
              justify="space-between"
              className="font-size-16 line-height-24 font-weight-6"
            >
              Câu hỏi {toNumber(data?.index) + 1}:{" "}
              {configs?.generalConfig?.showQuizScore && (
                <span className="total-point font-size-14">
                  {`(${data?.totalPoints || 0} điểm)`}
                </span>
              )}
            </Flex> */}
            <Flex align="flex-start" gap={8}>
              <div
                className="font-size-16 line-height-24 font-weight-6"
                dangerouslySetInnerHTML={{ __html: `${data?.question}` }}
              ></div>
              {data?.settingHint?.isDisplayInstruction && data?.isShowHint ? (
                <Tooltip
                  placement="bottom"
                  title={
                    <div>
                      {data?.settingHint?.content ? (
                        <p className="text-14 font-weight-5">
                          Hướng dẫn: {data?.settingHint?.content}
                        </p>
                      ) : (
                        "Không có hướng dẫn"
                      )}

                      {data?.settingHint?.schema ? (
                        <p className="font-size-14 mt-2">
                          Đường dẫn tham khảo:{" "}
                          <Link
                            target="_blank"
                            to={`${data?.settingHint?.uri}${data?.settingHint?.schema}`}
                          >
                            {`${data?.settingHint?.uri}${data?.settingHint?.schema}`}
                          </Link>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  }
                >
                  <span className="quest-icon text-secondary w-max-content">
                    <QuestionMarkIcon />
                  </span>
                </Tooltip>
              ) : (
                ""
              )}
            </Flex>
          </div>
        </div>
        {renderVideoScreen()}
      </Spin>
    </div>
  );
};

export default VideoRecorder;
