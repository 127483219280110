import React, { useState } from "react";
import {
  CaretDownOutlined,
  InfoCircleOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import SingleMenu from "../../../../components/menu/SingleMenu";
import {
  Draft,
  GroupsExam,
  IndustryOrUniversityProps,
  QuizProps,
  ResultExam,
  UserInfoProps,
} from "../../../../types/scoring-course";
import { isEmpty } from "lodash";
import ScoreByGroupItem from "./ScoreByGroupItem";

interface OverviewProps {
  userInfo?: UserInfoProps;
  quizzes?: QuizProps[];
  result?: ResultExam;
  groups?: GroupsExam[];
  quizzesCompleted?: Draft[];
  showCollapseBtn?: boolean;
}
function Overview({
  userInfo,
  quizzes,
  result,
  groups,
  showCollapseBtn = true,
  quizzesCompleted = [],
}: OverviewProps) {
  const [collapsed, setCollapsed] = useState(false);

  const totalQuizCompleted = quizzesCompleted?.length;

  const points =
    quizzesCompleted?.reduce((total: number, item: Draft) => {
      return total + item.points;
    }, 0) || 0;

  const totalPoints =
    quizzes?.reduce((total: number, item: QuizProps) => {
      return total + item.totalPoints;
    }, 0) ||
    result?.totalPoints ||
    0;

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div
      className={`scoring-overview w-full ${
        collapsed ? "overview-hidden" : ""
      }`}
    >
      <div className="w-full">
        <div className="over-view-header flex align-center">
          {showCollapseBtn && (
            <Button className="border-none" onClick={toggleCollapsed}>
              {collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
            </Button>
          )}

          {!collapsed && (
            <div className="flex gap-16">
              <InfoCircleOutlined />
              <span className="font-size-16 font-weight-5">
                Thông tin chung
              </span>
            </div>
          )}
        </div>
        {!collapsed && (
          <SingleMenu
            className="overview-menu"
            title={<span className="font-weight-5">Thông tin học viên</span>}
            content={
              <div className="overview-menu-content">
                <Row>
                  <Col span={12}>
                    <span className="font-weight-5">Họ và tên</span>
                  </Col>
                  <Col span={12}>
                    {isEmpty(userInfo?.lastName) ||
                    isEmpty(userInfo?.firstName) ? (
                      "_"
                    ) : (
                      <span className="one-line">{`${userInfo?.firstName} ${userInfo?.lastName}`}</span>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <span className="font-weight-5">Email</span>
                  </Col>
                  <Col span={12}>
                    <span className="one-line">{userInfo?.email || "_"}</span>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <span className="font-weight-5">Trường</span>
                  </Col>
                  <Col span={12}>
                    {userInfo?.universities &&
                    userInfo?.universities?.length > 0
                      ? userInfo?.universities?.map(
                          (item: IndustryOrUniversityProps, i: number) => (
                            <div key={i} className="one-line">
                              {item.name}
                            </div>
                          )
                        )
                      : "_"}
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <span className="font-weight-5">Chuyên ngành</span>
                  </Col>
                  <Col span={12}>
                    <span className="one-line">
                      {" "}
                      {userInfo?.industries && userInfo?.industries?.length > 0
                        ? userInfo?.industries?.map(
                            (item: IndustryOrUniversityProps, i: number) => (
                              <div key={i} className="one-line">
                                {item.name}
                              </div>
                            )
                          )
                        : "_"}
                    </span>
                  </Col>
                </Row>
              </div>
            }
          />
        )}
        {!collapsed && (
          <SingleMenu
            className="overview-menu"
            title={<span className="font-weight-5">Thông tin bài chấm</span>}
            content={
              <div className="overview-menu-content">
                <Row>
                  <Col span={12}>
                    <span className="font-weight-5">Đã chấm</span>
                  </Col>
                  <Col span={12}>
                    <span className="one-line font-weight-5">
                      {totalQuizCompleted}/{quizzes?.length} câu
                    </span>
                  </Col>
                </Row>
                <Row>
                  <SingleMenu
                    expendIcon={<CaretDownOutlined />}
                    className="total-point-detail"
                    title={
                      <Row>
                        <Col span={12}>
                          <span className="font-weight-5">Tổng điểm</span>
                        </Col>
                        <Col span={12}>
                          <span className="font-weight-5">
                            {points}/{totalPoints} điểm
                          </span>
                        </Col>
                      </Row>
                    }
                    content={
                      <div>
                        {groups?.map((item: GroupsExam, i) => (
                          <ScoreByGroupItem
                            key={i}
                            group={item}
                            quizzesCompleted={quizzesCompleted}
                          />
                        ))}
                      </div>
                    }
                  />
                </Row>
              </div>
            }
          />
        )}
      </div>
    </div>
  );
}

export default Overview;
