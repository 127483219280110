import {Button, Dropdown, Input, message, Modal, Space, Typography,} from "antd";
import "../course-constructor/course-construct.scss";
import React, {useState} from "react";
import {
  DeleteFilled,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FileUnknownOutlined,
  MoreOutlined,
  SettingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  deleteCourseUnit,
  downloadCourseUnit,
  publishUnit,
  putCourseUpdateUnit,
} from "../../../../service/course-construct";
import {routesConfig} from "../../../../config/routes";
import {useNavigate} from "react-router-dom";
import {isEmpty} from "lodash";
import AddLearningMaterials from "../../../drawer/add-learning-materials/AddLearningMaterials";
import ActiveSettingsChaptersDrawer from "../../active-settings-chapters-drawer/ActiveSettingsChaptersDrawer";
import AddLearningMaterialsReferences
  from "../../../drawer/add-learning-materials-references/AddLearningMaterialsReferences";
import EditExerciseDrawer from "../../../drawer/edit-exercise";
import {HelpService} from "../../../../service/helper.service";
import "./unit-card.scss"

var indexDB = require("idbcache").default;
type IProp = {
  data?: any;
  lessonDetailData?: any;
  id?: any;
  handleGetDetail?: any;
  name?: string;
  setLoading?: (value: boolean) => void;
  updateUnit: (idSection: number, idSequence: number, data: any) => void;
  sequenceId?: number;
};
const imgPath = "/images/";
const UnitCard = (props: IProp) => {
  const helpService = new HelpService();
  const typeModule = props.data.block.some((item: { module: string }) => item.module === 'quiz');
  const [cardHeader, setCardHeader] = useState<string>(props.data.name);
  const [oldName, setOldName] = useState<string>('');
  const [active, setActive] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();
  const [isAddLearningMaterials, setIsAddLearningMaterials] = useState<boolean>(false);
  const [isAddLearningMaterialsReference, setIsAddLearningMaterialsReference] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isUnit, setIsUnit] = useState<boolean>(false);
  const [isLesson, setIsLesson] = useState<boolean>(false);
  const [unitId, setUnitId] = useState<number | undefined>(undefined);
  const [isOpenSettingDraw, setIsOpenSettingDrawer] = useState(false);
  const [isOpenEditDraw, setIsOpenEditDrawer] = useState(false);
  const [iconCardModule, setIconCardModule] = useState<number>(props.data?.block?.[0]?.icon_type);

  const handleChangeNameUnit = async () => {
    const data = {
      name: cardHeader,
      sequenceId: props.id,
    };
    try {
      const response = await putCourseUpdateUnit(props.data.id, data).then(
        (result) => {
          props.handleGetDetail();
          message.success("Lưu cấu trúc thành công");
        }
      );
    } catch (error) {
    }
  };

  const handleBlur = () => {
    setActive(false);
    handleChangeNameUnit();
  };

  const handleClick = () => {
    if (!isEmpty(props.data.block) && props.data.block?.[0]?.module === "quiz") {
      indexDB.set("current-course", JSON.stringify({name: props.data.name, parent: props.name, tabCurrent: '2'}), 1440);
      navigate(`${routesConfig.addLesson}?unitId=${props.data.id}`, {state: {unitId: props.data.id ?? ''},});
    } else {
      setUnitId(props?.data?.id);
      if (props.data.block?.[0]?.module === "reference") {
        setIsAddLearningMaterialsReference(true);
      } else {
        setIsAddLearningMaterials(true);
      }
      setIsEdit(true);
    }
  };

  const handleCloseEdit = () => {
    setIsOpenEditDrawer(false)
  }

  const handleCancel = () => {
    setCardHeader(oldName);
    setActive(false);
    setErrorMessage('');
  };

  const handleSave = () => {
    if (cardHeader) {
      Modal.confirm({
        title: `Xác nhận lưu cấu trúc`,
        content: `Bạn có chắc chắn muốn lưu cấu trúc ${cardHeader} của khoá học ${props.name}?`,
        onOk: () => {
          handleBlur();
        },
        okText: "Xác nhận",
        cancelText: "Huỷ",
        centered: true,
        icon: <ExclamationCircleOutlined style={{color: "#1677FF"}}/>,
        footer: (_, {OkBtn, CancelBtn}) => (
          <>
            <CancelBtn/>
            <OkBtn/>
          </>
        ),
      });
    } else {
      setErrorMessage("Vui lòng nhập tên học liệu");
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCardHeader(event.target.value);
  };

  const handleDeleteUnit = async () => {
    try {
      const response = await deleteCourseUnit(Number(props.data.id)).then(
        (result) => {
          props.handleGetDetail();
          message.success("Xóa học liệu thành công");
        }
      );
    } catch (error) {
    }
  };

  const {confirm} = Modal;


  const showConfirm = () => {
    confirm({
      title: (
        <div className="title-container">
          <div style={{color: "red", fontSize: "20px"}}>
            <DeleteFilled/>
          </div>
          <span>Bạn muốn xóa học liệu?</span>
        </div>
      ),
      icon: null,
      okText: "Xác nhận",
      cancelText: "Hủy",
      okType: "danger",
      onOk() {
        handleDeleteUnit();
      },
      onCancel() {
      },
    });
  };

  const downloadUnit = (unitLink: string) => {
    if (unitLink) {
      const xmlHttp = new XMLHttpRequest();
      xmlHttp.onreadystatechange = () => {
        if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
          const blobUrl = window.URL.createObjectURL(xmlHttp.response);
          const e = document.createElement('a');
          e.href = blobUrl;
          e.download = blobUrl.substr(blobUrl.lastIndexOf('/') + 1);
          document.body.appendChild(e);
          e.click();
          document.body.removeChild(e);
        }
      };
      xmlHttp.responseType = 'blob';
      xmlHttp.open('GET', unitLink, true);
      xmlHttp.send(null);
    } else {
      helpService.errorMessage('Tài nguyên không tồn tại');
    }
  }

  const downloadUnitFeedback = async (id: number) => {
    await downloadCourseUnit(id).then((res: any) => {
      const link = document.createElement('a');
      link.href = res.data;
      link.download = '';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch((error: any) => {
      helpService.errorMessage(error.response.data.message);
    });
  }

  const handlePublishUnit = async (unitId: number) => {
    try {
      await publishUnit(unitId);
      message.success("Xuất bản học liệu thành công!")
    } catch (err) {
    }
  }

  const renderQuizIcon = (value: number) => {
    switch (value) {
      case 1:
        return "play-circle.png";
      case 2:
        return "pic-center.png";
      case 3:
        return "duplicate.png";
      case 4:
        return "heart-map.png";
      default:
        return '';
    }
  }

  const renderCourseIcon = (value: number) => {
    switch (value) {
      case 1:
        return "play-square-outline.svg";
      case 2:
        return "audio-outline.svg";
      case 3:
        return "xAPI-outline.svg";
      case 4:
        return "exercise-outline.svg";
      case 5:
        return "PDF-outline.svg";
      case 6:
        return "scorm-icon-outline.svg";
      case 7:
        return "audio-outline.svg";
      case 8:
        return "Text.svg";
      case 9:
        return "PDF-outline.svg";
      default:
        return '';
    }
  }

  return (
    <>
      <div className="chapter-header">
        <div className="chapter-title">
          <img
            className=''
            loading="eager"
            alt=''
            src={imgPath + "ic_arrow_down.svg"}
          />
          <img
            className=''
            loading="eager"
            alt=''
            src={imgPath + (typeModule ? renderQuizIcon(iconCardModule) : renderCourseIcon(iconCardModule))}
          />
          <div>
            {!active ? (
              <div className="card-header">{cardHeader}</div>
            ) : (
              <div>
                <Input
                  placeholder="Xin vui lòng nhập"
                  // onClick={handleActive}
                  // onBlur={handleBlur}
                  value={cardHeader}
                  onChange={handleChange}
                  className={
                    active
                      ? "course-input active-input"
                      : "course-input inactive-input"
                  }
                />
                {errorMessage ? (
                  <p className="validate-text">{errorMessage}</p>
                ) : null}
              </div>
            )}
          </div>
        </div>

        <Space>
          <a className="action-course"
             onClick={() =>
               Modal.confirm({
                 title: "Xuất bản học liệu",
                 content:
                   "Bạn có muốn xuất bản học liệu này?",
                 okText: "Xác nhận",
                 cancelText: "Huỷ",
                 onOk: () => {
                   handlePublishUnit(Number(props.data.id))

                 },
                 onCancel() {
                   // Optional: Handle the cancel event
                 },
               })
             }>
            <UploadOutlined/>
          </a>
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: typeModule ? 'Thiết lập câu hỏi' : 'Sửa học liệu',
                  icon: <FileUnknownOutlined/>,
                },
                typeModule && {
                  key: "2",
                  label: "Sửa học liệu",
                  icon: <EditOutlined/>,
                },
                {
                  key: "3",
                  label: "Tải xuống",
                  icon: <DownloadOutlined/>,
                },
                {
                  key: "4",
                  label: "Cài đặt hoạt động",
                  icon: <SettingOutlined/>,
                },
                {
                  key: "5",
                  label: "Xóa",
                  icon: <DeleteOutlined/>,
                  danger: true,
                },
                props?.data?.block?.[0]?.module === "quiz" && {
                  key: "6",
                  label: "Cài đặt giám sát thi cử",
                  icon: <SettingOutlined/>,
                },
              ],
              onClick: ({key}) => {
                if (key === "1") {
                  // edit item
                  handleClick();
                }
                if (key === "2") {
                  setIsOpenEditDrawer(true)
                }
                if (key === "3") {
                  if (props.data?.block?.[0]?.module !== 'text' && props.data?.block?.[0]?.module !== 'quizz' && !typeModule) {
                    downloadUnit(props.data?.block?.[0]?.mainKey)
                  }
                  if (typeModule) {
                    downloadUnitFeedback(props.data?.block?.[0]?.course_unit_id)
                  }
                }
                if (key === "4") {
                  setIsOpenSettingDrawer(true);
                  setIsUnit(true)
                  setIsLesson(false)
                }
                if (key === "5") {
                  // delete item
                  showConfirm();
                }

                if (key === '6') {
                  navigate(`/${routesConfig.settingSupervisionDetail}/${props?.data?.block?.[0]?.id}`)
                }
              },
            }}
            placement="bottomRight"
          >
            <Button className="action-course">
              <MoreOutlined/>
            </Button>
          </Dropdown>
        </Space>
      </div>
      {active && (
        <div className="edit-container">
          <Button onClick={handleCancel}>Hủy</Button>
          <Button onClick={handleSave} className="btn-primary">
            <Typography.Text>Lưu</Typography.Text>
          </Button>
        </div>
      )}

      <AddLearningMaterials
        sequenceId={props.data.id}
        title="Basic Drawer"
        setIsOpenDrawer={setIsAddLearningMaterials}
        open={isAddLearningMaterials}
        handleDetail={props.handleGetDetail}
        itemDetail={props?.data}
        isEdit={isEdit}
        id={unitId}
        setIsEdit={setIsEdit}
        // multimediaType={multimediaType}
      />

      <AddLearningMaterialsReferences
        sequenceId={props.data.id}
        title="Basic Drawer"
        setIsOpenDrawer={setIsAddLearningMaterialsReference}
        open={isAddLearningMaterialsReference}
        handleDetail={props.handleGetDetail}
        itemDetail={props?.data}
        isEdit={isEdit}
        id={unitId}
        setIsEdit={setIsEdit}
        // multimediaType={multimediaType}
      />

      <ActiveSettingsChaptersDrawer
        id={props.id}
        setIsOpened={setIsOpenSettingDrawer}
        open={isOpenSettingDraw}
        handleDetail={props.handleGetDetail}
        itemDetail={props?.data}
        isLesson={isLesson}
        isUnit={isUnit}
        lessonDetailData={props.lessonDetailData}
      />
      <EditExerciseDrawer handleDetail={props.handleGetDetail}
                          setLoading={props.setLoading}
                          open={isOpenEditDraw} itemDetail={props?.data}
                          id={props.data.id} onClose={handleCloseEdit}/>
    </>
  );
};

export default UnitCard;
