import { Editor, Plugin } from "grapesjs";

export type PluginOptions = {
  props?: {
    min?: number;
    units: [];
  };
};

const FontSizeConfig: Plugin<PluginOptions> | any = (editor: Editor, optionsPlugin: PluginOptions = {}) => {
  editor.StyleManager.addType('font-size-custom', {
    create({ props, change }) {
      const el = document.createElement('div');
      const data = props as {
        max: number;
        min: number;
        units: [];
      }
      const options = data.units?.length ? data.units : [];
      el.innerHTML = `
<div class="gjs-field gjs-input">
        <span id="gjs-sm-input-holder">
        <input type="number" class="my-input" value="" placeholder="auto" min="${data.min}" oninput="this.value = 
 !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"/>
        </span>
        <span class="gjs-field-units">
        <select class="gjs-input-unit my-select">
        <option hidden disabled>-</option>
        ${options.map((unit: any) => `<option class="gjs-input-unit" value=${unit}>${unit}</option>`).join("")}
</select>
</span>
        <div class="gjs-field-arrows" data-arrows="">
        <div class="gjs-field-arrow-u" data-arrow-up=""></div>
        <div class="gjs-field-arrow-d" data-arrow-down=""></div>
      </div>
      </div>`;
      const inputEl = el.querySelector<HTMLInputElement>('.my-input');
      const selectEl = el.querySelector<HTMLSelectElement>('.my-select');
      const arrowUpEl = el.querySelector<HTMLDivElement>('.gjs-field-arrow-u');
      const arrowDownEl = el.querySelector<HTMLDivElement>('.gjs-field-arrow-d');

      let isHoldingUp = false;
      let isHoldingDown = false;
      let intervalId: number | null = null;


      if (!inputEl || !selectEl || !arrowUpEl || !arrowDownEl) {
        console.error('One or more elements not found');
        return el;
      }

      let isDragging = false;
      let initialY: number | null = null;

       const setDefaultUnitOnBlur = () => {
  if (!selectEl.value) {  // If no unit is selected
    selectEl.value = 'px';  // Set default unit to 'px'
    updateInputValueWithUnit();  // Update input field with the new value including unit
  }
};

       // Function to get the numeric value without the unit from the input field
const getNumericValue = (value: string) => {
  return parseInt(value, 10) || 0;  // Fallback to 0 if the value is NaN
};





      // Function to update input value with unit
      const updateInputValueWithUnit = () => {
          const numericValue = getNumericValue(inputEl.value);  // Get the numeric part
  const combinedValue = `${numericValue}`;  // Combine the value and unit
  inputEl.dispatchEvent(new CustomEvent('input', { detail: { combinedValue } }));
      };




      // Increment and Decrement functions
      const incrementValue = () => {
        let currentValue = parseInt(inputEl.value, 10) || 0;  // Ensure the value is an integer
        if (currentValue < data.max) {
          inputEl.value = (currentValue + 1).toString();  // Decrease the value
          if (!selectEl.value) {  // If no unit is selected
            selectEl.value = 'px';
          }
          inputEl.dispatchEvent(new Event('input'));  // Trigger 'input' event
        }
      };

      const decrementValue = () => {
        let currentValue = parseInt(inputEl.value, 10) || 0;  // Ensure the value is an integer
        if (currentValue > data.min) {
          inputEl.value = (currentValue - 1).toString();  // Decrease the value
          if (!selectEl.value) {  // If no unit is selected
            selectEl.value = 'px';
          }
          inputEl.dispatchEvent(new Event('input'));  // Trigger 'input' event
        }
      }


      const startHolding = (direction: 'up' | 'down') => {
        if (direction === 'up') {
          incrementValue(); // Do it once immediately
          isHoldingUp = true;
          isHoldingDown = false;
        } else if (direction === 'down') {
          decrementValue(); // Do it once immediately
          isHoldingDown = true;
          isHoldingUp = false;
        }

        intervalId = window.setInterval(() => {
          if (isHoldingUp) {
            incrementValue();
          } else if (isHoldingDown) {
            decrementValue();
          }
        }, 100); // Repeat every 100ms while holding
      };

      // Stop dragging
      const stopHolding = () => {
        isHoldingUp = false;
        isHoldingDown = false;
        if (intervalId) {
          clearInterval(intervalId);
          intervalId = null;
        }
      };

      // Function to handle mouse move during dragging
      const handleMouseMove = (event: MouseEvent) => {
        if (!isDragging || initialY === null) return;

        const deltaY = initialY - event.clientY;

        let currentValue = parseInt(inputEl.value || '0', 10); // Ensure 0 if input is empty

        if (deltaY > 0 && currentValue < data.max) {
          inputEl.value = (currentValue + 1).toString();
        } else if (deltaY < 0 && currentValue > data.min) {
          inputEl.value = (currentValue - 1).toString();
        }

        const changeEvent = new Event('input');
        inputEl.dispatchEvent(changeEvent);
        initialY = event.clientY;
      };


      // Start dragging when holding down the arrow
      const startDragging = (event: MouseEvent) => {
        isDragging = true;
        initialY = event.clientY;

        // Change cursor to `n-resize` when dragging starts
        document.body.style.cursor = 'n-resize';

        window.addEventListener('mousemove', handleMouseMove);
      };

      // Stop dragging when mouse is released
      const stopDragging = () => {
        isDragging = false;
        initialY = null;

        // Reset cursor when dragging stops
        document.body.style.cursor = 'default';

        window.removeEventListener('mousemove', handleMouseMove);
      };


     inputEl.addEventListener('input', event => change({ event, partial: true }));
      selectEl.addEventListener('change', event => change({ event }));


      inputEl.addEventListener('blur', () => setDefaultUnitOnBlur());
      // Handle Enter key to append unit and propagate the change
      inputEl.addEventListener('keydown', (event) => event.code === '13' && updateInputValueWithUnit())

      // Arrow Up: Increase input value
      arrowUpEl.addEventListener('click', () => incrementValue());

      // Arrow Down: Decrease input value
      arrowDownEl.addEventListener('click', () => decrementValue());

      arrowUpEl.addEventListener('mousedown', () => startHolding('up'));
      arrowUpEl.addEventListener('mouseup', stopHolding);
      arrowUpEl.addEventListener('mouseleave', stopHolding);

      arrowUpEl.addEventListener('mouseup', startDragging);
      arrowDownEl.addEventListener('mousedown', startDragging);

      window.addEventListener('mouseup', stopDragging); // Stop dragging when mouse is released
      window.addEventListener('mouseleave', stopDragging); // Stop dragging when mouse leaves the window

      arrowUpEl.addEventListener('mouseenter', () => {
        document.body.style.cursor = 'n-resize';
      });

      arrowDownEl.addEventListener('mouseenter', () => {
        document.body.style.cursor = 'n-resize';
      });

      // **Reset cursor when leaving the arrows**
      arrowUpEl.addEventListener('mouseleave', () => {
        if (!isDragging) document.body.style.cursor = 'default';
      });

      arrowDownEl.addEventListener('mouseleave', () => {
        if (!isDragging) document.body.style.cursor = 'default';
      });


      arrowDownEl.addEventListener('mousedown', () => startHolding('down'));
      arrowDownEl.addEventListener('mouseup', stopHolding);
      arrowDownEl.addEventListener('mouseleave', stopHolding);

      return el;
    },
    // Propagate UI changes up to the targets
    emit({ props, updateStyle }, { event, partial = false }) {
      if (!event) {
        console.error("No event provided to emit function");
        return;
      }

      // Retrieve the event target or fallback to currentTarget
      const target = event.target as HTMLElement || event.currentTarget as HTMLElement;

      if (!target) {
        console.error("Event target or currentTarget is undefined");
        return;
      }

      const container = target.closest('div'); // Find the parent container

      if (!container) {
        console.error("Container not found");
        return;
      }

      // Get the input and select elements within the container
      const inputEl = container.querySelector<HTMLInputElement>('.my-input');
      const selectEl = container.querySelector<HTMLSelectElement>('.my-select');

      if (!inputEl || !selectEl) {
        console.error("Input or Select element not found in container");
        return;
      }


      // Retrieve the input and select values
      const valueInput = inputEl.value;  // Default to '0' if input is empty
      const valueSelect = selectEl.value || 'px';  // Default to 'px' if select is not set
      // Combine the input value with the unit from the select element
      const combinedValue = `${valueInput}${valueSelect}`;

      // Update the style with the combined value
      updateStyle(combinedValue, { partial });
    },

    // Update UI (eg. when the target is changed)
    update({ value, el }) {

      const inputEl = el.querySelector<HTMLInputElement>('.my-input');
      const selectEl = el.querySelector<HTMLSelectElement>('.my-select');

      if (!inputEl || !selectEl) return;

      // Extract number and unit from value (e.g., "10px" -> "10" and "px")
      const numberValue = parseInt(value, 10);  // Extract the numeric part
      const unitValue = value.replace(numberValue.toString(), '');  // Extract the unit part (e.g., "px" or "%")

      // Update both input and select based on the target value
      inputEl.value = String(numberValue);
      selectEl.value = unitValue;

      },
    // Clean the memory from side effects if necessary (eg. global event listeners, etc.)
    destroy() {
    },
  });

};

export default FontSizeConfig;
