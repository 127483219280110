import React from "react";
import { Col, Empty, Row, Spin, TableColumnsType } from "antd";
import StatisticalReportCard from "../../StatisticalReportCard";
import TableWithTotals from "../../../../../../components/table-with-totals/TableWithTotals";
import { createTableData } from "../../../../../../components/static-report/table/tableConvertData";
import { handleExportReport } from "../../../../../../utils/staticReport";
import {
  exportTableCourseFormatUniversityAction,
  exportTableMultiMediaUnit,
  exportTableTeacherAssignByInstruct,
  exportTableTestByIndustryGroup,
  exportTableUnitByIndustryGroup,
  exportTableUniversityReferenceSourceByType,
  exportTableUniversityScormUnit,
} from "../../../../../../service/statistical-report";
import { staticReportStore } from "../../../../../../stores/stores";
import dayjs from "dayjs";

type ResouceTableProps = {
  loading: boolean;
  dataListCourseByFormatUniversity: Array<any>;
  dataListCourseByTeacherUniversity: Array<any>;
  dataListUnitByIndustryGroup: Array<any>;
  dataListUniversityTestByType: Array<any>;
  dataListUniversityReferenceSourceByType: Array<any>;
  dataListUniversityScormXapiUnit: Array<any>;
  dataListUniversityMultiMediaUnit: Array<any>;
  dataListCourseByIndustryGroup: Array<any>;
};

const ResourceTable = ({
  loading,
  dataListCourseByFormatUniversity,
  dataListCourseByIndustryGroup,
  dataListCourseByTeacherUniversity,
  dataListUnitByIndustryGroup,
  dataListUniversityTestByType,
  dataListUniversityReferenceSourceByType,
  dataListUniversityScormXapiUnit,
  dataListUniversityMultiMediaUnit,
}: ResouceTableProps) => {
  const { filterValues } = staticReportStore();

  const tableColumn: TableColumnsType<any> = [
    {
      title: "Loại đánh giá",
      dataIndex: "criteria",
      key: "criteria",
      render: (text: any) => <div className="font-weight-5">{text}</div>,
    },
    {
      title: "Số lượng",
      dataIndex: "count",
      key: "count",
      align: "right",
    },
  ];

  const tableCourseByDepartmentColumn: TableColumnsType<any> = [
    {
      title: "Khoa",
      dataIndex: "criteria",
      key: "criteria",
      render: (text: any) => <div className="font-weight-5">{text}</div>,
    },
    {
      title: "Số lượng",
      dataIndex: "count",
      key: "count",
      align: "right",
    },
  ];

  const tableQuantityDataColumn: TableColumnsType<any> = [
    {
      title: "Định dạng tài liệu",
      dataIndex: "criteria",
      key: "criteria",
      render: (text: any) => <div className="font-weight-5">{text}</div>,
    },
    {
      title: "Số lượng",
      dataIndex: "count",
      key: "count",
      align: "right",
    },
  ];

  const exportPayload = {
    from: dayjs(filterValues.dateFrom).toISOString(),
    to: dayjs(filterValues.dateTo).toISOString(),
    courseLevelIds: filterValues?.courseLevelIds,
    industryGroupIds: filterValues?.industryGroupIds,
    courseIds: filterValues?.courseIds,
    timeUnit: filterValues.timeUnit,
    teacherIds: filterValues?.lecturerIds,
    universityIds: filterValues?.universityIds,
  };

  return (
    <>
      <section className="section-table--chart">
        <div className="table-chart--label">
          <p className="text">Thống kê khoá học</p>
        </div>
        <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
          <Col sm={{ span: 24 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượng khoá học theo hình thức xuất bản"
              content={
                <Spin spinning={loading}>
                  {dataListCourseByIndustryGroup.length > 0 ? (
                    <TableWithTotals
                      totalLabel={"Tổng"}
                      data={
                        createTableData(dataListCourseByIndustryGroup)
                          .dataSource
                      }
                      columns={[
                        {
                          title: "Khoa",
                          dataIndex: "name",
                          key: "name",
                        },
                        ...createTableData(
                          dataListCourseByIndustryGroup,
                          "right"
                        ).columns,
                        {
                          title: "Tổng",
                          dataIndex: "Tổng",
                          key: "Tổng",
                          render: (text: any) => (
                            <div className="font-weight-5">{text}</div>
                          ),
                          align: "right",
                        },
                      ]}
                      keyColumn="criteria"
                      height={275}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "Excel",
                  action: () =>
                    handleExportReport(
                      exportTableCourseFormatUniversityAction,
                      "Số lượng khoá học theo hình thức xuất bản",
                      "excel",
                      exportPayload
                    ),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportReport(
                      exportTableCourseFormatUniversityAction,
                      "Số lượng khoá học theo hình thức xuất bản",
                      "pdf",
                      exportPayload
                    ),
                },
              ]}
            />
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượng khóa học theo giảng viên"
              content={
                <Spin spinning={loading}>
                  {dataListCourseByTeacherUniversity.length > 0 ? (
                    <TableWithTotals
                      totalLabel={"Tổng"}
                      data={
                        createTableData(dataListCourseByTeacherUniversity)
                          .dataSource
                      }
                      columns={[
                        {
                          title: "Giảng viên",
                          dataIndex: "name",
                          key: "name",
                        },
                        ...createTableData(
                          dataListCourseByTeacherUniversity,
                          "right"
                        ).columns,
                        {
                          title: "Tổng",
                          dataIndex: "Tổng",
                          key: "Tổng",
                          render: (text: any) => (
                            <div className="font-weight-5">{text}</div>
                          ),
                          align: "right",
                        },
                      ]}
                      keyColumn="criteria"
                      height={275}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "Excel",
                  action: () =>
                    handleExportReport(
                      exportTableTeacherAssignByInstruct,
                      "Số lượng khóa học theo giảng viên",
                      "excel",
                      exportPayload
                    ),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportReport(
                      exportTableTeacherAssignByInstruct,
                      "Số lượng khóa học theo giảng viên",
                      "pdf",
                      exportPayload
                    ),
                },
              ]}
            />
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượng bài giảng theo khoa"
              content={
                <Spin spinning={loading}>
                  {dataListUnitByIndustryGroup.length > 0 ? (
                    <TableWithTotals
                      totalLabel={"Tổng"}
                      data={dataListUnitByIndustryGroup}
                      columns={tableCourseByDepartmentColumn}
                      keyColumn="type"
                      height={275}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "Excel",
                  action: () =>
                    handleExportReport(
                      exportTableUnitByIndustryGroup,
                      "Số lượng bài giảng theo khoa",
                      "excel",
                      exportPayload
                    ),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportReport(
                      exportTableUnitByIndustryGroup,
                      "Số lượng bài giảng theo khoa",
                      "pdf",
                      exportPayload
                    ),
                },
              ]}
            />
          </Col>
        </Row>
      </section>
      <section className="section-table--chart">
        <div className="table-chart--label">
          <p className="text">Thống kê số lượng học liệu</p>
        </div>
        <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượng bài kiểm tra"
              content={
                <Spin spinning={loading}>
                  {dataListUniversityTestByType.length > 0 ? (
                    <TableWithTotals
                      totalLabel={"Tổng"}
                      data={dataListUniversityTestByType}
                      columns={tableColumn}
                      keyColumn="type"
                      height={275}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "Excel",
                  action: () =>
                    handleExportReport(
                      exportTableTestByIndustryGroup,
                      "Số lượng bài kiểm tra",
                      "excel",
                      exportPayload
                    ),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportReport(
                      exportTableTestByIndustryGroup,
                      "Số lượng bài kiểm tra",
                      "pdf",
                      exportPayload
                    ),
                },
              ]}
            />
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượng tài liệu tham khảo"
              content={
                <Spin spinning={loading}>
                  {dataListUniversityReferenceSourceByType.length > 0 ? (
                    <TableWithTotals
                      totalLabel={"Tổng"}
                      data={dataListUniversityReferenceSourceByType}
                      columns={tableQuantityDataColumn}
                      keyColumn="type"
                      height={275}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "Excel",
                  action: () =>
                    handleExportReport(
                      exportTableUniversityReferenceSourceByType,
                      "Số lượng tài liệu tham khảo",
                      "excel",
                      exportPayload
                    ),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportReport(
                      exportTableUniversityReferenceSourceByType,
                      "Số lượng tài liệu tham khảo",
                      "pdf",
                      exportPayload
                    ),
                },
              ]}
            />
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượng Scorm & xAPI"
              content={
                <Spin spinning={loading}>
                  {dataListUniversityScormXapiUnit.length > 0 ? (
                    <TableWithTotals
                      totalLabel={"Tổng"}
                      data={dataListUniversityScormXapiUnit}
                      columns={tableQuantityDataColumn}
                      keyColumn="type"
                      height={275}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "Excel",
                  action: () =>
                    handleExportReport(
                      exportTableUniversityScormUnit,
                      "Số lượng Scorm & xAPI",
                      "excel",
                      exportPayload
                    ),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportReport(
                      exportTableUniversityScormUnit,
                      "Số lượng Scorm & xAPI",
                      "pdf",
                      exportPayload
                    ),
                },
              ]}
            />
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượng học liệu đa phương tiện"
              content={
                <Spin spinning={loading}>
                  {dataListUniversityMultiMediaUnit.length > 0 ? (
                    <TableWithTotals
                      totalLabel={"Tổng"}
                      data={dataListUniversityMultiMediaUnit}
                      columns={tableQuantityDataColumn}
                      keyColumn="type"
                      height={275}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "Excel",
                  action: () =>
                    handleExportReport(
                      exportTableMultiMediaUnit,
                      "Số lượng học liệu đa phương tiện",
                      "excel",
                      exportPayload
                    ),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportReport(
                      exportTableMultiMediaUnit,
                      "Số lượng học liệu đa phương tiện",
                      "pdf",
                      exportPayload
                    ),
                },
              ]}
            />
          </Col>
        </Row>
      </section>
    </>
  );
};

export default ResourceTable;
