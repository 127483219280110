import {EyeOutlined, FileDoneOutlined, MoreOutlined, ThunderboltOutlined,} from "@ant-design/icons";
import {Button, Dropdown, Form, Layout, message, Modal, Space, TableColumnsType, TableProps,} from "antd";
import {isEmpty} from "lodash";
import React, {ChangeEvent, KeyboardEvent, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import FormFilter from "../../../../components/course-browse/publish-course/filter-group/FormFilter";
import PageHeader from "../../../../components/page-header/PageHeader";
import TableData from "../../../../components/table-data/TableData";
import {routesConfig} from "../../../../config/routes";
import {assignMultiCourse,} from "../../../../service/assign-course-construction";
import {formatOriginTimev3} from "../../../../utils/converDate";
import "./index.scss";
import FilterDataPublishCourse from "../../../../components/course-browse/publish-course/filter-table/FilterTable";
import {
  getAllPublishCourse,
  getCostCourse,
  getDataGeneral,
  publishContentCourse,
  unPublishContentCourse
} from "../../../../service/content-course/contenCourseApi";
import PublishContentCourseModal from "../../../../components/modal/publish-content-course-modal";
import {renderStatusCourse} from "../../../../utils/functionUltils";

type Props = {
  title?: string;
};

const PublishCoursePage = (props: Props) => {
  const [tab, setTab] = useState("1");
  const [listData, setListData] = useState<any>([])
  const {title} = props;
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [approvedTotal, setApprovedTotal] = useState<number>(0);
  const [pendingApprovalTotal, setPendingApprovalTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [clearForm, setClearForm] = useState<boolean>(false);
  const [openApprovalContentModal, setOpenApprovalContentModal] = useState<boolean>(false);
  const [quickReview, setQuickReview] = useState(false)
  const [quickReviewId, setQuickReviewId] = useState<string>("");
  const [openPublishContentModal, setOpenPublishContentModal] = useState(false);
  const [clearRowkey, setClearRowkey] = useState<boolean>(false);
  const [filtersCleared, setFiltersCleared] = useState(false);

  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [courseApprovalList, setCourseApprovalList] = useState([]);
  const [dataConfigCourse, setDataConfigCourse] = useState([]);
  const [dataConfigCostCourse, setDataConfigCostCourse] = useState({});
  const [filterData, setFilterData] = useState<any>({
    status: null,
    courseType: null,
    approvedDate: null,
    courseFormats: []
  });

  const navigate = useNavigate();
  const location = useLocation();


  const [form] = Form.useForm();

  const handleOpenCreate = () => {
    if (isEmpty(selectedRowKeys)) {
      message.info("Vui lòng chọn khoá học phê duyệt");
      return;
    }
    setIsOpened(true);
  };

  const formatDateWithTime = (dateString: string | null): string | null => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split('-');
    const formattedDate = `${year}-${month}-${day}`;
    return `${formattedDate}T00:00:00.000Z`;
  }
  const getListData = () => {
    setLoading(true);
    const approvedDate = filterData.approvedDate?.filter((item: any) => !!item);
    const data = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue || undefined,
      publicDateFrom: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[0]) ? formatDateWithTime(approvedDate[0]) : null,
      publicDateTo: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[1]) ? formatDateWithTime(approvedDate[1]) : null,
      courseType: filterData.courseType ? filterData.courseType : [],
      courseFormats: filterData.courseFormats ? filterData.courseFormats : [],
      status: filterData.status ? [filterData.status] : [12, 13],
      sort: statusOrder ? [`${fieldSortOrder},${statusOrder}`] : [],
    };
    getAllPublishCourse(data).then((res) => {
      setListData(res.data?.content)
      setTotalRecords(res.data?.totalElements)
      setLoading(false)
    })
  };


  const getDataConfigCourse = async (id: number) => {
    setLoading(true);
    try {
      const res = await Promise.all([getDataGeneral(id), getCostCourse(id)])
      const dataConfig = res[0].data.data;
      const dataCost = res[1].data.data;
      setDataConfigCourse(dataConfig);
      setDataConfigCostCourse(dataCost);
      setLoading(false)
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (location.state?.tab) {
      setTab(location.state?.tab);
    }
  }, [navigate]);

  useEffect(() => {
    getListData();
  }, [tab, pageNumber, pageSize, statusOrder, form, filtersCleared]);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleSubmitSearch = () => {
    if (pageNumber === 1) {
      getListData();
    } else {
      setPageNumber(1);
    }
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      if (pageNumber === 1) {
        getListData();
      } else {
        setPageNumber(1);
      }
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      getListData();
    }
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const clearFilter = () => {
    // setLoading(true)
    form.resetFields();
    setFilterData({
      status: null,
      courseType: null,
      approvedDate: null,
      courseFormats: []
    });
    setFiltersCleared(prev => !prev)
  };

  const revokeContentCourse = async (id: string) => {
    const data = {
      courseIds: [id]
    }
    await unPublishContentCourse(data).then((res) => {
      if (res.status === 200) {
        getListData()
      }
    })
  }
  const optionsMenu = (slideStatus: number) => {
    return [
      ...(slideStatus !== 13
        ? [
          {
            key: "1",
            label: "Xuất bản",
            icon: <FileDoneOutlined/>,
          },
        ]
        : [
          {
            key: "2",
            label: "Thu hồi",
            icon: <ThunderboltOutlined/>,
          }
        ]),
      {
        key: "3",
        label: "Kết quả duyệt",
        icon: <ThunderboltOutlined/>,
      }
    ]
  }


  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Mã khoá học",
      dataIndex: "code",
      key: "code",
      sorter: true,
      width: 20,
    },
    {
      title: "Tên khoá học",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 30,
      render: (_: any, record) => {
        return (
          <div className="table-order-course-name">
            <img
              className="item-carousel__image"
              loading="eager"
              alt=""
              src={record?.image || "/images/ic_school_placeholder.svg"}
            />
            <div>
              <span className="course-title">{record?.name}</span>
              <span>{record?.enterpriseName}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: 20,
      render: (_: any, record: any) => {
        return (
          <span className="flex items-center">
            {renderStatusCourse(record?.status)}
          </span>
        );
      },
    },

    {
      title: "Loại khoá học",
      dataIndex: "courseType",
      key: "courseType",
      width: 20,
      render: (_: any, record: any) => {
        return (
          <>
            <div className="table-course-type">
              {record.courseType === 2 ? (
                <div className="table-course-type-2">Hợp tác xây dựng</div>
              ) : record.courseType === 1 ? (
                <div className="table-course-type-1">Tự triển khai</div>
              ) : <div className="table-course-type-3">Dùng chung</div>}
            </div>
          </>
        );
      },
    },
    {
      title: "Hình thức khoá học",
      dataIndex: "courseFormat",
      key: "courseFormat",
      width: 20,
      render: (_: any, record: any) => {
        return (
          <>
            <div className="table-course-type">
              {record.courseFormat === 2 ? (
                <div>Riêng tư</div>
              ) : record.courseFormat === 1 ? (
                <div>Thẻ ghi danh</div>
              ) : (
                <div>Tự do</div>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Ngày xuất bản",
      dataIndex: "publicDate",
      key: "publicDate",
      width: 20,
      render: (_: any, record: any) => {
        return (
          <>
            {record?.approveDate !== null
              ? formatOriginTimev3(record?.approveDate)
              : ""}
          </>
        );
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "createDate",
      key: "createDate",
      width: 20,
      render: (_: any, record: any) => {
        return (
          <>
            {" "}
            {record?.createDate !== null
              ? formatOriginTimev3(record?.createDate)
              : ""}
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };
        return (
          <>
            {tab === "1" ?
              <Space>
                <a
                  className="action-table"
                  onClick={() =>
                    navigate(
                      `${routesConfig.detailPublishCourse}/${location.id}`,
                      {
                        state: {
                          ...record,
                          isCourseEndorse: true,
                          name: record?.name,
                          mode: 'view',
                        },
                      }
                    )
                  }
                >
                  <EyeOutlined/>
                </a>

                <Dropdown
                  menu={{
                    items: optionsMenu(record?.status),
                    onClick: ({key}) => {
                      if (key === "1") {
                        setOpenPublishContentModal(true)
                        setQuickReviewId(record?.id)
                        getDataConfigCourse(record?.id)
                      }
                      if (key === "2") {
                        Modal.confirm({
                          title: `Xác nhận thu hồi`,
                          content: `Bạn có chắc chắn muốn thu hồi của khoá học ${record?.name}?`,
                          onOk: () => {
                            revokeContentCourse(record.id);
                          },
                          okText: "Xác nhận",
                          cancelText: "Huỷ",
                          centered: true,
                          // icon: <ExclamationCircleOutlined style={{ color: "#1677FF" }} />,
                          footer: (_, {OkBtn, CancelBtn}) => (
                            <>
                              <CancelBtn/>
                              <OkBtn/>
                            </>
                          ),
                        });
                      }
                      if (key === "3") {
                        navigate(
                          `${routesConfig.detailPublishCourse}/${location.id}`,
                          {
                            state: {
                              ...record,
                              isCourseEndorse: true,
                              tab: "3"
                            },
                          }
                        )
                      }
                    },
                  }}
                  placement="bottomRight"
                >
                  <Button>
                    <MoreOutlined/>
                  </Button>
                </Dropdown>
              </Space> : null
            }
          </>
        );
      },
      fixed: "right",
      width: 10,
    },
  ];


  const defaultCheckedList = dataColumns.map((item) => item.key as string);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const listBreadcrumb = [
    {
      title: "Quản trị cơ sở",
    },
  ];

  const onChange = (key: string) => {
    setTab(key);
    setFilterData({});
    setPageNumber(1);
    form.resetFields();
  };

  const confirmModal = () => {
    message.success("Phân công khoá học thành công");
    setIsOpened(!isOpened);
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({...filterData, [name]: value});
  };

  const saveMultiAssign = async (data: object) => {
    await assignMultiCourse(data).then((res) => {
      if (res.status === 200) {
        message.success("Phân công thành công khoá học");
        setIsOpened(!isOpened);
        getListData();
      }
    });
  };

  const publishCourse = async () => {
    const arr = selectedRowKeys.map((item: any) => item?.id)
    const data = {
      courseIds: arr
    }
    await publishContentCourse(data).then((res) => {
      if (res.status === 200) {
        message.success("Xuất bản khoá học thành công")
        setClearRowkey(true)
        getListData()
      }
    })
  }

  return (
    <Layout className="page-header-group public-course">
      <Form.Provider
        onFormFinish={(name, {values, forms}) => {
          if (name === "create-course-plan-modal") {
            const listRowKey = selectedRowKeys.map((item: any) => {
              return item.id;
            });
            const data = {
              courseIds: listRowKey,
              assigners: values.lecturerName,
            };
            saveMultiAssign(data);
            getListData();
            setClearForm(true);
          }
          if (name === "filterForm") {
            getListData();
          }
        }}
      >
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
          }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb}/>
            </div>
            <FilterDataPublishCourse
              publishCourse={publishCourse}
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              dataColumns={dataColumns}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
              selectedRowKeys={selectedRowKeys}
              // tab={tab}
            />
          </div>
          <FormFilter
            handleCloseFilter={handleCloseFilter}
            clearFilter={clearFilter}
            form={form}
            showFilter={showFilter}
            filterData={filterData}
            setFilterData={handleChangeFilter}
            setPageNumber={setPageNumber}
          />
        </div>

        <TableData
          dataTable={listData}
          dataColumns={dataColumns}
          setPageNumber={setPageNumber}
          hideSelection={true}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          loadingTable={loading}
          scroll={{x: 1500, y: 600}}
          totalRecords={totalRecords}
          setSelectedRowKeysProps={setSelectedRowKeys}
          handleTableChangeProps={handleTableChange}
          pagination={true}
          clearRowkey={clearRowkey}
        />

        <PublishContentCourseModal
          dataGeneralConfig={dataConfigCourse}
          dataGeneralCostConfig={dataConfigCostCourse}
          open={openPublishContentModal}
          handleClose={() => setOpenPublishContentModal(false)}
          getListData={getListData}
          quickReviewId={quickReviewId}
          setClearRowkey={setClearRowkey}
        />

      </Form.Provider>


    </Layout>
  );
};

export default PublishCoursePage;
