import {
  Button,
  Col,
  Form,
  message,
  Modal,
  Row,
  TableColumnsType,
  TableProps,
  Typography,
} from "antd";
import PageHeader from "../../../../../components/page-header/PageHeader";
import TableData from "../../../../../components/table-data/TableData";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import "./import-industry-db-modal.scss";
import FormInput from "../../../../../components/form-input/FormInput";
import { SaveOutlined, SearchOutlined } from "@ant-design/icons";
import {
  getStudentMoocSynchronizationDataByFilter,
  importApprovedStudentMoocSynchronizationData,
} from "../../../../../service/category-management/student-management";

type Props = {
  openImportStudentModal: boolean;
  handleCloseImportStudentModal: () => void;
};

const ImportIndustryDBModal = (props: Props) => {
  const { openImportStudentModal, handleCloseImportStudentModal } = props;
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [listDataStudent, setListDataStudent] = useState<any>();

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 5,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Tên sinh viên",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 20,
    },
    {
      title: "Mã sinh viên",
      dataIndex: "code",
      key: "code",
      width: 10,
    },
    {
      title: "Cơ sở đào tạo",
      dataIndex: "universityName",
      key: "universityName",
      width: 30,
    },
    // {
    //   title: "Chuyên ngành",
    //   dataIndex: "specialized",
    //   key: "specialized",
    //   width: 30,
    // },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   key: "email",
    //   width: 30,
    // },
  ];

  const listBreadcrumb = [
    {
      title: "Quản lý sinh viên",
    },
  ];

  // sort
  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  // Search
  const handleSubmitSearch = () => {
    setPageNumber(1);
    if (pageNumber === 1) {
      fetchData();
    }
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setPageNumber(1);
      if (pageNumber === 1) {
        fetchData();
      }
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };

  // fetch data
  const fetchData = async () => {
    setLoading(true);
    const reqData = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue,
    };
    await getStudentMoocSynchronizationDataByFilter(reqData)
      .then((res) => {
        const listStudent = res.data.data.moocUniversityMemberData;
        const newListStudent = listStudent?.map((item: any, index: number) => ({
          ...item,
          key: index + 1,
        }));

        setListDataStudent(newListStudent);
        setTotalRecords(res.data.data.total);
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      })
      .catch((err) => {
        {
          setLoading(false);
          console.error(err);
        }
      });
  };

  // Import Student
  const handleImportStudent = async () => {
    setLoading(true);
    const listStudentImport = selectedRowKeys?.map((item: any) => item.id);
    await importApprovedStudentMoocSynchronizationData({
      ids: listStudentImport,
    })
      .then((res) => {
        if (res.status === 200) {
          message.success("Thêm mới sinh viên thành công !");
          setSelectedRowKeys([]);
          fetchData();
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const handleSaveStudent = () => {
    Modal.confirm({
      title: `Thêm mới sinh viên`,
      content: `Xác nhận thêm mới sinh viên đã chọn từ CSDL ngành?`,
      onOk: () => handleImportStudent(),
      centered: true,
      okText: "Xác nhận",
      cancelText: "Huỷ bỏ",
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
    });
  };

  // useEffect
  useEffect(() => {
    fetchData();
  }, [pageNumber, pageSize]);

  return (
    <Modal
      title="Thêm sinh viên từ CSDL ngành"
      className="list-student-import"
      open={openImportStudentModal}
      onCancel={handleCloseImportStudentModal}
      footer={null}
    >
      <Form.Provider onFormFinish={(name, { values, forms }) => {}}>
        <div className={`page-header-group--layout`}>
          <div className="table-header--wrapper modal-import-header">
            <div className="table-header--left">
              <PageHeader
                title={"Danh sách sinh viên"}
                listBreadcrumb={listBreadcrumb}
              />
            </div>
            <div>
              <Button
                className="btn btn-primary"
                onClick={handleSaveStudent}
                disabled={!(selectedRowKeys.length > 0)}
              >
                <SaveOutlined />
                <Typography.Text>Lưu</Typography.Text>
              </Button>
            </div>
          </div>
        </div>
        <div className="tabs-heading">
          <Row className="page-header-group--layout modal-import-search">
            <Col
              md={{ span: 7 }}
              lg={{ span: 7 }}
              xl={{ span: 7 }}
              xs={{ span: 7 }}
            >
              <div className="heading-search">
                <FormInput
                  placeholder="Nhập từ khóa tìm kiếm..."
                  value={searchValue}
                  onChange={handleChangeInput}
                  onKeyPress={handleSearch}
                  afterPrefixIcon={
                    <SearchOutlined onClick={handleSubmitSearch} />
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
        <TableData
          dataTable={listDataStudent}
          dataColumns={dataColumns}
          setSelectedRowKeysProps={setSelectedRowKeys}
          rowSelected={selectedRowKeys}
          unit="bản ghi"
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalRecords}
          loadingTable={loading}
          hideSelection={true}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
    </Modal>
  );
};

export default ImportIndustryDBModal;
