import { FunctionComponent, ReactNode } from "react";
import "./page-header.scss";
import { Breadcrumb, Button, Modal, Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, CloseOutlined, DeleteOutlined, SaveOutlined, WarningOutlined, } from "@ant-design/icons";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";
import { usePreventRouteChange } from "../../hooks/usePreventRouteChange";

type PageHeaderType = {
  arrowLeft?: string;
  subTitle?: string;
  title?: string | null;
  removeButton?: boolean;
  headerGroupButton?: boolean;
  accountConfiguration?: boolean;
  tabs?: boolean;
  breadcrumb?: boolean;
  positionItem?: string;
  listBreadcrumb?: ItemType[];
  childrenGroupButton?: ReactNode;
  stateBack?: any;
  cancelSave?: () => void;
  saveModalConfirm?: () => void;
};


const PageHeader: FunctionComponent<PageHeaderType> = ({
  arrowLeft,
  title,
  removeButton,
  tabs,
  breadcrumb,
  positionItem,
  listBreadcrumb,
  childrenGroupButton,
  headerGroupButton,
  subTitle,
  accountConfiguration,
  cancelSave,
  saveModalConfirm
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { confirmNavigation } = usePreventRouteChange();

  const removeModal = () => {
    Modal.confirm({
      title: "Xoá tài khoản",
      content:
        "Mọi dữ liệu tài khoản đã xoá sẽ không thể khôi phục. Bạn chắc chắn muốn xoá tài khoản?",
      icon: <WarningOutlined />,
      okType: "danger",
      cancelText: "Huỷ",
      okText: "Xác nhận",
      wrapClassName: "modal-custom",
      onCancel: () => {
        // cancel action here
      },
    });
  };

  const cancelModal = () => {
    Modal.confirm({
      title: "Xác nhận hủy",
      content:
        "Bạn có chắc chắn muốn hủy các thông tin vừa chỉnh sửa?",
      // icon: <WarningOutlined />,
      okType: "primary",
      cancelText: "Đóng",
      okText: "Xác nhận",
      wrapClassName: "modal-custom",
      onOk: () => {
        if (cancelSave) {
          cancelSave()
        }
      },
    });
  };

  const saveModal = () => {
    if (saveModalConfirm) {
      saveModalConfirm()
    }
  }

  const pathName = location.pathname

  return (
    <div className={`page-header ${positionItem ? "item-vertical" : ""} ${!breadcrumb ? "only-title" : ""}`} id="page-header">
      {breadcrumb && <Breadcrumb items={listBreadcrumb} />}
      <div className="page-heading">
        <div className="heading-left">
          {arrowLeft && location.state?.modeback !== 'backState' && location.state !== null && (
            <ArrowLeftOutlined
              className="arrowleft-icon"
              onClick={() => {
                confirmNavigation({ link: '' }, false, () => {
                  navigate(-1);
                });
              }}
            />
          )}
          {arrowLeft && location.state?.modeback === 'backState' && location.state !== null && (
            <ArrowLeftOutlined
              className="arrowleft-icon"
              onClick={() => {
                confirmNavigation({ link: location.state.backLink, path: { state: location.state } });
              }}
            />
          )}
          {/* <h3 className="title54">{`${subTitle ?? ''} ${title}`}</h3> */}
          <div className="flex">
            <p className="title54">{subTitle}</p>
            <div className="title54" dangerouslySetInnerHTML={{ __html: `${title}` }}></div>
          </div>
        </div>
        {removeButton && (
          <div className="heading-right">
            <Button className="btn btn-remove" onClick={removeModal}>
              <DeleteOutlined />
              <Typography.Text>Xoá tài khoản</Typography.Text>
            </Button>
          </div>
        )}
        {headerGroupButton && (<div className="heading-right">{childrenGroupButton}</div>)}
        {accountConfiguration && (
          <div className="heading-right flex gap-2">
            <Button className="" onClick={cancelModal}>
              <CloseOutlined />
              <Typography.Text>Hủy</Typography.Text>
            </Button>
            <Button className="" type="primary" onClick={saveModal}>
              <SaveOutlined />
              <Typography.Text>Lưu</Typography.Text>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PageHeader;
