import { RightOutlined } from "@ant-design/icons";
import { Flex } from "antd";
import React, { ReactNode, useState } from "react";
import "./style.scss";

interface SingleMenuCustomProps {
  title?: string | ReactNode;
  children?: ReactNode;
  className?: string;
  alignItem?: string;
  iconClassName?: string;
  titleClassName?: string;
  onOpen?: (mode?: any) => void;
}

function SingleMenuCustom({
  title,
  children = <></>,
  className = "",
  alignItem = "center",
  iconClassName = "",
  titleClassName = "",
  onOpen = () => {},
}: SingleMenuCustomProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div className={`single-menu-custom ${className}`}>
      <Flex
        className={`menu-title ${titleClassName}`}
        align={alignItem}
        gap={8}
        onClick={() => {
          setIsOpen(!isOpen);
          onOpen(!isOpen);
        }}
      >
        <RightOutlined
          className={`menu-icon-arrow ${
            isOpen ? "menu-custom-icon-open" : ""
          } ${iconClassName}`}
        />
        <div className="text-20">{title}</div>
      </Flex>
      {isOpen && <div className="menu-content">{children}</div>}
    </div>
  );
}

export default SingleMenuCustom;
