import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";

export const getSettingAuthority: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/setting-authority/get-all-roles`);
};

export const getSettingAuthorityById: (
  id?: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/setting-authority/get/${id}`);
};

export const getPermissionsAuthority: (
  id?: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/setting-authority/get-permissions/${id}`);
};

export const getSettingAuthorityGroup: (
  courseId?: string,
  filterParam?: any
) => Promise<AxiosResponse<any>> = (courseId, filterParam) => {
  return axiosConfig.get(
    `/setting-authority/get-groups/${courseId}?skip=${filterParam?.skip}&take=${filterParam?.take}&search=${filterParam?.search}&course_id=${filterParam?.course_id}`
  );
};

export const getAuthorityGroupScoring: (
  courseId?: string,
  filterParam?: any
) => Promise<AxiosResponse<any>> = (courseId, filterParam) => {
  return axiosConfig.get(
    `/setting-score-authority/get-type/${courseId}?type=${filterParam?.type}`
  );
};

export const getAuthorityGroupAttendance: (
  courseId?: string,
  filterParam?: any
) => Promise<AxiosResponse<any>> = (courseId, filterParam) => {
  return axiosConfig.get(
    `/setting-authority/get-type/${courseId}?type=${filterParam?.type}`
  );
};

export const getSettingUser: (
  courseId?: string
) => Promise<AxiosResponse<any>> = (courseId) => {
  return axiosConfig.get(`/setting-authority/get-many-users/${courseId}?is_assign=1`);
};

export const createSettingAuthority: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/setting-authority/create`, dataReq);
};

export const getSettingAuthorityAllAttendance: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/setting-authority/many-users`, dataReq);
};

export const getSettingAuthorityAllExam: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/setting-score-authority/many-users`, dataReq);
};

export const updateSettingAuthority: (
  id: string,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(`/setting-authority/update/${id}`, dataReq);
};

export const updateSettingAuthorityExam: (
  id: string,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(`/setting-score-authority/update/${id}`, dataReq);
};
export const deleteSettingAuthority: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.delete(`/setting-authority/delete/${id}`);
};
