import { Checkbox, Flex, Form, Radio } from "antd";
import {
  JSON_SUB_TYPE,
  QuestionBeProps,
  QuestionProps,
  QuestionPropsV2,
} from "../../../../../types/course";
import { QuestionTypeEnum } from "../../../../../constants";
import { convertNumberingTypeToText } from "../../../../../utils/format";
import EmptyComponent from "../../../../../components/empty";

function ChooseVideo({ data, initialData, disabled }: QuestionPropsV2) {
  const placeholders = data
    ? 2 - (data.questions.length % 2 === 0 ? 2 : data.questions.length % 2)
    : null;
  return (
      <Radio.Group disabled={disabled}>
        <div className="flex layout-two-col">
          {data?.questions && data?.questions?.length > 0 ? (
            data?.questions?.map((quiz: QuestionBeProps, index) => (
              <div className="image-quiz-wrap" key={quiz.uuid + index}>
                <Radio id={quiz.uuid + index} value={quiz?.uuid} checked={quiz?.isTrue}>
                  <Flex className="font-size-16 line-height-24 font-weight-5 gap-1">
                    {data?.settingGeneral && convertNumberingTypeToText(
                      index + 1,
                      Number(JSON.parse(data?.settingGeneral).numberingType)
                    )}
                    .
                    <div
                      className="font-size-16 line-height-24"
                      dangerouslySetInnerHTML={{ __html: `${quiz?.content}` }}
                    ></div>
                  </Flex>
                </Radio>
                <video className="quest-video" controls>
                  <source src={quiz?.filePath || ""} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ))
          ) : (
            <div className="center">
              <EmptyComponent description="Không có nội dung" />
            </div>
          )}
          {Array.from({ length: placeholders || 0 }).map((_, index) => (
            <div
              key={`placeholder-${index}`}
              className={`image-quiz-wrap choose-correct placeholder`}
            />
          ))}
        </div>
      </Radio.Group>
  );
}

export default ChooseVideo;
