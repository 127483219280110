import {
  Dropdown,
  Flex,
  Form,
  Layout,
  MenuProps,
  Space,
  TableColumnsType,
  TableProps,
  Tooltip,
  Typography,
} from "antd";
import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState
} from "react";

import { useNavigate } from "react-router-dom";

import { FieldTimeOutlined, SettingOutlined } from "@ant-design/icons";

import { FormInstance } from "antd/lib";
import { isEmpty } from "lodash";
import PageHeader from "../../../components/page-header/PageHeader";
import TableData from "../../../components/table-data/TableData";
import { routesConfig } from "../../../config/routes";
import useDebounce from "../../../hooks/useDebounce";
import { Permission } from "../../../layout/sidebar/permission.enum";
import {
  getRcmHistory,
  getSystemSuggestCourseAll
} from "../../../service/system-suggest-course";
import { CheckRole, getPermissions } from "../../../utils/checkRole";
import FormFilter from "./components/filter-group/FormFilter";
import FilterTable from "./components/filter-table/FilterTable";

type LearningDocumentationSystemProps = {
  title?: string;
};

const LearningDocumentationSystem = ({
  title,
}: LearningDocumentationSystemProps) => {
  const navigate = useNavigate();

  const [documentSystem, setDocumentSystem] = useState<any[]>([]);
  const [dataFilter, setDataFilter] = useState({
    name: "",
    tags: [],
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [optionSelectTag, setOptionSelectTag] = useState([]);
  const [displayRoleSetting, setDisplayRoleSetting] = useState(false);
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("asc");
  const [keySearch, setKeySearch] = useState<string>("");
  const [isHistory, setIsHistory] = useState(false);

  const keywordDebounce = useDebounce(keySearch, 500);

  const [listPermission, setListPermission] = useState([]);

  const getRoles = async () => {
    const params = await getPermissions();
    const listPermissionConvert = JSON.parse(JSON.stringify(params));
    setListPermission(listPermissionConvert);
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    const roleSetting = [Permission.LearningResourcesSystemUpdate];

    const checkRoleSetting = CheckRole(roleSetting, listPermission);
    if (checkRoleSetting.includes(true)) {
      setDisplayRoleSetting(true);
    }
  }, [listPermission]);

  const fetchData = async (searchParam: any) => {
    try {
      const response = await getSystemSuggestCourseAll(searchParam);
      const { data } = response.data;
      setDocumentSystem(data?.data);
      setTotalPage(data?.count?._count?.id);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchListTag = async () => {
    try {
      const response = await getRcmHistory({skip: 0, take: 6, search: keywordDebounce});
      const { data } = response.data;
      setOptionSelectTag(
        data?.data?.map((tag: any) => ({
          label: <div>{data?.is_history && <FieldTimeOutlined />} <span>{tag?.name}</span></div>,
          value: tag?.id,
          title: tag?.name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData({
      name: searchValue,
      tags: [],
      sort: fieldSortOrder,
      take: pageSize,
      skip: (pageNumber - 1) * pageSize,
    });
  }, [pageSize, pageNumber, fieldSortOrder]);

  useMemo(() => {
    fetchListTag();
  },[keywordDebounce])
  const dataColumnsReportCheckIn: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 5,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Mã khoá học",
      dataIndex: "code",
      key: "code",
      width: 15,
    },
    {
      title: "Tên khoá học",
      dataIndex: "name",
      key: "name",
      width: 25,
      render: (_: any, record) => {
        return (
          <Flex className="table-order-course-name">
            <img
              className=""
              loading="eager"
              alt=""
              src={record?.image || "/images/ic_school_placeholder.svg"}
            />
            <div className="pl-2">
              <span className="course-title">{record?.name}</span>
            </div>
          </Flex>
        );
      },
      sorter: {
        compare: (a, b) => a.name.length - b.name.length,
      },
    },
    {
      title: "Thẻ tag",
      dataIndex: "tag",
      key: "tag",
      width: 30,
      render: (_, data: any) => {
        return (
          <div>
            {!isEmpty(data?.mooc_recommendation) ? (
              <Flex align="center">
                {data?.mooc_recommendation?.map((data: any, index: number) => {
                  if (index <= 2) {
                    return (
                      <div className="border rounded-md bg-[#FAFAFA] px-2 mr-1">
                        {data?.mooc_recommendation_tag?.name}
                      </div>
                    );
                  }
                })}
                {data?.mooc_recommendation?.length > 3 && (
                  <Tooltip
                    placement="topLeft"
                    title={data?.mooc_recommendation?.map(
                      (value: any, index: number) => {
                        if (
                          index >= 3 &&
                          value?.mooc_recommendation_tag?.name
                        ) {
                          return (
                            <span>
                              {value?.mooc_recommendation_tag?.name
                                ? value?.mooc_recommendation_tag?.name + ", "
                                : ""}
                            </span>
                          );
                        }
                      }
                    )}
                    className="border rounded-md bg-[#FAFAFA] px-2 mr-1"
                  >
                    +{data?.mooc_recommendation?.length - 3}
                  </Tooltip>
                )}
              </Flex>
            ) : (
              <div>Chưa có dữ liệu</div>
            )}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const idDocument = `${record?.id}`;

        const items: MenuProps["items"] = [
          {
            label: (
              <Flex
                onClick={() =>
                  navigate(
                    `/system-suggest-course/${routesConfig.infoCourseDetailSystem}/${idDocument}`
                  )
                }
              >
                <SettingOutlined />
                <Typography.Text className="pl-2">
                  Cài đặt mở rộng
                </Typography.Text>
              </Flex>
            ),
            key: "1",
          },
        ];
        return (
          <Space>
            {displayRoleSetting && (
              <Dropdown
                menu={{ items }}
                trigger={["click"]}
                className="action-table"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <SettingOutlined />
                </a>
              </Dropdown>
            )}
          </Space>
        );
      },
      fixed: "right",
      width: 8,
    },
  ];

  const defaultCheckedList = dataColumnsReportCheckIn.map(
    (item) => item.key as string
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const columnChecked = useMemo(() => {
    return dataColumnsReportCheckIn.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
  }, [checkedList, displayRoleSetting]);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };

  const handleSearch = async (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      const searchParam = {
        ...dataFilter,
        take: 10,
        skip: 0,
        name: searchValue,
      };
      setPageNumber(1);
      fetchData(searchParam);
    }
  };

  const handleSubmitSearch = () => {
    const searchParam = {
      ...dataFilter,
      take: 10,
      skip: 0,
      name: searchValue,
    };
    setPageNumber(1);
    fetchData(searchParam);
  };

  const clearFilter = async (formInstance: FormInstance) => {
    setLoading(true);
    setKeySearch("")
    setDataFilter({
      name: "",
      tags: [],
    });
    const searchParam = {
      tags: [],
      take: pageSize,
      skip: (pageNumber - 1) * pageSize,
      name: searchValue,
    };
    setPageNumber(1);
    fetchData(searchParam);
    formInstance.resetFields();
  };

  const handleFinish = (values: any) => {
    setLoading(true);

    const searchParam = {
      ...values,
      take: pageSize,
      skip: (pageNumber - 1) * pageSize,
      name: searchValue,
    };
    setPageNumber(1);
    fetchData(searchParam);
    fetchListTag();
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter?.order === "ascend"
        ? "asc"
        : sorter?.order === "descend"
        ? "desc"
        : "";
    setFieldSortOrder(data);
  };

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <div
          className={`page-header-group--layout ${
            showFilter ? "open-filter" : ""
          }`}
        >
          <div className="table-header--wrapper justify-between">
            <div className="table-header--left">
              <PageHeader title={"Danh sách học liệu"} />
            </div>
            <FilterTable
              searchValue={searchValue}
              dataColumns={dataColumnsReportCheckIn}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
              displayRoleSetting={displayRoleSetting}
            />
          </div>
          <FormFilter
            clearFilter={clearFilter}
            onFinish={handleFinish}
            showFilter={showFilter}
            optionSelectTag={optionSelectTag}
            setDataFilter={setDataFilter}
            setKeySearch={setKeySearch}
          />
        </div>
        <TableData
          dataTable={documentSystem}
          dataColumns={columnChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
    </Layout>
  );
};

export default LearningDocumentationSystem;
