import {
  Breadcrumb,
  Col,
  Flex,
  Form,
  Layout,
  Row,
  Modal,
  message,
  Spin,
  Button,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
import { toNumber } from "lodash";
import "./style.scss";
import { InfoCircleOutlined, LinkOutlined } from "@ant-design/icons";
import {
  approveTransaction,
  getApprovalHistories,
  getTransactionDetail,
} from "../../../service/transaction";
import {
  ApproveTransactionPayload,
  TransactionHistoryProps,
  TransactionProps,
} from "../../../types/transaction";
import moment from "moment";
import { formatCurrency } from "../../../utils/format";
import TransactionItem from "./TransactionItem";
import UpdateForm from "./UpdateForm";
import ApproveForm from "./ApproveForm";

function Transaction() {
  const params = useParams();
  const transactionId = toNumber(params?.id);
  const [mode, setMode] = useState<"non-confirm" | "confirmed" | "done">(
    "non-confirm"
  );
  const [transaction, setTransaction] = useState<TransactionProps>();
  const [transactionHistories, setTransactionHistories] = useState<
    TransactionHistoryProps[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isShowUpdateForm, setIsShowUpdateForm] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);
  const isApprove = transaction?.approvalStatus;
  const isShowBtnUpdate =
    transactionHistories[transactionHistories?.length - 1]?.status === false;

  useEffect(() => {
    if (isApprove && isShowBtnUpdate) {
      setMode("confirmed");
    }
    if (isApprove && !isShowBtnUpdate) {
      setMode("done");
    }
  }, [isApprove, isShowBtnUpdate]);

  useEffect(() => {
    const getTransaction = async () => {
      setLoading(true);
      try {
        const res = await getTransactionDetail(transactionId);
        setTransaction(res.data.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getTransaction();
  }, [transactionId, refetch]);

  useEffect(() => {
    if (isApprove) {
      const fetchApprovalHistories = async () => {
        try {
          setLoading(true);
          const res = await getApprovalHistories(transactionId);
          setTransactionHistories(res.data.data);
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      };
      fetchApprovalHistories();
    }
  }, [isApprove, refetch]);

  const breadcrumb = [
    {
      title: (
        <Link to={`${routesConfig.enrollmentConfirmation}`}>
          Danh sách giao dịch
        </Link>
      ),
    },
    {
      title: `${transactionId}`,
    },
  ];

  const updateTransaction = async (payload: ApproveTransactionPayload) => {
    Modal.confirm({
      title: "Xác nhận gửi cập nhật",
      type: "info",
      icon: <InfoCircleOutlined className="text-primary" />,
      content: (
        <>
          <p className="text-warning">
            Thông tin đã gửi sẽ không thể chỉnh sửa.
          </p>
          <p className="text-14">
            Đảm bảo tất cả các thông tin liên quan đã được kiểm tra và cập nhập
            đầy đủ trước khi lưu.{" "}
          </p>
        </>
      ),
      okText: "Xác nhận",
      cancelText: "Hủy",
      onOk: async () => {
        try {
          await approveTransaction(payload);
          setRefetch(!refetch);
          message.success("Cập nhật thông tin thành công");
          setMode("confirmed");
          if (payload?.receivedAmount) {
            setIsShowUpdateForm(false);
          }
        } catch (err) {
          console.log(err);
        }
      },
    });
  };

  const handleSend = async (fieldsValue: any) => {
    await updateTransaction({
      status: fieldsValue?.status,
      reason: fieldsValue?.reason,
      transactionId: transactionId,
    });
  };

  const handleUpdateTransaction = async (fieldsValue: any) => {
    await updateTransaction({
      status: fieldsValue?.status,
      reason: fieldsValue?.reason,
      transactionId: transactionId,
      billImage: fieldsValue?.billImage?.file?.response?.fileKey,
      receivedAmount: fieldsValue?.receivedAmount,
      additionalAmount: fieldsValue?.additionalAmount,
      refundAmount: fieldsValue?.refundAmount,
    });
  };

  const renderCardTitle = () => {
    switch (mode) {
      case "non-confirm":
        return (
          <Flex align="center" gap={8}>
            <div className="card-title text-16 font-weight-5 line-height-24">
              Phê duyệt giao dịch
            </div>
            <div className="transaction-mark mark-non-confirm">
              Chưa xác nhận
            </div>
          </Flex>
        );
      case "confirmed":
        return (
          <Flex align="center" gap={8}>
            <div className="card-title text-16 font-weight-5 line-height-24">
              Phê duyệt
            </div>
            <div className="transaction-mark mark-processing">Đang xử lý</div>
          </Flex>
        );
      case "done":
        return (
          <Flex align="center" gap={8}>
            <div className="card-title text-16 font-weight-5 line-height-24">
              Phê duyệt
            </div>
            <div className="transaction-mark mark-completed">Xác nhận</div>
          </Flex>
        );
      default:
        break;
    }
  };

  function sumNumbers(a: any, b: any) {
    if (isNaN(a) || isNaN(b)) {
      return 0;
    }

    return Number(a) + Number(b);
  }

  return (
    <Spin spinning={loading}>
      <Layout className="page-header-group">
        <Form.Provider>
          <div className="enrollment-page w-full">
            <div className="enrollment-page-header">
              <Breadcrumb items={breadcrumb} className="mb-1" />
              <div className="flex justify-between align-center gap-8">
                <h3 className="page-title flex-1">Phê duyệt giao dịch</h3>
              </div>
            </div>
            <div className="transaction-page-content">
              <Row gutter={[32, 32]}>
                <Col md={24} xl={12} xxl={12} lg={12}>
                  <div className="trans-card">
                    <div className="card-header">
                      <Flex align="center" justify="space-between">
                        <div className="card-title text-16 font-weight-5 line-height-24">
                          Thông tin giao dịch
                        </div>
                        <Flex
                          gap={6}
                          className="text-primary pointer font-weight-4 mr-3"
                        >
                          <LinkOutlined className="text-primary" />

                          <a
                            href={transaction?.billImage}
                            target="_blank"
                            className="text-primary"
                          >
                            Sao kê
                          </a>
                        </Flex>
                      </Flex>
                    </div>
                    <div className="card-content">
                      <div className="trans-card-item">
                        <span className="text-16 font-weight-5">
                          Mã giao dịch: {transaction?.id || "_"}
                        </span>
                        <Flex
                          align="center"
                          justify="space-between"
                          wrap="wrap"
                          className="mt-2"
                        >
                          <div className="text-info">
                            <span className="font-weight-6">Thời gian:</span>
                            <span className="font-weight-4">
                              {" "}
                              {transaction?.createdDate
                                ? moment(transaction?.createdDate).format(
                                    "HH:mm:ss - DD/MM/YYYY"
                                  )
                                : "---"}
                            </span>
                          </div>
                          <div className="text-info">
                            <span className="font-weight-6">
                              Email học viên:
                            </span>
                            <span className="font-weight-4">
                              {" "}
                              {transaction?.studentEmail}
                            </span>
                          </div>
                        </Flex>
                        <div className="break-line mt-2 mb-2"></div>

                        <Row>
                          <Col md={12}>
                            <Flex
                              className="text-14"
                              justify="space-between"
                              align="center"
                            >
                              <span className="font-weight-5">
                                Chi phí gốc:{" "}
                              </span>
                              <span>
                                {" "}
                                {sumNumbers(
                                  transaction?.certificatePrice,
                                  transaction?.coursePrice
                                )}{" "}
                                {`(VND)`}
                              </span>
                            </Flex>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col md={12}>
                            <Flex
                              className="text-14 text-info"
                              justify="space-between"
                              align="center"
                            >
                              <span>Giá khoá học: </span>
                              <span>
                                {" "}
                                {formatCurrency(transaction?.coursePrice)}{" "}
                                {`(VND)`}
                              </span>
                            </Flex>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col md={12}>
                            <Flex
                              className="text-14 text-info"
                              justify="space-between"
                              align="center"
                            >
                              <span>Giá chứng chỉ: </span>
                              <span>
                                {" "}
                                {formatCurrency(
                                  transaction?.certificatePrice
                                )}{" "}
                                {`(VND)`}
                              </span>
                            </Flex>
                          </Col>
                        </Row>
                        <div className="break-line mt-2 mb-2"></div>
                        <Row>
                          <Col md={12}>
                            <Flex
                              className="text-14"
                              justify="space-between"
                              align="center"
                            >
                              <span className="font-weight-5">
                                Tổng chi phí:{" "}
                              </span>
                              <span className="font-weight-5">
                                {sumNumbers(
                                  transaction?.certificatePrice,
                                  transaction?.coursePrice
                                )}{" "}
                                {`(VND)`}
                              </span>
                            </Flex>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={24} xl={12} xxl={12} lg={12}>
                  <div className="trans-card">
                    <div className="card-header">{renderCardTitle()}</div>
                    <div className="card-content">
                      {!isApprove && <ApproveForm onSubmit={handleSend} />}

                      {transactionHistories?.map(
                        (item: TransactionHistoryProps, index: number) => (
                          <TransactionItem
                            data={item}
                            key={index}
                            type={item.status ? "success" : "warning"}
                            className={
                              index !== transactionHistories?.length - 1
                                ? "mb-2"
                                : ""
                            }
                          />
                        )
                      )}

                      {isShowBtnUpdate && !isShowUpdateForm && (
                        <Flex justify="flex-end" className="w-full-100 mt-5">
                          <Button
                            loading={loading}
                            className="btn-primary"
                            onClick={() => setIsShowUpdateForm(true)}
                          >
                            Cập nhật
                          </Button>
                        </Flex>
                      )}

                      {isShowUpdateForm && (
                        <UpdateForm onSubmit={handleUpdateTransaction} />
                      )}
                      {/* 
                      <TransactionItem type="success" className="mb-2" />
                      <TransactionItem type="warning" /> */}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Form.Provider>
      </Layout>
    </Spin>
  );
}

export default Transaction;
