import React, { ReactNode, useEffect, useRef, useState } from "react";
import CustomModal from "../../../components/modal/custom-modal";
import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  UploadProps,
} from "antd";
import "./style.scss";
import FileUploader from "../../../components/file-uploader/file-uploader.component";
import { FileType, getBase64 } from "../../../utils/uploadImage";
import {
  InfoCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  createBankInfo,
  getBankInfo,
  getBankList,
} from "../../../service/transaction";
import { BankProps } from "../../../types/transaction";

interface BankInfoModalProps {
  children?: ReactNode;
}

function BankInfoModal({ children }: BankInfoModalProps) {
  const [form] = Form.useForm();
  const modalRef = useRef<{ open: () => void; close: () => void }>(null);

  const [loadingUpload, setLoadingUpload] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [banks, setBanks] = useState<BankProps[]>([]);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  useEffect(() => {
    const getBanks = async () => {
      const res = await getBankList();
      setBanks(res.data?.data);
    };
    const getBankInformation = async () => {
      const res = await getBankInfo();
      const bankInfo = res.data.data;
      form.setFieldsValue({
        bankId: bankInfo?.bankId,
        accountNumber: bankInfo?.accountNumber,
        email: bankInfo?.email,
        name: bankInfo?.accountName,
        qrCode: bankInfo?.qrCode,
      });
      setImageUrl(bankInfo?.qrCode);
    };
    getBankInformation();
    getBanks();
  }, []);

  const handleUpdateBankInfo = async (fieldsValue?: any) => {
    Modal.confirm({
      title: "Xác nhận lưu thông tin",
      type: "info",
      icon: <InfoCircleOutlined className="text-primary" />,
      content: (
        <>
          <p className="text-14">
            Đảm bảo tất cả các thông tin liên quan đã được kiểm tra và cập nhập
            đầy đủ trước khi lưu
          </p>
        </>
      ),
      okText: "Xác nhận",
      cancelText: "Hủy",
      onOk: async () => {
        try {
          setLoadingSubmit(true);
          await createBankInfo({
            bankId: fieldsValue?.bankId,
            accountNumber: fieldsValue?.accountNumber,
            email: fieldsValue?.email,
            name: fieldsValue?.name,
            qrCode: fieldsValue?.qrCode?.file?.response?.fileKey || "",
          });
          message.success("Lưu thông tin thành công");
          modalRef.current?.close();
        } catch (err) {
          console.log(err);
        } finally {
          setLoadingSubmit(false);
        }
      },
    });
  };

  const beforeUpload = (file: FileType) => {
    setLoadingUpload(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isJpgOrPng) {
      message.error("Ảnh yêu cầu định dạng jpeg/png!");
      setTimeout(() => {
        setLoadingUpload(false);
      }, 300);
    }
    if (!isLt2M) {
      message.error("Ảnh phải có dung lượng nhỏ hơn 2M!");
      setTimeout(() => {
        setLoadingUpload(false);
      }, 300);
    }

    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (info) => {
    if (info.file.status === "uploading") {
      setLoadingUpload(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as FileType, (url) => {
        setLoadingUpload(false);
        setImageUrl(url);
      });
    }
  };

  return (
    <div>
      <CustomModal
        ref={modalRef}
        closable
        footer={false}
        wrapClassName="bank-modal"
        title={
          <div className="text-16 font-weight-5">
            Thiết lập thông tin tài khoản nhận học phí
          </div>
        }
        okText="Lưu thông tin"
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        content={
          <div className="form-update-bank">
            <Form form={form} onFinish={handleUpdateBankInfo}>
              <Row gutter={8}>
                <Col md={6}>
                  <Flex gap={3} justify="flex-end" style={{ marginTop: 5 }}>
                    <div className="text-danger">*</div>
                    <span className="text-14">Ngân hàng:</span>
                  </Flex>
                </Col>
                <Col md={18}>
                  <Form.Item
                    name="bankId"
                    rules={[
                      { required: true, message: "Vui lòng chọn ngân hàng" },
                    ]}
                  >
                    <Select
                      options={banks?.map((item: BankProps) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      showSearch
                      filterOption={(input: any, option: any) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      placeholder="Tên ngân hàng"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mt-2" gutter={8}>
                <Col md={6}>
                  <Flex gap={3} justify="flex-end" style={{ marginTop: 5 }}>
                    <div className="text-danger">*</div>
                    <span className="text-14">Số tài khoản:</span>
                  </Flex>
                </Col>
                <Col md={18}>
                  <Form.Item
                    name="accountNumber"
                    rules={[
                      { required: true, message: "Vui lòng nhập số tài khoản" },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Vui lòng nhập số hợp lệ!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Nhập số tài khoản"
                      className="Nhập số tài khoản"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mt-2" gutter={8}>
                <Col md={6}>
                  <Flex gap={3} justify="flex-end" style={{ marginTop: 5 }}>
                    <div className="text-danger">*</div>
                    <span className="text-14">Chủ tài khoản:</span>
                  </Flex>
                </Col>
                <Col md={18}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập tên chủ tài khoản",
                      },
                    ]}
                  >
                    <Input placeholder="Tên của chủ tài khoản" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mt-2" gutter={8}>
                <Col md={6}>
                  <Flex gap={3} justify="flex-end" style={{ marginTop: 5 }}>
                    <div className="text-danger">*</div>
                    <span className="text-14">Email liên hệ:</span>
                  </Flex>
                </Col>
                <Col md={18}>
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "Nhập email liên hệ" },
                      {
                        type: "email",
                        message: "Email không hợp lệ!",
                      },
                    ]}
                  >
                    <Input placeholder="Nhập email liên hệ" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mt-2" gutter={8}>
                <Col md={6}>
                  <Flex gap={3} justify="flex-end">
                    <div className="text-danger">*</div>
                    <span className="text-14">Mã QR code:</span>
                  </Flex>
                </Col>
                <Col md={18}>
                  <Flex gap={8}>
                    <Form.Item
                      name="qrCode"
                      rules={[
                        { required: true, message: "Vui lòng tải lên mã QR" },
                      ]}
                    >
                      <FileUploader
                        listType="picture-card"
                        multiple
                        beforeUpload={beforeUpload}
                        showUploadList={false}
                        onChange={handleChange}
                      >
                        {imageUrl ? (
                          <img
                            className="bank-qr-code"
                            src={imageUrl}
                            alt="Bank QR code"
                          />
                        ) : (
                          <button
                            style={{ border: 0, background: "none" }}
                            type="button"
                          >
                            {loadingUpload ? (
                              <LoadingOutlined />
                            ) : (
                              <PlusOutlined />
                            )}
                            <div style={{ marginTop: 8 }}>Tải lên</div>
                          </button>
                        )}
                      </FileUploader>
                    </Form.Item>
                    <span className="text-gray">
                      Tải lên mã QR dùng để nhận học phí
                    </span>
                  </Flex>
                </Col>
              </Row>
              <Flex
                justify="flex-end"
                gap={8}
                className="mt-5 btn-group-footer"
              >
                <Button
                  className="btn-primary"
                  htmlType="submit"
                  loading={loadingSubmit}
                >
                  Lưu thông tin
                </Button>
              </Flex>
            </Form>
          </div>
        }
      >
        {children}
      </CustomModal>
    </div>
  );
}

export default BankInfoModal;
