import { CaretRightOutlined, DoubleRightOutlined, InfoCircleOutlined, } from "@ant-design/icons";
import type { RadioChangeEvent } from "antd";
import { Button, Collapse, message, Radio, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getApproveInformationDetail, getCourseEvaluationDetail, } from "../../service/course-construct";
import { approvedInformationTabStore } from "../../stores/approved-information-tab-store/slice";
import { objectToQueryString } from "../../utils/getPageFromLink";
import CommonModal from "../modal/common";
import "./approved-information-tab.scss";
import { putPublishCourse } from "../../service/content-course/contenCourseApi";
import { FORMAT_REACT_QUILL } from "../../constants";

type Props = {
  setTab?: (value: string) => void;
  tab?: string;
  screenText?: string;
  informationDetail?: any;
};

const { Title, Text } = Typography;
const { Panel } = Collapse;
const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ['formula']
  ],
};

const ApprovedInformationTabSlide = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { flag, toggleFlag } = approvedInformationTabStore();

  const { setTab, tab, screenText } = props;
  const [transferAppraisal, setTransferAppraisal] = useState(1);
  const [approvedResult, setApprovedResult] = useState(1);
  const [evaluationResults, setEvaluationResults] = useState(1);
  const [value, setValue] = useState("");
  const [appraisalBoardList, setAppraisalBoardList] = useState([]);
  const [appraisalBoard, setAppraisalBoard] = useState("");
  const [appriseGroupId, setAppriseGroupId] = useState("");
  const [lecturers, setLecturers] = useState("");
  const [isOpenMemberList, setIsOpenMemberList] = useState(false);
  const { id, appriseCourseId, resultAppriseId, appriseFormId } =
    location.state;
  const [approveInformationDetail, setApproveInformationDetail] =
    useState<Detail>();
  const [isConfirm, setIsConfirm] = useState(false);
  const [statusCourse, setStatusCourse] = useState(false);

  const params = useParams()

  const {
    isCourseApprove, // duyệt
    isCourseEvaluation, // thẩm định
    isCourseEndorse, //phê duyệt
    contentStatus,
    slideStatus,
    // isCourseEvaluationTotal, //tổng hợp thẩm định
    // isCourseApproveTotal, // tổng hợp phê duyệt
    status,
  } = location.state;

  const getDetailData = async () => {
    const params = {
      courseId: id,
      type: 0,
    };
    const paramsGet = objectToQueryString(params);
    const urlLink = isCourseApprove
      ? getApproveInformationDetail(id)
      : isCourseEvaluation
        ? getCourseEvaluationDetail(paramsGet)
        : getCourseEvaluationDetail(paramsGet);
    await urlLink.then((res: any) => {
      const { data } = res;
      setApproveInformationDetail(data);
    });
  };

  useEffect(() => {
    getDetailData();
  }, []);

  const onChangeApprovedResult = (event: RadioChangeEvent) => {
    setApprovedResult(event.target.value);
  };

  const arrSection = [
    {
      title: "Thông tin gửi",
      content: (
        <div>
          <div className="list-item">
            <Title level={5} className="list-item--title">
              Trường chủ trì:
            </Title>
            <div className="list-item--description">
              <Text className="content">
                {approveInformationDetail?.enterpriseName ||
                  approveInformationDetail?.enterpriseNameSponsor}
              </Text>
            </div>
          </div>

          <div className="list-item">
            <Title level={5} className="list-item--title">
              Người gửi:
            </Title>
            <div className="list-item--description">
              <Text className="content">
                {approveInformationDetail?.userNameSendApprove ||
                  approveInformationDetail?.fromUserName}
              </Text>
            </div>
          </div>
          <div className="list-item">
            <Title level={5} className="list-item--title">
              Ngày gửi:
            </Title>
            <div className="list-item--description">
              <Text className="content">
                {moment(
                  approveInformationDetail?.approveSendDate ||
                  approveInformationDetail?.createdDate
                ).format("DD/MM/YYYY")}
              </Text>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Duyệt trang trình bày",
      content: (
        <>
          {
            slideStatus !== 13 ?
              <>
                <Radio.Group
                  onChange={onChangeApprovedResult}
                  value={approvedResult}
                  style={{ display: "flex" }}
                >
                  <div className="radio-group-list">
                    <div className="wrapper-radio">
                      <Radio value={1}>Đồng ý</Radio>
                      <Radio value={2}>Không đồng ý</Radio>
                    </div>
                  </div>
                </Radio.Group><Title
                  level={5}
                  className="list-item--title"
                  style={{ padding: "10px 0px" }}
                >
                  Mô tả duyệt:
                </Title>
                <ReactQuill
                  className="approve-react-quill"
                  modules={modules}
                  formats={FORMAT_REACT_QUILL}
                  theme="snow"
                  onChange={setValue}
                  placeholder="Nhập text" /></>
              :
              <>
                <Title level={5} className="approved-infor--title">
                  {approveInformationDetail?.approveUser}
                </Title>
                <div className="approved-infor--item">
                  <span className="content">
                    {moment(approveInformationDetail?.updatedDate).format(
                      "DD/MM/yyyy"
                    )}
                  </span>
                  <span className="dot-green"></span>
                  <span className="content">Đồng ý</span>
                </div>
                <Typography.Title level={5}>Trạng thái</Typography.Title>
                <div
                  dangerouslySetInnerHTML={{
                    __html: approveInformationDetail?.description,
                  }}
                />
              </>
          }
        </>
      ),
    },
  ];

  const submitData = async () => {
    const data = {
      courseIds: [params?.id],
      type: 2,
      status: approvedResult,
      description: value
    };
    await putPublishCourse(data).then((result) => {
      if (approvedResult === 1) {
        message.success(`Duyệt trang trình bày thành công`);
      } else {
        message.error(`Duyệt trang trình bày thất bại`);
      }
      setIsConfirm(false)
      setApprovedResult(1)
    });
  };

  return (
    <>
      <div className={`approved-information-tab ${flag ? "" : "w-50px"}`}>
        <DoubleRightOutlined
          className="approved-information-move-btn"
          rotate={flag ? 0 : 180}
          onClick={toggleFlag}
        />
        {flag ? (
          <>
            <div className="flex">
              <div className="approved-information-title">Thông tin duyệt</div>
            </div>
            <Collapse
              defaultActiveKey={["sub-2", "sub-3", "sub-4", "sub-5"]}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined
                  rotate={isActive ? 90 : 0}
                  style={{ fontSize: "20px" }}
                />
              )}
            >
              {arrSection.map((el, index) => {
                return (
                  <Panel key={`sub-${index + 1}`} header={el?.title}>
                    <div className="sub-content">{el?.content}</div>
                  </Panel>
                );
              })}
            </Collapse>
            {
              slideStatus !== 13 ?
                <div className="approved-information-btn">
                  <Button
                    className="btn btn-outlined h-32"
                    onClick={() => navigate(-1)}
                  >
                    <Text>Hủy</Text>
                  </Button>

                  <Button
                    className="btn btn-primary h-32"
                    htmlType="submit"
                    onClick={
                      () => {
                        setStatusCourse(false);
                        setIsConfirm(true);
                      }
                    }
                  >
                    <Text>Lưu</Text>
                  </Button>
                </div> : null
            }

            <CommonModal
              open={isConfirm}
              title={"Thông báo xác nhận lưu"}
              desc={<div>
                Đảm bảo rằng tất cả các{" "}
                <span className="text-browse">
                  thông tin liên quan đã được kiểm tra và cập nhật đầy đủ{" "}
                </span>
                trước khi Xác nhận kết quả duyệt trang trình bày khoá học.
              </div>}
              closeIcon={true}
              okText={"Xác nhận"}
              cancelText={"Huỷ"}
              icon={<InfoCircleOutlined
                style={{ color: "#FFC53D", marginRight: "10px" }} />}
              confirm={submitData}
              closeModal={() => setIsConfirm(false)}
            />
          </>
        ) : null}
      </div>
    </>
  );
};

export default ApprovedInformationTabSlide;

interface Detail {
  enterpriseName: string;
  userNameSendApprove: string;
  approveSendDate: any;
  approveDate: any;
  enterpriseNameSponsor: string;
  fromUserName: string;
  createdDate: any;
  approveUser: any;
  updatedDate: any;
  description: any;
  appraiseResults: any;
  appraiseUser: string;
  approveResults: any;
}

interface StatusResult {
  [key: string]: boolean;
}
