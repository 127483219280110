import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { ExportTransactionFilePayload } from "../../../types/transaction";
import { exportPaymentTransactionFile } from "../../../service/transaction";
import moment from "moment";

function ExportFile({ isConfirmed }: ExportTransactionFilePayload) {
  const [loading, setLoading] = useState<boolean>(false);

  const handleExportFile = async () => {
    try {
      setLoading(true);
      const res = await exportPaymentTransactionFile({
        isConfirmed: isConfirmed,
      });
      const fileData = res.data;
      const blob = new Blob([fileData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `Danh_sách_giao_dịch_${moment(Date.now()).format(
        "DD/MM/YYYY"
      )}.xlsx`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      loading={loading}
      onClick={handleExportFile}
      icon={<DownloadOutlined />}
    >
      Xuất file
    </Button>
  );
}

export default ExportFile;
