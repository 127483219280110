export enum Permission {
  //Group
  CanAddGroup = "add_group",
  CanChangeGroup = "change_group",
  CanDeleteGroup = "delete_group",
  CanViewGroup = "view_group",

  //User
  CanAddUser = "add_user",
  CanChangeUser = "change_user",
  CanDeleteUser = "delete_user",
  CanViewUser = "view_user",

  //Role
  CanAddRole = "add_role",
  CanChangeRole = "change_role",
  CanDeleteRole = "delete_role",
  CanViewRole = "view_role",

  //Khảo sát
  CanParticipateSurvey = "participate_survey",

  //Course-plan kế hoạch khóa học
  CanSearchCoursePlan = "can_search_course_plan",
  CanEditCoursePlan = "can_edit_course_plan",
  CanDeleteCoursePlan = "can_delete_course_plan",

  CanViewRegisterCoursePlan = "view_register_course_plan", //đăng ký kế hoạch
  CanViewApprovalRegisterCoursePlan = "view_approval_register_course_plan", //duyệt đăng ký kế hoạch
  CanViewCoursePlanAssignment = "view_course_plan_assignment", //phân công lập kế hoạch
  CanViewCoursePlanning = "view_course_planning", // lập kế hoạch khóa học
  CanViewApprovalCoursePlanning = "view_approval_course_planning", //duyệt lập kế hoạch
  CanViewSpecializedGroup = "can_view_specialized_group", //lập tổ chuyên môn
  CanViewAppraisalBoard = "can_view_appraisal_board", //lập hội đồng thẩm định
  CanViewApprovalCoursePlanningSending = "view_aprroval_course_planning_sending", //Gửi phê duyệt kế hoạch
  CanViewSubmitPlanAppraisal = "view_submit_plan_appraisal", //Gửi thẩm định kế hoạch
  CanApprovalCoursePlanning = "aprroval_course_planning", //Phê duyệt kế hoạch
  CanViewPlanAppraisal = "view_plan_appraisal", //Thẩm định kế hoạch

  //accessMenu
  //quản lý người dùng
  CanAccessUser = "mooc_access_user",

  //quản lý vai trò
  CanAccessRole = "mooc_access_role",

  //khảo sát
  CanAccessParticipateSurvey = "mooc_access_participate_survey",

  //kế hoạch khóa học
  CanAccessRegisterCoursePlan = "mooc_access_register_course_plan", //đăng ký kế hoạch
  CanAccessApprovalRegisterCoursePlan = "mooc_access_approval_register_course_plan", //duyệt đăng ký kế hoạch
  CanAccessCoursePlanAssignment = "mooc_access_course_plan_assignment", //phân công lập kế hoạch
  CanAccessCoursePlanning = "mooc_access_course_planning", // lập kế hoạch khóa học
  CanAccessPlanningApproval = "mooc_access_planning_approval", //duyệt lập kế hoạch
  CanAccessSpecializedGroup = "mooc_access_specialized_group", //lập tổ chuyên môn
  CanAccessAppraisalBoard = "mooc_access_appraisal_board", //lập hội đồng thẩm định
  CanAccessApprovalCoursePlanningSending = "mooc_access_aprroval_course_planning_sending", //Gửi phê duyệt kế hoạch
  CanAccessSubmitPlanAppraisal = "mooc_access_submit_plan_appraisal", //Gửi thẩm định kế hoạch
  CanAccessApprovalCoursePlan = "mooc_access_aprroval_course_plan", //Phê duyệt kế hoạch
  CanAccessPlanAppraisal = "mooc_access_plan_appraisal", //Thẩm định kế hoạch

  //
  AddPermission = "add_permission", // danh cho cac menu khac vai tro giang vien
  ViewPermission = "view_permission", // menu danh cho giang vien

  //phân công xây dựng khóa học
  AssignCourseConstructionUpdate = "assign_course_construction.update",
  ManageCourseStructureUpdate = "manage_course_structure.update",
  SetWebCourseActivityUpdate = "set_web_course_activity.update",
  SetCourseTagsUpdate = "set_course_tags.update",
  EditCourseTagsUpdate = "edit_course_tags.update",
  PreviewCoursePresentationInterfaceUpdate = "preview_course_presentation_interface.update",
  SelectPresentationScreenUpdate = "select_presentation_screen.update",
  SaveCoursePresentationInterfaceUpdate = "save_course_presentation_interface.update",
  CreateDraftPresentationInterfaceUpdate = "create_draft_presentation_interface.update",
  OperatePresentationPageUpdate = "operate_presentation_page.update",
  ReviewCoursePresentationPageUpdate = "review_course_presentation_page.update",

  //Xây dựng nội dung khóa học
  CreateLectureUpdate = "create_lecture.update",
  CreateChapterUpdate = "create_chapter.update",
  CreateLearningMaterialUpdate = "create_learning_material.update",
  CreateAssignmentTestExamUpdate = "create_assignment_test_exam.update",
  CreateAssignmentTestExamQuestionUpdate = "create_assignment_test_exam_question.update",
  CreateCourseSurveyPlanUpdate = "create_course_survey_plan.update",
  CreateCourseSurveyQuestionUpdate = "create_course_survey_question.update",

  //kiểm định nội dung khóa học
  ApproveCourseContentUpdate = "approve_course_content.update",
  AssessCourseContentUpdate = "assess_course_content.update",
  PublishCourseUpdate = "publish_course.update",

  //Cài đặt định danh
  MenuIdentitySettingUpdate = "menu_identity_setting.update",
  TabIdentityCaseSettingUpdate = "tab_identity_case_setting.update",

  //Cài đặt điểm danh
  TabIdentityDocumentSettingUpdate = "tab_identity_document_setting.update",
  MenuAttendanceSettingUpdate = "menu_attendance_setting.update",
  TabAttendanceReminderSettingUpdate = "tab_attendance_reminder_setting.update",
  ButtonViewAttendanceUpdate = "button_view_attendance.update",
  ToggleSwitchAttendanceModeUpdate = "toggle_switch_attendance_mode.update",
  MenuPermissionByCourseUpdate = "menu_permission_by_course.update",
  TabAttendancePermissionSettingUpdate = "tab_attendance_permission_setting.update",
  MenuCourseListUpdate = "menu_course_list.update",
  ButtonPerformAttendanceUpdate = "button_perform_attendance.update",
  TabAttendanceReportUpdate = "tab_attendance_report.update",
  ButtonExamMonitoringSettingUpdate = "button_exam_monitoring_setting.update",
  TabExamMonitoringCharacteristicsSettingUpdate = "tab_exam_monitoring_characteristics_setting.update",
  TabExamViolationNotificationSettingUpdate = "tab_exam_violation_notification_setting.update",

  //Kiểm soát chất lượng tiết học video
  MenuLessonQualityControlUpdate = "menu_lesson_quality_control.update",
  TabQualityAssessmentCriteriaSettingUpdate = "tab_quality_assessment_criteria_setting.update",
  TabViewCourseVideoAssessmentUpdate = "tab_view_course_video_assessment.update",
  CourseQualityControlUpdate = "course_quality_control.update",
  LectureStructureSettingUpdate = "lecture_structure_setting.update",
  CourseQualityAssessmentCriteriaSettingUpdate = "course_quality_assessment_criteria_setting.update",
  AutomatedExamGradingUpdate = "automated_exam_grading.update",
  AutomatedGradingPermissionsUpdate = "automated_grading_permissions.update",
  TabPerformAutomatedGradingUpdate = "tab_perform_automated_grading.update",
  GradeManagementUpdate = "grade_management.update",
  ButtonEditAttendanceReportUpdate = "button_edit_attendance_report.update",
  ButtonDeleteAttendanceReportUpdate = "button_delete_attendance_report.update",
  ButtonExportAttendanceReportUpdate = "button_export_attendance_report.update",
  ButtonShareAttendanceReportUpdate = "button_share_attendance_report.update",
  ButtonEvaluateAttendanceReportUpdate = "button_evaluate_attendance_report.update",
  ButtonViewAttendanceReportUpdate = "button_view_attendance_report.update",

  //Quản lý nhóm nội dung
  MenuContentGroupManagementUpdate = "menu_content_group_management.update",
  TabStudentInputInformationUpdate = "tab_student_input_information.update",
  TabStudentBehaviorInformationUpdate = "tab_student_behavior_information.update",
  MenuAnalysisSettingUpdate = "menu_analysis_setting.update",
  TabTrendAnalysisModelFactorsSettingUpdate = "tab_trend_analysis_model_factors_setting.update",
  TabTrainingResultUpdate = "tab_training_result.update",
  LearningResourcesSystemUpdate = "learning_resources_system.update",
  SummaryReportUpdate = "summary_report.update",
  ListOfStudentsWithSimilarInterestsUpdate = "list_of_students_with_similar_interests.update",

  //cổng thông tin
  PortalManagementAddNewPortalUpdate = "portal_management.add_new_portal.update",
  PortalManagementIntroductionPageUpdate = "portal_management.introduction_page.update",
  PortalManagementCourseListPageUpdate = "portal_management.course_list_page.update",
  PortalManagementProgramListPageUpdate = "portal_management.program_list_page.update",

  //quản trị nội dung blog và diễn đàn
  BlogForumManagementBlogContentUpdate = "blog_forum_management.blog_content.update",
  BlogForumManagementSetupInterfaceUpdate = "blog_forum_management.setup_interface.update",
  BlogForumManagementForumTopicsUpdate = "blog_forum_management.forum_topics.update",

  //quản lý thông báo
  NotificationManagementUpdate = "notification_management.update",

  //quản lý chấm điểm
  GradingManagementUpdate = "grading_management.update",

  //Nhật kí người dùng
  MenuActivityLogUpdate = "menu_activity_log.update",

  //quản lí vai trò
  AddRoleUpdate = "add_role.update",
  EditRoleUpdate = "edit_role.update",
  DeleteRoleUpdate = "delete_role.update",
  AssignPermissionsUpdate = "assign_permissions.update",
  AssignAccountUpdate = "assign_account.update",
  
  //quản lí trường tham gia nền tảng
  ProcessRequestUpdate = "process_request.update",

  //quản lí tài khoản cơ sở
  LockUnlockFAAccountUpdate = "lockunlock_fa_account.update",
  ChangeFAAccountUpdate = "change_fa_account.update",
  DeleteFAAccountUpdate = "delete_fa_account.update",
  CreateFAAccountUpdate = "create_fa_account.update",
  
  //quản lí tài khoản người dùng cấp bộ
  LockUnlockMUAccountUpdate = "lockunlock_mu_account.update",
  ChangeMUAccountUpdate = "change_mu_account.update",
  DeleteMUAccountUpdate = "delete_mu_account.update",
  CreateMUAccountUpdate = "create_mu_account.update",

  //quản lí tài khoản giảng viên
  LockUnlockLecturerAccountUpdate = "lockunlock_lecturer_account.update",
  ChangeLecturerAccountUpdate = "change_lecturer_account.update",
  DeleteLecturerAccountUpdate = "delete_lecturer_account.update",
  CreateLecturerAccountUpdate = "create_lecturer_account.update",

  //quản lí tài khoản sinh viên
  LockUnlockStudentAccountUpdate = "lockunlock_student_account.update",
  ChangeStudentAccountUpdate = "change_student_account.update",
  DeleteStudentAccountUpdate = "delete_student_account.update",
  ApproveStudentAccountVerificationUpdate = "approve_student_account_verification.update",
  ReapproveStudentAccountVerificationUpdate = "reapprove_student_account_verification.update",
  CreateStudentAccountUpdate = "create_student_account.update",

  //báo cáo đăng ký tài khoản
  StudentAccountOverviewView = "student_account_overview.view",

  //thiết lập giao diện và quản trị
  InterfaceSetupAdminUpdate = "interface_setup_admin.update",

  //thiết lập chứng chỉ/chứng nhận
  CertificateSetupUpdate = "certificate_setup.update",

  //quản lí kết nối hệ thống
  DataSharingSetupUpdate = "data_sharing_setup.update",

  //quản lí danh mục
  CommonCategoryManagementUpdate = "common_category_management.update",
  CategoryValueManagementUpdate = "category_value_management.update",
  UniversityManagementUpdate = "university_management.update",
  LecturerManagementUpdate = "lecturer_management.update",
  StudentManagementUpdate = "student_management.update",
  MinistryUserManagementUpdate = "ministry_user_management.update",

  //báo cáo thống kê view
  LecturerStatisticsReportView = "lecturer_statistics_report.view",
  MinistryStatisticsReportView = "ministry_statistics_report.view",
  SchoolStatisticsReportView = "school_statistics_report.view",

  //cấu hình tài khoản
  AccountSettingsUpdate = "account_settings.update",

  // xác nhận ghi danh
  TransactionManagement = "payment_transaction.update",
  isQTCS = "is_qtcs",
  isQTHT = "is_qtht"
}
