import { Form, Input } from "antd";
import { QuestionTypeEnum } from "../../../../../constants";
import { JSON_SUB_TYPE, QuestionProps } from "../../../../../types/course";


const FillText = ({ data, disabled, initialData }: QuestionProps) => {
  return (
    <div className="flex mt-4 align-center gap-8">
      A là{" "}
      <Form.Item
        initialValue={initialData}
        name={`fillText-${data?.id}-${QuestionTypeEnum.FillText}-${JSON_SUB_TYPE.ContentRequest}`}
      >
        <Input
          disabled={disabled}
          className="h-40"
          style={{ width: 420 }}
          placeholder="Nhập câu trả lời của bạn"
        />
      </Form.Item>
    </div>
  );
};

export default FillText;
