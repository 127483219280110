import React from "react";
import { Checkbox, Flex, Form } from "antd";
import {
  JSON_SUB_TYPE,
  QuestionBeProps,
  QuestionProps,
  QuestionPropsV2,
} from "../../../../../types/course";
import { QuestionTypeEnum } from "../../../../../constants";
import { convertNumberingTypeToText } from "../../../../../utils/format";
import EmptyComponent from "../../../../../components/empty";

function ChooseMultiText({ data, initialData, disabled }: QuestionPropsV2) {
  return (
    <div>
      {data?.questions && data?.questions?.length > 0 ? (
        data?.questions?.map((quiz: QuestionBeProps, index) => (
            <Checkbox disabled={disabled} checked={quiz?.isTrue === true}>
              <Flex className="font-size-16 line-height-24 gap-1">
                {data?.settingGeneral && convertNumberingTypeToText(
                  index + 1,
                  Number(JSON.parse(data?.settingGeneral).numberingType)
                )}
                .
                <div
                  className="font-size-16 line-height-24"
                  dangerouslySetInnerHTML={{ __html: `${quiz?.content}` }}
                ></div>
              </Flex>
            </Checkbox>
        ))
      ) : (
        <div className="center">
          <EmptyComponent description="Không có nội dung" />
        </div>
      )}
    </div>
  );
}

export default ChooseMultiText;
