import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import ResultQuizLayout from "./quiz";
import { isEmpty, toNumber } from "lodash";
import SingleMenu from "../../../../components/menu/SingleMenu";
import { Flex, Form, InputNumber, Switch } from "antd";
import ReactQuill from "react-quill";
import {
  FORMAT_REACT_QUILL,
  QuestionTypeEnum,
  TOOLBAROPTIONS,
} from "../../../../constants";
import {
  Draft,
  QuestionBeProps,
  QuizProps,
} from "../../../../types/scoring-course";
import EmptyComponent from "../../../../components/empty";
import SingleMenuCustom from "../../../../components/menu/SingleMenuCustom";
import FormulaRenderer from "../../../../components/math-formula";

export interface ScoringFormValues {
  isConfigPointForTeacher: boolean;
  points: number;
  comment?: string;
}

export interface ContentRef {
  resetFields: () => void;
}

interface ContentProps {
  currentQuiz: QuizProps;
  disabled?: boolean;
  quizzesCompleted?: Draft[];
  commentOverview?: string;
  onChange?: (v?: ScoringFormValues) => void;
  onChangeOverviewComment?: (v: string) => void;
}

const initialValues = {
  isConfigPointForTeacher: false,
  points: 0,
};

const Content = forwardRef<ContentRef, ContentProps>(
  (
    {
      currentQuiz,
      disabled,
      quizzesCompleted = [],
      commentOverview,
      onChange = () => {},
      onChangeOverviewComment = () => {},
    }: ContentProps,
    ref
  ) => {
    const [form] = Form.useForm();
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const type = currentQuiz?.type;
    const isConfigPointForTeacher = Form.useWatch(
      "isConfigPointForTeacher",
      form
    );
    const [comment, setComment] = useState<string>("");
    const [fieldValues, setFieldValues] =
      useState<ScoringFormValues>(initialValues);

    const result = currentQuiz?.result;
    const questions = currentQuiz?.questions;
    const modules = {
      toolbar: TOOLBAROPTIONS,
    };

    useImperativeHandle(ref, () => ({
      resetFields() {
        resetValue();
      },
    }));

    useEffect(() => {
      if (isDirty)
        onChange({
          ...fieldValues,
          comment: comment,
          isConfigPointForTeacher: isConfigPointForTeacher,
        });
    }, [fieldValues, comment, isConfigPointForTeacher, isDirty]);

    const resetValue = () => {
      form.resetFields();
      setFieldValues(initialValues);
      setIsDirty(false);
      setComment("");
    };

    useEffect(() => {
      const currentQuizCompleted = quizzesCompleted?.find(
        (quiz: Draft) => quiz.quizId === currentQuiz?.id
      );

      // cập nhật form nếu câu vừa được chấm trong phiên hiện tại
      if (currentQuizCompleted) {
        form.setFieldsValue({
          isConfigPointForTeacher: currentQuizCompleted.isConfigPointForTeacher,
          points: currentQuizCompleted.points,
        });
        setComment(currentQuizCompleted?.comment);
        setFieldValues({
          isConfigPointForTeacher:
            currentQuizCompleted.isConfigPointForTeacher as boolean,
          points: currentQuizCompleted.points,
          comment: currentQuizCompleted?.comment,
        });
      }
      // cập nhật form nếu câu đã được chấm trong phiên trước đó
      else if (currentQuiz?.result?.markedTeacherId) {
        form.setFieldsValue({
          isConfigPointForTeacher: true,
          points: currentQuiz?.result?.points,
        });
        setComment(currentQuiz?.result?.comment);
        setFieldValues({
          isConfigPointForTeacher: true,
          points: currentQuiz?.result?.points,
          comment: currentQuiz?.result?.comment,
        });
      }

      // reset form nếu câu chưa được chấm
      else {
        resetValue();
      }

      return () => resetValue();
    }, [currentQuiz]);

    const handleChangeValues = (
      changedValues: any,
      values: ScoringFormValues
    ) => {
      setFieldValues(values);
      setIsDirty(true);
    };

    const renderSingleMenuByType = () => {
      switch (type) {
        case QuestionTypeEnum.SHORT_TEXT:
        case QuestionTypeEnum.FILE_UPLOAD:
        case QuestionTypeEnum.VIDEO:
        case QuestionTypeEnum.MP3:
        case QuestionTypeEnum.LONG_TEXT:
        case QuestionTypeEnum.FillText:
          return (
            <SingleMenu
              className="result-system mt-2 criteria"
              titleClassName="bg-gray"
              contentClassName="bg-gray"
              title={
                <span className="text-14 font-weight-5">
                  Tiêu chí chấm điểm
                </span>
              }
              content={
                !isEmpty(currentQuiz) && (
                  <div>
                    <p className="text-14">
                      Trong câu trả lời phải có những tiêu chí sau:{" "}
                    </p>
                    {questions?.map((item: QuestionBeProps) => (
                      <Flex key={item?.uuid} gap={4} align="center">
                        •
                        <FormulaRenderer
                          className="ml-1"
                          htmlString={item.content}
                        />
                      </Flex>
                    ))}
                  </div>
                )
              }
            />
          );

        default:
          return (
            <SingleMenu
              className="result-system mt-2"
              titleClassName="bg-gray"
              contentClassName="result-system bg-gray"
              title={<span className="text-14 font-weight-5">Đáp án</span>}
              content={
                !isEmpty(currentQuiz) && (
                  <ResultQuizLayout data={currentQuiz} showCorrect={true} />
                )
              }
            />
          );
      }
    };

    return (
      <div className="scoring-content">
        {isEmpty(currentQuiz) ? (
          <EmptyComponent description="Không có thông tin" />
        ) : (
          <>
            <div className="overall-comment">
              <SingleMenuCustom
                title={<span className="text-20">Nhận xét tổng quát</span>}
                children={
                  <div className={`comment-detail mt-1`}>
                    <ReactQuill
                      style={{ height: "max-content" }}
                      readOnly={disabled}
                      modules={modules}
                      formats={FORMAT_REACT_QUILL}
                      theme="snow"
                      value={commentOverview}
                      onChange={(value) => {
                        onChangeOverviewComment(value);
                      }}
                      placeholder="Nhập nhận xét bài làm của học viên tại đây"
                    />
                  </div>
                }
              />
            </div>
            <div className="scoring-quiz">
              <div className="text-20 font-weight-5 line-height-28 text-nowrap">
                <span>{`Câu ${toNumber(currentQuiz?.index) + 1}:`}</span>
                <p
                  className="font-size-20 font-weight-5 line-height-28 mb-2 text-title-content"
                  dangerouslySetInnerHTML={{
                    __html: `${currentQuiz?.question}`,
                  }}
                />
              </div>

              {!isEmpty(currentQuiz) && (
                <ResultQuizLayout data={currentQuiz} showCorrect={false} />
              )}

              {renderSingleMenuByType()}

              <Form
                form={form}
                disabled={disabled}
                onValuesChange={handleChangeValues}
              >
                <div className="scores-info">
                  <div className="flex align-center gap-12">
                    <p className="text-16 font-weight-5">Thông tin điểm</p>
                    <div className="score-number">
                      Điểm: {result?.points || 0}/
                      {result?.totalPoints || currentQuiz?.totalPoints || 0}
                    </div>
                  </div>
                  <div className="ml-5">
                    <div className="flex gap-16 align-center mt-2">
                      <span className="text-14">
                        Thiết lập điểm theo giảng viên
                      </span>
                      <Form.Item
                        name="isConfigPointForTeacher"
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    </div>

                    <Flex className="mt-1" align="center" gap={63}>
                      <span className="text-14">Nhập điểm</span>
                      <Form.Item name="points" initialValue={0}>
                        <InputNumber
                          min={0}
                          step={0.5}
                          style={{ width: 100, textAlign: "right" }}
                          disabled={disabled || !isConfigPointForTeacher}
                        />
                      </Form.Item>
                    </Flex>
                  </div>
                  <p className="text-16 font-weight-5 mt-2 mb-1">Nhận xét</p>
                  <ReactQuill
                    readOnly={disabled}
                    modules={modules}
                    formats={FORMAT_REACT_QUILL}
                    theme="snow"
                    value={comment}
                    onChange={(value) => {
                      setIsDirty(true);
                      setComment(value);
                    }}
                    placeholder="Nhập nhận xét câu trả lời của học viên tại đây"
                  />
                </div>
              </Form>
            </div>
          </>
        )}
      </div>
    );
  }
);

export default Content;
