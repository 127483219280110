import React, { forwardRef, useImperativeHandle, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface Props {
  data: { criteria: string; value: number }[];
  title?: string;
  lineColor?: string;
  lineName?: string;
  yAxisTitle?: string;
  xAxisTitle?: string;
  hasTotal?: boolean;
}

const LineChart = forwardRef((props: Props, ref) => {
  const {
    data,
    title,
    lineColor,
    lineName,
    yAxisTitle = "Số lượng",
    xAxisTitle,
    hasTotal = true,
  } = props;

  const chartRef = useRef<HighchartsReact.RefObject>(null);

  useImperativeHandle(ref, () => ({
    getChart: () => chartRef.current?.chart,
  }));

  const total = data.reduce((sum, item) => sum + item.value, 0);

  const options: Highcharts.Options = {
    chart: {
      type: "column",
      scrollablePlotArea: {
        minWidth: data?.length * 68,
        scrollPositionX: 1,
      },
    },
    title: {
      text: hasTotal
        ? `<div class="static-chart-total">Tổng số: <span class="static-chart-count">${total}</span></div>`
        : title || "",
    },
    xAxis: {
      categories: data?.map((item) => item.criteria),
      title: {
        text: xAxisTitle,
      },
      allowDecimals: false,
    },
    yAxis: {
      title: {
        text: yAxisTitle,
      },
      allowDecimals: false,
    },

    series: [
      {
        type: "line",
        data: data?.map((item) => item.value),
        name: lineName,
        color: lineColor,
        marker: {
          enabled: data?.length === 1,
        },
      },
    ],
    legend: {
      itemStyle: { fontSize: "12px" },
    },
    exporting: {
      enabled: true,
      allowHTML: true,
      chartOptions: {
        credits: {
          enabled: false,
        },
      },
    },
  };

  return (
    <div className="mt-2">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </div>
  );
});

export default LineChart;
