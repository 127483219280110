import { Col, Image, Modal, Row, Typography } from "antd";
import { ReactNode, useEffect, useState } from "react";
import "./index.scss";
import { useAddLesson } from "../../../stores/stores";
import { ModeQuestion, QuestionProgressModeClick, QuestionTypeEnum } from "../../../constants";
import { renderQuestionDescriptionByType, renderQuestionNameByType } from "../../../utils/functionUltils";
import { useDirty } from "../../../contexts/DirtyProvider";
import { getIdIndexDB, saveToIndexDB } from "../../../pages/page-builder/grapesjs-service";
import { createMoocCourseBlockQuiz, createMoocCourseBlockQuizConnect } from "../../../service/course-plan-managment/mooc-course-block-quiz/mooc-course-block-quiz";
import { HelpService } from "../../../service/helper.service";

type Props = {
  open: boolean;
  closeIcon: boolean;
  title: string;
  okText: string;
  cancelText: string;
  courseBlockGroupIdClick: number;
  icon: ReactNode;
  closeModal: (value: boolean) => void;
  onSelectQuestionType?: (type: number) => void; 
};

export default function AddQuestionCourseModal(props: Props) {
  const { open, title, closeIcon, okText, cancelText, courseBlockGroupIdClick, closeModal, icon,onSelectQuestionType } = props;
  const { Text, Link } = Typography;
  const setTypeQuestionChoose = useAddLesson((state) => state.setTypeQuestionChoose);
  const setQuestion = useAddLesson((state) => state.setQuestion);
  const setCurrentQuestionInfo = useAddLesson((state) => state.setCurrentQuestionInfo);
  const setModeClick = useAddLesson((state) => state.setModeClick);
  const setQuestionSettingsBe = useAddLesson((state) => state.setQuestionSettingsBe);
  const setModeQuestion = useAddLesson((state) => state.setModeQuestion);
  const setCurrentIdQuestionAdd = useAddLesson((state) => state.setCurrentIdQuestionAdd);
  const onRefreshQuizBlock = useAddLesson((state) => state.onRefreshQuizBlock);
  const reviewList = useAddLesson((state) => state.reviewList);

  const [activeType, setActiveType] = useState(0);
  const [currentParentCourse, setCurrentParentCourse] = useState<string>();
  const [currentCourse, setCurrentCourse] = useState<any>();

  const helpService = new HelpService();
  const { setDirty } = useDirty();

  useEffect(() => {
    getIdIndexDB('current-course', (val: any) => {
      setCurrentParentCourse(val.parent);
      setCurrentCourse({ id: val.id, name: val.name });
    });
  }, []);

  useEffect(() => {
    setCurrentCourse({ id: reviewList.id, name: reviewList.name });
  }, [reviewList]);

  const confirmCreateQuestion = (type: number) => {
    setActiveType(type);
    onSelectQuestionType?.(type);
    setQuestion(
      new Object({
        type: type,
        question: renderQuestionNameByType(type),
        courseBlockGroupId: courseBlockGroupIdClick,
      })
    );
    setTypeQuestionChoose(type);
    setModeQuestion(ModeQuestion.CREATE);
    setQuestionSettingsBe({});
    setCurrentQuestionInfo({});
    setModeClick(QuestionProgressModeClick.INPROGRESS);
    closeModal(true);
    setDirty(true);
    let payload = {
      answers: [],
      courseBlockGroupId: courseBlockGroupIdClick,
      orderNumber: 1,
      question: renderQuestionNameByType(type),
      questionValue: renderQuestionNameByType(type),
      type: type,
      settingGeneral: {
        displayScoreCriteria: true,
        fileCapacity: 0,
        fileQuantity: 0,
        haveCorrectAnswer: true,
        isAutoMark: true,
        numberingType: 1,
        requiredAnswer: true,
        showForViewer: true,
        shuffleQuestion: true,
        timeToPlay: 0,
      },
      settingHint: {
        content: '',
        isDisplayInstruction: false,
        schema: '',
        uri: 'https://',
      },
      settingPoint: {
        correct: 0,
        incorrect: 0,
        isPerQuiz: false,
      },
      settingResponse: {
        correct: '',
        incorrect: '',
        isDisplayPerResponse: false,
        notYet: '',
      },
    };
    createQuiz(payload);
  };

  const createQuiz = async (dataReq: any) => {
    try {
      let apiRespone: any;
      if (dataReq.type === QuestionTypeEnum.CONNECT) {
        apiRespone = await createMoocCourseBlockQuizConnect(dataReq);
      } else {
        apiRespone = await createMoocCourseBlockQuiz(dataReq);
      }
      const dataRespone = apiRespone.data;
      setModeClick(QuestionProgressModeClick.END);
      setCurrentIdQuestionAdd(dataRespone.body.id);
      saveToIndexDB('current-course', { currentId: dataReq.id, id: dataReq.id, name: currentCourse.name, parent: currentParentCourse, });
      onRefreshQuizBlock();
    } catch (err: any) {
      helpService.errorMessage(err?.message || "Tạo mới thất bại");
      console.error(err);
    } finally { }
  };

  useEffect(() => {
    setActiveType(0);
  }, [open]);

  const dataChooseList = [
    {
      type: 1,
      title: "Nhiều lựa chọn",
      childs: [
        {
          type: QuestionTypeEnum.CHOOSE_TEXT,
          title: "Nhiều lựa chọn văn bản",
          image: "/images/choose-text.svg",
        },
        {
          type: QuestionTypeEnum.CHOOSE_IMAGE,
          title: "Nhiều lựa chọn ảnh",
          image: "/images/choose-image.svg",
        },
        {
          type: QuestionTypeEnum.CHOOSE_VIDEO,
          title: "Nhiều lựa chọn video",
          image: "/images/choose-video.svg",
        },
        {
          type: QuestionTypeEnum.TRUE_OR_FALSE,
          title: "Nhiều lựa chọn đúng sai",
          image: "/images/choose-true-false.svg",
        },
        {
          type: QuestionTypeEnum.DROP_DOWN,
          title: "Danh sách",
          image: "/images/choose-list.svg",
        },
      ],
    },
    {
      type: 2,
      title: "Nhiều câu trả lời",
      childs: [
        {
          type: QuestionTypeEnum.CHOOSE_MULTI_TEXT,
          title: "Nhiều đáp án văn bản",
          image: "/images/choose-multi-text.svg",
        },
        {
          type: QuestionTypeEnum.CHOOSE_MULTI_IMAGE,
          title: "Nhiều đáp án hình ảnh",
          image: "/images/choose-multi-image.svg",
        },
        {
          type: QuestionTypeEnum.CHOOSE_MULTI_VIDEO,
          title: "Nhiều đáp án video",
          image: "/images/choose-multi-video.svg",
        },
      ],
    },
    {
      type: 3,
      title: "Văn bản",
      childs: [
        {
          type: QuestionTypeEnum.SHORT_TEXT,
          title: "Câu trả lời ngắn",
          image: "/images/choose-short.svg",
        },
        {
          type: 992,
          title: "Điền vào chỗ trống",
          image: "/images/choose-fill.svg",
          disable: true,
        },
        {
          type: 993,
          title: "Trình soạn thảo",
          image: "/images/choose-textarea.svg",
          disable: true,
        },
      ],
    },
    {
      type: 4,
      title: "Kết nối",
      childs: [
        {
          type: QuestionTypeEnum.CONNECT,
          title: "Kết nối",
          image: "/images/choose-connect.svg",
        },
        {
          type: 994,
          title: "Sắp xếp",
          image: "/images/choose-sort.svg",
          disable: true,
        },
      ],
    },
    {
      type: 5,
      title: "Ghi âm/Ghi hình",
      childs: [
        {
          type: QuestionTypeEnum.MP3,
          title: "Ghi âm",
          image: "/images/choose-mp3.svg",
        },
        {
          type: QuestionTypeEnum.VIDEO,
          title: "Ghi hình",
          image: "/images/choose-record.svg",
        },
      ],
    },
    {
      type: 6,
      title: "File dính kèm",
      childs: [
        {
          type: QuestionTypeEnum.FILE_UPLOAD,
          title: "Đính kèm",
          image: "/images/choose-file.svg",
        },
      ],
    },
  ];
  return (
    <Modal
      className="add-type-question-modal"
      title={
        <>
          {icon} <Typography.Title level={5}>{title}</Typography.Title>
        </>
      }
      closeIcon={closeIcon}
      centered
      open={open}
      okText={okText}
      cancelText={cancelText}
      onCancel={() => closeModal(false)}
      footer={null}
      width={"50vw"}
    >
      <section className="list-type-question">
        {dataChooseList.map((item, i) => (
          <div className="item-type" key={i + 1}>
            <div className="item-type__title">
              <Typography.Title level={5}>{item.title}</Typography.Title>
            </div>
            <div className="item-type__list">
              <Row gutter={16}>
                {item.childs.map((itemChild, iChild) => (
                  <Col span={6} key={iChild + 1}>
                    <div className={`item-type__list__item ${activeType === itemChild.type ? "type-active" : ""} ${itemChild?.disable ? "item-disable" : ""}`}
                      onClick={() => confirmCreateQuestion(itemChild.type)}
                    >
                      <Typography.Paragraph className="title">
                        {itemChild.title}
                      </Typography.Paragraph>
                      <Image
                        preview={false}
                        src={itemChild.image}
                        className="icon"
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        ))}
      </section>
    </Modal>
  );
}
