import { Editor, Plugin } from "grapesjs";

export type PluginOptions = {};

const FontFamilyCustomPlugin: Plugin<PluginOptions> | any = (editor: Editor, optionsPlugin: PluginOptions = {}) => {
  // editor.addComponents(`
  //   <style>
  //     @font-face {
  //       font-family: 'AmaticSC-Regular';
  //       src: 
  //         url("../../public/fonts/Amatic_SC/AmaticSC-Regular.tff") format("truetype");
  //       font-weight: normal;
  //       font-style: normal;
  //     }

  //     @font-face {
  //       font-family: 'AmaticSC-Bold';
  //       src: 
  //         url("../../public/fonts/Amatic_SC/AmaticSC-Bold.ttf") format("truetype");
  //       font-weight: normal;
  //       font-style: normal;
  //     }

  //     @font-face {
  //       font-family: 'BreeSerif-Regular';
  //       src: 
  //         url("../../public/fonts/Amatic_SC/BreeSerif-Regular.ttf") format("truetype");
  //       font-weight: normal;
  //       font-style: normal;
  //     }

  //   </style>
  // `);
};
export default FontFamilyCustomPlugin;
