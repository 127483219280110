import { Form, Input } from "antd";
import { JSON_SUB_TYPE, QuestionProps } from "../../../../../types/course";
import { QuestionTypeEnum } from "../../../../../constants";


function ShortText({ data, disabled, initialData }: QuestionProps) {
  console.log('data', data)
  return (
    <Form.Item
      // initialValue={initialData}
      name={`shortText-${data?.id}-${QuestionTypeEnum.SHORT_TEXT}-${JSON_SUB_TYPE.ContentRequest}`}
    >
      <Input.TextArea
        disabled={disabled}
        style={{ width: "50%", resize: "none" }}
        rows={3}
        placeholder="Nhập câu trả lời của bạn"
      />
    </Form.Item>
  );
}

export default ShortText;
