import { AxiosResponse } from "axios";
import { axiosConfigV2OpenAPI } from "../../../config/api/configOpenApiV2";
import { axiosConfig } from "../../../config/api/configApi";
import { axiosConfigV2 } from "../../../config/api/configApiv2";
import { axiosConfigToUpload } from "../../../config/api/configApiUpload";

export const getMoocLecturerById: (id: number) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfigV2.get(`/mooc-university-teacher/${id}`)
}

export const getAcademicLevelLecturer: () => Promise<AxiosResponse<any>> = () => {
    return axiosConfigV2OpenAPI.get(`/profile/get-academic-level`)
}

export const getIndustryGroupLecturer: () => Promise<AxiosResponse<any>> = () => {
    return axiosConfig.get(`/lookup/get-industry-group`)
}

export const getIndustryLecturer: (codeGroup: string) => Promise<AxiosResponse<any>> = (codeGroup) => {
    return axiosConfig.get(`/lookup/get-industry?codeGroup=${codeGroup}`)
}

export const downloadLecturerTemplateFile: () => Promise<AxiosResponse<any>> = () => {
    return axiosConfigV2.get(`/mooc-university-teacher/user/download-template-excel`, {responseType: "arraybuffer",})
}

export const getPositionsLecturer: () => Promise<AxiosResponse<any>> = () => {
    return axiosConfigV2OpenAPI.get(`/profile/get-positions`)
}

export const getAddressProvinces: () => Promise<AxiosResponse<any>> = () => {
    return axiosConfigV2OpenAPI.get(`/address/provinces?code=4`)
}

export const getMoocEnterpriseUniversity: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-university/get-by-condition`, data)
}

export const getLecturerDetailByFilter: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-history/user-profile/get-all-by-filter`,data);
}

export const getAllLecturerByFilter: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-university-teacher/get-all-by-filter`, data)
}

export const exportExcelMoocLecturerDetail: (id: any) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfigV2.get(`/mooc-university-teacher/export-excel/${id}`, {responseType: "arraybuffer",})
}

export const exportExcelMoocLecturer: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-university-teacher/export-excel`, data, {responseType: "arraybuffer",})
}

export const importExcelMoocLecturer: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigToUpload.post(`/mooc-university-teacher/import-excel`, data, {responseType: "arraybuffer",})
}

export const addMoocLecturer: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-university-teacher`, data)
}

export const updateMoocLecturer: (userId: number, data: any) => Promise<AxiosResponse<any>> = (userId, data) => {
    return axiosConfigV2.put(`/mooc-university-teacher/${userId}`, data)
}

export const changeStatusLecturer: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.put(`/mooc-university-teacher/change-status`, data)
}

export const deleteMoocLecturer: (id: number) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfigV2.delete(`/mooc-university-teacher/${id}`)
}

export const importApprovedTeacherMoocSynchronizationData: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.put(`/mooc-synchronization-data/approved/teacher`, data)
}

export const getTeacherMoocSynchronizationDataByFilter: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-synchronization-data/search/teacher`, data)
}