import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  FormInstance,
  Progress,
  Spin,
  Tooltip,
  Upload,
  UploadProps,
} from "antd";

import { isEmpty, toNumber } from "lodash";
import { notifyWarning } from "../../../../../../../utils/notification";
import {
  CloudIcon,
  DeleteIcon,
  HoursGlassIcon,
  QuestionMarkIcon,
  ReloadIcon,
} from "../../../../../../../components/icons";
import { bytesToMegabytesBinary } from "../../../../../../../utils/format";
import { QuestionProps, QuizProps } from "../../../../../../../types/course";
import {
  JSON_SUB_TYPE,
  MAX_QUIZ_FILE_UPLOAD_SIZE,
  QuestionTypeEnum,
} from "../../../../../../../constants/course";
import {
  getFileDetailsFromUrl,
  getFileSize,
} from "../../../../../../../utils/uploadImage";
import Countdown from "../../../../../../../components/countdown/CountDown";
import useKeyword from "../../../../../../../hooks/useKeyword";
import { startSingleQuiz } from "../../../../../../../service/learning-course";
import { Link } from "react-router-dom";
import { uploadFileV2 } from "../../../../../../../service/uploadFile/infoDetailApi";

interface FileUploadProps extends QuestionProps {
  data?: QuizProps;
  form?: FormInstance<any>;
  onChange?: (value: any) => void;
}

const { Dragger } = Upload;

function FileUpload({
  data,
  pause,
  configs,
  disabled,
  initialData,
  onFinish = () => {},
  onChange = () => {},
}: FileUploadProps) {
  const maxSize = data?.settingGeneral
  ? data?.settingGeneral.fileCapacity * 1024 * 1024
  : MAX_QUIZ_FILE_UPLOAD_SIZE;

  const initialTime =
    data?.timeToCompleted || configs?.actionConfig?.timeForQuiz || 0;
  const [fileInfo, setFileInfo] = useState<any>({});
  const [progress, setProgress] = useState<number>(0);
  const [isUploadDone, setIsUploadDone] = useState<boolean>(true);
  const [filePath, setFilePath] = useState<string>("");

  const blockId = toNumber(useKeyword("blockId"));
  const currentScreen = useKeyword("screen");
  const isSequentiallyQuiz = configs?.generalConfig?.sequentiallyQuiz;
  const [loading, setLoading] = useState<boolean>(false);
  const [canStart, setCanStart] = useState<boolean>(false);

  useEffect(() => {
    // Xử lý câu hỏi có đếm thời gian riêng
    if (currentScreen === "train") {
      if (!isEmpty(data) && isSequentiallyQuiz && initialTime) {
        const startSingle = async () => {
          try {
            setLoading(true);
            await startSingleQuiz({
              blockId: blockId,
              quizId: data?.id,
            });
          } catch (err: any) {
          } finally {
            setCanStart(true);
            setLoading(false);
          }
        };
        startSingle();
      }
    }
  }, [isSequentiallyQuiz]);

  const handleChangeFile: UploadProps["onChange"] = async (info) => {
    const file = info.file.originFileObj;

    if (file && isCorrectFile(file)) {
      setIsUploadDone(false);
      setFileInfo(file);
      const formData = new FormData();
      formData.append("file", file);

      try {
        const res = await uploadFileV2(formData);
        setProgress(100);

        setFilePath(res?.data.filePath);
        setTimeout(() => setIsUploadDone(true), 500);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (!isEmpty(initialData?.answer[0])) {
      const { fileName, fileType } = getFileDetailsFromUrl(
        initialData?.answer[0]
      );
      getFileSize(initialData?.answer[0]).then((fileSize) => {
        setFileInfo({ name: fileName, type: fileType, size: fileSize });
      });
    }
  }, []);

  useEffect(() => {
    if (filePath)
      onChange({
        [`fileUpload-${data?.id}-${QuestionTypeEnum.FILE_UPLOAD}-${JSON_SUB_TYPE.ContentRequest}`]:
          filePath,
      });
  }, [filePath]);

  const isCorrectFile = (file: any) => {
    const acceptFile = [
      // pdf
      "application/pdf",
      // excel
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // word
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      // powerpoint
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ];

    const isJpgOrPng = acceptFile.includes(file.type);
    const isCorrectFile = file.size < maxSize;
    if (isJpgOrPng && isCorrectFile) {
      return true;
    }
    return false;
  };

  const renderFileType = () => {
    const type = fileInfo?.type;
    switch (type) {
      case "xlsx":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "xlxs-icon.svg";
      case "xls":
      case "application/vnd.ms-excel":
        return "xls-icon.svg";
      case "ppt":
      case "application/vnd.ms-powerpoint":
        return "ppt-icon.svg";
      case "pptx":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return "pptx-icon.svg";
      case "doc":
      case "application/msword":
        return "doc-icon.svg";
      case "docx":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "docx-icon.svg";
      case "application/pdf":
      case "pdf":
        return "pdf-icon.svg";
      default:
        break;
    }
  };

  const handleDeleteFile = () => {
    setFileInfo({});
    setProgress(0);
    onChange({
      [`fileUpload-${data?.id}-${QuestionTypeEnum.FILE_UPLOAD}-${JSON_SUB_TYPE.ContentRequest}`]:
        null,
    });
  };

  const beforeUpload = (file: any) => {
    if (!isCorrectFile(file)) {
      notifyWarning(
        "Bạn phải tải lên file PDF, Word, excel hoặc PowperPoint (Tối đa 2 MB)!"
      );
      setFileInfo({});
      setProgress(0);
    }
  };

  const renderContent = () => {
    if (isEmpty(fileInfo)) {
      return (
        <div>
          <Dragger
            disabled={disabled}
            id={`file-upload-${data?.id}`}
            className="dragger-record"
            multiple={false}
            showUploadList={false}
            onChange={handleChangeFile}
            beforeUpload={beforeUpload}
          >
            <div className="center">
              <div className="icon-file-upload">
                <CloudIcon />
              </div>
            </div>

            <p>
              <span className="font-weight-6 text-primary">
                Nhấn để tải tệp
              </span>{" "}
              hoặc kéo thả tệp vào đây
            </p>
            <p className="font-size-12 mt-1">
              PDF,word, excel hoặc PowperPoint (Tối đa 2 MB)
            </p>
          </Dragger>
        </div>
      );
    }
    return (
      <div className="flex mt-2" key={`fileUpload-${data?.id}`}>
        <div className="file-uploaded">
          <div className="file-info flex gap-8">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/${renderFileType()}`}
              alt="pdf-icon"
            />

            <div className="file-detail w-full">
              <p className="font-size-14">{fileInfo?.name}</p>
              <p className="font-size-14">
                {bytesToMegabytesBinary(fileInfo?.size)}MB
              </p>
              {!isUploadDone && (
                <Progress strokeColor="#7F56D9" percent={progress} />
              )}
            </div>
          </div>
        </div>

        <div className="file-upload-btn-group flex flex-column justify-space-between ml-2">
          <Button className="file-upload-btn" onClick={handleDeleteFile}>
            <label htmlFor="file-upload">
              <ReloadIcon />
            </label>
          </Button>
          <Button
            className="file-upload-btn btn-delete"
            onClick={handleDeleteFile}
          >
            <DeleteIcon />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Spin spinning={loading}>
        <div className="question-header mb-3">
          {configs?.actionConfig?.timeForQuiz && configs?.generalConfig?.sequentiallyQuiz ? (
            <div className="question-time flex align-center gap-8">
              <HoursGlassIcon />
              <div className="text-primary font-size-14 line-height-20 font-weight-6 flex align-center gap-8">
                Trả lời câu hỏi trong:
                <Countdown
                  pause={pause}
                  initialTime={canStart ? toNumber(initialTime) : 0}
                  onFinish={onFinish}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="question-title">
            {/* <Flex
              justify="space-between"
              className="font-size-16 line-height-24 font-weight-6"
            >
              Câu hỏi {toNumber(data?.index) + 1}:{" "}
              {configs?.generalConfig?.showQuizScore && (
                <span className="total-point font-size-14">
                  {`(${data?.totalPoints || 0} điểm)`}
                </span>
              )}
            </Flex> */}
            <Flex align="flex-start" gap={8}>
              <div
                className="font-size-16 line-height-24 font-weight-6"
                dangerouslySetInnerHTML={{ __html: `${data?.question}` }}
              ></div>
              {data?.settingHint?.isDisplayInstruction && data?.isShowHint ? (
                <Tooltip
                  placement="bottom"
                  title={
                    <div>
                      {data?.settingHint?.content ? (
                        <p className="text-14 font-weight-5">
                          Hướng dẫn: {data?.settingHint?.content}
                        </p>
                      ) : (
                        "Không có hướng dẫn"
                      )}

                      {data?.settingHint?.schema ? (
                        <p className="font-size-14 mt-2">
                          Đường dẫn tham khảo:{" "}
                          <Link
                            target="_blank"
                            to={`${data?.settingHint?.uri}${data?.settingHint?.schema}`}
                          >
                           {`${data?.settingHint?.uri}${data?.settingHint?.schema}`}
                          </Link>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  }
                >
                  <span className="quest-icon text-secondary w-max-content">
                    <QuestionMarkIcon />
                  </span>
                </Tooltip>
              ) : (
                ""
              )}
            </Flex>
          </div>
        </div>
        {renderContent()}
      </Spin>
    </div>
  );
}

export default FileUpload;
