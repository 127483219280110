// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sider {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  text-align: left;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  z-index: 9; }
  .sider .menu-wrapper {
    border-inline-end: 0 !important; }
  .sider .sidebar-screen {
    max-height: 850px; }
    @media (max-width: 1366px) {
      .sider .sidebar-screen {
        max-height: 670px; } }
`, "",{"version":3,"sources":["webpack://./src/layout/sidebar/sidebar.scss"],"names":[],"mappings":"AAEA;EACE,yCAAyC;EACzC,gBAAgB;EAChB,eAAe;EACf,0BAA0B;EAC1B,UAAU,EAAA;EALZ;IAQI,+BAA+B,EAAA;EARnC;IAYI,iBAAiB,EAAA;IAEjB;MAdJ;QAeM,iBAAiB,EAAA,EAEpB","sourcesContent":["@import \"../../styles/mixin\";\n\n.sider {\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);\n  text-align: left;\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.85);\n  z-index: 9;\n\n  .menu-wrapper {\n    border-inline-end: 0 !important;\n  }\n\n  .sidebar-screen {\n    max-height: 850px;\n\n    @media (max-width: 1366px) {\n      max-height: 670px;\n    }\n  }\n}\n\n\n@include respond(tab-port) {\n  // .sider {\n  //   display: none;\n  // }\n\n  // .menu-mobile {\n  //   padding: 0 10px;\n  // }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
