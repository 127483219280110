import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../config/api/configApiv2";
import { axiosConfigToUpload } from "../../config/api/configApiUpload";

export const getListSequenceById: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/mooc-courses/get-course-detail`, data);
};

export const getTableDataDetailSequence: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.get(
    `/history-course/get-sequence-history-by-id?sequenceId=${data.sequenceId}&page=${data.page}&size=${data.size}`
  );
};

export const getTableDataDetailSection: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.get(
    `/history-course/get-section-history-by-id?sectionId=${data.sequenceId}&page=${data.page}&size=${data.size}`
  );
};

export const getTableDataDetailUnit: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.get(
    `/history-course/get-unit-history-by-id?unitId=${data.sequenceId}&page=${data.page}&size=${data.size}`
  );
};

export const getAllSectionByCourseId: (
  id: string | undefined
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/course-section/get-section-by-courseId/${id}`);
};

export const getAllSequenceByCourseId: (
  id: string | undefined
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/course-sequence/get-sequence-by-courseId/${id}`);
};

export const getSyncTemplate: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/synchronization/export-excel-template`, {
    responseType: "arraybuffer",
  });
};

export const syncSequenceCourse: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigToUpload.post(`/synchronization/upload-excel`, data);
};

export const getListLecturer: (id: string | undefined) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.get(`/mooc-courses/get-lecturer-by-course/${id}`);
};

export const assignCourseLecturer: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/mooc-courses/assign-lecturer`, data);
};

export const updateSettingUnit: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigToUpload.put(`/mooc-course-unit/update-config`, data);
};

export const copyTemplateSection: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/mooc-courses/duplicate-section`, data);
};

export const copyTemplateSequence: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/mooc-courses/duplicate-sequence`, data);
};

export const getSequenceBySection: (id: string | undefined) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.get(`/course-sequence/get-sequence-by-section/${id}`);
};

export const getUnitBySequence: (id: string | undefined) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.get(`/mooc-course-unit/get-units-by-sequence/${id}`);
};

export const copyTemplateUnit: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/mooc-courses/duplicate-unit`, data);
};

export const createTemplateSection: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/mooc-courses/import-section-template`, data);
};

export const createTemplateSequence: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/mooc-courses/import-sequence-template`, data);
};

export const getListSectionTemplate: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/mooc-courses/search-key-section`, data);
};

export const getListSequenceTemplate: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/mooc-courses/search-key-sequence`, data);
};

export const saveSectionTemplateCourse: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/mooc-courses/save-course-section-template`, data);
};

export const saveSequenceTemplateCourse: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/mooc-courses/save-course-sequence-template`, data);
};


export const getSearchQuizCourse: (dataReq?: any) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfigV2.post(`/mooc-courses/search-quiz-by-course`, dataReq)
}

export const getHistoryQuiz: (quizId: number) => Promise<AxiosResponse<any>> = (quizId) => {
  return axiosConfigV2.get(`/mooc-course-block-quiz/get-history-block-quiz/${quizId}`)
}
