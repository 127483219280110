import React, { useEffect, useState } from 'react';
import { Button, Col, Collapse, CollapseProps, Drawer, Form, Input, message, Modal, Row, Space, Typography } from 'antd'

import FormInput from "../../../../components/form-input/FormInput";
import FormItemDropdown from "../../../../components/form-dropdown/FormItemDropdown";
import { FormInstance } from "antd/lib";
import "./index.scss"
import { CloseOutlined, ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { regexEmailV2, regexPassword } from '../../../../utils/regex';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { createBasicAdminAccount, getAllEnterpriseUniversity, getDetailBasicAdminAccount, getDetailManagementParticipatePlatform, getListManagementParticipatePlatform, getRoleDefaultAccount, updateBasicAdminAccount } from '../../../../service/management-participate-platform';
import { getpositions } from '../../../user-info/account/services';
import { getAllEnterpriseCustomer, getRoles } from '../../../../service/list-account-users/listAccountUsersApi';
import _ from 'lodash';

type Props = {
	open: boolean
	handleClose: () => void
	fetchData: () => void
	detailData: any
}

const FormCreateUser = (props: Props) => {
	const { open, handleClose, fetchData, detailData } = props
	const [form] = Form.useForm()
	const { Panel } = Collapse;
	const [status, setStatus] = useState<number>(1)
	const [listParticipant, setListParticipant] = useState<any>([]);
	const [listRole, setListRole] = useState<any>([]);
	const [listPosition, setListPosition] = useState<any>([]);
	const [listEnterprise, setListEnterprise] = useState<any>([]);
	const [password, setPassword] = useState<any>("")
	const [requestChoose, setRequestChoose] = useState<any>(0)
	const [enterPriseId, setEnterPriseId] = useState<any>(0)
	const [qtcsId, setQtcsId] = useState<any>(0)
	const [dataDetail, setDataDetail] = useState<any>({})

	const validations = {
		contactPeople: {
			required: true,
			message: "Vui lòng nhập thông tin người liên hệ"
		},
		contactRolePeople: {
			required: true,
			message: "Vui lòng chọn chức vụ người liên hệ"
		},
		phone: {
			required: true,
			message: "Vui lòng nhập số điện thoại"
		},
		emailContact: [
			{
				required: true,
				message: "Vui lòng nhập địa chỉ email"
			},
			({ getFieldValue }: { getFieldValue: (name: string) => StoreValue }) => ({
				validator: (_: unknown, value: any) => {
					if (!isEmpty(value) && !value.match(new RegExp(regexEmailV2))) {
						return Promise.reject(
							new Error("Định dạng email không đúng, vui lòng nhâp lại")
						);
					}
					return Promise.resolve();
				},
			}),
		],
		trainingFacility: {
			required: true,
			message: "Vui lòng chọn cơ sở đào tạo"
		},
		emailLogin: [
			{
				required: true,
				message: "Vui lòng nhập địa chỉ email"
			},
			({ getFieldValue }: { getFieldValue: (name: string) => StoreValue }) => ({
				validator: (_: unknown, value: any) => {
					if (!isEmpty(value) && !value.match(new RegExp(regexEmailV2))) {
						return Promise.reject(
							new Error("Định dạng email không đúng, vui lòng nhâp lại")
						);
					}
					return Promise.resolve();
				},
			}),
		],
		roleLogin: {
			required: true,
			message: "Vui lòng chọn vai trò"
		},
		passwordLogin: [
			{
				required: dataDetail?.id ? false : true,
				message: "Vui lòng nhập mật khẩu",
			},
			{
				min: 8,
				max: 25,
				message: "Độ dài mật khẩu từ 8-25 ký tự.",
			},
			{
				pattern: regexPassword,
				message:
					"Mật khẩu phải bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt, và có độ dài từ 8-25 ký tự.",
			},
			({ getFieldValue }: { getFieldValue: (name: string) => StoreValue }) => ({
				validator(_: RuleObject, value: StoreValue) {
					if (value && getFieldValue("oldPassword") === value) {
						return Promise.reject(
							new Error("Mật khẩu mới phải khác mật khẩu hiện tại.")
						);
					}
					return Promise.resolve();
				},
			}),
		],
		passwordLoginConfirm: [
			{
				required: dataDetail?.id ? false : true,
				message: "Vui lòng xác nhập mật khẩu mới",
			},
			({ getFieldValue }: { getFieldValue: (name: string) => StoreValue }) => ({
				validator(_: RuleObject, value: StoreValue) {
					if (!isEmpty(value)) {
						if (getFieldValue("passwordLogin") === value) {
							return Promise.resolve();
						}
						return Promise.reject(
							new Error("Mật khẩu không khớp.")
						);
					}
					return Promise.resolve();
				},
			}),
		],
	}

	const blockOne = () => (
		<>
			<Row gutter={24}>
				<Col span={24}>
					<Form.Item name="managementAgency" label="Yêu cầu đăng ký tham gia nền tảng" >
						<FormItemDropdown
							options={listParticipant}
							onChange={setRequestChoose}
							allowClear={true}
							placeholder="Chọn đơn vị yêu cầu tham gia nền tảng"
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item name="contactPeople" label="Người liên hệ" rules={[validations.contactPeople]}>
						<FormInput
							placeholder="Nhập người liên hệ"
							disabled={(requestChoose !== 0 && requestChoose !== undefined) || dataDetail?.universityRegisterId}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item name="contactRolePeople" label="Chức vụ" rules={[validations.contactRolePeople]}>
						<FormItemDropdown
							options={listPosition}
							allowClear={true}
							placeholder="Chọn chức vụ"
							disabled={(requestChoose !== 0 && requestChoose !== undefined) || dataDetail?.universityRegisterId}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={24} className="">
				<Col span={12}>
					<Form.Item name="phone" label="Số điện thoại" rules={[validations.phone]}>
						<FormInput
							placeholder="Nhập số điện thoại người liên hệ"
							disabled={(requestChoose !== 0 && requestChoose !== undefined) || dataDetail?.universityRegisterId}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item name="emailContact" label="Email" rules={validations.emailContact}>
						<FormInput
							placeholder="Nhập email người liên hệ"
							disabled={(requestChoose !== 0 && requestChoose !== undefined) || dataDetail?.universityRegisterId}
						/>
					</Form.Item>
				</Col>
			</Row>
		</>
	)

	const blockTwo = () => (
		<>
			<Row gutter={24}>
				<Col span={24}>
					<Form.Item name="trainingFacility" label="Cơ sở đào tạo" rules={[validations.trainingFacility]}>
						<FormItemDropdown
							options={listEnterprise}
							allowClear={true}
							placeholder="Chọn cơ sở đào tạo"
							disabled={(requestChoose !== 0 && requestChoose !== undefined) || dataDetail?.universityRegisterId}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item name="emailLogin" label="Email đăng nhập" rules={validations.emailLogin}>
						<FormInput
							placeholder='Nhập email đăng nhập'
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item name="roleLogin" label="Vai trò" rules={[validations.roleLogin]}>
						<FormItemDropdown
							options={listRole}
							allowClear={true}
							placeholder="Chọn vai trò"
							modeDropdown="multiple"
						/>
					</Form.Item>
				</Col>
			</Row>
			{
				dataDetail?.id ? null :
					<Row gutter={24} className="">
						<Col span={12}>
							<Form.Item name="passwordLogin" label="Mật khẩu" rules={validations.passwordLogin}>
								<FormInput
									placeholder="Nhập mật khẩu cho tài khoản"
									style={{ height: '32px' }}
									passwordInput={true}
									autoComplete="new-password"
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="passwordLoginConfirm" label="Xác nhận mật khẩu" rules={validations.passwordLoginConfirm}>
								<FormInput
									placeholder="Xác nhận mật khẩu cho tài khoản"
									passwordInput={true}
									style={{ height: '32px' }}
									autoComplete="new-password"
								/>
							</Form.Item>
						</Col>
					</Row>
			}
			<Row gutter={24} className="">
				<Col span={24}>
					<Form.Item name="status" label="Trạng thái">
						<Space.Compact block>
							<Button className={status === 1 ? 'active' : ''} onClick={() => setStatus(1)}>Mở khóa</Button>
							<Button className={status === 2 ? 'active' : ''} onClick={() => setStatus(2)}>Khóa</Button>
						</Space.Compact>
					</Form.Item>
				</Col>
			</Row>
		</>
	)

	const items: CollapseProps['items'] = [
		{
			key: '1',
			label: <Typography.Title level={5}><strong>Thông tin cơ sở đào tạo</strong></Typography.Title>,
			children: blockOne(),
		},
		{
			key: '2',
			label: <Typography.Title level={5}><strong>Thông tin tài khoản</strong></Typography.Title>,
			children: blockTwo(),
		},
	];

	//lay danh sach yeu cau tham gia nen tang
	const getListRequestJoin = async () => {
		const data = {
			page: 1,
			size: 10000,
			keyword: "",
			status: dataDetail?.id ? null : 0
		};

		await getListManagementParticipatePlatform(data)
			.then((res) => {
				const { data } = res.data
				const listOption = data?.data?.map((item: any) => ({
					id: item?.id,
					name: item?.name
				}))
				setListParticipant(listOption)
			})
	}

	//lay danh sach yeu cau tham gia nen tang
	const getEnterprise = async () => {
		const data = {
			page: 1,
			size: 10000,
			keyword: "",
		};

		await getAllEnterpriseUniversity(data)
			.then((res) => {
				const listOption = res?.data?.data?.content?.map((item: any) => ({
					id: item?.universityId,
					name: item?.name
				}))
				setListEnterprise(listOption)
			})
	}

	//lay detail yeu cau tham gia nen tang
	const getDetailRequest = async () => {
		await getDetailManagementParticipatePlatform(requestChoose).then((res) => {
			if (res.status === 200) {
				const detail = res.data?.data
				form.setFieldsValue({
					contactPeople: detail?.userContact,
					contactRolePeople: detail?.userContactPositionId,
					phone: detail?.userContactPhone,
					emailContact: detail?.userContactEmail,
					trainingFacility: detail?.name,
				})
				setEnterPriseId(detail?.universityId)
			}
		})
	}

	//lay danh sach chuc vu
	const getPostion = async () => {
		await getpositions()
			.then((res) => {
				const { data } = res.data
				setListPosition(data)
			})
	}

	//lay danh sach vai trò
	const getRole = async () => {
		await getRoles()
			.then((res) => {
				const { data } = res.data
				setListRole(data)
			})
	}

	//lay danh sach vai trò default
	const getRoleDefault = async () => {
		const data = {
			keyword: "",
			pageNumber: 1,
			pageSize: 1000,
			defaultQTCS: true
		}
		await getRoleDefaultAccount(data)
			.then((res) => {
				if (!isEmpty(res.data.data)) {
					form.setFieldValue("roleLogin", res.data?.data?.[0]?.id)
				}
			})
	}

	const createAccount = async (values: any) => {
		const data = {
			universityRegisterId: values.managementAgency,
			universityId: requestChoose !== 0 ? enterPriseId : values.trainingFacility,
			userContact: values.contactPeople,
			userContactPhone: values.phone,
			userContactEmail: values.emailContact,
			userContactPosition: [values.contactRolePeople],
			userEmail: values.emailLogin,
			userPassword: values.passwordLogin ?? null,
			userRePassword: values.passwordLoginConfirm ?? null,
			userStatus: status === 1 ? true : false,
			roles: _.isArray(values.roleLogin) ? values.roleLogin : [values.roleLogin]
		}
		if (dataDetail?.id) {
			await updateBasicAdminAccount(detailData?.id, data).then((res) => {
				if (res.status === 200) {
					message.success("Sửa tài khoản thành công")
				}
			})

		} else {
			await createBasicAdminAccount(data).then((res) => {
				if (res.status === 200) {
					message.success("Tạo tài khoản thành công")
				}
			})
		}
		handleClose()
		fetchData()
		form.resetFields()
		setStatus(1)
    setDataDetail([])
	}

	const getListDataDetail = async () => {
		await getDetailBasicAdminAccount(detailData?.id).then((res) => {
			if (res.status === 200) {
				const detail = res.data?.data
				setDataDetail(res.data?.data)
				console.log('detail', detail)
				form.setFieldsValue({
					managementAgency: detail?.universityRegisterId ? detail?.universityRegisterId : undefined,
					contactPeople: detail?.userContact,
					contactRolePeople: detail?.userContactPositionId,
					phone: detail?.userContactPhone,
					emailContact: detail?.userContactEmail,
					trainingFacility: detail?.unitId || detail?.unitName,
					emailLogin: detail?.username,
					roleLogin: detail?.roles?.map((item: any) => { return item.id }),
				})
				// setRequestChoose(detail?.universityRegisterId)
				setEnterPriseId(detail?.unitId)
        setStatus(detail?.isActive ? 1 : 2)
			}
		})
	}

	useEffect(() => {
		if (open) {
			getListRequestJoin()
			getPostion()
			getRole()
			if(!detailData?.id) {
        getRoleDefault()
      }
			getEnterprise()
		}
	}, [open])

	useEffect(() => {
		console.log(requestChoose)
		if ((requestChoose !== 0 && requestChoose !== undefined) || (dataDetail?.universityRegisterId)) {
			getDetailRequest()
		} else {
			form.resetFields()
		}
	}, [requestChoose])

	useEffect(() => {
		if (detailData?.id) {
			getListDataDetail();
		} else {
			form.resetFields()
		}
	}, [detailData])

	useEffect(() => {
		if (dataDetail?.id) {
			getListRequestJoin();
		}
	}, [dataDetail])

	const clearFilter = () => {
		form.resetFields()
		setStatus(1)
		setDataDetail([])
		handleClose()
	}

	return (
		<>
			<Form.Provider onFormFinish={(name, { values, forms }) => {
				if (name === "add-chapter") {
					Modal.confirm({
						title: `Xác nhận tạo tài khoản`,
						content: `Đảm bảo tất cả các thông tin liên quan đã được kiểm tra trước khi xác nhận.`,
						onOk: () => {
							createAccount(values)
						},
						okText: "Xác nhận",
						cancelText: "Huỷ",
						centered: true,
						icon: <ExclamationCircleOutlined style={{ color: "#1677FF" }} />,
						footer: (_, { OkBtn, CancelBtn }) => (
							<>
								<CancelBtn />
								<OkBtn />
							</>
						),
					});
				}
			}}>
				<Drawer
					title={`${dataDetail?.id ? "Sửa" : "Tạo"} tài khoản Quản trị cơ sở (QTCS)`}
					open={open}
					onClose={clearFilter}
					width={736}
					extra={
						<Space>
							<Button onClick={() => {
								form.resetFields()
								clearFilter()
							}} className="btn btn-outlined">
								<CloseOutlined />
								<Typography.Text>
									Hủy
								</Typography.Text>
							</Button>
							<Button className="btn btn-primary"
								onClick={() => form.submit()
								}>
								<SaveOutlined />
								<Typography.Text>
									Lưu
								</Typography.Text>
							</Button>
						</Space>
					}
				>
					<Form
						className="create-form-facility-management"
						layout={"vertical"}
						form={form}
						name="add-chapter"
						autoComplete="off"
					>
						<Collapse defaultActiveKey={['1', '2']} ghost items={items} />
					</Form>
				</Drawer>
			</Form.Provider>
		</>
	);
};

export default FormCreateUser;
