import React, { useEffect, useState } from "react";
import {
  ArrowLeftOutlined,
  EditOutlined,
  InfoCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Flex,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Tooltip,
} from "antd";
import { isArray, isEmpty, toNumber } from "lodash";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  findHavingSchoolForStudent,
  getAllEnterprise,
  getNotification,
  updateNotification,
} from "../../../service/notifications";
import {
  EnterpriseProps,
  NotificationProps,
  SchoolProps,
} from "../../../types/notifications";
import { routesConfig } from "../../../config/routes";
import { NotificationStatus } from "../../../constants/notifications";
import useDebounce from "../../../hooks/useDebounce";
import TextEditor from "../TextEditor";
import TextArea from "antd/es/input/TextArea";

function EditNotification() {
  const navigate = useNavigate();
  const params = useParams();

  const notificationId = toNumber(params?.id);
  const [form] = Form.useForm();

  const [notification, setNotification] = useState<NotificationProps>();
  const [loading, setLoading] = useState<boolean>(false);
  const [schools, setSchools] = useState<EnterpriseProps[]>([]);
  const [schoolsForSv, setSchoolForSv] = useState<SchoolProps[]>([]);
  const [keywordSv, setKeywordSv] = useState<string>("");
  const [keyword, setKeyword] = useState<string>("");
  const keyDebounce = useDebounce(keyword, 100);
  const keywordSvDebounce = useDebounce(keywordSv, 100);

  const forQtcs = Form.useWatch("forQtcs", form);
  const forGv = Form.useWatch("forGv", form);
  const forSv = Form.useWatch("forSv", form);

  const [content, setContent] = useState("");
  const [studentOption, setStudentOption] =
    useState<number>(0); /* 0: tat ca sinh vien, 1: tuy chon sinh vien */

  const systemChecked = Form.useWatch("systemChecked", form); // check qtht
  const isForQtcs = Form.useWatch("isForQtcs", form); // check qtcs
  const isForSv = Form.useWatch("isForSv", form); // check sinh vien
  const isForGv = Form.useWatch("isForGv", form); // check giang vien
  const isChooseOptional = Form.useWatch("optional", form); // check optional
  const isSendToAll = Form.useWatch("sendToAll", form); // check sendToAll

  const schoolsOpt = [
    {
      label: "Tất cả cơ sở đào tạo",
      value: "ALL ENTERPRISES",
    },
  ].concat(
    schools?.map((school: any) => ({
      label: school?.name,
      value: school?.uuid,
    }))
  );

  const schoolsOptForSv = [
    {
      label: "Tất cả cơ sở đào tạo",
      value: "ALL ENTERPRISES",
    },
  ].concat(
    schoolsForSv?.map((school: any) => ({
      label: school?.name,
      value: school?.id,
    }))
  );

  useEffect(() => {
    const getEnterprise = async () => {
      const res = await getAllEnterprise({
        page: 1,
        size: 100,
        keyword: keyDebounce,
      });
      setSchools(res.data.data?.content);
    };
    getEnterprise();
  }, [keyDebounce]);

  useEffect(() => {
    const getHavingSchool = async () => {
      const res = await findHavingSchoolForStudent(keywordSvDebounce);
      setSchoolForSv(res.data.data);
    };
    getHavingSchool();
  }, [keywordSvDebounce]);

  useEffect(() => {
    if (notificationId) {
      const getNotificationDetail = async () => {
        try {
          setLoading(true);
          const res = await getNotification(notificationId);
          const notify: NotificationProps = res.data.data;
          if (notify?.status === 2) {
            navigate(routesConfig.notifications);
            message.warning("Không thể sửa thông báo đã gửi");
          }

          setNotification(notify);
          form.setFieldsValue({
            systemChecked: notify?.isForQtht,
            isForQtcs: !isEmpty(notify?.forQtcs),
            isForGv: !isEmpty(notify?.forGv),
            isForSv: !isEmpty(notify?.forSv),
            forQtcs: notify?.forQtcs?.map((item: EnterpriseProps) => item.uuid),
            forGv: notify?.forGv?.map((item: EnterpriseProps) => item.uuid),
            forSv: notify?.forSv?.map((item: EnterpriseProps) => item.uuid),
            sendToAll: notify?.sendToAll,
            title: notify?.title,
            optional:
              !isEmpty(notify?.forSv) ||
              !isEmpty(notify?.forQtcs) ||
              !isEmpty(notify?.forGv) ||
              notify?.isForQtht,
          });
          setContent(notify?.message);
          if (
            notify?.forSv?.find(
              (item: EnterpriseProps) =>
                item.uuid === "ALL" || item.uuid === "all"
            )
          ) {
            setStudentOption(0);
          } else setStudentOption(1);
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      };
      getNotificationDetail();
    }
  }, [notificationId]);

  const breadcrumb = [
    {
      title: <Link to={routesConfig.notifications}>Danh sách thông báo</Link>,
    },
    {
      title: notificationId,
    },
  ];

  const handleEditNotify = (fieldsValue: any) => {
    Modal.confirm({
      type: "info",
      icon: <InfoCircleOutlined className="text-primary" />,
      title: <span className="font-weight-5">Xác nhận lưu thông báo</span>,
      content: (
        <span>
          Đảm bảo tất cả các thông tin liên quan đã được kiểm tra và cập nhập
          đầy đủ trước khi lưu
        </span>
      ),
      okText: "Xác nhận",
      cancelText: "Huỷ",
      onOk: async () => {
        try {
          setLoading(true);
          await updateNotification({
            ...getNotificationPayload(fieldsValue),
            id: notificationId,
            message: content,
          });
          navigate(`${routesConfig.notifications}/${notificationId}`);
          message.success("Lưu thông báo thành công!");
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const getNotificationPayload = (fieldsValue: any) => {
    if (fieldsValue?.sendToAll) {
      return {
        title: fieldsValue?.title,
        isForQtht: fieldsValue?.systemChecked,
        sendToAll: fieldsValue?.sendToAll,
      };
    }
    return {
      title: fieldsValue?.title,
      isForQtht: fieldsValue?.systemChecked,
      sendToAll: false,
      forQtcs: fieldsValue?.isForQtcs
        ? getEnterprisePayload(fieldsValue?.forQtcs)
        : [],
      forGv: fieldsValue?.isForGv
        ? getEnterprisePayload(fieldsValue?.forGv)
        : [],
      forSv: fieldsValue?.isForSv
        ? studentOption === 1
          ? getSchoolForSvPayload(fieldsValue?.forSv)
          : [
              {
                uuid: "ALL",
                name: "ALL",
              },
            ]
        : [],
    };
  };

  const getEnterprisePayload = (uuids: string[] | 0) => {
    if (isArray(uuids) && isEmpty(uuids)) return [];

    if (uuids === 0)
      return [
        {
          uuid: "ALL ENTERPRISES",
          name: "ALL ENTERPRISES",
        },
      ];

    return uuids?.map((uuid: string) => {
      const enterprise = schools?.find(
        (item: EnterpriseProps) => item?.uuid === uuid
      );
      if (enterprise) return enterprise;

      return {
        uuid: "ALL ENTERPRISES",
        name: "ALL ENTERPRISES",
      };
    });
  };

  const getSchoolForSvPayload = (ids: string[] | 0) => {
    if (isArray(ids) && isEmpty(ids)) return [];

    if (ids === 0)
      return [
        {
          uuid: "ALL ENTERPRISES",
          name: "ALL ENTERPRISES",
        },
      ];

    return ids?.map((id: string) => {
      const school = schoolsForSv?.find((item: SchoolProps) => item?.id === id);
      if (school)
        return {
          uuid: school.id,
          name: school.name,
        };

      return {
        uuid: "ALL ENTERPRISES",
        name: "ALL ENTERPRISES",
      };
    });
  };

  const handleChangeStOpt = (value: number) => {
    setStudentOption(value);
    if (value === 1) {
      form.setFieldValue("forSv", []);
    }
  };

  const displayStatus = (status: number | string) => {
    switch (status) {
      case NotificationStatus.DRAFT:
        return (
          <Flex align="center">
            <span className="dot blue"></span>Nháp
          </Flex>
        );
      case NotificationStatus.SENT:
        return (
          <Flex align="center">
            <span className="dot green"></span> Đã gửi
          </Flex>
        );
      case NotificationStatus.RE_CALL:
        return (
          <Flex align="center">
            <span className="dot orange"></span>Thu hồi
          </Flex>
        );
      default:
        return "_";
    }
  };

  const displayDetailEnterprise = (uuids: string[], isSv?: boolean) => {
    if (isEmpty(uuids)) return "";

    if (isSv) {
      return uuids.map((uuid: string) => {
        const enterprise: SchoolProps | undefined = schoolsForSv?.find(
          (item: SchoolProps) => item.id === uuid
        );
        if (!isEmpty(enterprise)) return <p key={uuid}>{enterprise?.name}</p>;

        return "Tất cả các cở sở đào tạo";
      });
    }

    return uuids.map((uuid: string) => {
      const enterprise: EnterpriseProps | undefined = schools?.find(
        (item: EnterpriseProps) => item.uuid === uuid
      );
      if (!isEmpty(enterprise)) return <p key={uuid}>{enterprise?.name}</p>;

      return "Tất cả các cở sở đào tạo";
    });
  };

  const handleValuesChange = (value: any) => {
    const validateChange = ["isForQtcs", "isForSv", "isForGv"];
    if (validateChange.includes(Object.keys(value)[0]))
      form.setFields([
        {
          name: "forQtcs",
          errors: [],
        },
        {
          name: "forGv",
          errors: [],
        },
        {
          name: "forSv",
          errors: [],
        },
      ]);
  };

  const isDisableSubmit = () => {
    if (!isSendToAll && !isChooseOptional) return true;

    if (isSendToAll) return false;

    if (
      isChooseOptional &&
      !isForQtcs &&
      !isForGv &&
      !isForSv &&
      !systemChecked
    )
      return true;
    return false;
  };

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <div className="notifications-page w-full">
          <Form
            form={form}
            onFinish={handleEditNotify}
            onValuesChange={handleValuesChange}
          >
            <div className="notifications-page-header w-full">
              <Breadcrumb items={breadcrumb} className="mb-1" />
              <div className="flex justify-between align-center">
                <div className="flex gap-8 align-center">
                  <ArrowLeftOutlined
                    className="arrowleft-icon"
                    onClick={() => navigate(routesConfig.notifications)}
                  />
                  <h3 className="page-title">{notification?.id}</h3>
                </div>
                <Flex gap={8}>
                  <Button
                    className="btn-cancel-edit"
                    icon={<EditOutlined />}
                    onClick={() =>
                      navigate(
                        `${routesConfig.notifications}/${notificationId}`
                      )
                    }
                  >
                    Huỷ chỉnh sửa
                  </Button>
                  <Button
                    disabled={isDisableSubmit()}
                    htmlType="submit"
                    className="btn-primary"
                    icon={<SaveOutlined />}
                  >
                    Lưu thông tin
                  </Button>
                </Flex>
              </div>
            </div>
            <Spin spinning={loading}>
              <div className="notifications-detail-content">
                <p className="content-title font-weight-5 text-20">
                  {notification?.id}
                </p>
                <p className="text-14 secondary-color">
                  Nội dung chi tiết thông báo
                </p>
                <div className="break-line mt-2 mb-2" />
                <Row>
                  <Col span={7}>
                    <p className="font-weight-5 text-14">Mã thông báo</p>
                  </Col>
                  <Col span={17}>
                    <p className="text-14">{notification?.id}</p>
                  </Col>
                </Row>
                <div className="break-line mt-2 mb-2" />
                <Row>
                  <Col span={7}>
                    <p className="font-weight-5 text-14">Trạng thái</p>
                  </Col>
                  <Col span={17}>
                    <div className="text-14 flex align-center">
                      {displayStatus(notification?.status as number)}
                    </div>
                  </Col>
                </Row>
                <div className="break-line mt-2 mb-2" />
                <Row>
                  <Col span={7}>
                    <p className="font-weight-5 text-14">
                      <span className="text-danger">*</span> Đối tượng nhận
                      thông báo
                    </p>
                  </Col>
                  <Col span={17}>
                    <Row>
                      <Col span={24} className="flex align-center gap-8">
                        <Form.Item name="sendToAll" valuePropName="checked">
                          <Switch
                            className="mr-1"
                            onChange={() =>
                              form.setFieldValue("optional", undefined)
                            }
                          />
                        </Form.Item>
                        Toàn bộ người dùng trên hệ thống
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="flex align-center gap-8">
                        <Form.Item name="optional" valuePropName="checked">
                          <Switch
                            className="mr-1"
                            onChange={() =>
                              form.setFieldValue("sendToAll", undefined)
                            }
                          />
                        </Form.Item>
                        Tuỳ chọn
                      </Col>
                    </Row>
                    {isChooseOptional && (
                      <div className="optional">
                        <Row>
                          <Col span={24} className="flex align-center gap-8">
                            <Form.Item
                              name="systemChecked"
                              valuePropName="checked"
                            >
                              <Checkbox />
                            </Form.Item>
                            Quản trị hệ thống
                          </Col>
                        </Row>
                        <Row className="mt-1" align="middle">
                          <Col span={8} className="flex align-center gap-8">
                            <Form.Item name="isForQtcs" valuePropName="checked">
                              <Checkbox />
                            </Form.Item>
                            Quản trị cơ sở
                          </Col>
                          <Col span={16}>
                            <Tooltip title={displayDetailEnterprise(forQtcs)}>
                              <Form.Item
                                name="forQtcs"
                                rules={[
                                  {
                                    required: isForQtcs,
                                    message: "Vui lòng chọn đối tượng",
                                  },
                                ]}
                              >
                                <Select
                                  disabled={!isForQtcs}
                                  options={schoolsOpt}
                                  placeholder="Chọn đối tượng nhận thông báo"
                                  mode="multiple"
                                  maxTagCount="responsive"
                                  className="w-full"
                                  onSearch={(value: string) =>
                                    setKeyword(value.trim())
                                  }
                                  filterOption={false}
                                />
                              </Form.Item>
                            </Tooltip>
                          </Col>
                        </Row>
                        <Row className="mt-1" align="middle">
                          <Col span={8} className="flex align-center gap-8">
                            <Form.Item name="isForGv" valuePropName="checked">
                              <Checkbox />
                            </Form.Item>
                            Giảng viên
                          </Col>
                          <Col span={16}>
                            <Tooltip title={displayDetailEnterprise(forGv)}>
                              <Form.Item
                                name="forGv"
                                rules={[
                                  {
                                    required: isForGv,
                                    message: "Vui lòng chọn đối tượng",
                                  },
                                ]}
                              >
                                <Select
                                  disabled={!isForGv}
                                  placeholder="Chọn đối tượng nhận thông báo"
                                  options={schoolsOpt}
                                  mode="multiple"
                                  maxTagCount="responsive"
                                  className="w-full"
                                  onSearch={(value: string) =>
                                    setKeyword(value.trim())
                                  }
                                  filterOption={false}
                                />
                              </Form.Item>
                            </Tooltip>
                          </Col>
                        </Row>
                        <Row className="mt-1" align="middle">
                          <Col span={8} className="flex align-center gap-8">
                            <Form.Item name="isForSv" valuePropName="checked">
                              <Checkbox />
                            </Form.Item>
                            Sinh viên
                          </Col>
                          <Col span={16}>
                            <Select
                              disabled={!isForSv}
                              value={studentOption}
                              className="w-full"
                              defaultValue={0}
                              onChange={handleChangeStOpt}
                              options={[
                                { label: "Tất cả sinh viên", value: 0 },
                                { label: "Tuỳ chọn", value: 1 },
                              ]}
                            />
                          </Col>
                        </Row>
                        {studentOption === 1 && isForSv && (
                          <Row className="mt-1">
                            <Col span={8}></Col>
                            <Col span={16}>
                              <Tooltip
                                title={displayDetailEnterprise(forSv, true)}
                              >
                                <Form.Item
                                  name="forSv"
                                  initialValue={0}
                                  rules={[
                                    {
                                      required: isForSv,
                                      message: "Vui lòng chọn đối tượng",
                                    },
                                  ]}
                                >
                                  <Select
                                    disabled={!isForSv}
                                    options={schoolsOptForSv}
                                    placeholder="Chọn đối tượng nhận thông báo"
                                    mode="multiple"
                                    maxTagCount="responsive"
                                    onSearch={(value: string) =>
                                      setKeywordSv(value.trim())
                                    }
                                    filterOption={false}
                                  />
                                </Form.Item>
                              </Tooltip>
                            </Col>
                          </Row>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="notifications-detail-content">
                <label htmlFor="" className="text-16 font-weight-5">
                  <span className="text-danger mr-1 ">*</span>Tiêu đề
                </label>
                <Form.Item
                  name="title"
                  className="mb-2"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập tiêu đề của thông báo",
                    },
                  ]}
                >
                  <TextArea
                    autoSize={{ minRows: 3 }}
                    className="notification-title text-20 font-weight-5"
                  />
                </Form.Item>

                <label htmlFor="" className="text-16 font-weight-5">
                  Nội dung
                </label>
                <TextEditor
                  value={content}
                  onChange={setContent}
                  placeholder="Nhập nội dung thông báo"
                />
              </div>
            </Spin>
          </Form>
        </div>
      </Form.Provider>
    </Layout>
  );
}

export default EditNotification;
