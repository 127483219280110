import { createBrowserRouter } from "react-router-dom";
import ApprovedInformationTab from "../components/approved-information-tab/approvedInformationTab";
import { routesConfig } from "../config/routes";
import DashboardRootPage from "../layout/dashboard/DashboardRootPage";
import DashboardRootPageNoMenu from "../layout/dashboard/DashboardRootPageNoMenu";
import Page403 from "../pages/403";
import Page500 from "../pages/500";
import AutomaticMarkDetail from "../pages/automatic-mark-detail";
import AutoMarkAction from "../pages/automatic-mark-detail/auto-mark-action";
import ListExamStudent from "../pages/automatic-mark-detail/list-exam-studen";
import ManagementListScore from "../pages/automatic-mark-detail/management-table-scores";
import DetailScore from "../pages/automatic-mark-detail/management-table-scores/detail-score";
import RateAutoScore from "../pages/automatic-mark-detail/management-table-scores/rate-auto-scores";
import AddSurvyPage from "../pages/content-construction/add-survy/add-survy";
import ContentConstruction from "../pages/content-construction/content-construction-list";
import ContentCourseDetail from "../pages/content-construction/content-course-detail";
import ContentListCourse from "../pages/content-construction/content-list-course";
import AssignedDetailCourseConstruction from "../pages/course-construction/assigned-course-construction";
import CourseApprovalPage from "../pages/course-construction/course-browse/course-approval";
import AppraisedHistory from "../pages/course-construction/course-browse/course-approval/appraised-history";
import DetailCourseApproval from "../pages/course-construction/course-browse/course-approval/course-approval";
import CourseBrowserIndex from "../pages/course-construction/course-browse/course-browse";
import DetailCourseBrowse from "../pages/course-construction/course-browse/course-browse/course-browse";
import CourseEvaluation from "../pages/course-construction/course-browse/course-evaluation";
import DetailCourseEvaluation from "../pages/course-construction/course-browse/course-evaluation/course-evaluation";
import PublishCoursePage from "../pages/course-construction/course-browse/publish-course";
import DetailCoursePublish from "../pages/course-construction/course-browse/publish-course/course-approval";
import DetailCourseConstruction from "../pages/course-construction/detail-course-construction";
import HistoryBuildCourseConstruction from "../pages/course-construction/history-build-course-construction";
import HistoryCourseConstruction from "../pages/course-construction/history-course-construction";
import SurveyPlan from "../pages/course-construction/survey-plan";
import SurveyPlanDetail from "../pages/course-construction/survey-plan/survey-plan-detail/SurveyPlanDetail";
import ContentPreview from "../pages/course-preview/CoursePreview";
import DetailUser from "../pages/detail/Detail";
import ListTeacher from "../pages/list-teachers/ListTeachers";
import ListUser from "../pages/list-user/ListUser";
import Login from "../pages/login/Login";
import PageBuilderView from "../pages/page-builder/PageBuilderView";
import PortalAdministrationPage from "../pages/portar-adminstration";
import ManagementGroupContent from "../pages/prediction-model/management-group-content";
import SettingPrediction from "../pages/prediction-model/setting-prediction";
import InfoCourseList from "../pages/prediction-model/setting-prediction/list-info/info-course";
import InfoProfileStudent from "../pages/prediction-model/setting-prediction/list-info/info-profile-student";
import StudentAfterJoin from "../pages/prediction-model/setting-prediction/list-info/student-after-join";
import StudentBeforeJoin from "../pages/prediction-model/setting-prediction/list-info/student-before-join";
import StudentJoin from "../pages/prediction-model/setting-prediction/list-info/student-join";
import SettingDetailModel from "../pages/prediction-model/setting-prediction/setting-detail-model";
import PreviewQuiz from "../pages/preview-quiz";
import SettingSupervisionDetail from "../pages/setting-supervision-detail";
import MyCourseDetail from "../pages/study-management/course/my-course/my-course-detail";
import ReportCourseDocument from "../pages/study-management/course/my-course/report-course-document";
import SettingIdentify from "../pages/study-management/setting-identify";
import LearningDocumentationSystem from "../pages/system-suggest-course/learning-documentation-system";
import InfoCourseSystemDetail from "../pages/system-suggest-course/learning-documentation-system/info-course-detail";
import ForumBlockPageReview from "../components/blog-and-forum/design-theme-blog-content/ForumBlogPreview";
import AccountRegistrationReport from "../pages/account-registration-report";
import AdminInterFacePage from "../pages/admin-interface";
import DetailAdminInterface from "../pages/admin-interface/detail-admin-interface";
import LecturerManagementDetail from "../pages/category-management/lecturer-management/lecturer-management-detail/LecturerManagementDetail";
import LecturerManagement from "../pages/category-management/lecturer-management/LecturerManagement";

import StudentManagementDetail from "../pages/category-management/student-management/student-management-detail/StudentManagementDetail";
import StudentManagement from "../pages/category-management/student-management/StudentManagement";

import RegistrationClassPage from "../pages/content-construction/registration-class/registration-class";
import CourseManagement from "../pages/course-management";
import CourseList from "../pages/course-management/course-list/CourseList";

import ListAccountConfiguration from "../pages/account-configuration/ListAccountConfiguration";
import EnrollmentConfirmation from "../pages/enrollment-confirmation";
import MyNotificationPage from "../pages/my-notification-page";
import Notifications from "../pages/notifications";
import NotificationDetail from "../pages/notifications/notification-detail";
import EditNotification from "../pages/notifications/notification-detail/EditNotification";
import NotificationHistory from "../pages/notifications/notification-detail/History";
import UniversityPageBuilderViewCourseList from "../pages/portar-adminstration/course-list/UniversityPageViewer";
import DetailPortalAdministration from "../pages/portar-adminstration/detail-portal-administration";
import UniversityPageBuilderView from "../pages/portar-adminstration/general-introduction/UniversityPageViewer";
import PreviewIntroPageCourse from "../pages/portar-adminstration/list-program/setting-intro-page/PreviewIntroPage";
import SettingIntroPage from "../pages/portar-adminstration/list-program/setting-intro-page/SettingIntroPage";
import UniversityPageBuilderViewCourseListPage from "../pages/portar-adminstration/page-course-list/UniversityPageViewer";
import CourseScoringList from "../pages/scoring/course-list";
import ExerciseList from "../pages/scoring/exercise-list";
import ManagementScoreAuto from "../pages/scoring/management-scoring-auto";
import AutoMarkPermission from "../pages/scoring/management-scoring-auto/auto-mark-permission";
import ScoringDetail from "../pages/scoring/scoring-detail";
import ScoringList from "../pages/scoring/scoring-list";
import Sso from "../pages/sso";

import { AuthGuard } from "../components/authentication/auth-guard";
import DetailExam from "../components/details-exam";
import { Account, Profile, RoleManagement } from "../pages";
import LocationList from "../pages/enrollment-confirmation/locations";
import Transaction from "../pages/enrollment-confirmation/transaction";
import ListStudents from "../pages/list-student/ListStudents";
import ManagementParticipatePlatform from "../pages/management-participate-platform";
import StatisticalReportForMinistry from "../pages/statistical-report/for-university";

export const router = createBrowserRouter([
  {
    path: "/error/403",
    element: <Page403 />,
  },
  {
    path: "/sso",
    element: (
      <AuthGuard>
        <Sso />
      </AuthGuard>
    ),
  },
  {
    path: "/",
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: `${routesConfig.detail}/:id`,
        errorElement: <Page500 />,
        children: [{ index: true, element: <DetailUser /> }],
      },
      {
        path: routesConfig.account,
        errorElement: <Page500 />,
        children: [{ index: true, element: <Account /> }],
      },
      {
        path: routesConfig.profile,
        errorElement: <Page500 />,
        children: [{ index: true, element: <Profile /> }],
      },
      {
        path: `${routesConfig.roleManagement}`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <RoleManagement title="Danh sách vai trò" />,
            errorElement: <Page403 />,
          },
        ],
      },
      {
        path: `${routesConfig.portalAdministration}`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <PortalAdministrationPage title="Danh sách cổng thông tin" />
            ),
            errorElement: <Page403 />,
          },
          {
            path: `${routesConfig.detailPortalAdministration}/:id`,
            element: <DetailPortalAdministration />,
          },
        ],
      },
      {
        path: `${routesConfig.adminInterface}`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <AdminInterFacePage title="Thiết lập giao diện quản trị" />
            ),
            errorElement: <Page403 />,
          },
          {
            path: `${routesConfig.detailAdminInterface}`,
            element: <DetailAdminInterface />,
          },
        ],
      },
      {
        path: `${routesConfig.settingIntroCourse}/:id`,
        element: <SettingIntroPage />,
      },
    ],
  },
  {
    path: routesConfig.accountSystem,
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: routesConfig.schoolManagementParticipates,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ManagementParticipatePlatform title="Quản lý trường đại học đăng ký tham gia nền tảng" />
            ),
          },
        ],
      },
      {
        path: routesConfig.listUsers,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <ListUser title="Quản lý tài khoản Quản trị cơ sở" />,
          },
        ],
      },
      {
        path: routesConfig.listTeachers,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <ListTeacher title="Quản lý tài khoản giảng viên" />,
          },
        ],
      },

      {
        path: routesConfig.listStudents,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <ListStudents title="Quản lý tài khoản sinh viên" />,
          },
        ],
      },
      {
        path: routesConfig.accountRegistrationReport,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <AccountRegistrationReport title="Báo cáo đăng ký tài khoản sinh viên" />
            ),
          },
        ],
      },
      {
        path: routesConfig.accountConfiguration,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <ListAccountConfiguration title="Cấu hình tài khoản" />,
          },
        ],
      },
    ],
  },

  {
    path: routesConfig.courseConstruction,
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      // Xây dụng khoá học - phân công xây dựng khoá học
      {
        errorElement: <Page500 />,
        children: [
          {
            path: routesConfig.courseConstructionManagement,
            element: <ApprovedInformationTab />,
          },
        ],
      },

      {
        errorElement: <Page500 />,
        children: [
          {
            path: `${routesConfig.assignCourseConstruction}/${routesConfig.detailAssignCourseConstruction}/:id`,
            element: <DetailCourseConstruction />,
          },
          {
            path: `${routesConfig.assignCourseConstruction}/${routesConfig.detailAssignedCourseConstruction}/:id`,
            element: <AssignedDetailCourseConstruction />,
          },
          {
            path: `${routesConfig.assignCourseConstruction}/${routesConfig.historyCourseConstruction}`,
            element: <HistoryCourseConstruction />,
          },
        ],
      },

      // Xây dụng khoá học - xây dựng nội dung
      {
        errorElement: <Page500 />,
        children: [
          {
            path: routesConfig.contentConstruction,
            element: <ContentConstruction title="Danh sách khóa học" />,
          },
          {
            path: `${routesConfig.contentConstruction}/${routesConfig.contentCourseDetail}/:id`,
            element: <ContentCourseDetail />,
          },
          {
            path: `${routesConfig.contentConstruction}/${routesConfig.contentListCourse}/:id`,
            element: <ContentListCourse />,
          },
          {
            path: `${routesConfig.contentConstruction}/${routesConfig.contentCourseDetail}/:id/${routesConfig.addLesson}`,
            element: <AddSurvyPage />,
          },
          {
            path: `${routesConfig.contentConstruction}/${routesConfig.historyCourseConstruction}`,
            element: <HistoryBuildCourseConstruction />,
          },
          {
            path: `${routesConfig.contentConstruction}/${routesConfig.historyCourseConstruction}`,
            element: <HistoryBuildCourseConstruction />,
          },
          {
            path: `${routesConfig.contentConstruction}/${routesConfig.contentCourseDetail}/:id/${routesConfig.registrationClass}`,
            element: <RegistrationClassPage />,
          },
        ],
      },

      // Xây dụng khoá học - duyệt khoá học
      {
        errorElement: <Page500 />,
        children: [
          {
            path: `${routesConfig.courseBrowse}/${routesConfig.contentBrowse}`,
            element: <CourseBrowserIndex title="Danh sách duyệt khoá học" />,
          },
          {
            path: `${routesConfig.courseBrowse}/${routesConfig.courseEvaluation}`,
            element: <CourseEvaluation title="Danh sách thẩm định khoá học" />,
          },
          {
            path: `${routesConfig.courseBrowse}/${routesConfig.courseApproval}`,
            element: (
              <CourseApprovalPage title="Danh sách phê duyệt khoá học" />
            ),
          },
          {
            path: `${routesConfig.courseBrowse}/${routesConfig.publishCourse}`,
            element: <PublishCoursePage title="Danh sách xuất bản khoá học" />,
          },
          {
            path: `${routesConfig.courseBrowse}/${routesConfig.contentBrowse}/${routesConfig.detailCourseBrowse}/:id`,
            element: <DetailCourseBrowse />,
          },
          {
            path: `${routesConfig.courseBrowse}/${routesConfig.courseEvaluation}/${routesConfig.detailCourseEvaluation}/:id`,
            element: <DetailCourseEvaluation />,
          },
          {
            path: `${routesConfig.courseBrowse}/${routesConfig.courseApproval}/${routesConfig.detailCourseApproval}/:id`,
            element: <DetailCourseApproval />,
          },
          {
            path: `${routesConfig.courseBrowse}/${routesConfig.publishCourse}/${routesConfig.detailPublishCourse}/:id`,
            element: <DetailCoursePublish />,
          },
          {
            path: `${routesConfig.courseBrowse}/${routesConfig.courseEvaluation}/${routesConfig.appraisedHistory}/:id`,
            element: <AppraisedHistory />,
          },
          {
            path: `${routesConfig.courseBrowse}/${routesConfig.courseApproval}/${routesConfig.appraisedHistory}/:id`,
            element: <AppraisedHistory />,
          },
        ],
      },
      {
        errorElement: <Page500 />,
        children: [
          {
            path: routesConfig.surveyPlan,
            element: <SurveyPlan title="Danh sách kế hoạch khảo sát" />,
          },
          {
            path: `${routesConfig.surveyPlan}/survey-plan-detail/:id`,
            element: <SurveyPlanDetail />,
          },
          {
            path: `${routesConfig.surveyPlan}/survey-plan-detail/:id/${routesConfig.addSurvey}`,
            element: <AddSurvyPage isDisplaySetting={true} />,
          },
        ],
      },
    ],
  },
  // Quản lý danh mục
  {
    path: routesConfig.categoryManagement,
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: routesConfig.lecturerManagement,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <LecturerManagement title="Danh sách giảng viên" />,
          },
        ],
      },
      {
        path: `${routesConfig.lecturerManagement}/${routesConfig.lecturerManagementDetail}/:id`,
        errorElement: <Page500 />,
        element: <LecturerManagementDetail />,
      },
      {
        path: routesConfig.studentManagement,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <StudentManagement title="Danh sách sinh viên" />,
          },
        ],
      },
      {
        path: `${routesConfig.studentManagement}/${routesConfig.studentManagementDetail}/:id`,
        errorElement: <Page500 />,
        element: <StudentManagementDetail />,
      },
    ],
  },

  //Quản lý khóa học
  {
    path: "/management-study",
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: `${routesConfig.course}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <MyCourseDetail />,
          },
        ],
      },

      {
        path: `${routesConfig.reportCourseDocument}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <ReportCourseDocument />,
          },
        ],
      },
    ],
  },

  //Cài đặt giám sát thi cử
  {
    path: "/",
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: `${routesConfig.settingSupervisionDetail}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <SettingSupervisionDetail />,
          },
        ],
      },
    ],
  },
  //Chấm bài thi tự động
  {
    path: "/",
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: `setting-high-level/${routesConfig.settingIdentify}`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <SettingIdentify />,
          },
        ],
      },

      {
        path: `${routesConfig.automaticMarkDetail}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <AutomaticMarkDetail />,
          },
        ],
      },
      {
        path: `${routesConfig.autoMarkActionDetail}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <AutoMarkAction />,
          },
        ],
      },
      {
        path: `${routesConfig.autoMarkPermissionDetail}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <AutoMarkPermission />,
          },
        ],
      },
      {
        path: `${routesConfig.managementListScore}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <ManagementListScore />,
          },
        ],
      },
      {
        path: `${routesConfig.managementScoreDetail}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <DetailScore />,
          },
        ],
      },
      {
        path: `${routesConfig.rateAutoScore}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <RateAutoScore />,
          },
        ],
      },
    ],
  },
  //Hệ thống gợi khóa học
  {
    path: "/system-suggest-course",
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: routesConfig.learningDocumentationSystem,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <LearningDocumentationSystem />,
          },
        ],
      },
      {
        path: `${routesConfig.infoCourseDetailSystem}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <InfoCourseSystemDetail />,
          },
        ],
      },
    ],
  },

  // Mô hình dự đoán, phân tích xu hướng học tập
  {
    path: "/prediction-model",
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: routesConfig.managementGroupContent,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <ManagementGroupContent />,
          },
        ],
      },
      {
        path: routesConfig.settingPrediction,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <SettingPrediction />,
          },
        ],
      },
      {
        path: `${routesConfig.settingDetailModel}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <SettingDetailModel />,
          },
        ],
      },
      {
        path: `${routesConfig.viewSettingDetailModel}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <SettingDetailModel />,
          },
        ],
      },
      {
        path: `${routesConfig.profileStudentModel}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <InfoProfileStudent />,
          },
        ],
      },
      {
        path: `${routesConfig.infoCourseListModel}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <InfoCourseList />,
          },
        ],
      },
      {
        path: `${routesConfig.studentBeforeJoin}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <StudentBeforeJoin />,
          },
        ],
      },
      {
        path: `${routesConfig.studentAfterJoin}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <StudentAfterJoin />,
          },
        ],
      },
      {
        path: routesConfig.studentJoin,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <StudentJoin />,
          },
        ],
      },
    ],
  },
  {
    path: `${routesConfig.examStudentList}/:id`,
    element: <ListExamStudent />,
  },

  {
    path: routesConfig.login,
    element: <Login />,
  },

  {
    path: `${routesConfig.pageBuilderView}/:id`,
    element: <PageBuilderView />,
  },
  {
    path: `${routesConfig.universityPageBuilderView}/:id`,
    element: <UniversityPageBuilderView />,
  },
  {
    path: `${routesConfig.universityPageBuilderViewCourseList}/:id`,
    element: <UniversityPageBuilderViewCourseList />,
  },
  {
    path: `${routesConfig.universityPageBuilderViewCourseListPage}/:id`,
    element: <UniversityPageBuilderViewCourseListPage />,
  },
  {
    path: `${routesConfig.courseConstruction}/${routesConfig.contentConstruction}/${routesConfig.coursePreview}/:id`,
    element: <ContentPreview />,
  },

  {
    path: `${routesConfig.courseConstruction}/${routesConfig.previewPlan}`,
    element: <DashboardRootPageNoMenu />,
    errorElement: <Page500 />,
    children: [
      {
        index: true,
        element: <PreviewQuiz />,
      },
    ],
  },
  {
    path: `${routesConfig.blogAndForum}/${routesConfig.blogPreview}`,
    element: <DashboardRootPageNoMenu />,
    errorElement: <Page500 />,
    children: [
      {
        index: true,
        element: <ForumBlockPageReview />,
      },
    ],
  },

  {
    path: `${routesConfig.personalExamRepository}/${routesConfig.previewExam}`,
    element: <DashboardRootPageNoMenu />,
    errorElement: <Page500 />,
    children: [
      {
        index: true,
        // element: <PreviewExam />,
        element: <DetailExam />,
      },
    ],
  },

  {
    path: `${routesConfig.ministryContentModeration}/${routesConfig.previewExam}`,
    element: <DashboardRootPageNoMenu />,
    errorElement: <Page500 />,
    children: [
      {
        index: true,
        // element: <PreviewExam />,
        element: <DetailExam />,
      },
    ],
  },

  {
    path: `${routesConfig.universityContentModeration}/${routesConfig.previewExam}`,
    element: <DashboardRootPageNoMenu />,
    errorElement: <Page500 />,
    children: [
      {
        index: true,
        // element: <PreviewExam />,
        element: <DetailExam />,
      },
    ],
  },

  {
    path: `${routesConfig.settingIntroCourse}/${routesConfig.previewSettingIntroCourse}/:id`,
    element: <DashboardRootPageNoMenu />,
    errorElement: <Page500 />,
    children: [
      {
        index: true,
        element: <PreviewIntroPageCourse />,
      },
    ],
  },

  {
    path: routesConfig.scoringListCourse,
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        index: true,
        errorElement: <Page500 />,
        element: <CourseScoringList title="Danh sách khoá học" />,
      },
      {
        path: `${routesConfig.scoringListCourse}/:id`,
        errorElement: <Page500 />,
        element: <ExerciseList title="Danh sách bài tập" />,
      },
      {
        path: `${routesConfig.scoringListCourse}/:id/exercise`,
        errorElement: <Page500 />,
        element: <ScoringList title="Danh sách bài chấm" />,
      },
      {
        path: `${routesConfig.scoringListCourse}/${routesConfig.managementScoreAuto}/:id`,
        errorElement: <Page500 />,
        element: <ManagementScoreAuto />,
      },
    ],
  },
  {
    path: `${routesConfig.scoring}/:id`,
    element: <DashboardRootPageNoMenu />,
    errorElement: <Page500 />,
    children: [
      {
        index: true,
        element: <ScoringDetail />,
      },
    ],
  },
  {
    path: `${routesConfig.myNotification}`,
    element: <DashboardRootPageNoMenu />,
    errorElement: <Page500 />,
    children: [
      {
        index: true,
        element: <MyNotificationPage />,
      },
    ],
  },
  {
    path: routesConfig.notifications,
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        index: true,
        errorElement: <Page500 />,
        element: <Notifications title="Danh sách thông báo" />,
      },
      {
        path: `${routesConfig.notifications}/:id`,
        errorElement: <Page500 />,
        element: <NotificationDetail />,
      },
      {
        path: `${routesConfig.notifications}/:id/history`,
        errorElement: <Page500 />,
        element: <NotificationHistory />,
      },
      {
        path: `${routesConfig.notifications}/:id/edit`,
        errorElement: <Page500 />,
        element: <EditNotification />,
      },
    ],
  },
  {
    path: routesConfig.enrollmentConfirmation,
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        index: true,
        errorElement: <Page500 />,
        element: <EnrollmentConfirmation />,
      },
      {
        path: `${routesConfig.enrollmentConfirmation}/:id`,
        errorElement: <Page500 />,
        element: <Transaction />,
      },
    ],
  },
  {
    path: `${routesConfig.trainingLocations}`,
    errorElement: <Page500 />,
    element: <DashboardRootPage />,
    children: [
      {
        index: true,
        errorElement: <Page500 />,
        element: <LocationList />,
      },
    ],
  },
  {
    path: "/",
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: `${routesConfig.courseManagement}/${routesConfig.courseList}`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <CourseList title="Danh sách khoá học" />,
          },
          {
            path: `${routesConfig.courseManagement}/${routesConfig.courseList}/:id`,
            errorElement: <Page500 />,
            element: <CourseManagement />,
          },
        ],
      },
    ],
  },
  {
    path: "/",
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: `${routesConfig.statisticalReportForUniversity}`,
        errorElement: <Page500 />,
        index: true,
        element: (
          <StatisticalReportForMinistry title="Báo cáo thống kê dành cho trường" />
        ),
      },
    ],
  },
]);
