import { Menu, theme } from "antd";
import Sider from "antd/es/layout/Sider";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import { useDirty } from "../../contexts/DirtyProvider";
import { getDetailAdminInterface } from "../../service/admin-interface";
import { getPermission } from "../../service/common";
import { globalStore } from "../../stores/stores";
import { MenuItem } from "../../types/sidebar";
import "./sidebar.scss";

interface SidebarProps {
  selectedMenu: MenuItem[];
  collapsedContent: boolean;
  isHideSider: boolean;
}

const SiderLayout: React.FC<SidebarProps> = ({
  selectedMenu,
  collapsedContent,
  isHideSider,
}) => {
  const navigate = useNavigate();
  const { reloadAdminInterface } = globalStore();
  const { isDirty, setDirty } = useDirty();

  const location = useLocation();
  const pathName = location.pathname;
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const defaultOpenKeys = location.pathname.split("/").filter((x) => {
    return x !== "";
  });
  const defaultSelectedKeys = [
    `/` +
      `${defaultOpenKeys[0]}/` +
      `${!isEmpty(defaultOpenKeys[1]) ? defaultOpenKeys[1] : ""}`,
  ];
  const listOpenKeys = [`${defaultOpenKeys[0]}`, `${defaultOpenKeys[1]}`];

  const [loading, setLoading] = useState<boolean>(false);
  const [color, setColor] = useState<any>({});
  const [backgroundColor, setBackgroundColor] = useState<any>({});

  const getColor = async () => {
    setLoading(true);
    await getPermission().then((res) => {
      if (res.status === 200) {
        setLoading(false);
        setColor(res.data?.data?.color);
      }
    });
  };

  useEffect(() => {
    getColor();
  }, [navigate, reloadAdminInterface]);

  const getColorDetail = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id: any = urlParams.get("interfaceId");
    if (id) {
      await getDetailAdminInterface(id).then((res) => {
        if (res.status === 200) {
          setBackgroundColor(res.data?.data?.color?.menuColor);
        }
      });
    }
  };

  useEffect(() => {
    getColorDetail();
  }, [navigate, reloadAdminInterface]);

  return (
    <>
      {!isHideSider ? (
        <Sider
          breakpoint="lg"
          width={"100%"}
          style={{
            height: "100%",
            boxShadow: "unset",
            background: `${
              pathName.includes(routesConfig.detailAdminInterface)
                ? backgroundColor
                : !isEmpty(color) && !loading
                ? color?.menuColor
                : "#FFFFFF"
            }`,
          }}
          className="sider"
          collapsible={!isHideSider}
          collapsed={collapsedContent}
          trigger={null}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={defaultSelectedKeys}
            defaultOpenKeys={listOpenKeys}
            items={selectedMenu}
            className="menu-wrapper"
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              paddingBottom: "48px",
              height: "100%",
              background: `${
                pathName.includes(routesConfig.detailAdminInterface)
                  ? backgroundColor
                  : !isEmpty(color) && !loading
                  ? color?.menuColor
                  : "#FFFFFF"
              }`,
            }}
            onSelect={(info) => {}}
          />
        </Sider>
      ) : null}
    </>
  );
};

export default SiderLayout;
